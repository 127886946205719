import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "../style/InformationSide.scss";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import studentIcon from "../../../../assets/iconsStudentDashboard/studentHat_icon.png"
import TeoriLogoWhite from "../../../../assets/TeoriLogoHvid.png";
import { LAPTOP_VIEW_WIDTH } from '../../../../constants/viewConstants';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import toClass from "../../../../assets/toClass.png";
import { useHistory, useLocation } from "react-router";

export type IInformationSideProps = {
    header: string;
    underHeader: string;
    underHeaderExplainer: string;
    buttonText?: string;
    buttonAction?: () => void;
}

const InformationSide: React.FC<IInformationSideProps> = (props: IInformationSideProps) => {
    const { width, height } = useWindowDimensions();

    const history = useHistory(); 

    const [isLaptopView, setIsLaptopView] = useState<boolean>(
      LAPTOP_VIEW_WIDTH <= width
    );

   const handleClickToClass = () => {
        history.push("/skole");
    }
  
    useEffect(() => {
      setIsLaptopView(LAPTOP_VIEW_WIDTH <= width);
    }, [width, height]);
    if(isLaptopView){

        return (
            <div className='InformationSide'>
            <div className='backButton clickable' onClick={props.buttonAction}>
            <Button size={"small"}><ArrowBackRoundedIcon/></Button>
            <label className='clickable'>Til forsiden</label></div>
            <div className='content'>
                <div className='whiteLogo'><img src={TeoriLogoWhite}></img></div>
                <h3>{props.header}</h3>
                <hr className='line'></hr>
                <div className='underHeader'>
                    <img src={studentIcon}></img><h4>{props.underHeader}</h4>
                    <div className='underHeaderText'>
                        
                        <span className='spanText'>{props.underHeaderExplainer}</span>
                    </div>
                </div>
            </div>
            <div className='toClass'><Button onClick={handleClickToClass}><img src={toClass}></img></Button></div>
        </div>
    );
}else{
    return (
        <div className='InformationSide'>
        <div className='backButton clickable' onClick={props.buttonAction}>
        <Button size={"small"}><ArrowBackRoundedIcon/></Button>
        <label className='clickable'>Til forsiden</label></div>
        <div className='content'>
            <div className='whiteLogo'><img src={TeoriLogoWhite}></img></div>
            {/* <h3>{props.header}</h3> */}
            {/* <hr className='line'></hr> */}
            <div className='underHeader'>
                <img src={studentIcon}></img><h4>{props.underHeader}</h4>
                <div className='underHeaderText'>
                    
                    <span className='spanText'>{props.underHeaderExplainer}</span>
                </div>
            </div>
        </div>
        <div className='toClass'><Button onClick={handleClickToClass}><img src={toClass}></img></Button></div>
    </div>
);
}
}

export { InformationSide };