import { Button, Input, MenuItem, Select, TextField } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Quiz_Slide, useGetAllSlidesQuery } from "../../../../graphql/generated/graphql";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../translations/translationProvider";
import { AdminSlide } from "./AdminSlide";
import "./AdminSlideSearch.scss"

export type IAdminSlideSearchProps = {};

interface filterAttributes {
  language: number;
  slideType: number;
}

const AdminSlideSearch: React.FC<IAdminSlideSearchProps> = ({}) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [includeSlides, setincludeSlides] = useState("1");
  const [languageChosen, setlanguageChosen] = useState("1");

  const [filteredSlides, setfilteredSlides] = useState<any[]>();
  const [currentSearch, setcurrentSearch] = useState<string>();

  const [shownSlides, setshownSlides] = useState(30);

  const { data: slideData, loading: slideDataLoading } = useGetAllSlidesQuery({
    variables: {
      included_slides: Number(includeSlides),
      language_chosen: Number(languageChosen),
    },
  });

  useEffect(() => {
    if (slideData?.getAllSlides) {
      setfilteredSlides(slideData.getAllSlides);
      setshownSlides(30);
    }
  }, [slideData]);

  function findSlidesWithCommonString(searchString: string, slides: any[]) {
    const result: any[] = [];
  
    for (const slide of slides) {
      let score = 0;
  
      for (const prop in slide) {
        if (typeof slide[prop] === 'string' && slide[prop].toLowerCase().includes(searchString.toLowerCase())) {
          score++;
        } else if (Array.isArray(slide[prop])) {
          for (const item of slide[prop]) {
            if (typeof item === 'object' && item !== null) {
              for (const itemProp in item) {
                if (
                  typeof item[itemProp] === 'string' &&
                  item[itemProp].toLowerCase().includes(searchString.toLowerCase())
                ) {
                  score++;
                }
              }
            }
          }
        }
      }
  
      if (score > 0) {
        result.push({ slide, score });
      }
    }
  
    result.sort((a, b) => b.score - a.score);
    return result.map(entry => entry.slide);
  }

  useEffect(() => {
    if(currentSearch !== "" && currentSearch && filteredSlides && slideData?.getAllSlides){
      setfilteredSlides(findSlidesWithCommonString(currentSearch, slideData?.getAllSlides))
      setshownSlides(30);
    }else if(currentSearch === "" && slideData?.getAllSlides){
      setfilteredSlides(slideData?.getAllSlides);
    }
  }, [currentSearch]);

  const selects = (
    <div className="selects">
      <div className="includedSlides">
        <label>Type af slides</label>
        <Select
          value={includeSlides}
          onChange={(event) => {
            setincludeSlides(String(event.target.value));
          }}
        >
          <MenuItem value={"0"}>Ingen</MenuItem>
          <MenuItem value={"1"}>Elever</MenuItem>
          <MenuItem value={"2"}>Lærere</MenuItem>
          <MenuItem value={"3"}>Begge</MenuItem>
        </Select>
      </div>
      <div className="languageOfSlides">
        <label>Sprog i slides</label>
        <Select
          value={languageChosen}
          onChange={(event) => setlanguageChosen(String(event.target.value))}
        >
          <MenuItem value={"1"}>
            Dansk
          </MenuItem>
          <MenuItem value={"2"}>
            Engelsk
          </MenuItem>
          <MenuItem value={"3"}>
            Færøsk
          </MenuItem>
          <MenuItem value={"4"}>
            Polsk
          </MenuItem>
          <MenuItem value={"5"}>
            Arabisk
          </MenuItem>
        </Select>
      </div>
    </div>
  );


  const searchBox = (
    <div className="searchBox">
      <label>Søg</label>
      <TextField variant="outlined" value={currentSearch} onChange={e => setcurrentSearch(e.target.value)}/>
    </div>
  )

  const slideShow = (
    <div className="adminSlideshow">
      {filteredSlides
        ? filteredSlides.map((slide, index) => {
            return index <= shownSlides ? (
              <AdminSlide slide={slide!} key={slide!.quiz_slide_id}/>
            ) : null;
          })
        : null}
    </div>
  );

  const showMoreSlides = () => {
    setshownSlides(shownSlides + 30);
  }

  return (
    <div className="AdminSlideSearch">
      <div className="controls">
      {selects}
      {searchBox}
      <div className="hotinfo"><div className="amount">Antal slides i søgning: {filteredSlides?.length}</div></div>
      </div>
      {slideDataLoading ? <div className="loadingMore"><CircularProgress /></div> : null }
      {slideShow}
      {filteredSlides ? shownSlides < filteredSlides?.length ? <Button onClick={showMoreSlides} className="showMore">VIS FLERE</Button> : null : null}
    </div>
  );
};

export { AdminSlideSearch };
