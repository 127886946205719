import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useManageSchoolMutation } from '../../../../graphql/generated/graphql';
import "./AddSchoolBox.scss";
import CloseIcon from '@mui/icons-material/Close';
import IntermediaryLoading from '../../../../globalComponents/IntermediaryLoading';

export type IAddSchoolBoxProps = {
    setcreatingNewTeacher: React.Dispatch<React.SetStateAction<boolean>>
}

interface newSchool{
    school_name?: string;
    email_id?: string;
    language_choice?: number;
    vat_number?: number;
    contact_name?: string;
    optional_note?: string;
    password?: string;
    zipcode?: number;
    city?: string
}

const AddSchoolBox: React.FC<IAddSchoolBoxProps> = (props: IAddSchoolBoxProps) => {

    const [manageSchool] = useManageSchoolMutation();

    const [schoolValues, setschoolValues] = useState<newSchool>();
    const [isIntermediaryLoading, setisIntermediaryLoading] = useState(false);

    const handleClose = () => {
        props.setcreatingNewTeacher(false);
    }

    const handleSubmit = () => {
        setisIntermediaryLoading(true);
        if(schoolValues){
            schoolValues.language_choice = 1;
            manageSchool({variables: {school: schoolValues}}).then((data) => {
                if(data.data)
                handleClose();
            })
        }
    }

    const handleChange_school_name = (e) => {
        if(e){
            setschoolValues({...schoolValues, school_name: e.target.value})
        }
    }
    const handleChange_email_id = (e) => {
        if(e){
            setschoolValues({...schoolValues, email_id: e.target.value})
        }
    }
    const handleChange_language_choice = (e) => {
        if(e){
            setschoolValues({...schoolValues, language_choice: e.target.value})
        }
    }
    const handleChange_vat_number = (e) => {
        if(e){
            setschoolValues({...schoolValues, vat_number: Number(e.target.value)})
        }
    }
    const handleChange_contact_name = (e) => {
        if(e){
            setschoolValues({...schoolValues, contact_name: e.target.value})
        }
    }
    const handleChange_optional_note = (e) => {
        if(e){
            setschoolValues({...schoolValues, optional_note: e.target.value})
        }
    }
    const handleChange_password = (e) => {
        if(e){
            setschoolValues({...schoolValues, password: e.target.value})
        }
    }
    const handleChange_zipcode = (e) => {
        if(e){
            setschoolValues({...schoolValues, zipcode: Number(e.target.value)})
        }
    }
    const handleChange_city = (e) => {
        if(e){
            setschoolValues({...schoolValues, city: e.target.value})
        }
    }



    return (
        <div className='BGBOX'>
            {isIntermediaryLoading ? <IntermediaryLoading/> : null}
        <div className="AddSchoolBox">
            <div className='header'><h3>Tilføj en skole</h3><Button onClick={handleClose}><CloseIcon/></Button></div>      
            <div className='form'>
                <div>
                <label>Skole-navn</label>
                <input value={schoolValues?.school_name} onChange={handleChange_school_name}></input>
                </div>
                <div>
                <label>Email</label>
                <input value={schoolValues?.email_id} onChange={handleChange_email_id}></input>
                </div>
                <div>
                <label>Kodeord</label>
                <input value={schoolValues?.password} onChange={handleChange_password}></input>
                </div>
                <div>
                <label>VAT-nummber</label>
                <input value={schoolValues?.vat_number} type={"number"} onChange={handleChange_vat_number}></input>
                </div>{/* <input value={schoolValues?.language_choice} onChange={handleChange_language_choice}></input> */}
                <div>
                <label>ZIP</label>
                <input value={schoolValues?.zipcode} type={"number"} onChange={handleChange_zipcode}></input>
                </div>
                <div>
                <label>By</label>
                <input value={schoolValues?.city} onChange={handleChange_city}></input>
                </div>
                <div>
                <label>Kontakt</label>
                <input value={schoolValues?.contact_name} onChange={handleChange_contact_name}></input>
                </div>
                <div>
                <label>Note</label>
                <input value={schoolValues?.optional_note} onChange={handleChange_optional_note}></input>
                </div>
                <Button onClick={handleSubmit}>TILFØJ</Button>
            </div>      
        </div>
        </div>
    );
}

export { AddSchoolBox };