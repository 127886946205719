import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import { LessonSlide } from '../../../model';
import { AppStateContext } from '../../../../apollo/TeoriTeacherProvider';
import { PopupSlideContext } from '../../../../apollo/PopupSlideProvider';

export type ILessonPageBarPopupProps = {
}

const LessonPageBarPopup: React.FC<ILessonPageBarPopupProps> = (props: ILessonPageBarPopupProps) => {

    const { closePopup, openPopup, popupState, popupSlide } = useContext(PopupSlideContext);

    const [child, setchild] = useState<Window | null>();
    const currentPath = window.location.href.split("/skole");

    useEffect(() => {
        console.log(popupState);
        
        if(popupState === true){
            console.log("i attempt to open the popup");
            
            const tempchild = window.open(currentPath[0] + "/skole/notes",'popUpWindow','height=750,width=750,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
            setchild(tempchild);
        }
    }, [popupState])

    useEffect(() => {
        console.log(popupSlide);
        
        if(child){    
            child.postMessage({current: popupSlide})
        }
    }, [popupSlide]);

    useEffect(() => {
        if(child){
            console.log("i try to push to popup");
            var timer = setInterval(function() { 
                if(child.closed) {
                    clearInterval(timer);
                    closePopup();
                }
            }, 1000);
            console.log(popupSlide);
            
            setTimeout(() => child.postMessage({current: popupSlide}),2200)
            //child.postMessage(props.currentText)
        }
    }, [child]);

    // const sendSomething = () => {
    //      if(child){
    //             child.postMessage(props.currentText)
    //         }
    // }

    

    return (<div className='LessonPageBarPopUp'>
    </div>)
}

export { LessonPageBarPopup };