import React, { useState } from "react";
import { CreateQuizSlide, LessonSlide, MediaType } from "../../../model";
import expandIcon from "../../../../assets/expand_icon.png";
import deleteIcon from "../../../../assets/delete_icon.png";
import "./style/Item.scss";
import VideoThumbnail from "react-video-thumbnail";
import ReactTooltip from "react-tooltip";
import { availableSounds } from "../CreateQuizPage";

export type IContentItemProps = {
  slideOrderNumber: number;
  slide: CreateQuizSlide;
  handleSelectSlide: () => void;
  handleExpandSlide: () => void;
  handleDeleteSlide: () => void;
  introItem?: availableSounds;
};

const ContentItem: React.FC<IContentItemProps> = (props: IContentItemProps) => {
  const [thumbnail, setthumbnail] = useState<string | undefined>(undefined);
  const imageORvideo = () => {
    if (props.slide.media) {
      return <img className="mainMedia" src={props.slide.media}></img>;
    } else {
      return <div className="mediaThumb">MEDIA</div>;
    }
  };

  const renderToolTipContent = () => {
    if (props.slide.statements) {
      if (props.slide.statements.length !== 0) {
        const statements = props.slide.statements.map((statement) => {
          return (
            <div>
              {statement.id}. {statement.soundAndText.soundText}
            </div>
          );
        });

        return (
          <ReactTooltip id={"item" + props.slide.id}>
            <div>
              {props.introItem
                ? props.introItem?.label
                : "Intet intro spørgsmål valgt"}
            </div>
            {statements}
          </ReactTooltip>
        );
      }
    }
  };
  const renderFilledItem = () => {
    return (
      <div className="filledItem">
        <img
          src={deleteIcon}
          onClick={props.handleDeleteSlide}
          alt="icon"
          className="itemDelete"
        />
        <img
          src={expandIcon}
          onClick={props.handleExpandSlide}
          alt="icon"
          className="itemExpand"
        />
        {imageORvideo()}
      </div>
    );
  };

  return (
    <>
      <div
        className={"Item"}
        onClick={props.handleSelectSlide}
        data-tip
        data-for={"item" + props.slide.id}
      >
        <div className="itemNumber">{`${props.slideOrderNumber}.`}</div>
        <div
          className={
            props.slide.hasError ? "itemContent hasError" : "itemContent"
          }
        >
          {renderFilledItem()}
        </div>
      </div>{" "}
      {renderToolTipContent()}
    </>
  );
};

export { ContentItem };
