import React, { useEffect, useRef, useState } from "react";
import { VideoNav } from "../../../../globalComponents/VideoNav";
import { MediaType, VideoType } from "../../../model";
import ReactPlayer from 'react-player';


export type ILessonPageVideoProps = {
  key: string;
  video: string;
  videoType: VideoType;
  isLesson?:boolean;
  shouldMute?: boolean;
};

const LessonPageVideo: React.FC<ILessonPageVideoProps> = (
  props: ILessonPageVideoProps
) => {
  const [src, setSrc] = useState<string>(props.video);
  const videoRef = useRef<any>();
  const [isPlaying, setisPlaying] = useState(true);

  const handlePlay = () => {  
    if (props.videoType === VideoType.AZURE) {
      const player = amp("player");
      player.play();
    }else if(props.videoType === VideoType.LOCALVIDEO && videoRef.current instanceof HTMLVideoElement){
      videoRef.current.play();
    }else if(props.videoType === VideoType.STREAM && videoRef.current instanceof ReactPlayer){
      setisPlaying(true);
    }
  };

  const handlePause = () => {
    if (props.videoType === VideoType.AZURE) {
      const player = amp("player");
      player.pause();
    }else if(props.videoType === VideoType.LOCALVIDEO && videoRef.current instanceof HTMLVideoElement){
      videoRef.current.pause();
    }else if(props.videoType === VideoType.STREAM && videoRef.current instanceof ReactPlayer){
      setisPlaying(false);
      
    }
  };

  const createVideoPlayer = (amp) => {
    const video = amp(
      "player",
      {
        techOrder: [
          "azureHtml5JS",
          "flashSS",
          "html5FairPlayHLS",
          "silverlightSS",
          "html5",
        ],
        nativeControlsForTouch: false,
        controls: false,
        logo: { enabled: false },
        width: "100%",
        heigth: "100%",
      },
      function () {
        console.log("Good to go!");
        // add an event listener
        video.addEventListener("ended", function () {
          console.log("Finished!");
          video.currentTime(video.duration());
        });
      }
    );

    video.addEventListener(amp.eventName.error, (errorDetails) => {
      console.log(errorDetails);
    });
    return video;
  };
  const waitForAmp = () => {
    return new Promise((resolve, reject) => {
      let waited = 0;
      const wait = (interval) => {
        setTimeout(() => {
          waited += interval;
          const amp = window["amp"];
          if (amp !== undefined) {
            console.log("play resolved");
            return resolve(amp);
          }
          if (waited >= 3000 * 100) {
            console.log("play failed");
            return reject();
          }
          wait(interval * 2);
          return null;
        }, interval);
      };
      wait(30);
    });
  };

  const renderRenderer = () => {
    waitForAmp()
      .then((amp) => {
        const player = createVideoPlayer(amp);
        player.src([{ src: src, type: "application/vnd.ms-sstr+xml" }]);
      })
      .catch((e) =>
        console.error("Could not found Azure Media Player plugin", e)
      );
  };

  const renderPlayer = () => {
    if (props.videoType === "Azure") {
      renderRenderer();
    } else if (props.videoType === "Youtube") {
    }
  };

  useEffect(() => {
    setSrc(props.video);
    if (props.videoType === "Azure") {
      renderRenderer();

      return () => {
        const player = amp("player");
        player.dispose();
      };
    }
  }, [props.video]);

  if (props.videoType === "Azure") {
    return (
      <>
        <video
          id="player"
          className=" azuremediaplayer amp-default-skin azurePlayer"
          playsInline={true}
          controls={false}
          ref={videoRef}
        >
          <source src={src} type="application/vnd.ms-sstr+xml" />
          <source src={src} type=".mp4" />
        </video>
        <VideoNav
          hasVideoEnded={false}
          handlePlayClick={handlePlay}
          handlePauseClick={handlePause}
        />
      </>
    );
  } else if (props.videoType === "Youtube") {
    return <video></video>;
  } else if (props.videoType === VideoType.LOCALVIDEO) {
    return (
      <>
      <video controls={false}  ref={videoRef}>
        <source src={src}/>
      </video>
      <VideoNav hasVideoEnded={false} handlePlayClick={handlePlay} handlePauseClick={handlePause}/>
      </>
    );
  }else if(props.videoType === VideoType.STREAM){
    return(
      <>
        <ReactPlayer url={props.video} controls={props.isLesson ? true : false} muted={props.shouldMute}  ref={videoRef} playing={isPlaying}/>
        {props.isLesson ? null : <VideoNav hasVideoEnded={false} handlePlayClick={handlePlay} handlePauseClick={handlePause} isPlaying={isPlaying}/>}
      </>
    )
  } 
  else {
    return <span>"WHOOPS SOMETHING WENT WRONG"</span>;
  }
};

export { LessonPageVideo };
