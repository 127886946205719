import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { getNumberOrEmptyStringFromEventTarget } from '../../../../services/utilFunctions';
import { getStringsForChosenLanguage, getChosenLanguage } from '../../../../translations/translationProvider';
import "./style/SlideSwapper.scss"

export type ISlideSwapperProps = {
    swapSlides: (firstSlideIndex: number, secondSlideIndex: number) => void;
}

const SlideSwapper: React.FC<ISlideSwapperProps> = (props: ISlideSwapperProps) => {
    const languageString = getStringsForChosenLanguage(getChosenLanguage());

	const [firstSlideIndex, setFirstSlideIndex] = useState<number | string>("");
	const [secondSlideIndex, setSecondSlideIndex] = useState<number | string>("");

	const handleChangeFirstSlideIndex = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setFirstSlideIndex(getNumberOrEmptyStringFromEventTarget(event.target));
	};

	const handleChangeSecondSlideIndex = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setSecondSlideIndex(getNumberOrEmptyStringFromEventTarget(event.target));
	};

	const handleSwapSlides = (): void => {
		if (typeof firstSlideIndex === "number" && typeof secondSlideIndex === "number") {
			props.swapSlides(firstSlideIndex, secondSlideIndex);
			setFirstSlideIndex("");
			setSecondSlideIndex("");
		}
	};

	const renderIntegerInput = (
		value: number | string,
		handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
	): JSX.Element => {
		return <TextField className="slideSwapperInput" type="number" inputProps={{min: 1, max: 25, step: 1}} value={value} onChange={handleChange} />;
	};

	return (
		<div className="SlideSwapper">
			<div>{languageString.createLessonPage.movePicture}</div>
			{renderIntegerInput(firstSlideIndex, handleChangeFirstSlideIndex)}
			<div>{languageString.createLessonPage.withPicture}</div>
			{renderIntegerInput(secondSlideIndex, handleChangeSecondSlideIndex)}
			<Button variant="outlined" className="swapButton" onClick={handleSwapSlides}>
				{languageString.createLessonPage.movePicture}
			</Button>
		</div>
	);
};

export { SlideSwapper };