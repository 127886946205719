import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { LAPTOP_VIEW_WIDTH } from "../../../constants/viewConstants";
import { A2HSComponent } from "../../../globalComponents/A2HSComponent";
import IntermediaryLoading from "../../../globalComponents/IntermediaryLoading";
import { TeoriLogo } from "../../../globalComponents/TeoriLogo";
import { useLoginStudentMutation } from "../../../graphql/generated/graphqlStudent";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
  setLanguage,
} from "../../../translations/translationProvider";
import { LanguageType } from "../../../translations/translationTypes";
import { LoginStep } from "../../model";
import { AccessCode } from "./components/AccessCode";
import AccessExpired from "./components/AccessExpired";
import { Confirmation } from "./components/Confirmation";
import { ConfirmationMobileAccess } from "./components/ConfirmationMobileAccess";
import { CreatePin } from "./components/CreatePin";
import { FirstLogin } from "./components/FirstLogin";
import { InformationSide } from "./components/InformationSide";
import { Initial } from "./components/Initial";
import "./LoginPage.scss";

export type ILoginPageProps = {};

const LoginPage: React.FC<ILoginPageProps> = ({}) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [loginStep, setLoginStep] = useState<LoginStep>(LoginStep.Initial);
  const [IsLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();

  const [forcedRerender, setforcedRerender] = useState(false);

  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [PINCode, setPINCode] = useState<string>("");

  /* For ConfirmationPage */
  const [confirmationCode, setConfirmationCode] = useState("");

  const [preFix, setpreFix] = useState("+45");

  const handleChangePrefix = (value: string) => {
    if(value.length < 5){
      if(value.length > 0 && value[0] === "+"){
        setpreFix(value);
      }
    }
  }

  const { width, height } = useWindowDimensions();

  const [isLaptopView, setIsLaptopView] = useState<boolean>(
    LAPTOP_VIEW_WIDTH <= width
  );

  const [isAccessCode, setisAccessCode] = useState(false);

  useEffect(() => {
    setIsLaptopView(LAPTOP_VIEW_WIDTH <= width);
  }, [width, height]);

  const script = document.createElement("script");
  const script2 = document.createElement("script");

  /*useEffect(() => {
    if (isLaptopView) {
      const chatBot = document.getElementById("LeadboosterContainer");
      if (!chatBot) {

        script2.text =
          "window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 7803695,playbookUuid: '61d6f774-c7b6-4f7a-ada0-589d225c8fa6',version: 2};(function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();";
        script.src = "https://leadbooster-chat.pipedrive.com/assets/loader.js";
        script.async = true;

        document.body.appendChild(script2);
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
          document.body.removeChild(script2);
        };
      }
    }

  }, [isLaptopView]);
*/

useEffect(() => {
  if (isLaptopView) {
    const chatBot = document.getElementById("ChatbaseContainer");
    if (!chatBot) {
      const scriptConfig = document.createElement("script");
      const scriptLoader = document.createElement("script");

      scriptConfig.text = `
        window.embeddedChatbotConfig = {
          chatbotId: "F5cWisrpnFyentotu3VCK",
          domain: "www.chatbase.co"
        };
      `;

      scriptLoader.src = "https://www.chatbase.co/embed.min.js";
      scriptLoader.setAttribute("chatbotId", "F5cWisrpnFyentotu3VCK");
      scriptLoader.setAttribute("domain", "www.chatbase.co");
      scriptLoader.defer = true;

      document.body.appendChild(scriptConfig);
      document.body.appendChild(scriptLoader);

      // Cleanup on unmount
      return () => {
        document.body.removeChild(scriptConfig);
        document.body.removeChild(scriptLoader);
      };
    }
  }
}, [isLaptopView]);

  const removeLoginSupport = () => {
    try{
        console.log("i actually try ");
        const chatbox = document.getElementById("LeadboosterContainer");
        if(chatbox){
          chatbox.remove();
        }
    }catch(e){
    }
  }

  const handleLanguageClick = () => {
    if (getChosenLanguage() === LanguageType.Danish) {
      setLanguage(LanguageType.English);
    } else {
      setLanguage(LanguageType.Danish);
    }
    setforcedRerender(!forcedRerender);
  };

  const handleRegisterButton = () => {
    setLoginStep(LoginStep.FirstLogin);
  };

  const handleActivationCode = () => {
    setisAccessCode(true);
    setLoginStep(LoginStep.ConfirmMobileAccess);
  }

  const renderLoginState = (): JSX.Element => {
    switch (loginStep) {
      case LoginStep.Initial: {
        return (
          <Initial
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            setLoginStep={setLoginStep}
            setPIN={setPINCode}
            PIN={PINCode}
            setIsLoading={setIsLoading}
            buttonText={
              languageString.studentLoginPage.InformationSide.Initial.buttonText
            }
            preFix={preFix}
            setpreFix={handleChangePrefix}
            buttonAction={handleRegisterButton}
            handleActivationClick={handleActivationCode}
            handleRemoveSupport={removeLoginSupport}
          />
        );
      }
      case LoginStep.FirstLogin: {
       return (
          <FirstLogin
            setLoginStep={setLoginStep}
            mobileNumber={mobileNumber}
            preFix={preFix}
            setpreFix={handleChangePrefix}
            setConfirmationCode={setConfirmationCode}
            setIsLoading={setIsLoading}
            setisAccessCode={setisAccessCode}
          />
        );
      }  
      case LoginStep.FirstLoginAccessCode: {
        return (
          <AccessCode
            setLoginStep={setLoginStep}
            setMobileNumber={setMobileNumber}
            mobileNumber={mobileNumber}
            setIsLoading={setIsLoading}
            setpreFix={handleChangePrefix}
            preFix={preFix}
          />
        );
      }
      case LoginStep.Confirmation: {
        return (
          <Confirmation
            setLoginStep={setLoginStep}
            setMobileNumber={setMobileNumber}
            mobileNumber={mobileNumber}
            confirmationCode={confirmationCode}
            isAccessCode={isAccessCode}
            preFix={preFix}
            setpreFix={handleChangePrefix}
          />
        );
      }
      case LoginStep.CreatePin: {
        return (
          <CreatePin
            setLoginStep={setLoginStep}
            mobileNumber={mobileNumber}
            setIsLoading={setIsLoading}
            setPINCode={setPINCode}
            preFix={preFix}
            setpreFix={handleChangePrefix}
          />
        );
      }
      case LoginStep.AccessExpired: {
        return <AccessExpired />;
      }
      case LoginStep.ConfirmMobileAccess: {
        return (
          <ConfirmationMobileAccess
            setLoginStep={setLoginStep}
            setMobileNumber={setMobileNumber}
            mobileNumber={mobileNumber}
            confirmationMobileAccessCode={confirmationCode}
            isAccessCode={isAccessCode}
            preFix={preFix}
            setpreFix={handleChangePrefix}
          />
        );
      }
      default: {
        return (
          <Initial
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            setLoginStep={setLoginStep}
            setPIN={setPINCode}
            PIN={PINCode}
            setIsLoading={setIsLoading}
            buttonText={
              languageString.studentLoginPage.InformationSide.Initial.buttonText
            }
            buttonAction={handleRegisterButton}
            preFix={preFix}
            setpreFix={handleChangePrefix}
            handleActivationClick={handleActivationCode}
            handleRemoveSupport={removeLoginSupport}
          />
        );
      }
    }
  };

 /*  useEffect(() => {
    if (!localStorage.getItem("TEORIFIRSTTIME")) {
      setLoginStep(LoginStep.FirstLogin);
    } else {
    }
  }, []);
 */
  const renderInformationSide = () => {
    switch (loginStep) {
      case LoginStep.Initial: {
        return (
          <InformationSide
            header={languageString.studentLoginPage.InformationSide.header}
            underHeader={
              languageString.studentLoginPage.InformationSide.Initial
                .underHeader
            }
            underHeaderExplainer={
              languageString.studentLoginPage.InformationSide.Initial
                .underHeaderExplainer
            }
            buttonAction={handleTeoriLogoClick}
          />
        );
      }
      case LoginStep.FirstLogin: {
        return (
          <InformationSide
            header={languageString.studentLoginPage.InformationSide.header}
            underHeader={
              languageString.studentLoginPage.InformationSide.FirstLogin
                .underHeader
            }
            underHeaderExplainer={
              languageString.studentLoginPage.InformationSide.FirstLogin
                .underHeaderExplainer
            }
            buttonAction={backToStart}
          />
        );
      }
      case LoginStep.FirstLoginAccessCode: {
        return (
          <InformationSide
            header={languageString.studentLoginPage.InformationSide.header}
            underHeader={
              languageString.studentLoginPage.InformationSide.AccessCode
                .underHeader
            }
            underHeaderExplainer={
              languageString.studentLoginPage.InformationSide.AccessCode
                .underHeaderExplainer
            }
            buttonAction={backToStart}
          />
        );
      }
      case LoginStep.Confirmation: {
        return (
          <InformationSide
            header={languageString.studentLoginPage.InformationSide.header}
            underHeader={
              languageString.studentLoginPage.InformationSide.Confirmation
                .underHeader
            }
            underHeaderExplainer={
              languageString.studentLoginPage.InformationSide.Confirmation
                .underHeaderExplainer
            }
            buttonAction={backToStart}
          />
        );
      }
      case LoginStep.CreatePin: {
        return (
          <InformationSide
            header={languageString.studentLoginPage.InformationSide.header}
            underHeader={
              languageString.studentLoginPage.InformationSide.CreatePIN
                .underHeader
            }
            underHeaderExplainer={
              languageString.studentLoginPage.InformationSide.CreatePIN
                .underHeaderExplainer
            }
            buttonAction={backToStart}
          />
        );
      }
      case LoginStep.ConfirmMobileAccess: {
        return (
          <InformationSide
            header={languageString.studentLoginPage.InformationSide.header}
            underHeader={
              languageString.studentLoginPage.InformationSide.Confirmation
                .underHeader
            }
            underHeaderExplainer={
              languageString.studentLoginPage.InformationSide.Confirmation
                .underHeaderExplainer
            }
            buttonAction={backToStart}
          />
        );
      }
      default: {
        return (
          <InformationSide
            header={languageString.studentLoginPage.InformationSide.header}
            underHeader={
              languageString.studentLoginPage.InformationSide.Initial
                .underHeader
            }
            underHeaderExplainer={
              languageString.studentLoginPage.InformationSide.Initial
                .underHeaderExplainer
            }
            buttonText={
              languageString.studentLoginPage.InformationSide.Initial.buttonText
            }
            buttonAction={handleTeoriLogoClick}
          />
        );
      }
    }
  };

  const handleTeoriLogoClick = () => {
    window.location.href = "https://www.teoriundervisning.dk/";
  };
  const backToStart = () => {
    setLoginStep(LoginStep.Initial);
  };
  return (
    <div className="studentLogin">
      {renderInformationSide()}
      <div className="logoAndPages">
        <div className="languageLink" onClick={handleLanguageClick}>
          {getChosenLanguage() === LanguageType.English ? "Dansk" : "English"}
        </div>
        <TeoriLogo studentPage={true} onClick={handleTeoriLogoClick} />
        <div className="interactionPages">
          <TransitionGroup className={"transitionGroup"}>
            <CSSTransition
              key={loginStep}
              classNames="studentLoginTransition"
              timeout={300}
            >
              {renderLoginState()}
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
      {IsLoading ? <IntermediaryLoading /> : null}
    </div>
  );
};

export default LoginPage;
