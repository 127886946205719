import { Autocomplete, Button, createFilterOptions, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getChosenLanguage,
  getStringsForChosenLanguage,
} from "../../../../translations/translationProvider";
import { CreateQuizStatement, CreateQuizStatementElaboration, CreateQuizStatementSound } from "../../../model";
import ClearIcon from "@mui/icons-material/Clear";
import "./style/Statement.scss";
import { findById, findIndexById } from "../../../modelFunctions";

export type IStatementProps = {
  key: number;
  narrationSounds: availableSounds[];
  statement: CreateQuizStatement;
  setStatements: React.Dispatch<React.SetStateAction<CreateQuizStatement[]>>;
  deleteStatement: (id: number, apiID?: string) => void;
  length: number;
};
interface availableSounds {
  key: string;
  id: number;
  label: string;
}

const Statement: React.FC<IStatementProps> = (props: IStatementProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  const [chosenSound, setchosenSound] = useState<availableSounds>();
  const [elab, setelab] = useState<CreateQuizStatementElaboration[]>(props.statement.elaboration);
  const [answer, setanswer] = useState(-1);

  const [inputValue, setinputValue] = useState("");

  const [elabLanguage, setelabLanguage] = useState(0);

  const [isSoundSelected, setisSoundSelected] = useState(false);
  

  const setCurrentStatement = (statement: CreateQuizStatement) => {
    props.setStatements((prevState) => {
      const elementIndex = findIndexById(prevState, props.statement.id);
      const copyOfState = [...prevState];
      copyOfState[elementIndex] = statement;
      return copyOfState;
    });
  };

  const handleSoundChange = (event, newValue) => {
    if (newValue) {
      setisSoundSelected(true);
      setchosenSound(newValue);
    }
  };

  const handleElabChange = (event) => {
    const elabs = [...elab];
      elabs[elabLanguage].text = event.target.value;
    setelab(elabs);
  };

  const handleAnswerChange = (event) => {
    if (event.target.value) {
      setanswer(Number(event.target.value));
    }
  };

  const handleElabLanguageChange = (event) => {
    if(event.target.value){
      setelabLanguage(event.target.value)
    }
  }

  useEffect(() => {
    if (props.statement.soundAndText.id !== -1) {
      setisSoundSelected(true);
    } else {
      setisSoundSelected(false);
    }
  }, [props.statement]);

  useEffect(() => {
    setchosenSound({
      id: props.statement.soundAndText.id,
      label: props.statement.soundAndText.soundText,
      key: String(props.statement.soundAndText.id),
    });
    setelab(props.statement.elaboration);
    setanswer(props.statement.answer);
  }, [props.length]);

  const renderAnswersSelect = (): JSX.Element => {
    const answerOptions = [
      { value: -1 , label: " "},
      { value: 1, label: languageString.generalMessages.yes },
      { value: 2, label: languageString.generalMessages.no },
      { value: 3, label: languageString.generalMessages.both },
    ];
    const options = answerOptions.map((option) => {
      return <option value={option.value}>{option.label}</option>;
    });
    return (
      <select onChange={handleAnswerChange} value={answer}>
        {options}
      </select>
    );
  };

  const renderElabLanguageSelect = ():JSX.Element =>  {
    const answerOptions = [
      { value: 0, label : "Dansk"},
      { value: 1, label: "Engelsk"}
    ]
    const options = answerOptions.map((option) => {
      return <option value={option.value}>{option.label}</option>;
    });
    return (
      <div className="answerOptions languageSelect">
        <label>Rediger uddybelsens sprog på </label>
      <select onChange={handleElabLanguageChange} value={elabLanguage}>
        {options}
      </select></div>
    )
  }

  useEffect(() => {
    if (chosenSound) {
        console.log("execution");
        
      const newStatement: CreateQuizStatement = {
        originalIndex: props.statement.originalIndex,
        id: props.statement.id,
        editID: props.statement.editID ? props.statement.editID : undefined,
        soundAndText: {
          id: chosenSound.id,
          soundText: chosenSound.label,
          soundLocation: "",
        },
        answer: answer,
        elaboration: elab,
      };
      setCurrentStatement(newStatement);
    }
  }, [elab, answer, chosenSound]);


  if (!isSoundSelected) {
    return (
      <div className={props.statement.hasError ? "SoundSelection hasError" : "SoundSelection"}>
        <div className="top">
          <span>{props.statement.id}.</span>
          <div className="deleteButton">
            <Button onClick={() => props.deleteStatement(props.statement.id)}>
              <ClearIcon />
            </Button>
          </div>
        </div>

        <h2>Søg efter en lyd og vælg den</h2>
        <Autocomplete
          className="soundAutocomplete"
          value={chosenSound}
          onChange={handleSoundChange}
          onInputChange={(event, newInputValue) => setinputValue(newInputValue)}
          inputValue={inputValue}
          options={props.narrationSounds}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
    );
  }

  return (
    <div className={props.statement.hasError ? "ActiveStatement hasError" : "ActiveStatement"}>
      <div className="displayer">
        <div className="soundText">
          <div>{props.statement.id}.</div>
          <div>{chosenSound ? chosenSound?.label : "Ingen lyd valgt"}</div>
          <div className="deleteButton">
            <Button onClick={() => props.deleteStatement(props.statement.id, String(props.statement.editID))}>
              <ClearIcon />
            </Button>
          </div>
        </div>
      </div>
      {renderElabLanguageSelect()}
      <TextField
        className="elaboration"
        multiline
        label={"Uddybelse"}
        variant="outlined"
        rows={4}
        value={elab[elabLanguage].text}
        onChange={handleElabChange}
      />
      <div className="answerOptions">
        <label>Vælg svaret til spørgsmålet</label>
        {renderAnswersSelect()}
      </div>
    </div>
  );
};

export { Statement };
