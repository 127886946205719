import React, { useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import {
  Slide_Progress,
  Statement_Grade,
  useFinishClassOnlineQuizMutation,
  useGradeSlideMutation,
} from "../../../../../graphql/generated/graphqlStudent";
import {
  TeacherStudentSlide as TeacherStudentSlideAlias,
  TeacherStudentStatement,
} from "../../../../model";
import { TeacherStudentGraduation } from "./TeacherStudentGraduation";
import { TeacherStudentSlide } from "./TeacherStudentSlide";

export type ITeacherStudentQuizProps = {
  quizState: TeacherStudentSlideAlias[];
  setQuizState: any;
  socket: Socket;
  finishQuizNow(): void;
};

const TeacherStudentQuiz: React.FC<ITeacherStudentQuizProps> = (
  props: ITeacherStudentQuizProps
) => {
  const [currentCount, setcurrentCount] = useState(0);
  const [isQuizDone, setisQuizDone] = useState(false);
  const [gradeSlide] = useGradeSlideMutation();

  const handleSetAnswer = (statementNumber: number, answer: number) => {
    props.setQuizState((prevState) => {
      const copyState = prevState;
      if (copyState) {




        copyState[currentCount].statements[statementNumber].answer = answer;
        return copyState;
      }
    });
  };

  function gradeSlidetoAPI(slide: TeacherStudentSlideAlias) {
    let gradedSlide: Slide_Progress;
    const gradedStatements: Statement_Grade[] = [];
    for (const statement of slide.statements) {
      if (statement.answer) {
        if (statement.correctAnswer === 3) {
          gradedStatements.push({
            statement_progress_id: String(statement.progressID),
            slide_pass_status: true,
            statement_progress_answer: statement.answer,
          });
        } else {
          if (statement.correctAnswer === statement.answer) {
            gradedStatements.push({
              statement_progress_id: String(statement.progressID),
              slide_pass_status: true,
              statement_progress_answer: statement.answer,
            });
          } else {
            gradedStatements.push({
              statement_progress_id: String(statement.progressID),
              slide_pass_status: false,
              statement_progress_answer: statement.answer,
            });
          }
        }
      } else {
        gradedStatements.push({
          statement_progress_id: String(statement.progressID),
          slide_pass_status: false,
          statement_progress_answer: statement.answer,
        });
      }
    }
    gradedSlide = {
      progress_id: String(slide.id),
      statement_progress: gradedStatements,
    };
    return gradedSlide;
  }

  function sendSlideOrEnd(slide: TeacherStudentSlideAlias) {
   

    if (props.quizState.length - 1 <= currentCount) {
      gradeSlide({ variables: { gradedSlide: gradeSlidetoAPI(slide) } }).then(
        () => {
          props.finishQuizNow();
        }
      );
    } else {
      gradeSlide({ variables: { gradedSlide: gradeSlidetoAPI(slide) } });
    }
  }

  useEffect(() => {
    //IN HERE WE WILL LISTEN TO THE QUIZ, AND WHEN THE SLIDE CHANGES THROUGH A SOCKET
    props.socket.on("slide-move", (data) => {
      if(Number(data) > currentCount){
        setcurrentCount(Number(data));
      }
    });
  }, []);

  return (
    <div className="TeacherStudentQuiz">
      <TeacherStudentSlide
        length={props.quizState.length}
        current={currentCount + 1}
        key={currentCount}
        slide={props.quizState[currentCount]}
        handleSetAnswer={handleSetAnswer}
        sendSlideOrEnd={sendSlideOrEnd}
      />
    </div>
  );
};

export { TeacherStudentQuiz };
