import React, { useState } from 'react';
import Calendar from 'react-calendar';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import 'react-calendar/dist/Calendar.css';
import { Button } from '@mui/material';
import "./DatePicker.scss";

export type IDatePickerProps = {
    value: string;
    onChange: any;
}

const DatePicker: React.FC<IDatePickerProps> = (props: IDatePickerProps) => {

    const [isExpanded, setisExpanded] = useState(false);

    const handleChangeCalendar = (value): void => {
        const date: Date = value;
        console.log(date.toDateString());
        props.onChange(date.getTime() / 1000);
        setisExpanded(false)
      };


    const renderCalendar = () => {
        return (
          <div className="calendarBox">
            <Calendar
              value={new Date(Number(props.value) * 1000)}
              onChange={handleChangeCalendar}
            />
          </div>
        );
      };
    return (
        <div className='DatePicker'>
            {isExpanded ? 
            renderCalendar()
             : <Button onClick={() => setisExpanded(true)} className="DateButton"><CalendarTodayRoundedIcon/></Button>}
        </div>
    );
}

export { DatePicker };