import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppStateContext } from "../../../apollo/TeoriTeacherProvider";
import { PATH_DASHBOARD } from "../../../constants/pathConstants";
import IntermediaryLoading from "../../../globalComponents/IntermediaryLoading";
import Loading from "../../../globalComponents/Loading";
import {
  Quiz_Edit,
  Quiz_Input,
  Quiz_Slide_Input,
  Slide_Explanation_Input,
  Statement,
  Statement_Elaboration_Input,
  Tag_Input,
  useGetAvailableTagsQuery,
  useGetCompleteQuizEditQuery,
  useGetSasQuery,
  useGetSoundContentQuery,
  useManageQuizMutation,
} from "../../../graphql/generated/graphql";
import { uploadExplanationSound } from "../../../services/azureStorage";
import {
  CreateQuizExplanation,
  CreateQuizSlide,
  CreateQuizStatement,
  CreateQuizStatementElaboration,
  MediaType,
  Tag,
} from "../../model";
import { numberToMediaType } from "../../modelFunctions";
import { CreateQuizContent } from "./CreateQuizContent";
import "./CreateQuizPage.scss";

interface location {
  isEdit?: boolean;
  ID: number;
}
export interface availableSounds {
  key: string;
  id: number;
  label: string;
}

export type ICreateQuizPageProps = {};

const CreateQuizPage: React.FC<ICreateQuizPageProps> = ({}) => {
  const location = useLocation<location>();
  const [QuizState, setQuizState] = useState<CreateQuizSlide[]>([]);
  const [name, setname] = useState<string>("Starter name");
  const [quizID, setquizID] = useState<number | undefined>();
  const [tags, setTags] = useState<Tag[]>([{ id: -1, name: "Loading" }]);
  const [editLoader, seteditLoader] = useState(location.state ? true : false);
  const [isEdit, setisEdit] = useState(location.state ? true : false);

  const context = useContext(AppStateContext);
  const history = useHistory();

  const [narrationSounds, setnarrationSounds] = useState<availableSounds[]>([]);

  const { data: soundData, loading: soundLoading, refetch: refetchSound } = useGetSoundContentQuery();

  const { data: SASKey } = useGetSasQuery({ fetchPolicy: "network-only" });

  const [manageQuiz] = useManageQuizMutation();
  const { data: tagsData, loading: tagsLoading } = useGetAvailableTagsQuery();
  const {
    data: quizData,
    loading: quizLoading,
    refetch: refetchQuizData,
  } = useGetCompleteQuizEditQuery({
    variables: {
      quiz_id: location.state ? String(location.state.ID) : "0",
      language_id: String(1),
    },
    skip: location.state === undefined,
    fetchPolicy: "network-only",
  });

  function setTagsFunction() {
    const tempArray: Tag[] = [];
    tagsData?.getAvailableTags?.forEach((tag) => {
      if (tag) {
        tempArray.push({
          id: Number(tag!.tag_id!),
          name: tag!.tag_name!,
        });
      }
    });
    setTags(tempArray);
  }

  function renameFiles(arr: availableSounds[]){
    var dup_count;
    let new_name = "";
    arr.forEach((item, index) => {
      dup_count = arr.filter(x => x.label === item.label).length;
      if (dup_count > 1) {
        for(let n = 0; n < dup_count;){
          do {
            new_name = `${item.label.split("(")[0]} (${n+=1})`;
          } while (arr.some((item) => item.label === new_name));
          arr[arr.indexOf(item)].label = new_name;
        }
      }
    });
    return arr
  }

  const setSounds = () => {
    const tempArray: availableSounds[] = [];
    if(soundData?.getSoundContent?.length === 0){
      refetchSound();
      return;
    }
    if (soundData) {
      soundData.getSoundContent?.forEach((sound) => {
        if (sound && sound.sound_content_id && sound.sound_text) {
             tempArray.push({
               key: sound.sound_content_id,
               id: Number(sound.sound_content_id),
               label: sound.sound_text,
              });
        }
      });
      const newSounds = renameFiles(tempArray);
      setnarrationSounds(newSounds);
    }
  };

  useEffect(() => {
    if (!tagsLoading) {
      setTagsFunction();
    }
  }, [tagsLoading]);

  useEffect(() => {
    if(location.state){

      if(location.state.isEdit){
        if (!quizLoading) {
          setSlides();
        }
      }
    }
  }, [quizLoading]);

  useEffect(() => {
    if(!soundLoading){
      setSounds();
    }
  },[soundLoading, soundData])

  async function fetchVideoThumb(mediaLocation) {
    const thumbnailAddress = mediaLocation.split("/")[3];
    return await fetch(
      `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${thumbnailAddress}`
    )
      .then((data) => data.json())
      .then((result) => {
        return result.thumbnail_url;
      });
  }

  async function convertAPIStateToQuizState(apiState: Quiz_Edit) {
    const tempQuizState: CreateQuizSlide[] = [];
    setquizID(Number(apiState.quiz_Id));
    setname(apiState.quiz_name ? apiState.quiz_name : "No name");
    let slideIndex = 1;
    for (const slide of apiState.slides) {
      if (slide) {
        const tempTags: Tag[] = [];
        if (slide.tags) {
          for (const tag of slide.tags) {
            tempTags.push({
              id: Number(tag!.tag_id!),
              name: tag!.tag_name!,
            });
          }
        }
        const tempQuizStatements: CreateQuizStatement[] = [];
        if (slide.slide_statements) {
          let statementIndex = 1;
          for (const statement of slide.slide_statements) {
            const tempElaborations: CreateQuizStatementElaboration[] = [];
            let elaborationIndex = 0;
            if (
              statement?.quiz_statement_elaboration &&
              statement?.quiz_statement_elaboration?.length !== 0
            ) {
              for (const elaboration of statement.quiz_statement_elaboration) {
                if(elaboration?.elaboration_language === "2" && statement.quiz_statement_elaboration.length === 1 ){
                  tempElaborations.push({
                    id: 0,
                    languageID: 1,
                    text: "",
                  });

                  tempElaborations.push({
                    id: Number(elaboration!.elaboration_id!),
                    editID: elaboration!.elaboration_id!,
                    languageID: Number(elaboration!.elaboration_language!),
                    text: elaboration!.elaboration_text!,
                    isOriginal: elaboration!.elaboration_is_original
                      ? true
                      : false,
                  });
                }else{

                  tempElaborations.push({
                    id: Number(elaboration!.elaboration_id!),
                  editID: elaboration!.elaboration_id!,
                  languageID: Number(elaboration!.elaboration_language!),
                  text: elaboration!.elaboration_text!,
                  isOriginal: elaboration!.elaboration_is_original
                    ? true
                    : false,
                  });
                }
              }
              if (tempElaborations.length <= 2) {
                tempElaborations.push({
                  id: 0,
                  text: "",
                  languageID: 2,
                });
              }
            } else {
              for (let i = 0; i <= 1; i++) {
                tempElaborations.push({
                  id: elaborationIndex,
                  text: "",
                  languageID: elaborationIndex + 1,
                });
                elaborationIndex++;
              }
            }
            try {
              console.log(statement?.quiz_Statement_Id);

              tempQuizStatements.push({
                id: Number(statementIndex),
                editID: Number(statement!.quiz_Statement_Id!),
                soundAndText: {
                  id: statement!.quiz_statement_sound
                    ? Number(
                        statement!.quiz_statement_sound[0]!.sound_content_id!
                      )
                    : -1,
                  soundLocation: statement!.quiz_statement_sound
                    ? statement!.quiz_statement_sound[0]!.sound_location!
                    : "",
                  soundText: statement!.quiz_statement_sound
                    ? statement!.quiz_statement_sound[0]!.sound_text!
                    : "",
                },
                answer: statement!.statement_answer_id!,
                elaboration: tempElaborations,
                originalIndex: statementIndex,
              });
              statementIndex++;
            } catch (e) {
              console.log(e);
              console.log("this is at the index it is wrong " + statementIndex);
            }
          }
        }
        const tempExplanations: CreateQuizExplanation[] = [];
        if (slide.slide_explanations) {
          for (const explanation of slide.slide_explanations) {
            tempExplanations.push({
              id: Number(explanation!.explanation_id!),
              editID: explanation!.explanation_id!,
              languageID: Number(explanation!.explanation_language!),
              tempFile: explanation!.explanation_location!,
              media: explanation!.explanation_location!,
              isOriginal: explanation!.explanation_is_original ? true : false,
            });
          }
        }
        if (slide.slide_content)
        if(slide.slide_content.length === 0){
          tempQuizState.push({
            // THIS IS FUCKING HORRIBLE CODE, BUT THERE IS NO OTHER WAY TO DO IT, WHEN THE RETURNS ARE "MAYBE"
            editID: Number(slide.quiz_slide_id),
            id: slideIndex,
            type: MediaType.AZUREIMG,
            media:
           "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png",
            tempFile: "failure",
            content_id: "0",
            introQuestion: slide!.intro_question_sound
              ? Number(slide!.intro_question_sound[0]?.sound_content_id)
              : -1,
            statements: tempQuizStatements,
            tags: tempTags,
            soundExplanations: tempExplanations,
            slideFilter: "-1" //TODO CHANGE THIS TO THE REAL IMPLEMENTATION
          });
        }else{
          tempQuizState.push({
            // THIS IS FUCKING HORRIBLE CODE, BUT THERE IS NO OTHER WAY TO DO IT, WHEN THE RETURNS ARE "MAYBE"
            editID: Number(slide.quiz_slide_id),
            id: slideIndex,
            type: slide.slide_content[0]!.media_type_id
              ? numberToMediaType(slide!.slide_content[0]!.media_type_id)!
              : MediaType.AZUREIMG,
            media:
              slide!.slide_content[0]!.media_type_id === 1
                ? await fetchVideoThumb(slide!.slide_content[0]!.media_location)
                : slide!.slide_content[0]!.media_location,
            tempFile: slide!.slide_content[0]!.media_location
              ? slide!.slide_content[0]!.media_location
              : "failure",
            content_id: slide!.slide_content[0]!.content_id
              ? slide!.slide_content[0]!.content_id
              : "-1",
            introQuestion: slide!.intro_question_sound
              ? Number(slide!.intro_question_sound[0]?.sound_content_id)
              : -1,
            statements: tempQuizStatements,
            tags: tempTags,
            soundExplanations: tempExplanations,
            slideFilter: slide!.slide_is_accesible !== undefined ? String(slide.slide_is_accesible) : "-1", //TODO CHANGE THIS TO THE REAL IMPLEMENTATION
          }); 
          console.log(slide!.slide_is_accesible);
        }
      }
      
      
      slideIndex++;
    }
    return tempQuizState;
  }

  const refetchTheQuiz = async () => {
    await refetchQuizData().then(async (data) => {
      if(data.data.getCompleteQuizEdit){
        if(data.data.getCompleteQuizEdit.slides.length === 0 || data.data.getCompleteQuizEdit === null){
          refetchTheQuiz()
        }else{
          const newQuizState = await convertAPIStateToQuizState(
          data.data.getCompleteQuizEdit
        );
        console.log(newQuizState);
        setQuizState(newQuizState);
        setTimeout(() => seteditLoader(false), 3000);
        }
        
      }else{
        refetchTheQuiz();
      }
    })
  }
  async function setSlides() {
    if (quizData?.getCompleteQuizEdit) {
      if (quizData.getCompleteQuizEdit.slides.length === 0 || quizData.getCompleteQuizEdit === null) {
        refetchQuizData();
      } else {
        console.log(quizData.getCompleteQuizEdit);

        const newQuizState = await convertAPIStateToQuizState(
          quizData?.getCompleteQuizEdit
        );
        console.log(newQuizState);
        setQuizState(newQuizState);
        setTimeout(() => seteditLoader(false), 3000);
      }
    } else {
      await refetchTheQuiz();
    }
  }

  function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
  /*################# THIS IS THE FUNCTION RESPONSIBLE FOR CONVERTING THE FRONTEND STATE TO THE BACKEND ######################## */
  async function convertQuizStateToAPI(
    currentQuizState: CreateQuizSlide[],
    isDraft?: boolean
  ) {
    let quiz: Quiz_Input;
    const quizSlides: Quiz_Slide_Input[] = [];
    //TODO STILL NEED THE CORRECT FORM OF INPUT TO THE API
    for (const slide of currentQuizState) {
      const tags: Tag_Input[] = [];
      slide.tags?.forEach((tag) => {
        tags.push({ tag_id: String(tag.id) });
      });

      const statements: Statement[] = [];
      for (const statement of slide.statements) {
        const elaborations: Statement_Elaboration_Input[] = [];
        //TODO WHEN INPUT TYPE IS CHANGED SO I CAN PUT IN ELABORATION, DO IT!!
        let elabIndex = 0;
        let currentIndex = 0;
        for (const elaboration of statement.elaboration) {
          if (elaboration.isOriginal) {
            arraymove(statement.elaboration, currentIndex, 0);
          }
          currentIndex++;
        }
        for (const elaboration of statement.elaboration) {
          if (elaboration.text.length > 2) {
            if (elaboration.isOriginal) {
              elaborations.push({
                elaboration_is_original: true,
                elaboration_language: String(elaboration.languageID),
                elaboration_text: elaboration.text,
                elaboration_id: elaboration.editID
                  ? elaboration.editID
                  : undefined,
                elaboration_owner: String(context.appGetUserID()),
              });
            } else {
              elaborations.push({
                elaboration_is_original: elabIndex === 0 ? true : false,
                elaboration_language: String(elaboration.languageID),
                elaboration_text: elaboration.text,
                elaboration_id: elaboration.editID
                  ? elaboration.editID
                  : undefined,
                elaboration_owner: String(context.appGetUserID()),
              });
            }
            elabIndex++;
          }
        }

        statements.push({
          statement_answer_id: Number(statement.answer),
          quiz_statement_no: String(statement.id),
          quiz_statement_elaboration: elaborations,
          quiz_statement_content: String(statement.soundAndText.id),
          quiz_Statement_Id: statement.editID
            ? String(statement.editID)
            : undefined,
        });
      }
      const tempExplanations: Slide_Explanation_Input[] = [];
      if (slide.soundExplanations) {
        if (SASKey) {
          let explanationIndex = 0;
          for (const explanation of slide.soundExplanations) {
            if (explanation.tempFile) {
              if (typeof explanation.tempFile === "string") {
                tempExplanations.push({
                  explanation_id: explanation.editID
                    ? explanation.editID
                    : undefined,
                  explanation_language: String(explanation.languageID),
                  explanation_location: explanation.tempFile,
                  explanation_is_original:
                    explanationIndex === 0 ? true : false,
                  explanation_owner: String(context.appGetUserID()),
                });
              } else {
                await uploadExplanationSound(
                  SASKey.getSAS,
                  explanation.tempFile,
                  Number(context.appGetUserID())
                  // eslint-disable-next-line no-loop-func
                ).then((data) => {
                  const url = data?.split("?sv")[0];
                  tempExplanations.push({
                    explanation_id: explanation.editID
                      ? explanation.editID
                      : undefined,
                    explanation_language: String(explanation.languageID),
                    explanation_location: url,
                    explanation_is_original:
                      explanationIndex === 0 ? true : false,
                    explanation_owner: String(context.appGetUserID()),
                  });
                });
              }
            }
            explanationIndex++;
          }
        }
      }

      quizSlides.push({
        quiz_slide_id: slide.editID ? String(slide.editID) : undefined,
        intro_question_content: String(slide.introQuestion) === "NaN" ? "0": String(slide.introQuestion),
        translation_content: slide.content_id,
        slide_explanations: tempExplanations,
        slide_statements: statements,
        tags: tags,
        slide_is_accesible: Number(slide.slideFilter) === -1 ? undefined : Number(slide.slideFilter)
      });
    }
    quiz = {
      quiz_Id: quizID ? String(quizID) : undefined,
      quiz_name: name,
      quiz_default_language: 1,
      quiz_is_draft: isDraft ? true : undefined,
      slides: quizSlides,
    };
    console.log(quiz);

    return quiz;
  }

  //THIS IS FOR VALIDATION
  const checkForErrors = (state: CreateQuizSlide[]) => {
    const copyOfState = [...state];
    let hasError = false;
    for (const slide of copyOfState) {
      for (const statement of slide.statements) {
        if (statement.answer === -1) {
          hasError = true;
          statement.hasError = true;
          slide.hasError = true;
        }
      }
    }
    console.log(copyOfState);

    setQuizState(copyOfState);
    return hasError;
  };

  const finishQuiz = async (isDraft?: boolean) => {
    if (!checkForErrors(QuizState)) {
      const convertedQuiz = await convertQuizStateToAPI(QuizState, isDraft);
      console.log(convertedQuiz);
      seteditLoader(true);
      manageQuiz({ variables: { quiz: convertedQuiz } }).then((data) => {
        if (data.data?.manageQuiz === "quiz_updated") {
          history.push(PATH_DASHBOARD);
        } else if(typeof data.data?.manageQuiz === "number") {
          history.push(PATH_DASHBOARD);
        }else{
          seteditLoader(false);
          alert("noget gik galt, kontakt udviklerne");

        }
      });
    }
  };
  return (
    <div className="CreateQuizPage">
      {isEdit && editLoader ? <IntermediaryLoading /> : null}
      <CreateQuizContent
        lessonState={QuizState}
        setLessonState={setQuizState}
        tags={tags}
        handleFinish={finishQuiz}
        name={name}
        setname={setname}
        narrationSounds={narrationSounds}
      />
    </div>
  );
};

export { CreateQuizPage };
