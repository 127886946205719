import { Socket } from "socket.io-client";

export const joinRoom = (socket: Socket, roomName: string) => {
    if(socket){
      socket.emit("join-room", roomName);
    }
  }

export const setUsername = (socket: Socket, userToken: any) => {
    if (socket) {
      socket.on("connect", () => {
      });
      socket.io.on("ping", () => {
        
      });
      
      socket.emit("setUsername", {
        user_id: String(userToken.id),
        email_id: userToken.name,
        role_id: userToken.role,
      });
    }
  };
export  const createRoom = (socket: Socket, roomName: string) => {
    if (socket) {
      socket.emit("create-room", roomName);
    }
  };
export const goToStats = (socket: Socket) => {
    socket!.emit("c-stats", () => {
    })
}
