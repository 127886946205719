import React, { useEffect, useState } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import "./style/VideoNav.scss"

export type IVideoNavProps = {
  hasVideoEnded: boolean;
  handlePlayClick: () => void;
  handlePauseClick: () => void;
  isPlaying?: boolean
};

const VideoNav: React.FC<IVideoNavProps> = (props: IVideoNavProps) => {
  const [isShowing, setisShowing] = useState(true);
  const [isPlaying, setisPlaying] = useState(false);
  const playIcon = (
    <PlayArrowIcon style={{ width: "2.8em", height: "2.8em" }} />
  );
  const pauseIcon = <PauseIcon style={{ width: "2.8em", height: "2.8em" }} />;

  const handlePlayClick = () => {
    props.handlePlayClick();
    setisPlaying(true);
    setTimeout(() => {
      setisShowing(false);
    }, 500);
  };
  const handlePauseClick = () => {
    props.handlePauseClick();
    setisPlaying(false);
    setisShowing(true);
  };

  useEffect(() => {
    if (isPlaying) {
      setisShowing(true);
      setTimeout(() => {
        setisShowing(false);
      }, 1000);
    }
  }, []);
  useEffect(() => {
    if(props.isPlaying){
      setisPlaying(true);
      setisShowing(false);
    }
  }, [props.isPlaying]);
  return (
    <div className={isShowing ? "VideoNav" : "VideoNav-fade-out"} onClick={isPlaying ? handlePauseClick : handlePlayClick}>
      <button >{isPlaying ? pauseIcon : playIcon}</button>
    </div>
  );
};

export { VideoNav };
