import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Button, TextField } from "@mui/material";

export type IIntroStatementProps = {
  key: number;
  narrationSounds: availableSounds[];
  introQuestion: number;
  deleteIntroStatement: () => void;
  updateIntroStatement: (soundValue: number) => void;
};
interface availableSounds {
  id: number;
  label: string;
}

const IntroStatement: React.FC<IIntroStatementProps> = (props: IIntroStatementProps) => {
  const [availableSounds, setavailableSounds] = useState<availableSounds[]>([
    { id: -1, label: "Loading" },
  ]);
  const [isSoundSelected, setisSoundSelected] = useState(false);

  const [chosenSound, setchosenSound] = useState<availableSounds>();

  const handleSoundChange = (event, newValue) => {
    console.log(newValue);

    if (newValue) {
      setisSoundSelected(true);
      setchosenSound(newValue);
      props.updateIntroStatement(newValue.id);
    }
  };

  useEffect(() => {
      if(props.introQuestion !== -1){
          setisSoundSelected(true)
          setchosenSound(props.narrationSounds.find((element) => element.id === props.introQuestion))
      }
  }, [props.key, props.narrationSounds]);


  if (!isSoundSelected) {
    return (
      <div className="SoundSelection">
        <div className="top">
          <span>Introduktions spørgsmål</span>
          <div className="deleteButton">
            <Button onClick={() => props.deleteIntroStatement()}>
              <ClearIcon />
            </Button>
          </div>
        </div>

        <h2>Søg efter en lyd og vælg den</h2>
        <Autocomplete
          className="soundAutocomplete"
          onChange={handleSoundChange}
          options={props.narrationSounds}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
    );
  }

  return (
    <div className="ActiveStatement">
    <div className="displayer--IntroQuestion">
      <div className="soundText">
        <div>{"Introduktions spørgsmål"}.<div className="idDisplay">Lyd og tekst ID: {chosenSound ? chosenSound?.id : "-"}</div></div>
        <div>{chosenSound ? chosenSound?.label : "Ingen lyd valgt"}</div>
        <div className="deleteButton">
          <Button onClick={() => props.deleteIntroStatement()}>
            <ClearIcon />
          </Button>
        </div>
      </div>
    </div>
  </div>
  );
};

export { IntroStatement };
