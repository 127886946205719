import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Time: any;
};

export type Achievement_Get = {
  __typename?: 'Achievement_get';
  achievement_desc?: Maybe<Scalars['String']>;
  achievement_has_fails?: Maybe<Scalars['Int']>;
  achievement_id?: Maybe<Scalars['ID']>;
  achievement_location?: Maybe<Scalars['String']>;
  achievement_owner_id?: Maybe<Scalars['ID']>;
  achievement_quiz_id?: Maybe<Scalars['String']>;
  achievement_status?: Maybe<Scalars['Int']>;
  achievement_type?: Maybe<Scalars['String']>;
  achievement_type_desc?: Maybe<Scalars['String']>;
  achievement_type_name?: Maybe<Scalars['String']>;
};

export type Achievement_Grade = {
  achievement_has_fails?: InputMaybe<Scalars['Int']>;
  achievement_id?: InputMaybe<Scalars['ID']>;
  achievement_quiz_id?: InputMaybe<Scalars['Int']>;
  achievement_status?: InputMaybe<Scalars['Int']>;
  time_spent?: InputMaybe<Scalars['String']>;
};

export type Class = {
  __typename?: 'Class';
  class_id?: Maybe<Scalars['ID']>;
  class_name?: Maybe<Scalars['String']>;
  creation_date?: Maybe<Scalars['DateTime']>;
  school_id?: Maybe<Scalars['ID']>;
};

export type Department = {
  __typename?: 'Department';
  department_name?: Maybe<Scalars['String']>;
  department_province?: Maybe<Scalars['String']>;
  department_zipcode?: Maybe<Scalars['String']>;
  email_id?: Maybe<Scalars['String']>;
  is_department?: Maybe<Scalars['Boolean']>;
  language_choice?: Maybe<Scalars['Int']>;
  optional_note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['ID']>;
  subuser_id: Scalars['ID'];
};

export type Language = {
  __typename?: 'Language';
  language_name?: Maybe<Scalars['String']>;
  teori_language_id?: Maybe<Scalars['String']>;
};

export type Mark_Slide = {
  __typename?: 'Mark_slide';
  explanation_location?: Maybe<Scalars['String']>;
  intro_sound_location?: Maybe<Scalars['String']>;
  intro_sound_text?: Maybe<Scalars['String']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_type_id?: Maybe<Scalars['ID']>;
  online_quiz_id: Scalars['ID'];
  original_slide_id?: Maybe<Scalars['Int']>;
  progress_id: Scalars['ID'];
  slide_pass_status?: Maybe<Scalars['Boolean']>;
  slide_statements?: Maybe<Array<Maybe<Statement>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AddPinStudent?: Maybe<Scalars['String']>;
  LoginStudent?: Maybe<UserLogin>;
  /** Slide mark endpoints */
  MarkSlide?: Maybe<Scalars['JSON']>;
  RefreshUser: UserLogin;
  UnmarkSlide?: Maybe<Scalars['JSON']>;
  UserLogout: Scalars['JSON'];
  /** Management of User (School, Teacher) object */
  VerifyStudent?: Maybe<Scalars['String']>;
  addAIStudent?: Maybe<Scalars['Int']>;
  addTeoriLessonStudent?: Maybe<Scalars['String']>;
  createClassOnlineQuiz?: Maybe<Online_Quiz>;
  /** Online Quiz object control */
  createFreeQuiz?: Maybe<Online_Quiz>;
  createQuiz?: Maybe<Online_Quiz>;
  finishClassOnlineQuiz?: Maybe<Online_Quiz_Result>;
  finishQuiz?: Maybe<Online_Quiz_Result>;
  gradeSlide?: Maybe<Scalars['JSON']>;
  /** Dashboard Control Mutations */
  manageAchievement: Scalars['JSON'];
  manageBeforeDriving: Scalars['JSON'];
  manageEvalTests: Scalars['JSON'];
  manageOnlineBook: Scalars['JSON'];
  manageSchoolLessons: Scalars['JSON'];
  manageStartContent: Scalars['JSON'];
  /** Management of Student object */
  manageStudent: Scalars['JSON'];
  manageSubject: Scalars['JSON'];
  startContent?: Maybe<Online_Quiz>;
  studentViewLesson?: Maybe<Scalars['JSON']>;
};


export type MutationAddPinStudentArgs = {
  phone_number?: InputMaybe<Scalars['String']>;
  pin_code?: InputMaybe<Scalars['String']>;
};


export type MutationLoginStudentArgs = {
  phone_number?: InputMaybe<Scalars['String']>;
  pin_code?: InputMaybe<Scalars['String']>;
};


export type MutationMarkSlideArgs = {
  marked_slides?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationRefreshUserArgs = {
  old_token: Scalars['String'];
};


export type MutationUnmarkSlideArgs = {
  unmarked_slides?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationVerifyStudentArgs = {
  entry_code?: InputMaybe<Scalars['String']>;
  student_phone_number?: InputMaybe<Scalars['String']>;
};


export type MutationAddAiStudentArgs = {
  config_id: Scalars['Int'];
};


export type MutationAddTeoriLessonStudentArgs = {
  lesson_id?: InputMaybe<Scalars['String']>;
};


export type MutationCreateClassOnlineQuizArgs = {
  online_quiz?: InputMaybe<Online_Quiz_Input>;
};


export type MutationCreateFreeQuizArgs = {
  online_quiz?: InputMaybe<Online_Quiz_Input>;
};


export type MutationCreateQuizArgs = {
  online_quiz?: InputMaybe<Online_Quiz_Input>;
};


export type MutationFinishClassOnlineQuizArgs = {
  quiz_class_id: Scalars['ID'];
};


export type MutationFinishQuizArgs = {
  online_quiz_id?: InputMaybe<Scalars['ID']>;
  quiz_token_id?: InputMaybe<Scalars['String']>;
};


export type MutationGradeSlideArgs = {
  graded_slide?: InputMaybe<Slide_Progress>;
};


export type MutationManageAchievementArgs = {
  achievement: Achievement_Grade;
};


export type MutationManageBeforeDrivingArgs = {
  achievement: Achievement_Grade;
};


export type MutationManageEvalTestsArgs = {
  achievement: Achievement_Grade;
};


export type MutationManageOnlineBookArgs = {
  achievement: Achievement_Grade;
};


export type MutationManageSchoolLessonsArgs = {
  achievement: Achievement_Grade;
};


export type MutationManageStartContentArgs = {
  achievement: Achievement_Grade;
};


export type MutationManageStudentArgs = {
  student: Student_Input;
};


export type MutationManageSubjectArgs = {
  achievement: Achievement_Grade;
};


export type MutationStartContentArgs = {
  online_quiz_id?: InputMaybe<Scalars['ID']>;
  quiz_token_id?: InputMaybe<Scalars['String']>;
  sound_language?: InputMaybe<Scalars['Int']>;
  text_language?: InputMaybe<Scalars['Int']>;
};


export type MutationStudentViewLessonArgs = {
  lesson_id?: InputMaybe<Scalars['ID']>;
  time_spent?: InputMaybe<Scalars['String']>;
};

export type Notice = {
  __typename?: 'Notice';
  notice_background_color?: Maybe<Scalars['String']>;
  notice_body?: Maybe<Scalars['String']>;
  notice_expire_on?: Maybe<Scalars['DateTime']>;
  notice_id?: Maybe<Scalars['ID']>;
  notice_language?: Maybe<Scalars['Int']>;
  notice_publish_on?: Maybe<Scalars['DateTime']>;
  notice_student_dashboard?: Maybe<Scalars['Boolean']>;
  notice_students_landing?: Maybe<Scalars['Boolean']>;
  notice_teacher_landing?: Maybe<Scalars['Boolean']>;
};

export type Online_Quiz = {
  __typename?: 'Online_quiz';
  achievement_name?: Maybe<Scalars['String']>;
  base_quiz?: Maybe<Scalars['ID']>;
  end_time?: Maybe<Scalars['DateTime']>;
  include_teori?: Maybe<Scalars['Boolean']>;
  online_Quiz_Slides?: Maybe<Array<Maybe<Online_Slide>>>;
  online_Quiz_Tags?: Maybe<Array<Maybe<Tag>>>;
  online_quiz_id?: Maybe<Scalars['ID']>;
  quiz_class_id?: Maybe<Scalars['ID']>;
  quiz_date?: Maybe<Scalars['DateTime']>;
  quiz_language?: Maybe<Scalars['ID']>;
  quiz_learning_mode?: Maybe<Scalars['Boolean']>;
  quiz_pass_status?: Maybe<Scalars['Boolean']>;
  quiz_random_evaluation_test?: Maybe<Scalars['Boolean']>;
  quiz_read_only?: Maybe<Scalars['Boolean']>;
  quiz_speed?: Maybe<Scalars['ID']>;
  quiz_speed_value?: Maybe<Scalars['Int']>;
  quiz_strength_test?: Maybe<Scalars['Boolean']>;
  quiz_subtitles?: Maybe<Scalars['ID']>;
  quiz_test_name?: Maybe<Scalars['String']>;
  quiz_token_id?: Maybe<Scalars['String']>;
  save_for_later?: Maybe<Scalars['Boolean']>;
  slide_amount?: Maybe<Scalars['Int']>;
  slide_progress?: Maybe<Array<Maybe<Progress>>>;
  start_time?: Maybe<Scalars['DateTime']>;
  student_id?: Maybe<Scalars['ID']>;
};

export type Online_Quiz_Input = {
  achievement_name?: InputMaybe<Scalars['String']>;
  base_quiz?: InputMaybe<Scalars['ID']>;
  include_teori?: InputMaybe<Scalars['Boolean']>;
  online_Quiz_Slides?: InputMaybe<Array<InputMaybe<Slide_Input>>>;
  online_Quiz_Tags?: InputMaybe<Array<InputMaybe<Tag_Input>>>;
  quiz_class_id?: InputMaybe<Scalars['ID']>;
  quiz_language?: InputMaybe<Scalars['ID']>;
  quiz_learning_mode?: InputMaybe<Scalars['Boolean']>;
  quiz_random_evaluation_test?: InputMaybe<Scalars['Boolean']>;
  quiz_read_only?: InputMaybe<Scalars['Boolean']>;
  quiz_speed?: InputMaybe<Scalars['ID']>;
  quiz_strength_test?: InputMaybe<Scalars['Boolean']>;
  quiz_subtitles?: InputMaybe<Scalars['ID']>;
  quiz_test_name?: InputMaybe<Scalars['String']>;
  quiz_token_id?: InputMaybe<Scalars['String']>;
  save_for_later?: InputMaybe<Scalars['Boolean']>;
  slide_amount?: InputMaybe<Scalars['Int']>;
  student_id?: InputMaybe<Scalars['ID']>;
};

export type Online_Quiz_Result = {
  __typename?: 'Online_quiz_result';
  achievement_name?: Maybe<Scalars['String']>;
  base_quiz?: Maybe<Scalars['ID']>;
  class_quiz_id?: Maybe<Scalars['ID']>;
  end_time?: Maybe<Scalars['DateTime']>;
  online_quiz_id?: Maybe<Scalars['ID']>;
  quiz_date?: Maybe<Scalars['DateTime']>;
  quiz_expired?: Maybe<Scalars['Boolean']>;
  quiz_language?: Maybe<Scalars['ID']>;
  quiz_learning_mode?: Maybe<Scalars['Boolean']>;
  quiz_pass_status?: Maybe<Scalars['Boolean']>;
  quiz_test_name?: Maybe<Scalars['String']>;
  slide_amount?: Maybe<Scalars['Int']>;
  slide_progress?: Maybe<Array<Maybe<Progress>>>;
  slides_failed?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['DateTime']>;
  student_id?: Maybe<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  /** General User Queries */
  confirmMobileNo: Scalars['JSON'];
  /** Dashboard data lookouts  */
  getAchievementTypes?: Maybe<Array<Maybe<Achievement_Type>>>;
  getActiveNotices?: Maybe<Array<Maybe<Notice>>>;
  getBeforeDrivingTest?: Maybe<Array<Maybe<Achievement_Get>>>;
  getClassInfo?: Maybe<Class>;
  getClassQuizByConfigID?: Maybe<Scalars['Int']>;
  getClassStudentParticipant?: Maybe<Online_Quiz_Participants>;
  getClassStudentResult?: Maybe<Array<Maybe<Progress>>>;
  getCorrectQuizzes?: Maybe<Scalars['JSON']>;
  getCorrectQuizzesResult?: Maybe<Array<Maybe<Online_Quiz_Result>>>;
  getDepartmentInfo?: Maybe<Department>;
  getDepartmentList?: Maybe<Array<Maybe<Department>>>;
  getDrivingSchoolClasses?: Maybe<Array<Maybe<Achievement_Get>>>;
  getEvalTests?: Maybe<Array<Maybe<Achievement_Get>>>;
  getFailedSlides?: Maybe<Scalars['JSON']>;
  getLanguages?: Maybe<Array<Maybe<Language>>>;
  getLatestResult?: Maybe<Array<Maybe<Online_Quiz_Result>>>;
  /** Lesson Service Lookouts */
  getLessonCategories?: Maybe<Array<Maybe<Lesson_Categories>>>;
  getLessonInfo?: Maybe<Array<Maybe<Lesson>>>;
  getMarkedSlides?: Maybe<Array<Maybe<Mark_Slide>>>;
  getMediaTypes?: Maybe<Array<Maybe<Media_Types>>>;
  getOnlineBookInfo?: Maybe<Array<Maybe<Achievement_Get>>>;
  getOnlineQuiz?: Maybe<Array<Maybe<Online_Quiz>>>;
  /** Quiz data lookouts */
  getOnlineQuizSpeed: Array<Maybe<Quiz_Speed>>;
  getQuizResult?: Maybe<Array<Maybe<Online_Quiz_Result>>>;
  /** School service data lookouts */
  getSchoolInfo?: Maybe<School>;
  getSchoolList?: Maybe<Array<Maybe<School>>>;
  getSlidesPreview?: Maybe<Array<Maybe<Short_Slide>>>;
  getSpokenLanguages?: Maybe<Array<Maybe<Language>>>;
  getStartContent?: Maybe<Array<Maybe<Achievement_Get>>>;
  getStudentAchievements?: Maybe<Array<Maybe<Student_Ach_Get>>>;
  getStudentCategories?: Maybe<Scalars['JSON']>;
  /** Student service data lookouts */
  getStudentInfo: Student;
  getStudentPubLessons?: Maybe<Array<Maybe<Lesson_Achievement>>>;
  getStudentSubjects?: Maybe<Array<Maybe<Achievement_Get>>>;
  getStudentTips?: Maybe<Array<Maybe<Scalars['String']>>>;
  getSubLanguages?: Maybe<Array<Maybe<Language>>>;
  /** Teacher service data lookouts */
  getSubuserInfo?: Maybe<Subuser>;
  verifyMobileNo: Scalars['JSON'];
};


export type QueryConfirmMobileNoArgs = {
  student_phone_number: Scalars['String'];
};


export type QueryGetAchievementTypesArgs = {
  category?: InputMaybe<Scalars['Int']>;
};


export type QueryGetClassInfoArgs = {
  class_id: Scalars['ID'];
};


export type QueryGetClassQuizByConfigIdArgs = {
  config_id: Scalars['Int'];
};


export type QueryGetClassStudentParticipantArgs = {
  class_quiz_id: Scalars['ID'];
};


export type QueryGetClassStudentResultArgs = {
  class_quiz_id: Scalars['ID'];
};


export type QueryGetDepartmentInfoArgs = {
  department_id: Scalars['ID'];
};


export type QueryGetLatestResultArgs = {
  base_quiz_id?: InputMaybe<Scalars['ID']>;
  class_quiz_id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetLessonInfoArgs = {
  language_id: Scalars['ID'];
  lesson_id: Scalars['ID'];
};


export type QueryGetOnlineQuizArgs = {
  online_quiz_id: Scalars['ID'];
  quiz_token_id: Scalars['String'];
  sound_language?: InputMaybe<Scalars['Int']>;
  text_language?: InputMaybe<Scalars['Int']>;
};


export type QueryGetQuizResultArgs = {
  online_quiz_id: Scalars['ID'];
};


export type QueryGetSlidesPreviewArgs = {
  language_id: Scalars['ID'];
  lesson_id: Scalars['ID'];
};


export type QueryGetStudentAchievementsArgs = {
  achievement_type?: InputMaybe<Scalars['ID']>;
};


export type QueryGetStudentTipsArgs = {
  category_id: Scalars['Int'];
};


export type QueryGetSubuserInfoArgs = {
  subuser_id: Scalars['ID'];
};


export type QueryVerifyMobileNoArgs = {
  student_phone_number: Scalars['String'];
};

/** Quiz Structure */
export type Quiz_Speed = {
  __typename?: 'Quiz_speed';
  quiz_speed_id?: Maybe<Scalars['ID']>;
  quiz_speed_name?: Maybe<Scalars['String']>;
  quiz_speed_value?: Maybe<Scalars['Int']>;
};

export type School = {
  __typename?: 'School';
  address?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  email_id?: Maybe<Scalars['String']>;
  language_choice?: Maybe<Scalars['Int']>;
  mobile_number?: Maybe<Scalars['String']>;
  optional_note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  school_departments?: Maybe<Array<Maybe<Department>>>;
  school_id?: Maybe<Scalars['ID']>;
  school_name: Scalars['String'];
  zipcode?: Maybe<Scalars['Int']>;
};

export type Slide_Input = {
  online_quiz_id?: InputMaybe<Scalars['ID']>;
  quiz_slide_id?: InputMaybe<Scalars['ID']>;
  slide_order?: InputMaybe<Scalars['Int']>;
};

export type Student = {
  __typename?: 'Student';
  expiry_date?: Maybe<Scalars['DateTime']>;
  first_name?: Maybe<Scalars['String']>;
  language_choice?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  optional_value?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['ID']>;
  student_category?: Maybe<Scalars['String']>;
  student_class_start?: Maybe<Scalars['DateTime']>;
  student_email?: Maybe<Scalars['String']>;
  student_id: Scalars['ID'];
  student_name?: Maybe<Scalars['String']>;
  student_phone_number?: Maybe<Scalars['String']>;
  teori_department?: Maybe<Scalars['ID']>;
};

export type Student_Input = {
  first_name?: InputMaybe<Scalars['String']>;
  language_choice?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  school_id?: InputMaybe<Scalars['ID']>;
  student_category?: InputMaybe<Scalars['Int']>;
  student_email?: InputMaybe<Scalars['String']>;
  student_name?: InputMaybe<Scalars['String']>;
  teori_department?: InputMaybe<Scalars['ID']>;
};

export type Student_Ach_Get = {
  __typename?: 'Student_ach_get';
  achievement_id?: Maybe<Scalars['ID']>;
  achievement_languages?: Maybe<Scalars['String']>;
  achievement_location?: Maybe<Scalars['String']>;
  achievement_media_type?: Maybe<Scalars['ID']>;
  achievement_name?: Maybe<Scalars['String']>;
  dash_has_fails?: Maybe<Scalars['Int']>;
  dash_quiz_id?: Maybe<Scalars['Int']>;
  dash_status?: Maybe<Scalars['Int']>;
  dash_track_id?: Maybe<Scalars['ID']>;
  student_id?: Maybe<Scalars['ID']>;
};

export type Subuser = {
  __typename?: 'Subuser';
  department_name?: Maybe<Scalars['String']>;
  department_province?: Maybe<Scalars['String']>;
  department_zipcode?: Maybe<Scalars['String']>;
  email_id?: Maybe<Scalars['String']>;
  is_department?: Maybe<Scalars['Boolean']>;
  language_choice?: Maybe<Scalars['Int']>;
  mobile_number?: Maybe<Scalars['String']>;
  optional_note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['ID']>;
  subuser_id: Scalars['ID'];
  teacher_name?: Maybe<Scalars['String']>;
  vat_number?: Maybe<Scalars['String']>;
};

export type Tag_Input = {
  tag_id?: InputMaybe<Scalars['ID']>;
  tag_name?: InputMaybe<Scalars['String']>;
};

export type UserLogin = {
  __typename?: 'UserLogin';
  accessToken?: Maybe<Scalars['String']>;
  login_before?: Maybe<Scalars['Boolean']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type Achievement_Type = {
  __typename?: 'achievement_type';
  achievement_category?: Maybe<Scalars['Int']>;
  achievement_desc?: Maybe<Scalars['String']>;
  achievement_language?: Maybe<Scalars['Int']>;
  achievement_name?: Maybe<Scalars['String']>;
  achievement_type_desc?: Maybe<Scalars['String']>;
  achievement_type_id?: Maybe<Scalars['Int']>;
};

export type Content = {
  __typename?: 'content';
  content_id?: Maybe<Scalars['ID']>;
  media_default?: Maybe<Scalars['Boolean']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_owner?: Maybe<Scalars['Int']>;
  media_type_id?: Maybe<Scalars['Int']>;
};

/** Lesson Structure */
export type Lesson = {
  __typename?: 'lesson';
  lesson_category?: Maybe<Scalars['Int']>;
  lesson_content_desc?: Maybe<Scalars['String']>;
  lesson_date_format?: Maybe<Scalars['String']>;
  lesson_default?: Maybe<Scalars['Int']>;
  lesson_default_language?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['ID']>;
  lesson_logo?: Maybe<Scalars['String']>;
  lesson_name?: Maybe<Scalars['String']>;
  slides?: Maybe<Array<Maybe<Slide>>>;
};

export type Lesson_Achievement = {
  __typename?: 'lesson_achievement';
  ach_pub_lesson_view_date?: Maybe<Scalars['DateTime']>;
  lesson_id?: Maybe<Scalars['Int']>;
  lesson_name?: Maybe<Scalars['String']>;
};

export type Lesson_Categories = {
  __typename?: 'lesson_categories';
  cat_id?: Maybe<Scalars['ID']>;
  cat_name?: Maybe<Scalars['String']>;
};

export type Media_Types = {
  __typename?: 'media_types';
  media_type_desc?: Maybe<Scalars['String']>;
  media_type_id?: Maybe<Scalars['ID']>;
  media_type_name?: Maybe<Scalars['String']>;
};

export type Online_Quiz_Participants = {
  __typename?: 'online_quiz_participants';
  class_quiz_id?: Maybe<Scalars['ID']>;
  student_amount?: Maybe<Scalars['Int']>;
  student_failed?: Maybe<Scalars['Int']>;
  student_passed?: Maybe<Scalars['Int']>;
};

export type Online_Slide = {
  __typename?: 'online_slide';
  online_quiz_id?: Maybe<Scalars['ID']>;
  quiz_slide_id?: Maybe<Scalars['ID']>;
  slide_order?: Maybe<Scalars['Int']>;
};

export type Progress = {
  __typename?: 'progress';
  explanation_location?: Maybe<Scalars['String']>;
  intro_sound_location?: Maybe<Scalars['String']>;
  intro_sound_text?: Maybe<Scalars['String']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_type_id?: Maybe<Scalars['ID']>;
  online_quiz_id?: Maybe<Scalars['ID']>;
  original_slide_id?: Maybe<Scalars['ID']>;
  progress_id?: Maybe<Scalars['ID']>;
  progress_is_marked?: Maybe<Scalars['Boolean']>;
  slide_pass_status?: Maybe<Scalars['Boolean']>;
  slide_statements?: Maybe<Array<Maybe<Statement>>>;
  statement_progress?: Maybe<Array<Maybe<Statement>>>;
};

export type Short_Slide = {
  __typename?: 'short_slide';
  language_id?: Maybe<Scalars['Int']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_type_name?: Maybe<Scalars['String']>;
  slide_id?: Maybe<Scalars['ID']>;
  slide_is_accesible?: Maybe<Scalars['Int']>;
  slide_note?: Maybe<Scalars['String']>;
  slide_order?: Maybe<Scalars['Int']>;
  slide_sound_location?: Maybe<Scalars['String']>;
};

export type Slide = {
  __typename?: 'slide';
  keywords?: Maybe<Scalars['String']>;
  language_id?: Maybe<Scalars['Int']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_type_id?: Maybe<Scalars['Int']>;
  slide_id?: Maybe<Scalars['ID']>;
  slide_is_accesible?: Maybe<Scalars['Int']>;
  slide_note?: Maybe<Scalars['String']>;
  slide_order?: Maybe<Scalars['Int']>;
  slide_sound_location?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type Slide_Progress = {
  progress_id?: InputMaybe<Scalars['ID']>;
  statement_progress?: InputMaybe<Array<InputMaybe<Statement_Grade>>>;
};

export type Statement = {
  __typename?: 'statement';
  elaboration_Text?: Maybe<Scalars['String']>;
  online_slide_id?: Maybe<Scalars['ID']>;
  original_statement_id?: Maybe<Scalars['ID']>;
  quiz_statement_no?: Maybe<Scalars['Int']>;
  slide_pass_status?: Maybe<Scalars['Boolean']>;
  sound_location?: Maybe<Scalars['String']>;
  sound_text?: Maybe<Scalars['String']>;
  statement_answer_id?: Maybe<Scalars['Int']>;
  statement_progress_answer?: Maybe<Scalars['Int']>;
  statement_progress_id?: Maybe<Scalars['ID']>;
};

export type Statement_Grade = {
  slide_pass_status?: InputMaybe<Scalars['Boolean']>;
  statement_progress_answer?: InputMaybe<Scalars['Int']>;
  statement_progress_id?: InputMaybe<Scalars['ID']>;
};

export type Statement_Marked = {
  __typename?: 'statement_marked';
  elaboration_id?: Maybe<Scalars['ID']>;
  elaboration_text?: Maybe<Scalars['String']>;
  quiz_Slide_id?: Maybe<Scalars['ID']>;
  quiz_Statement_Id?: Maybe<Scalars['ID']>;
  quiz_statement_content?: Maybe<Scalars['ID']>;
  quiz_statement_no?: Maybe<Scalars['ID']>;
  slide_pass_status?: Maybe<Scalars['Boolean']>;
  sound_language?: Maybe<Scalars['Int']>;
  sound_location?: Maybe<Scalars['String']>;
  sound_text?: Maybe<Scalars['String']>;
  statement_answer_id?: Maybe<Scalars['Int']>;
};

export type Tag = {
  __typename?: 'tag';
  tag_id?: Maybe<Scalars['ID']>;
  tag_name?: Maybe<Scalars['String']>;
};

export type LoginStudentMutationVariables = Exact<{
  pin_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
}>;


export type LoginStudentMutation = (
  { __typename?: 'Mutation' }
  & { LoginStudent?: Maybe<(
    { __typename?: 'UserLogin' }
    & Pick<UserLogin, 'accessToken' | 'refreshToken' | 'login_before'>
  )> }
);

export type RefreshUserMutationVariables = Exact<{
  old_token: Scalars['String'];
}>;


export type RefreshUserMutation = (
  { __typename?: 'Mutation' }
  & { RefreshUser: (
    { __typename?: 'UserLogin' }
    & Pick<UserLogin, 'accessToken' | 'refreshToken'>
  ) }
);

export type VerifyStudentMutationVariables = Exact<{
  entry_code?: Maybe<Scalars['String']>;
  student_phone_number?: Maybe<Scalars['String']>;
}>;


export type VerifyStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'VerifyStudent'>
);

export type AddPinStudentMutationVariables = Exact<{
  pin_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
}>;


export type AddPinStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddPinStudent'>
);

export type VerifyMobileNoQueryVariables = Exact<{
  student_phone_number: Scalars['String'];
}>;


export type VerifyMobileNoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verifyMobileNo'>
);

export type ConfirmMobileNoQueryVariables = Exact<{
  student_phone_number: Scalars['String'];
}>;


export type ConfirmMobileNoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'confirmMobileNo'>
);

export type GetActiveNoticesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveNoticesQuery = (
  { __typename?: 'Query' }
  & { getActiveNotices?: Maybe<Array<Maybe<(
    { __typename?: 'Notice' }
    & Pick<Notice, 'notice_body' | 'notice_background_color'>
  )>>> }
);

export type GetStudentSubjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudentSubjectsQuery = (
  { __typename?: 'Query' }
  & { getStudentSubjects?: Maybe<Array<Maybe<(
    { __typename?: 'Achievement_get' }
    & Pick<Achievement_Get, 'achievement_id' | 'achievement_owner_id' | 'achievement_desc' | 'achievement_status' | 'achievement_type_desc' | 'achievement_type_name' | 'achievement_location' | 'achievement_quiz_id' | 'achievement_has_fails'>
  )>>> }
);

export type GetStudentCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudentCategoriesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStudentCategories'>
);

export type GetEvalTestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEvalTestsQuery = (
  { __typename?: 'Query' }
  & { getEvalTests?: Maybe<Array<Maybe<(
    { __typename?: 'Achievement_get' }
    & Pick<Achievement_Get, 'achievement_id' | 'achievement_owner_id' | 'achievement_desc' | 'achievement_status' | 'achievement_type_name' | 'achievement_type_desc' | 'achievement_location' | 'achievement_quiz_id' | 'achievement_has_fails'>
  )>>> }
);

export type GetOnlineBookInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOnlineBookInfoQuery = (
  { __typename?: 'Query' }
  & { getOnlineBookInfo?: Maybe<Array<Maybe<(
    { __typename?: 'Achievement_get' }
    & Pick<Achievement_Get, 'achievement_id' | 'achievement_owner_id' | 'achievement_desc' | 'achievement_status' | 'achievement_type_desc' | 'achievement_type_name' | 'achievement_location' | 'achievement_quiz_id' | 'achievement_has_fails'>
  )>>> }
);

export type GetDrivingSchoolClassesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDrivingSchoolClassesQuery = (
  { __typename?: 'Query' }
  & { getDrivingSchoolClasses?: Maybe<Array<Maybe<(
    { __typename?: 'Achievement_get' }
    & Pick<Achievement_Get, 'achievement_id' | 'achievement_owner_id' | 'achievement_desc' | 'achievement_type_name' | 'achievement_status' | 'achievement_type_desc' | 'achievement_location' | 'achievement_quiz_id' | 'achievement_has_fails'>
  )>>> }
);

export type GetBeforeDrivingTestQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBeforeDrivingTestQuery = (
  { __typename?: 'Query' }
  & { getBeforeDrivingTest?: Maybe<Array<Maybe<(
    { __typename?: 'Achievement_get' }
    & Pick<Achievement_Get, 'achievement_id' | 'achievement_owner_id' | 'achievement_desc' | 'achievement_type_name' | 'achievement_status' | 'achievement_type_desc' | 'achievement_location' | 'achievement_quiz_id' | 'achievement_has_fails'>
  )>>> }
);

export type GetStartContentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStartContentQuery = (
  { __typename?: 'Query' }
  & { getStartContent?: Maybe<Array<Maybe<(
    { __typename?: 'Achievement_get' }
    & Pick<Achievement_Get, 'achievement_id' | 'achievement_owner_id' | 'achievement_desc' | 'achievement_type_name' | 'achievement_status' | 'achievement_type_desc' | 'achievement_location' | 'achievement_quiz_id' | 'achievement_has_fails'>
  )>>> }
);

export type ManageStartContentMutationVariables = Exact<{
  achievement: Achievement_Grade;
}>;


export type ManageStartContentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageStartContent'>
);

export type ManageSubjectMutationVariables = Exact<{
  achievement: Achievement_Grade;
}>;


export type ManageSubjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageSubject'>
);

export type ManageEvalTestsMutationVariables = Exact<{
  achievement: Achievement_Grade;
}>;


export type ManageEvalTestsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageEvalTests'>
);

export type ManageOnlineBookMutationVariables = Exact<{
  achievement: Achievement_Grade;
}>;


export type ManageOnlineBookMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageOnlineBook'>
);

export type ManageSchoolLessonsMutationVariables = Exact<{
  achievement: Achievement_Grade;
}>;


export type ManageSchoolLessonsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageSchoolLessons'>
);

export type ManageBeforeDrivingMutationVariables = Exact<{
  achievement: Achievement_Grade;
}>;


export type ManageBeforeDrivingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageBeforeDriving'>
);

export type GetStudentInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudentInfoQuery = (
  { __typename?: 'Query' }
  & { getStudentInfo: (
    { __typename?: 'Student' }
    & Pick<Student, 'school_id' | 'first_name' | 'last_name' | 'student_email' | 'student_phone_number' | 'student_category' | 'language_choice' | 'teori_department'>
  ) }
);

export type GetStudentNameQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudentNameQuery = (
  { __typename?: 'Query' }
  & { getStudentInfo: (
    { __typename?: 'Student' }
    & Pick<Student, 'first_name' | 'last_name'>
  ) }
);

export type ManageStudentMutationVariables = Exact<{
  student: Student_Input;
}>;


export type ManageStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageStudent'>
);

export type GetSchoolListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSchoolListQuery = (
  { __typename?: 'Query' }
  & { getSchoolList?: Maybe<Array<Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'school_id' | 'school_name' | 'zipcode'>
  )>>> }
);

export type GetDepartmentListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDepartmentListQuery = (
  { __typename?: 'Query' }
  & { getDepartmentList?: Maybe<Array<Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'subuser_id' | 'department_name' | 'department_zipcode' | 'school_id'>
  )>>> }
);

export type CreateQuizMutationVariables = Exact<{
  online_quiz?: Maybe<Online_Quiz_Input>;
}>;


export type CreateQuizMutation = (
  { __typename?: 'Mutation' }
  & { createQuiz?: Maybe<(
    { __typename?: 'Online_quiz' }
    & Pick<Online_Quiz, 'online_quiz_id' | 'quiz_token_id'>
  )> }
);

export type StartContentMutationVariables = Exact<{
  online_quiz_id?: Maybe<Scalars['ID']>;
  quiz_token_id?: Maybe<Scalars['String']>;
}>;


export type StartContentMutation = (
  { __typename?: 'Mutation' }
  & { startContent?: Maybe<(
    { __typename?: 'Online_quiz' }
    & Pick<Online_Quiz, 'quiz_test_name' | 'quiz_speed' | 'quiz_speed_value' | 'quiz_language' | 'quiz_subtitles' | 'quiz_read_only'>
    & { slide_progress?: Maybe<Array<Maybe<(
      { __typename?: 'progress' }
      & Pick<Progress, 'progress_is_marked' | 'progress_id' | 'original_slide_id' | 'online_quiz_id' | 'explanation_location' | 'intro_sound_text' | 'media_location' | 'media_type_id' | 'media_location_enc' | 'intro_sound_location' | 'slide_pass_status'>
      & { statement_progress?: Maybe<Array<Maybe<(
        { __typename?: 'statement' }
        & Pick<Statement, 'statement_progress_id' | 'online_slide_id' | 'original_statement_id' | 'statement_answer_id' | 'quiz_statement_no' | 'elaboration_Text' | 'sound_text' | 'sound_location' | 'slide_pass_status'>
      )>>> }
    )>>> }
  )> }
);

export type GradeSlideMutationVariables = Exact<{
  gradedSlide?: Maybe<Slide_Progress>;
}>;


export type GradeSlideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gradeSlide'>
);

export type FinishQuizMutationVariables = Exact<{
  online_quiz_id?: Maybe<Scalars['ID']>;
  online_quiz_token?: Maybe<Scalars['String']>;
}>;


export type FinishQuizMutation = (
  { __typename?: 'Mutation' }
  & { finishQuiz?: Maybe<(
    { __typename?: 'Online_quiz_result' }
    & Pick<Online_Quiz_Result, 'quiz_test_name' | 'start_time' | 'end_time' | 'slides_failed' | 'quiz_pass_status'>
  )> }
);

export type GetLatestResultQueryVariables = Exact<{
  base_quiz_id?: Maybe<Scalars['ID']>;
}>;


export type GetLatestResultQuery = (
  { __typename?: 'Query' }
  & { getLatestResult?: Maybe<Array<Maybe<(
    { __typename?: 'Online_quiz_result' }
    & Pick<Online_Quiz_Result, 'online_quiz_id' | 'quiz_test_name' | 'quiz_pass_status' | 'slide_amount' | 'slides_failed'>
    & { slide_progress?: Maybe<Array<Maybe<(
      { __typename?: 'progress' }
      & Pick<Progress, 'progress_is_marked' | 'progress_id' | 'original_slide_id' | 'online_quiz_id' | 'explanation_location' | 'intro_sound_text' | 'media_location' | 'media_type_id' | 'media_location_enc' | 'intro_sound_location' | 'slide_pass_status'>
      & { statement_progress?: Maybe<Array<Maybe<(
        { __typename?: 'statement' }
        & Pick<Statement, 'statement_progress_id' | 'online_slide_id' | 'original_statement_id' | 'statement_answer_id' | 'quiz_statement_no' | 'elaboration_Text' | 'sound_text' | 'sound_location' | 'slide_pass_status' | 'statement_progress_answer'>
      )>>> }
    )>>> }
  )>>> }
);

export type GetQuizResultQueryVariables = Exact<{
  online_quiz_id: Scalars['ID'];
}>;


export type GetQuizResultQuery = (
  { __typename?: 'Query' }
  & { getQuizResult?: Maybe<Array<Maybe<(
    { __typename?: 'Online_quiz_result' }
    & Pick<Online_Quiz_Result, 'online_quiz_id' | 'quiz_test_name' | 'quiz_pass_status' | 'slide_amount' | 'slides_failed'>
    & { slide_progress?: Maybe<Array<Maybe<(
      { __typename?: 'progress' }
      & Pick<Progress, 'progress_is_marked' | 'progress_id' | 'original_slide_id' | 'online_quiz_id' | 'explanation_location' | 'intro_sound_text' | 'media_location' | 'media_type_id' | 'media_location_enc' | 'intro_sound_location' | 'slide_pass_status'>
      & { statement_progress?: Maybe<Array<Maybe<(
        { __typename?: 'statement' }
        & Pick<Statement, 'statement_progress_id' | 'online_slide_id' | 'original_statement_id' | 'statement_answer_id' | 'quiz_statement_no' | 'elaboration_Text' | 'sound_text' | 'sound_location' | 'slide_pass_status' | 'statement_progress_answer'>
      )>>> }
    )>>> }
  )>>> }
);

export type CreateClassOnlineQuizMutationVariables = Exact<{
  online_quiz?: Maybe<Online_Quiz_Input>;
}>;


export type CreateClassOnlineQuizMutation = (
  { __typename?: 'Mutation' }
  & { createClassOnlineQuiz?: Maybe<(
    { __typename?: 'Online_quiz' }
    & Pick<Online_Quiz, 'quiz_token_id' | 'online_quiz_id'>
  )> }
);

export type FinishClassOnlineQuizMutationVariables = Exact<{
  quiz_class_id: Scalars['ID'];
}>;


export type FinishClassOnlineQuizMutation = (
  { __typename?: 'Mutation' }
  & { finishClassOnlineQuiz?: Maybe<(
    { __typename?: 'Online_quiz_result' }
    & Pick<Online_Quiz_Result, 'quiz_test_name' | 'quiz_pass_status' | 'slide_amount' | 'slides_failed'>
  )> }
);

export type GetCorrectQuizzesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCorrectQuizzesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCorrectQuizzes'>
);

export type GetAchievementTypesQueryVariables = Exact<{
  category?: Maybe<Scalars['Int']>;
}>;


export type GetAchievementTypesQuery = (
  { __typename?: 'Query' }
  & { getAchievementTypes?: Maybe<Array<Maybe<(
    { __typename?: 'achievement_type' }
    & Pick<Achievement_Type, 'achievement_type_id' | 'achievement_category' | 'achievement_language' | 'achievement_name' | 'achievement_desc' | 'achievement_type_desc'>
  )>>> }
);

export type GetStudentAchievementsQueryVariables = Exact<{
  achievement_type?: Maybe<Scalars['ID']>;
}>;


export type GetStudentAchievementsQuery = (
  { __typename?: 'Query' }
  & { getStudentAchievements?: Maybe<Array<Maybe<(
    { __typename?: 'Student_ach_get' }
    & Pick<Student_Ach_Get, 'dash_track_id' | 'achievement_id' | 'achievement_name' | 'achievement_location' | 'achievement_media_type' | 'achievement_languages' | 'dash_status' | 'dash_has_fails' | 'dash_quiz_id'>
  )>>> }
);

export type ManageAchievementMutationVariables = Exact<{
  achievement: Achievement_Grade;
}>;


export type ManageAchievementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageAchievement'>
);

export type CreateFreeQuizMutationVariables = Exact<{
  online_quiz?: Maybe<Online_Quiz_Input>;
}>;


export type CreateFreeQuizMutation = (
  { __typename?: 'Mutation' }
  & { createFreeQuiz?: Maybe<(
    { __typename?: 'Online_quiz' }
    & Pick<Online_Quiz, 'online_quiz_id' | 'quiz_token_id'>
  )> }
);

export type GetFailedSlidesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFailedSlidesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFailedSlides'>
);

export type MarkSlideMutationVariables = Exact<{
  marked_slides?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type MarkSlideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MarkSlide'>
);

export type UnmarkSlideMutationVariables = Exact<{
  unmarked_slides?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type UnmarkSlideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnmarkSlide'>
);

export type GetMarkedSlidesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMarkedSlidesQuery = (
  { __typename?: 'Query' }
  & { getMarkedSlides?: Maybe<Array<Maybe<(
    { __typename?: 'Mark_slide' }
    & Pick<Mark_Slide, 'slide_pass_status' | 'original_slide_id' | 'media_location' | 'intro_sound_text' | 'media_type_id'>
    & { slide_statements?: Maybe<Array<Maybe<(
      { __typename?: 'statement' }
      & Pick<Statement, 'statement_progress_id' | 'statement_progress_answer' | 'online_slide_id' | 'original_statement_id' | 'statement_answer_id' | 'quiz_statement_no' | 'elaboration_Text' | 'sound_text' | 'slide_pass_status'>
    )>>> }
  )>>> }
);

export type GetStudentPubLessonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudentPubLessonsQuery = (
  { __typename?: 'Query' }
  & { getStudentPubLessons?: Maybe<Array<Maybe<(
    { __typename?: 'lesson_achievement' }
    & Pick<Lesson_Achievement, 'lesson_id' | 'lesson_name' | 'ach_pub_lesson_view_date'>
  )>>> }
);

export type StudentViewLessonMutationVariables = Exact<{
  lesson_id?: Maybe<Scalars['ID']>;
  time_spent?: Maybe<Scalars['String']>;
}>;


export type StudentViewLessonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'studentViewLesson'>
);

export type GetCorrectQuizzesResultQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCorrectQuizzesResultQuery = (
  { __typename?: 'Query' }
  & { getCorrectQuizzesResult?: Maybe<Array<Maybe<(
    { __typename?: 'Online_quiz_result' }
    & Pick<Online_Quiz_Result, 'quiz_pass_status' | 'online_quiz_id' | 'quiz_test_name' | 'quiz_expired' | 'end_time' | 'achievement_name'>
  )>>> }
);

export type GetClassStudentResultQueryVariables = Exact<{
  class_quiz_id: Scalars['ID'];
}>;


export type GetClassStudentResultQuery = (
  { __typename?: 'Query' }
  & { getClassStudentResult?: Maybe<Array<Maybe<(
    { __typename?: 'progress' }
    & Pick<Progress, 'progress_id' | 'progress_is_marked' | 'intro_sound_text' | 'intro_sound_location' | 'slide_pass_status'>
    & { slide_statements?: Maybe<Array<Maybe<(
      { __typename?: 'statement' }
      & Pick<Statement, 'statement_progress_answer' | 'statement_answer_id' | 'sound_text' | 'slide_pass_status'>
    )>>> }
  )>>> }
);

export type GetClassStudentParticipantQueryVariables = Exact<{
  class_quiz_id: Scalars['ID'];
}>;


export type GetClassStudentParticipantQuery = (
  { __typename?: 'Query' }
  & { getClassStudentParticipant?: Maybe<(
    { __typename?: 'online_quiz_participants' }
    & Pick<Online_Quiz_Participants, 'student_amount' | 'student_passed' | 'student_failed'>
  )> }
);

export type AddAiStudentMutationVariables = Exact<{
  configId: Scalars['Int'];
}>;


export type AddAiStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addAIStudent'>
);

export type GetClassQuizByConfigIdQueryVariables = Exact<{
  config_id: Scalars['Int'];
}>;


export type GetClassQuizByConfigIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getClassQuizByConfigID'>
);

export type AddTeoriLessonStudentMutationVariables = Exact<{
  lessonId?: Maybe<Scalars['String']>;
}>;


export type AddTeoriLessonStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addTeoriLessonStudent'>
);

export type GetStudentTipsQueryVariables = Exact<{
  categoryId: Scalars['Int'];
}>;


export type GetStudentTipsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStudentTips'>
);


export const LoginStudentDocument = gql`
    mutation LoginStudent($pin_code: String, $phone_number: String) {
  LoginStudent(pin_code: $pin_code, phone_number: $phone_number) {
    accessToken
    refreshToken
    login_before
  }
}
    `;
export type LoginStudentMutationFn = Apollo.MutationFunction<LoginStudentMutation, LoginStudentMutationVariables>;

/**
 * __useLoginStudentMutation__
 *
 * To run a mutation, you first call `useLoginStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginStudentMutation, { data, loading, error }] = useLoginStudentMutation({
 *   variables: {
 *      pin_code: // value for 'pin_code'
 *      phone_number: // value for 'phone_number'
 *   },
 * });
 */
export function useLoginStudentMutation(baseOptions?: Apollo.MutationHookOptions<LoginStudentMutation, LoginStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginStudentMutation, LoginStudentMutationVariables>(LoginStudentDocument, options);
      }
export type LoginStudentMutationHookResult = ReturnType<typeof useLoginStudentMutation>;
export type LoginStudentMutationResult = Apollo.MutationResult<LoginStudentMutation>;
export type LoginStudentMutationOptions = Apollo.BaseMutationOptions<LoginStudentMutation, LoginStudentMutationVariables>;
export const RefreshUserDocument = gql`
    mutation RefreshUser($old_token: String!) {
  RefreshUser(old_token: $old_token) {
    accessToken
    refreshToken
  }
}
    `;
export type RefreshUserMutationFn = Apollo.MutationFunction<RefreshUserMutation, RefreshUserMutationVariables>;

/**
 * __useRefreshUserMutation__
 *
 * To run a mutation, you first call `useRefreshUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshUserMutation, { data, loading, error }] = useRefreshUserMutation({
 *   variables: {
 *      old_token: // value for 'old_token'
 *   },
 * });
 */
export function useRefreshUserMutation(baseOptions?: Apollo.MutationHookOptions<RefreshUserMutation, RefreshUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshUserMutation, RefreshUserMutationVariables>(RefreshUserDocument, options);
      }
export type RefreshUserMutationHookResult = ReturnType<typeof useRefreshUserMutation>;
export type RefreshUserMutationResult = Apollo.MutationResult<RefreshUserMutation>;
export type RefreshUserMutationOptions = Apollo.BaseMutationOptions<RefreshUserMutation, RefreshUserMutationVariables>;
export const VerifyStudentDocument = gql`
    mutation VerifyStudent($entry_code: String, $student_phone_number: String) {
  VerifyStudent(
    entry_code: $entry_code
    student_phone_number: $student_phone_number
  )
}
    `;
export type VerifyStudentMutationFn = Apollo.MutationFunction<VerifyStudentMutation, VerifyStudentMutationVariables>;

/**
 * __useVerifyStudentMutation__
 *
 * To run a mutation, you first call `useVerifyStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyStudentMutation, { data, loading, error }] = useVerifyStudentMutation({
 *   variables: {
 *      entry_code: // value for 'entry_code'
 *      student_phone_number: // value for 'student_phone_number'
 *   },
 * });
 */
export function useVerifyStudentMutation(baseOptions?: Apollo.MutationHookOptions<VerifyStudentMutation, VerifyStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyStudentMutation, VerifyStudentMutationVariables>(VerifyStudentDocument, options);
      }
export type VerifyStudentMutationHookResult = ReturnType<typeof useVerifyStudentMutation>;
export type VerifyStudentMutationResult = Apollo.MutationResult<VerifyStudentMutation>;
export type VerifyStudentMutationOptions = Apollo.BaseMutationOptions<VerifyStudentMutation, VerifyStudentMutationVariables>;
export const AddPinStudentDocument = gql`
    mutation AddPinStudent($pin_code: String, $phone_number: String) {
  AddPinStudent(pin_code: $pin_code, phone_number: $phone_number)
}
    `;
export type AddPinStudentMutationFn = Apollo.MutationFunction<AddPinStudentMutation, AddPinStudentMutationVariables>;

/**
 * __useAddPinStudentMutation__
 *
 * To run a mutation, you first call `useAddPinStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPinStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPinStudentMutation, { data, loading, error }] = useAddPinStudentMutation({
 *   variables: {
 *      pin_code: // value for 'pin_code'
 *      phone_number: // value for 'phone_number'
 *   },
 * });
 */
export function useAddPinStudentMutation(baseOptions?: Apollo.MutationHookOptions<AddPinStudentMutation, AddPinStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPinStudentMutation, AddPinStudentMutationVariables>(AddPinStudentDocument, options);
      }
export type AddPinStudentMutationHookResult = ReturnType<typeof useAddPinStudentMutation>;
export type AddPinStudentMutationResult = Apollo.MutationResult<AddPinStudentMutation>;
export type AddPinStudentMutationOptions = Apollo.BaseMutationOptions<AddPinStudentMutation, AddPinStudentMutationVariables>;
export const VerifyMobileNoDocument = gql`
    query verifyMobileNo($student_phone_number: String!) {
  verifyMobileNo(student_phone_number: $student_phone_number)
}
    `;

/**
 * __useVerifyMobileNoQuery__
 *
 * To run a query within a React component, call `useVerifyMobileNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyMobileNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyMobileNoQuery({
 *   variables: {
 *      student_phone_number: // value for 'student_phone_number'
 *   },
 * });
 */
export function useVerifyMobileNoQuery(baseOptions: Apollo.QueryHookOptions<VerifyMobileNoQuery, VerifyMobileNoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyMobileNoQuery, VerifyMobileNoQueryVariables>(VerifyMobileNoDocument, options);
      }
export function useVerifyMobileNoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyMobileNoQuery, VerifyMobileNoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyMobileNoQuery, VerifyMobileNoQueryVariables>(VerifyMobileNoDocument, options);
        }
export type VerifyMobileNoQueryHookResult = ReturnType<typeof useVerifyMobileNoQuery>;
export type VerifyMobileNoLazyQueryHookResult = ReturnType<typeof useVerifyMobileNoLazyQuery>;
export type VerifyMobileNoQueryResult = Apollo.QueryResult<VerifyMobileNoQuery, VerifyMobileNoQueryVariables>;
export const ConfirmMobileNoDocument = gql`
    query confirmMobileNo($student_phone_number: String!) {
  confirmMobileNo(student_phone_number: $student_phone_number)
}
    `;

/**
 * __useConfirmMobileNoQuery__
 *
 * To run a query within a React component, call `useConfirmMobileNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmMobileNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmMobileNoQuery({
 *   variables: {
 *      student_phone_number: // value for 'student_phone_number'
 *   },
 * });
 */
export function useConfirmMobileNoQuery(baseOptions: Apollo.QueryHookOptions<ConfirmMobileNoQuery, ConfirmMobileNoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmMobileNoQuery, ConfirmMobileNoQueryVariables>(ConfirmMobileNoDocument, options);
      }
export function useConfirmMobileNoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmMobileNoQuery, ConfirmMobileNoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmMobileNoQuery, ConfirmMobileNoQueryVariables>(ConfirmMobileNoDocument, options);
        }
export type ConfirmMobileNoQueryHookResult = ReturnType<typeof useConfirmMobileNoQuery>;
export type ConfirmMobileNoLazyQueryHookResult = ReturnType<typeof useConfirmMobileNoLazyQuery>;
export type ConfirmMobileNoQueryResult = Apollo.QueryResult<ConfirmMobileNoQuery, ConfirmMobileNoQueryVariables>;
export const GetActiveNoticesDocument = gql`
    query getActiveNotices {
  getActiveNotices {
    notice_body
    notice_background_color
  }
}
    `;

/**
 * __useGetActiveNoticesQuery__
 *
 * To run a query within a React component, call `useGetActiveNoticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveNoticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveNoticesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveNoticesQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveNoticesQuery, GetActiveNoticesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveNoticesQuery, GetActiveNoticesQueryVariables>(GetActiveNoticesDocument, options);
      }
export function useGetActiveNoticesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveNoticesQuery, GetActiveNoticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveNoticesQuery, GetActiveNoticesQueryVariables>(GetActiveNoticesDocument, options);
        }
export type GetActiveNoticesQueryHookResult = ReturnType<typeof useGetActiveNoticesQuery>;
export type GetActiveNoticesLazyQueryHookResult = ReturnType<typeof useGetActiveNoticesLazyQuery>;
export type GetActiveNoticesQueryResult = Apollo.QueryResult<GetActiveNoticesQuery, GetActiveNoticesQueryVariables>;
export const GetStudentSubjectsDocument = gql`
    query getStudentSubjects {
  getStudentSubjects {
    achievement_id
    achievement_owner_id
    achievement_desc
    achievement_status
    achievement_type_desc
    achievement_type_name
    achievement_location
    achievement_quiz_id
    achievement_has_fails
  }
}
    `;

/**
 * __useGetStudentSubjectsQuery__
 *
 * To run a query within a React component, call `useGetStudentSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentSubjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentSubjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentSubjectsQuery, GetStudentSubjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentSubjectsQuery, GetStudentSubjectsQueryVariables>(GetStudentSubjectsDocument, options);
      }
export function useGetStudentSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentSubjectsQuery, GetStudentSubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentSubjectsQuery, GetStudentSubjectsQueryVariables>(GetStudentSubjectsDocument, options);
        }
export type GetStudentSubjectsQueryHookResult = ReturnType<typeof useGetStudentSubjectsQuery>;
export type GetStudentSubjectsLazyQueryHookResult = ReturnType<typeof useGetStudentSubjectsLazyQuery>;
export type GetStudentSubjectsQueryResult = Apollo.QueryResult<GetStudentSubjectsQuery, GetStudentSubjectsQueryVariables>;
export const GetStudentCategoriesDocument = gql`
    query getStudentCategories {
  getStudentCategories
}
    `;

/**
 * __useGetStudentCategoriesQuery__
 *
 * To run a query within a React component, call `useGetStudentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentCategoriesQuery, GetStudentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentCategoriesQuery, GetStudentCategoriesQueryVariables>(GetStudentCategoriesDocument, options);
      }
export function useGetStudentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentCategoriesQuery, GetStudentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentCategoriesQuery, GetStudentCategoriesQueryVariables>(GetStudentCategoriesDocument, options);
        }
export type GetStudentCategoriesQueryHookResult = ReturnType<typeof useGetStudentCategoriesQuery>;
export type GetStudentCategoriesLazyQueryHookResult = ReturnType<typeof useGetStudentCategoriesLazyQuery>;
export type GetStudentCategoriesQueryResult = Apollo.QueryResult<GetStudentCategoriesQuery, GetStudentCategoriesQueryVariables>;
export const GetEvalTestsDocument = gql`
    query getEvalTests {
  getEvalTests {
    achievement_id
    achievement_owner_id
    achievement_desc
    achievement_status
    achievement_type_name
    achievement_type_desc
    achievement_location
    achievement_quiz_id
    achievement_has_fails
  }
}
    `;

/**
 * __useGetEvalTestsQuery__
 *
 * To run a query within a React component, call `useGetEvalTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvalTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvalTestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEvalTestsQuery(baseOptions?: Apollo.QueryHookOptions<GetEvalTestsQuery, GetEvalTestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEvalTestsQuery, GetEvalTestsQueryVariables>(GetEvalTestsDocument, options);
      }
export function useGetEvalTestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEvalTestsQuery, GetEvalTestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEvalTestsQuery, GetEvalTestsQueryVariables>(GetEvalTestsDocument, options);
        }
export type GetEvalTestsQueryHookResult = ReturnType<typeof useGetEvalTestsQuery>;
export type GetEvalTestsLazyQueryHookResult = ReturnType<typeof useGetEvalTestsLazyQuery>;
export type GetEvalTestsQueryResult = Apollo.QueryResult<GetEvalTestsQuery, GetEvalTestsQueryVariables>;
export const GetOnlineBookInfoDocument = gql`
    query getOnlineBookInfo {
  getOnlineBookInfo {
    achievement_id
    achievement_owner_id
    achievement_desc
    achievement_status
    achievement_type_desc
    achievement_type_name
    achievement_location
    achievement_location
    achievement_quiz_id
    achievement_has_fails
  }
}
    `;

/**
 * __useGetOnlineBookInfoQuery__
 *
 * To run a query within a React component, call `useGetOnlineBookInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnlineBookInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnlineBookInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnlineBookInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetOnlineBookInfoQuery, GetOnlineBookInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnlineBookInfoQuery, GetOnlineBookInfoQueryVariables>(GetOnlineBookInfoDocument, options);
      }
export function useGetOnlineBookInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnlineBookInfoQuery, GetOnlineBookInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnlineBookInfoQuery, GetOnlineBookInfoQueryVariables>(GetOnlineBookInfoDocument, options);
        }
export type GetOnlineBookInfoQueryHookResult = ReturnType<typeof useGetOnlineBookInfoQuery>;
export type GetOnlineBookInfoLazyQueryHookResult = ReturnType<typeof useGetOnlineBookInfoLazyQuery>;
export type GetOnlineBookInfoQueryResult = Apollo.QueryResult<GetOnlineBookInfoQuery, GetOnlineBookInfoQueryVariables>;
export const GetDrivingSchoolClassesDocument = gql`
    query getDrivingSchoolClasses {
  getDrivingSchoolClasses {
    achievement_id
    achievement_owner_id
    achievement_desc
    achievement_type_name
    achievement_status
    achievement_type_desc
    achievement_location
    achievement_quiz_id
    achievement_has_fails
  }
}
    `;

/**
 * __useGetDrivingSchoolClassesQuery__
 *
 * To run a query within a React component, call `useGetDrivingSchoolClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrivingSchoolClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrivingSchoolClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDrivingSchoolClassesQuery(baseOptions?: Apollo.QueryHookOptions<GetDrivingSchoolClassesQuery, GetDrivingSchoolClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDrivingSchoolClassesQuery, GetDrivingSchoolClassesQueryVariables>(GetDrivingSchoolClassesDocument, options);
      }
export function useGetDrivingSchoolClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDrivingSchoolClassesQuery, GetDrivingSchoolClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDrivingSchoolClassesQuery, GetDrivingSchoolClassesQueryVariables>(GetDrivingSchoolClassesDocument, options);
        }
export type GetDrivingSchoolClassesQueryHookResult = ReturnType<typeof useGetDrivingSchoolClassesQuery>;
export type GetDrivingSchoolClassesLazyQueryHookResult = ReturnType<typeof useGetDrivingSchoolClassesLazyQuery>;
export type GetDrivingSchoolClassesQueryResult = Apollo.QueryResult<GetDrivingSchoolClassesQuery, GetDrivingSchoolClassesQueryVariables>;
export const GetBeforeDrivingTestDocument = gql`
    query getBeforeDrivingTest {
  getBeforeDrivingTest {
    achievement_id
    achievement_owner_id
    achievement_desc
    achievement_type_name
    achievement_status
    achievement_type_desc
    achievement_location
    achievement_quiz_id
    achievement_has_fails
  }
}
    `;

/**
 * __useGetBeforeDrivingTestQuery__
 *
 * To run a query within a React component, call `useGetBeforeDrivingTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBeforeDrivingTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBeforeDrivingTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBeforeDrivingTestQuery(baseOptions?: Apollo.QueryHookOptions<GetBeforeDrivingTestQuery, GetBeforeDrivingTestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBeforeDrivingTestQuery, GetBeforeDrivingTestQueryVariables>(GetBeforeDrivingTestDocument, options);
      }
export function useGetBeforeDrivingTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBeforeDrivingTestQuery, GetBeforeDrivingTestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBeforeDrivingTestQuery, GetBeforeDrivingTestQueryVariables>(GetBeforeDrivingTestDocument, options);
        }
export type GetBeforeDrivingTestQueryHookResult = ReturnType<typeof useGetBeforeDrivingTestQuery>;
export type GetBeforeDrivingTestLazyQueryHookResult = ReturnType<typeof useGetBeforeDrivingTestLazyQuery>;
export type GetBeforeDrivingTestQueryResult = Apollo.QueryResult<GetBeforeDrivingTestQuery, GetBeforeDrivingTestQueryVariables>;
export const GetStartContentDocument = gql`
    query getStartContent {
  getStartContent {
    achievement_id
    achievement_owner_id
    achievement_desc
    achievement_type_name
    achievement_status
    achievement_type_desc
    achievement_location
    achievement_quiz_id
    achievement_has_fails
  }
}
    `;

/**
 * __useGetStartContentQuery__
 *
 * To run a query within a React component, call `useGetStartContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStartContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStartContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStartContentQuery(baseOptions?: Apollo.QueryHookOptions<GetStartContentQuery, GetStartContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStartContentQuery, GetStartContentQueryVariables>(GetStartContentDocument, options);
      }
export function useGetStartContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStartContentQuery, GetStartContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStartContentQuery, GetStartContentQueryVariables>(GetStartContentDocument, options);
        }
export type GetStartContentQueryHookResult = ReturnType<typeof useGetStartContentQuery>;
export type GetStartContentLazyQueryHookResult = ReturnType<typeof useGetStartContentLazyQuery>;
export type GetStartContentQueryResult = Apollo.QueryResult<GetStartContentQuery, GetStartContentQueryVariables>;
export const ManageStartContentDocument = gql`
    mutation manageStartContent($achievement: Achievement_grade!) {
  manageStartContent(achievement: $achievement)
}
    `;
export type ManageStartContentMutationFn = Apollo.MutationFunction<ManageStartContentMutation, ManageStartContentMutationVariables>;

/**
 * __useManageStartContentMutation__
 *
 * To run a mutation, you first call `useManageStartContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageStartContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageStartContentMutation, { data, loading, error }] = useManageStartContentMutation({
 *   variables: {
 *      achievement: // value for 'achievement'
 *   },
 * });
 */
export function useManageStartContentMutation(baseOptions?: Apollo.MutationHookOptions<ManageStartContentMutation, ManageStartContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageStartContentMutation, ManageStartContentMutationVariables>(ManageStartContentDocument, options);
      }
export type ManageStartContentMutationHookResult = ReturnType<typeof useManageStartContentMutation>;
export type ManageStartContentMutationResult = Apollo.MutationResult<ManageStartContentMutation>;
export type ManageStartContentMutationOptions = Apollo.BaseMutationOptions<ManageStartContentMutation, ManageStartContentMutationVariables>;
export const ManageSubjectDocument = gql`
    mutation manageSubject($achievement: Achievement_grade!) {
  manageSubject(achievement: $achievement)
}
    `;
export type ManageSubjectMutationFn = Apollo.MutationFunction<ManageSubjectMutation, ManageSubjectMutationVariables>;

/**
 * __useManageSubjectMutation__
 *
 * To run a mutation, you first call `useManageSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageSubjectMutation, { data, loading, error }] = useManageSubjectMutation({
 *   variables: {
 *      achievement: // value for 'achievement'
 *   },
 * });
 */
export function useManageSubjectMutation(baseOptions?: Apollo.MutationHookOptions<ManageSubjectMutation, ManageSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageSubjectMutation, ManageSubjectMutationVariables>(ManageSubjectDocument, options);
      }
export type ManageSubjectMutationHookResult = ReturnType<typeof useManageSubjectMutation>;
export type ManageSubjectMutationResult = Apollo.MutationResult<ManageSubjectMutation>;
export type ManageSubjectMutationOptions = Apollo.BaseMutationOptions<ManageSubjectMutation, ManageSubjectMutationVariables>;
export const ManageEvalTestsDocument = gql`
    mutation manageEvalTests($achievement: Achievement_grade!) {
  manageEvalTests(achievement: $achievement)
}
    `;
export type ManageEvalTestsMutationFn = Apollo.MutationFunction<ManageEvalTestsMutation, ManageEvalTestsMutationVariables>;

/**
 * __useManageEvalTestsMutation__
 *
 * To run a mutation, you first call `useManageEvalTestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageEvalTestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageEvalTestsMutation, { data, loading, error }] = useManageEvalTestsMutation({
 *   variables: {
 *      achievement: // value for 'achievement'
 *   },
 * });
 */
export function useManageEvalTestsMutation(baseOptions?: Apollo.MutationHookOptions<ManageEvalTestsMutation, ManageEvalTestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageEvalTestsMutation, ManageEvalTestsMutationVariables>(ManageEvalTestsDocument, options);
      }
export type ManageEvalTestsMutationHookResult = ReturnType<typeof useManageEvalTestsMutation>;
export type ManageEvalTestsMutationResult = Apollo.MutationResult<ManageEvalTestsMutation>;
export type ManageEvalTestsMutationOptions = Apollo.BaseMutationOptions<ManageEvalTestsMutation, ManageEvalTestsMutationVariables>;
export const ManageOnlineBookDocument = gql`
    mutation manageOnlineBook($achievement: Achievement_grade!) {
  manageOnlineBook(achievement: $achievement)
}
    `;
export type ManageOnlineBookMutationFn = Apollo.MutationFunction<ManageOnlineBookMutation, ManageOnlineBookMutationVariables>;

/**
 * __useManageOnlineBookMutation__
 *
 * To run a mutation, you first call `useManageOnlineBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageOnlineBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageOnlineBookMutation, { data, loading, error }] = useManageOnlineBookMutation({
 *   variables: {
 *      achievement: // value for 'achievement'
 *   },
 * });
 */
export function useManageOnlineBookMutation(baseOptions?: Apollo.MutationHookOptions<ManageOnlineBookMutation, ManageOnlineBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageOnlineBookMutation, ManageOnlineBookMutationVariables>(ManageOnlineBookDocument, options);
      }
export type ManageOnlineBookMutationHookResult = ReturnType<typeof useManageOnlineBookMutation>;
export type ManageOnlineBookMutationResult = Apollo.MutationResult<ManageOnlineBookMutation>;
export type ManageOnlineBookMutationOptions = Apollo.BaseMutationOptions<ManageOnlineBookMutation, ManageOnlineBookMutationVariables>;
export const ManageSchoolLessonsDocument = gql`
    mutation manageSchoolLessons($achievement: Achievement_grade!) {
  manageSchoolLessons(achievement: $achievement)
}
    `;
export type ManageSchoolLessonsMutationFn = Apollo.MutationFunction<ManageSchoolLessonsMutation, ManageSchoolLessonsMutationVariables>;

/**
 * __useManageSchoolLessonsMutation__
 *
 * To run a mutation, you first call `useManageSchoolLessonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageSchoolLessonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageSchoolLessonsMutation, { data, loading, error }] = useManageSchoolLessonsMutation({
 *   variables: {
 *      achievement: // value for 'achievement'
 *   },
 * });
 */
export function useManageSchoolLessonsMutation(baseOptions?: Apollo.MutationHookOptions<ManageSchoolLessonsMutation, ManageSchoolLessonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageSchoolLessonsMutation, ManageSchoolLessonsMutationVariables>(ManageSchoolLessonsDocument, options);
      }
export type ManageSchoolLessonsMutationHookResult = ReturnType<typeof useManageSchoolLessonsMutation>;
export type ManageSchoolLessonsMutationResult = Apollo.MutationResult<ManageSchoolLessonsMutation>;
export type ManageSchoolLessonsMutationOptions = Apollo.BaseMutationOptions<ManageSchoolLessonsMutation, ManageSchoolLessonsMutationVariables>;
export const ManageBeforeDrivingDocument = gql`
    mutation manageBeforeDriving($achievement: Achievement_grade!) {
  manageBeforeDriving(achievement: $achievement)
}
    `;
export type ManageBeforeDrivingMutationFn = Apollo.MutationFunction<ManageBeforeDrivingMutation, ManageBeforeDrivingMutationVariables>;

/**
 * __useManageBeforeDrivingMutation__
 *
 * To run a mutation, you first call `useManageBeforeDrivingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageBeforeDrivingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageBeforeDrivingMutation, { data, loading, error }] = useManageBeforeDrivingMutation({
 *   variables: {
 *      achievement: // value for 'achievement'
 *   },
 * });
 */
export function useManageBeforeDrivingMutation(baseOptions?: Apollo.MutationHookOptions<ManageBeforeDrivingMutation, ManageBeforeDrivingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageBeforeDrivingMutation, ManageBeforeDrivingMutationVariables>(ManageBeforeDrivingDocument, options);
      }
export type ManageBeforeDrivingMutationHookResult = ReturnType<typeof useManageBeforeDrivingMutation>;
export type ManageBeforeDrivingMutationResult = Apollo.MutationResult<ManageBeforeDrivingMutation>;
export type ManageBeforeDrivingMutationOptions = Apollo.BaseMutationOptions<ManageBeforeDrivingMutation, ManageBeforeDrivingMutationVariables>;
export const GetStudentInfoDocument = gql`
    query getStudentInfo {
  getStudentInfo {
    school_id
    first_name
    last_name
    student_email
    student_phone_number
    student_category
    language_choice
    teori_department
  }
}
    `;

/**
 * __useGetStudentInfoQuery__
 *
 * To run a query within a React component, call `useGetStudentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentInfoQuery, GetStudentInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentInfoQuery, GetStudentInfoQueryVariables>(GetStudentInfoDocument, options);
      }
export function useGetStudentInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentInfoQuery, GetStudentInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentInfoQuery, GetStudentInfoQueryVariables>(GetStudentInfoDocument, options);
        }
export type GetStudentInfoQueryHookResult = ReturnType<typeof useGetStudentInfoQuery>;
export type GetStudentInfoLazyQueryHookResult = ReturnType<typeof useGetStudentInfoLazyQuery>;
export type GetStudentInfoQueryResult = Apollo.QueryResult<GetStudentInfoQuery, GetStudentInfoQueryVariables>;
export const GetStudentNameDocument = gql`
    query getStudentName {
  getStudentInfo {
    first_name
    last_name
  }
}
    `;

/**
 * __useGetStudentNameQuery__
 *
 * To run a query within a React component, call `useGetStudentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentNameQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentNameQuery, GetStudentNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentNameQuery, GetStudentNameQueryVariables>(GetStudentNameDocument, options);
      }
export function useGetStudentNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentNameQuery, GetStudentNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentNameQuery, GetStudentNameQueryVariables>(GetStudentNameDocument, options);
        }
export type GetStudentNameQueryHookResult = ReturnType<typeof useGetStudentNameQuery>;
export type GetStudentNameLazyQueryHookResult = ReturnType<typeof useGetStudentNameLazyQuery>;
export type GetStudentNameQueryResult = Apollo.QueryResult<GetStudentNameQuery, GetStudentNameQueryVariables>;
export const ManageStudentDocument = gql`
    mutation manageStudent($student: Student_Input!) {
  manageStudent(student: $student)
}
    `;
export type ManageStudentMutationFn = Apollo.MutationFunction<ManageStudentMutation, ManageStudentMutationVariables>;

/**
 * __useManageStudentMutation__
 *
 * To run a mutation, you first call `useManageStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageStudentMutation, { data, loading, error }] = useManageStudentMutation({
 *   variables: {
 *      student: // value for 'student'
 *   },
 * });
 */
export function useManageStudentMutation(baseOptions?: Apollo.MutationHookOptions<ManageStudentMutation, ManageStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageStudentMutation, ManageStudentMutationVariables>(ManageStudentDocument, options);
      }
export type ManageStudentMutationHookResult = ReturnType<typeof useManageStudentMutation>;
export type ManageStudentMutationResult = Apollo.MutationResult<ManageStudentMutation>;
export type ManageStudentMutationOptions = Apollo.BaseMutationOptions<ManageStudentMutation, ManageStudentMutationVariables>;
export const GetSchoolListDocument = gql`
    query getSchoolList {
  getSchoolList {
    school_id
    school_name
    zipcode
  }
}
    `;

/**
 * __useGetSchoolListQuery__
 *
 * To run a query within a React component, call `useGetSchoolListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchoolListQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolListQuery, GetSchoolListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolListQuery, GetSchoolListQueryVariables>(GetSchoolListDocument, options);
      }
export function useGetSchoolListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolListQuery, GetSchoolListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolListQuery, GetSchoolListQueryVariables>(GetSchoolListDocument, options);
        }
export type GetSchoolListQueryHookResult = ReturnType<typeof useGetSchoolListQuery>;
export type GetSchoolListLazyQueryHookResult = ReturnType<typeof useGetSchoolListLazyQuery>;
export type GetSchoolListQueryResult = Apollo.QueryResult<GetSchoolListQuery, GetSchoolListQueryVariables>;
export const GetDepartmentListDocument = gql`
    query getDepartmentList {
  getDepartmentList {
    subuser_id
    department_name
    department_zipcode
    school_id
  }
}
    `;

/**
 * __useGetDepartmentListQuery__
 *
 * To run a query within a React component, call `useGetDepartmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDepartmentListQuery(baseOptions?: Apollo.QueryHookOptions<GetDepartmentListQuery, GetDepartmentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDepartmentListQuery, GetDepartmentListQueryVariables>(GetDepartmentListDocument, options);
      }
export function useGetDepartmentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDepartmentListQuery, GetDepartmentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDepartmentListQuery, GetDepartmentListQueryVariables>(GetDepartmentListDocument, options);
        }
export type GetDepartmentListQueryHookResult = ReturnType<typeof useGetDepartmentListQuery>;
export type GetDepartmentListLazyQueryHookResult = ReturnType<typeof useGetDepartmentListLazyQuery>;
export type GetDepartmentListQueryResult = Apollo.QueryResult<GetDepartmentListQuery, GetDepartmentListQueryVariables>;
export const CreateQuizDocument = gql`
    mutation createQuiz($online_quiz: Online_quiz_input) {
  createQuiz(online_quiz: $online_quiz) {
    online_quiz_id
    quiz_token_id
  }
}
    `;
export type CreateQuizMutationFn = Apollo.MutationFunction<CreateQuizMutation, CreateQuizMutationVariables>;

/**
 * __useCreateQuizMutation__
 *
 * To run a mutation, you first call `useCreateQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuizMutation, { data, loading, error }] = useCreateQuizMutation({
 *   variables: {
 *      online_quiz: // value for 'online_quiz'
 *   },
 * });
 */
export function useCreateQuizMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuizMutation, CreateQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuizMutation, CreateQuizMutationVariables>(CreateQuizDocument, options);
      }
export type CreateQuizMutationHookResult = ReturnType<typeof useCreateQuizMutation>;
export type CreateQuizMutationResult = Apollo.MutationResult<CreateQuizMutation>;
export type CreateQuizMutationOptions = Apollo.BaseMutationOptions<CreateQuizMutation, CreateQuizMutationVariables>;
export const StartContentDocument = gql`
    mutation startContent($online_quiz_id: ID, $quiz_token_id: String) {
  startContent(online_quiz_id: $online_quiz_id, quiz_token_id: $quiz_token_id) {
    quiz_test_name
    quiz_speed
    quiz_speed_value
    quiz_language
    quiz_subtitles
    quiz_read_only
    slide_progress {
      progress_is_marked
      progress_id
      original_slide_id
      online_quiz_id
      explanation_location
      intro_sound_text
      media_location
      media_type_id
      media_location_enc
      intro_sound_location
      slide_pass_status
      statement_progress {
        statement_progress_id
        online_slide_id
        original_statement_id
        statement_answer_id
        quiz_statement_no
        elaboration_Text
        sound_text
        sound_location
        slide_pass_status
      }
    }
  }
}
    `;
export type StartContentMutationFn = Apollo.MutationFunction<StartContentMutation, StartContentMutationVariables>;

/**
 * __useStartContentMutation__
 *
 * To run a mutation, you first call `useStartContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startContentMutation, { data, loading, error }] = useStartContentMutation({
 *   variables: {
 *      online_quiz_id: // value for 'online_quiz_id'
 *      quiz_token_id: // value for 'quiz_token_id'
 *   },
 * });
 */
export function useStartContentMutation(baseOptions?: Apollo.MutationHookOptions<StartContentMutation, StartContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartContentMutation, StartContentMutationVariables>(StartContentDocument, options);
      }
export type StartContentMutationHookResult = ReturnType<typeof useStartContentMutation>;
export type StartContentMutationResult = Apollo.MutationResult<StartContentMutation>;
export type StartContentMutationOptions = Apollo.BaseMutationOptions<StartContentMutation, StartContentMutationVariables>;
export const GradeSlideDocument = gql`
    mutation gradeSlide($gradedSlide: slide_progress) {
  gradeSlide(graded_slide: $gradedSlide)
}
    `;
export type GradeSlideMutationFn = Apollo.MutationFunction<GradeSlideMutation, GradeSlideMutationVariables>;

/**
 * __useGradeSlideMutation__
 *
 * To run a mutation, you first call `useGradeSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGradeSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gradeSlideMutation, { data, loading, error }] = useGradeSlideMutation({
 *   variables: {
 *      gradedSlide: // value for 'gradedSlide'
 *   },
 * });
 */
export function useGradeSlideMutation(baseOptions?: Apollo.MutationHookOptions<GradeSlideMutation, GradeSlideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GradeSlideMutation, GradeSlideMutationVariables>(GradeSlideDocument, options);
      }
export type GradeSlideMutationHookResult = ReturnType<typeof useGradeSlideMutation>;
export type GradeSlideMutationResult = Apollo.MutationResult<GradeSlideMutation>;
export type GradeSlideMutationOptions = Apollo.BaseMutationOptions<GradeSlideMutation, GradeSlideMutationVariables>;
export const FinishQuizDocument = gql`
    mutation finishQuiz($online_quiz_id: ID, $online_quiz_token: String) {
  finishQuiz(online_quiz_id: $online_quiz_id, quiz_token_id: $online_quiz_token) {
    quiz_test_name
    start_time
    end_time
    slides_failed
    quiz_pass_status
  }
}
    `;
export type FinishQuizMutationFn = Apollo.MutationFunction<FinishQuizMutation, FinishQuizMutationVariables>;

/**
 * __useFinishQuizMutation__
 *
 * To run a mutation, you first call `useFinishQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishQuizMutation, { data, loading, error }] = useFinishQuizMutation({
 *   variables: {
 *      online_quiz_id: // value for 'online_quiz_id'
 *      online_quiz_token: // value for 'online_quiz_token'
 *   },
 * });
 */
export function useFinishQuizMutation(baseOptions?: Apollo.MutationHookOptions<FinishQuizMutation, FinishQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishQuizMutation, FinishQuizMutationVariables>(FinishQuizDocument, options);
      }
export type FinishQuizMutationHookResult = ReturnType<typeof useFinishQuizMutation>;
export type FinishQuizMutationResult = Apollo.MutationResult<FinishQuizMutation>;
export type FinishQuizMutationOptions = Apollo.BaseMutationOptions<FinishQuizMutation, FinishQuizMutationVariables>;
export const GetLatestResultDocument = gql`
    query getLatestResult($base_quiz_id: ID) {
  getLatestResult(base_quiz_id: $base_quiz_id) {
    online_quiz_id
    quiz_test_name
    quiz_pass_status
    slide_amount
    slides_failed
    slide_progress {
      progress_is_marked
      progress_id
      original_slide_id
      online_quiz_id
      explanation_location
      intro_sound_text
      media_location
      media_type_id
      media_location_enc
      intro_sound_location
      slide_pass_status
      statement_progress {
        statement_progress_id
        online_slide_id
        original_statement_id
        statement_answer_id
        quiz_statement_no
        elaboration_Text
        sound_text
        sound_location
        slide_pass_status
        statement_progress_answer
      }
    }
  }
}
    `;

/**
 * __useGetLatestResultQuery__
 *
 * To run a query within a React component, call `useGetLatestResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestResultQuery({
 *   variables: {
 *      base_quiz_id: // value for 'base_quiz_id'
 *   },
 * });
 */
export function useGetLatestResultQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestResultQuery, GetLatestResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestResultQuery, GetLatestResultQueryVariables>(GetLatestResultDocument, options);
      }
export function useGetLatestResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestResultQuery, GetLatestResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestResultQuery, GetLatestResultQueryVariables>(GetLatestResultDocument, options);
        }
export type GetLatestResultQueryHookResult = ReturnType<typeof useGetLatestResultQuery>;
export type GetLatestResultLazyQueryHookResult = ReturnType<typeof useGetLatestResultLazyQuery>;
export type GetLatestResultQueryResult = Apollo.QueryResult<GetLatestResultQuery, GetLatestResultQueryVariables>;
export const GetQuizResultDocument = gql`
    query getQuizResult($online_quiz_id: ID!) {
  getQuizResult(online_quiz_id: $online_quiz_id) {
    online_quiz_id
    quiz_test_name
    quiz_pass_status
    slide_amount
    slides_failed
    slide_progress {
      progress_is_marked
      progress_id
      original_slide_id
      online_quiz_id
      explanation_location
      intro_sound_text
      media_location
      media_type_id
      media_location_enc
      intro_sound_location
      slide_pass_status
      statement_progress {
        statement_progress_id
        online_slide_id
        original_statement_id
        statement_answer_id
        quiz_statement_no
        elaboration_Text
        sound_text
        sound_location
        slide_pass_status
        statement_progress_answer
      }
    }
  }
}
    `;

/**
 * __useGetQuizResultQuery__
 *
 * To run a query within a React component, call `useGetQuizResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuizResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuizResultQuery({
 *   variables: {
 *      online_quiz_id: // value for 'online_quiz_id'
 *   },
 * });
 */
export function useGetQuizResultQuery(baseOptions: Apollo.QueryHookOptions<GetQuizResultQuery, GetQuizResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuizResultQuery, GetQuizResultQueryVariables>(GetQuizResultDocument, options);
      }
export function useGetQuizResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuizResultQuery, GetQuizResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuizResultQuery, GetQuizResultQueryVariables>(GetQuizResultDocument, options);
        }
export type GetQuizResultQueryHookResult = ReturnType<typeof useGetQuizResultQuery>;
export type GetQuizResultLazyQueryHookResult = ReturnType<typeof useGetQuizResultLazyQuery>;
export type GetQuizResultQueryResult = Apollo.QueryResult<GetQuizResultQuery, GetQuizResultQueryVariables>;
export const CreateClassOnlineQuizDocument = gql`
    mutation createClassOnlineQuiz($online_quiz: Online_quiz_input) {
  createClassOnlineQuiz(online_quiz: $online_quiz) {
    quiz_token_id
    online_quiz_id
  }
}
    `;
export type CreateClassOnlineQuizMutationFn = Apollo.MutationFunction<CreateClassOnlineQuizMutation, CreateClassOnlineQuizMutationVariables>;

/**
 * __useCreateClassOnlineQuizMutation__
 *
 * To run a mutation, you first call `useCreateClassOnlineQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassOnlineQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassOnlineQuizMutation, { data, loading, error }] = useCreateClassOnlineQuizMutation({
 *   variables: {
 *      online_quiz: // value for 'online_quiz'
 *   },
 * });
 */
export function useCreateClassOnlineQuizMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassOnlineQuizMutation, CreateClassOnlineQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassOnlineQuizMutation, CreateClassOnlineQuizMutationVariables>(CreateClassOnlineQuizDocument, options);
      }
export type CreateClassOnlineQuizMutationHookResult = ReturnType<typeof useCreateClassOnlineQuizMutation>;
export type CreateClassOnlineQuizMutationResult = Apollo.MutationResult<CreateClassOnlineQuizMutation>;
export type CreateClassOnlineQuizMutationOptions = Apollo.BaseMutationOptions<CreateClassOnlineQuizMutation, CreateClassOnlineQuizMutationVariables>;
export const FinishClassOnlineQuizDocument = gql`
    mutation finishClassOnlineQuiz($quiz_class_id: ID!) {
  finishClassOnlineQuiz(quiz_class_id: $quiz_class_id) {
    quiz_test_name
    quiz_pass_status
    slide_amount
    slides_failed
  }
}
    `;
export type FinishClassOnlineQuizMutationFn = Apollo.MutationFunction<FinishClassOnlineQuizMutation, FinishClassOnlineQuizMutationVariables>;

/**
 * __useFinishClassOnlineQuizMutation__
 *
 * To run a mutation, you first call `useFinishClassOnlineQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishClassOnlineQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishClassOnlineQuizMutation, { data, loading, error }] = useFinishClassOnlineQuizMutation({
 *   variables: {
 *      quiz_class_id: // value for 'quiz_class_id'
 *   },
 * });
 */
export function useFinishClassOnlineQuizMutation(baseOptions?: Apollo.MutationHookOptions<FinishClassOnlineQuizMutation, FinishClassOnlineQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishClassOnlineQuizMutation, FinishClassOnlineQuizMutationVariables>(FinishClassOnlineQuizDocument, options);
      }
export type FinishClassOnlineQuizMutationHookResult = ReturnType<typeof useFinishClassOnlineQuizMutation>;
export type FinishClassOnlineQuizMutationResult = Apollo.MutationResult<FinishClassOnlineQuizMutation>;
export type FinishClassOnlineQuizMutationOptions = Apollo.BaseMutationOptions<FinishClassOnlineQuizMutation, FinishClassOnlineQuizMutationVariables>;
export const GetCorrectQuizzesDocument = gql`
    query getCorrectQuizzes {
  getCorrectQuizzes
}
    `;

/**
 * __useGetCorrectQuizzesQuery__
 *
 * To run a query within a React component, call `useGetCorrectQuizzesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorrectQuizzesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorrectQuizzesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCorrectQuizzesQuery(baseOptions?: Apollo.QueryHookOptions<GetCorrectQuizzesQuery, GetCorrectQuizzesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCorrectQuizzesQuery, GetCorrectQuizzesQueryVariables>(GetCorrectQuizzesDocument, options);
      }
export function useGetCorrectQuizzesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCorrectQuizzesQuery, GetCorrectQuizzesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCorrectQuizzesQuery, GetCorrectQuizzesQueryVariables>(GetCorrectQuizzesDocument, options);
        }
export type GetCorrectQuizzesQueryHookResult = ReturnType<typeof useGetCorrectQuizzesQuery>;
export type GetCorrectQuizzesLazyQueryHookResult = ReturnType<typeof useGetCorrectQuizzesLazyQuery>;
export type GetCorrectQuizzesQueryResult = Apollo.QueryResult<GetCorrectQuizzesQuery, GetCorrectQuizzesQueryVariables>;
export const GetAchievementTypesDocument = gql`
    query getAchievementTypes($category: Int) {
  getAchievementTypes(category: $category) {
    achievement_type_id
    achievement_category
    achievement_language
    achievement_name
    achievement_desc
    achievement_type_desc
  }
}
    `;

/**
 * __useGetAchievementTypesQuery__
 *
 * To run a query within a React component, call `useGetAchievementTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAchievementTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAchievementTypesQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetAchievementTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAchievementTypesQuery, GetAchievementTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAchievementTypesQuery, GetAchievementTypesQueryVariables>(GetAchievementTypesDocument, options);
      }
export function useGetAchievementTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAchievementTypesQuery, GetAchievementTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAchievementTypesQuery, GetAchievementTypesQueryVariables>(GetAchievementTypesDocument, options);
        }
export type GetAchievementTypesQueryHookResult = ReturnType<typeof useGetAchievementTypesQuery>;
export type GetAchievementTypesLazyQueryHookResult = ReturnType<typeof useGetAchievementTypesLazyQuery>;
export type GetAchievementTypesQueryResult = Apollo.QueryResult<GetAchievementTypesQuery, GetAchievementTypesQueryVariables>;
export const GetStudentAchievementsDocument = gql`
    query getStudentAchievements($achievement_type: ID) {
  getStudentAchievements(achievement_type: $achievement_type) {
    dash_track_id
    achievement_id
    achievement_name
    achievement_location
    achievement_media_type
    achievement_languages
    dash_status
    dash_has_fails
    dash_quiz_id
  }
}
    `;

/**
 * __useGetStudentAchievementsQuery__
 *
 * To run a query within a React component, call `useGetStudentAchievementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentAchievementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentAchievementsQuery({
 *   variables: {
 *      achievement_type: // value for 'achievement_type'
 *   },
 * });
 */
export function useGetStudentAchievementsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentAchievementsQuery, GetStudentAchievementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentAchievementsQuery, GetStudentAchievementsQueryVariables>(GetStudentAchievementsDocument, options);
      }
export function useGetStudentAchievementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentAchievementsQuery, GetStudentAchievementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentAchievementsQuery, GetStudentAchievementsQueryVariables>(GetStudentAchievementsDocument, options);
        }
export type GetStudentAchievementsQueryHookResult = ReturnType<typeof useGetStudentAchievementsQuery>;
export type GetStudentAchievementsLazyQueryHookResult = ReturnType<typeof useGetStudentAchievementsLazyQuery>;
export type GetStudentAchievementsQueryResult = Apollo.QueryResult<GetStudentAchievementsQuery, GetStudentAchievementsQueryVariables>;
export const ManageAchievementDocument = gql`
    mutation manageAchievement($achievement: Achievement_grade!) {
  manageAchievement(achievement: $achievement)
}
    `;
export type ManageAchievementMutationFn = Apollo.MutationFunction<ManageAchievementMutation, ManageAchievementMutationVariables>;

/**
 * __useManageAchievementMutation__
 *
 * To run a mutation, you first call `useManageAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageAchievementMutation, { data, loading, error }] = useManageAchievementMutation({
 *   variables: {
 *      achievement: // value for 'achievement'
 *   },
 * });
 */
export function useManageAchievementMutation(baseOptions?: Apollo.MutationHookOptions<ManageAchievementMutation, ManageAchievementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageAchievementMutation, ManageAchievementMutationVariables>(ManageAchievementDocument, options);
      }
export type ManageAchievementMutationHookResult = ReturnType<typeof useManageAchievementMutation>;
export type ManageAchievementMutationResult = Apollo.MutationResult<ManageAchievementMutation>;
export type ManageAchievementMutationOptions = Apollo.BaseMutationOptions<ManageAchievementMutation, ManageAchievementMutationVariables>;
export const CreateFreeQuizDocument = gql`
    mutation createFreeQuiz($online_quiz: Online_quiz_input) {
  createFreeQuiz(online_quiz: $online_quiz) {
    online_quiz_id
    quiz_token_id
  }
}
    `;
export type CreateFreeQuizMutationFn = Apollo.MutationFunction<CreateFreeQuizMutation, CreateFreeQuizMutationVariables>;

/**
 * __useCreateFreeQuizMutation__
 *
 * To run a mutation, you first call `useCreateFreeQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFreeQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFreeQuizMutation, { data, loading, error }] = useCreateFreeQuizMutation({
 *   variables: {
 *      online_quiz: // value for 'online_quiz'
 *   },
 * });
 */
export function useCreateFreeQuizMutation(baseOptions?: Apollo.MutationHookOptions<CreateFreeQuizMutation, CreateFreeQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFreeQuizMutation, CreateFreeQuizMutationVariables>(CreateFreeQuizDocument, options);
      }
export type CreateFreeQuizMutationHookResult = ReturnType<typeof useCreateFreeQuizMutation>;
export type CreateFreeQuizMutationResult = Apollo.MutationResult<CreateFreeQuizMutation>;
export type CreateFreeQuizMutationOptions = Apollo.BaseMutationOptions<CreateFreeQuizMutation, CreateFreeQuizMutationVariables>;
export const GetFailedSlidesDocument = gql`
    query getFailedSlides {
  getFailedSlides
}
    `;

/**
 * __useGetFailedSlidesQuery__
 *
 * To run a query within a React component, call `useGetFailedSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFailedSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFailedSlidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFailedSlidesQuery(baseOptions?: Apollo.QueryHookOptions<GetFailedSlidesQuery, GetFailedSlidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFailedSlidesQuery, GetFailedSlidesQueryVariables>(GetFailedSlidesDocument, options);
      }
export function useGetFailedSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFailedSlidesQuery, GetFailedSlidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFailedSlidesQuery, GetFailedSlidesQueryVariables>(GetFailedSlidesDocument, options);
        }
export type GetFailedSlidesQueryHookResult = ReturnType<typeof useGetFailedSlidesQuery>;
export type GetFailedSlidesLazyQueryHookResult = ReturnType<typeof useGetFailedSlidesLazyQuery>;
export type GetFailedSlidesQueryResult = Apollo.QueryResult<GetFailedSlidesQuery, GetFailedSlidesQueryVariables>;
export const MarkSlideDocument = gql`
    mutation MarkSlide($marked_slides: [ID]) {
  MarkSlide(marked_slides: $marked_slides)
}
    `;
export type MarkSlideMutationFn = Apollo.MutationFunction<MarkSlideMutation, MarkSlideMutationVariables>;

/**
 * __useMarkSlideMutation__
 *
 * To run a mutation, you first call `useMarkSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSlideMutation, { data, loading, error }] = useMarkSlideMutation({
 *   variables: {
 *      marked_slides: // value for 'marked_slides'
 *   },
 * });
 */
export function useMarkSlideMutation(baseOptions?: Apollo.MutationHookOptions<MarkSlideMutation, MarkSlideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkSlideMutation, MarkSlideMutationVariables>(MarkSlideDocument, options);
      }
export type MarkSlideMutationHookResult = ReturnType<typeof useMarkSlideMutation>;
export type MarkSlideMutationResult = Apollo.MutationResult<MarkSlideMutation>;
export type MarkSlideMutationOptions = Apollo.BaseMutationOptions<MarkSlideMutation, MarkSlideMutationVariables>;
export const UnmarkSlideDocument = gql`
    mutation UnmarkSlide($unmarked_slides: [ID]) {
  UnmarkSlide(unmarked_slides: $unmarked_slides)
}
    `;
export type UnmarkSlideMutationFn = Apollo.MutationFunction<UnmarkSlideMutation, UnmarkSlideMutationVariables>;

/**
 * __useUnmarkSlideMutation__
 *
 * To run a mutation, you first call `useUnmarkSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkSlideMutation, { data, loading, error }] = useUnmarkSlideMutation({
 *   variables: {
 *      unmarked_slides: // value for 'unmarked_slides'
 *   },
 * });
 */
export function useUnmarkSlideMutation(baseOptions?: Apollo.MutationHookOptions<UnmarkSlideMutation, UnmarkSlideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnmarkSlideMutation, UnmarkSlideMutationVariables>(UnmarkSlideDocument, options);
      }
export type UnmarkSlideMutationHookResult = ReturnType<typeof useUnmarkSlideMutation>;
export type UnmarkSlideMutationResult = Apollo.MutationResult<UnmarkSlideMutation>;
export type UnmarkSlideMutationOptions = Apollo.BaseMutationOptions<UnmarkSlideMutation, UnmarkSlideMutationVariables>;
export const GetMarkedSlidesDocument = gql`
    query getMarkedSlides {
  getMarkedSlides {
    slide_pass_status
    original_slide_id
    media_location
    intro_sound_text
    media_type_id
    slide_statements {
      statement_progress_id
      statement_progress_answer
      online_slide_id
      original_statement_id
      statement_answer_id
      quiz_statement_no
      elaboration_Text
      sound_text
      slide_pass_status
    }
  }
}
    `;

/**
 * __useGetMarkedSlidesQuery__
 *
 * To run a query within a React component, call `useGetMarkedSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarkedSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarkedSlidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarkedSlidesQuery(baseOptions?: Apollo.QueryHookOptions<GetMarkedSlidesQuery, GetMarkedSlidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarkedSlidesQuery, GetMarkedSlidesQueryVariables>(GetMarkedSlidesDocument, options);
      }
export function useGetMarkedSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarkedSlidesQuery, GetMarkedSlidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarkedSlidesQuery, GetMarkedSlidesQueryVariables>(GetMarkedSlidesDocument, options);
        }
export type GetMarkedSlidesQueryHookResult = ReturnType<typeof useGetMarkedSlidesQuery>;
export type GetMarkedSlidesLazyQueryHookResult = ReturnType<typeof useGetMarkedSlidesLazyQuery>;
export type GetMarkedSlidesQueryResult = Apollo.QueryResult<GetMarkedSlidesQuery, GetMarkedSlidesQueryVariables>;
export const GetStudentPubLessonsDocument = gql`
    query getStudentPubLessons {
  getStudentPubLessons {
    lesson_id
    lesson_name
    ach_pub_lesson_view_date
  }
}
    `;

/**
 * __useGetStudentPubLessonsQuery__
 *
 * To run a query within a React component, call `useGetStudentPubLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentPubLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentPubLessonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentPubLessonsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentPubLessonsQuery, GetStudentPubLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentPubLessonsQuery, GetStudentPubLessonsQueryVariables>(GetStudentPubLessonsDocument, options);
      }
export function useGetStudentPubLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentPubLessonsQuery, GetStudentPubLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentPubLessonsQuery, GetStudentPubLessonsQueryVariables>(GetStudentPubLessonsDocument, options);
        }
export type GetStudentPubLessonsQueryHookResult = ReturnType<typeof useGetStudentPubLessonsQuery>;
export type GetStudentPubLessonsLazyQueryHookResult = ReturnType<typeof useGetStudentPubLessonsLazyQuery>;
export type GetStudentPubLessonsQueryResult = Apollo.QueryResult<GetStudentPubLessonsQuery, GetStudentPubLessonsQueryVariables>;
export const StudentViewLessonDocument = gql`
    mutation studentViewLesson($lesson_id: ID, $time_spent: String) {
  studentViewLesson(lesson_id: $lesson_id, time_spent: $time_spent)
}
    `;
export type StudentViewLessonMutationFn = Apollo.MutationFunction<StudentViewLessonMutation, StudentViewLessonMutationVariables>;

/**
 * __useStudentViewLessonMutation__
 *
 * To run a mutation, you first call `useStudentViewLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentViewLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentViewLessonMutation, { data, loading, error }] = useStudentViewLessonMutation({
 *   variables: {
 *      lesson_id: // value for 'lesson_id'
 *      time_spent: // value for 'time_spent'
 *   },
 * });
 */
export function useStudentViewLessonMutation(baseOptions?: Apollo.MutationHookOptions<StudentViewLessonMutation, StudentViewLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StudentViewLessonMutation, StudentViewLessonMutationVariables>(StudentViewLessonDocument, options);
      }
export type StudentViewLessonMutationHookResult = ReturnType<typeof useStudentViewLessonMutation>;
export type StudentViewLessonMutationResult = Apollo.MutationResult<StudentViewLessonMutation>;
export type StudentViewLessonMutationOptions = Apollo.BaseMutationOptions<StudentViewLessonMutation, StudentViewLessonMutationVariables>;
export const GetCorrectQuizzesResultDocument = gql`
    query getCorrectQuizzesResult {
  getCorrectQuizzesResult {
    quiz_pass_status
    online_quiz_id
    quiz_test_name
    quiz_expired
    end_time
    achievement_name
  }
}
    `;

/**
 * __useGetCorrectQuizzesResultQuery__
 *
 * To run a query within a React component, call `useGetCorrectQuizzesResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorrectQuizzesResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorrectQuizzesResultQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCorrectQuizzesResultQuery(baseOptions?: Apollo.QueryHookOptions<GetCorrectQuizzesResultQuery, GetCorrectQuizzesResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCorrectQuizzesResultQuery, GetCorrectQuizzesResultQueryVariables>(GetCorrectQuizzesResultDocument, options);
      }
export function useGetCorrectQuizzesResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCorrectQuizzesResultQuery, GetCorrectQuizzesResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCorrectQuizzesResultQuery, GetCorrectQuizzesResultQueryVariables>(GetCorrectQuizzesResultDocument, options);
        }
export type GetCorrectQuizzesResultQueryHookResult = ReturnType<typeof useGetCorrectQuizzesResultQuery>;
export type GetCorrectQuizzesResultLazyQueryHookResult = ReturnType<typeof useGetCorrectQuizzesResultLazyQuery>;
export type GetCorrectQuizzesResultQueryResult = Apollo.QueryResult<GetCorrectQuizzesResultQuery, GetCorrectQuizzesResultQueryVariables>;
export const GetClassStudentResultDocument = gql`
    query getClassStudentResult($class_quiz_id: ID!) {
  getClassStudentResult(class_quiz_id: $class_quiz_id) {
    progress_id
    progress_is_marked
    intro_sound_text
    intro_sound_location
    slide_pass_status
    slide_statements {
      statement_progress_answer
      statement_answer_id
      sound_text
      slide_pass_status
    }
  }
}
    `;

/**
 * __useGetClassStudentResultQuery__
 *
 * To run a query within a React component, call `useGetClassStudentResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassStudentResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassStudentResultQuery({
 *   variables: {
 *      class_quiz_id: // value for 'class_quiz_id'
 *   },
 * });
 */
export function useGetClassStudentResultQuery(baseOptions: Apollo.QueryHookOptions<GetClassStudentResultQuery, GetClassStudentResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassStudentResultQuery, GetClassStudentResultQueryVariables>(GetClassStudentResultDocument, options);
      }
export function useGetClassStudentResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassStudentResultQuery, GetClassStudentResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassStudentResultQuery, GetClassStudentResultQueryVariables>(GetClassStudentResultDocument, options);
        }
export type GetClassStudentResultQueryHookResult = ReturnType<typeof useGetClassStudentResultQuery>;
export type GetClassStudentResultLazyQueryHookResult = ReturnType<typeof useGetClassStudentResultLazyQuery>;
export type GetClassStudentResultQueryResult = Apollo.QueryResult<GetClassStudentResultQuery, GetClassStudentResultQueryVariables>;
export const GetClassStudentParticipantDocument = gql`
    query getClassStudentParticipant($class_quiz_id: ID!) {
  getClassStudentParticipant(class_quiz_id: $class_quiz_id) {
    student_amount
    student_passed
    student_failed
  }
}
    `;

/**
 * __useGetClassStudentParticipantQuery__
 *
 * To run a query within a React component, call `useGetClassStudentParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassStudentParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassStudentParticipantQuery({
 *   variables: {
 *      class_quiz_id: // value for 'class_quiz_id'
 *   },
 * });
 */
export function useGetClassStudentParticipantQuery(baseOptions: Apollo.QueryHookOptions<GetClassStudentParticipantQuery, GetClassStudentParticipantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassStudentParticipantQuery, GetClassStudentParticipantQueryVariables>(GetClassStudentParticipantDocument, options);
      }
export function useGetClassStudentParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassStudentParticipantQuery, GetClassStudentParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassStudentParticipantQuery, GetClassStudentParticipantQueryVariables>(GetClassStudentParticipantDocument, options);
        }
export type GetClassStudentParticipantQueryHookResult = ReturnType<typeof useGetClassStudentParticipantQuery>;
export type GetClassStudentParticipantLazyQueryHookResult = ReturnType<typeof useGetClassStudentParticipantLazyQuery>;
export type GetClassStudentParticipantQueryResult = Apollo.QueryResult<GetClassStudentParticipantQuery, GetClassStudentParticipantQueryVariables>;
export const AddAiStudentDocument = gql`
    mutation AddAIStudent($configId: Int!) {
  addAIStudent(config_id: $configId)
}
    `;
export type AddAiStudentMutationFn = Apollo.MutationFunction<AddAiStudentMutation, AddAiStudentMutationVariables>;

/**
 * __useAddAiStudentMutation__
 *
 * To run a mutation, you first call `useAddAiStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAiStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAiStudentMutation, { data, loading, error }] = useAddAiStudentMutation({
 *   variables: {
 *      configId: // value for 'configId'
 *   },
 * });
 */
export function useAddAiStudentMutation(baseOptions?: Apollo.MutationHookOptions<AddAiStudentMutation, AddAiStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAiStudentMutation, AddAiStudentMutationVariables>(AddAiStudentDocument, options);
      }
export type AddAiStudentMutationHookResult = ReturnType<typeof useAddAiStudentMutation>;
export type AddAiStudentMutationResult = Apollo.MutationResult<AddAiStudentMutation>;
export type AddAiStudentMutationOptions = Apollo.BaseMutationOptions<AddAiStudentMutation, AddAiStudentMutationVariables>;
export const GetClassQuizByConfigIdDocument = gql`
    query getClassQuizByConfigID($config_id: Int!) {
  getClassQuizByConfigID(config_id: $config_id)
}
    `;

/**
 * __useGetClassQuizByConfigIdQuery__
 *
 * To run a query within a React component, call `useGetClassQuizByConfigIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassQuizByConfigIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassQuizByConfigIdQuery({
 *   variables: {
 *      config_id: // value for 'config_id'
 *   },
 * });
 */
export function useGetClassQuizByConfigIdQuery(baseOptions: Apollo.QueryHookOptions<GetClassQuizByConfigIdQuery, GetClassQuizByConfigIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassQuizByConfigIdQuery, GetClassQuizByConfigIdQueryVariables>(GetClassQuizByConfigIdDocument, options);
      }
export function useGetClassQuizByConfigIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassQuizByConfigIdQuery, GetClassQuizByConfigIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassQuizByConfigIdQuery, GetClassQuizByConfigIdQueryVariables>(GetClassQuizByConfigIdDocument, options);
        }
export type GetClassQuizByConfigIdQueryHookResult = ReturnType<typeof useGetClassQuizByConfigIdQuery>;
export type GetClassQuizByConfigIdLazyQueryHookResult = ReturnType<typeof useGetClassQuizByConfigIdLazyQuery>;
export type GetClassQuizByConfigIdQueryResult = Apollo.QueryResult<GetClassQuizByConfigIdQuery, GetClassQuizByConfigIdQueryVariables>;
export const AddTeoriLessonStudentDocument = gql`
    mutation AddTeoriLessonStudent($lessonId: String) {
  addTeoriLessonStudent(lesson_id: $lessonId)
}
    `;
export type AddTeoriLessonStudentMutationFn = Apollo.MutationFunction<AddTeoriLessonStudentMutation, AddTeoriLessonStudentMutationVariables>;

/**
 * __useAddTeoriLessonStudentMutation__
 *
 * To run a mutation, you first call `useAddTeoriLessonStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeoriLessonStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeoriLessonStudentMutation, { data, loading, error }] = useAddTeoriLessonStudentMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useAddTeoriLessonStudentMutation(baseOptions?: Apollo.MutationHookOptions<AddTeoriLessonStudentMutation, AddTeoriLessonStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTeoriLessonStudentMutation, AddTeoriLessonStudentMutationVariables>(AddTeoriLessonStudentDocument, options);
      }
export type AddTeoriLessonStudentMutationHookResult = ReturnType<typeof useAddTeoriLessonStudentMutation>;
export type AddTeoriLessonStudentMutationResult = Apollo.MutationResult<AddTeoriLessonStudentMutation>;
export type AddTeoriLessonStudentMutationOptions = Apollo.BaseMutationOptions<AddTeoriLessonStudentMutation, AddTeoriLessonStudentMutationVariables>;
export const GetStudentTipsDocument = gql`
    query getStudentTips($categoryId: Int!) {
  getStudentTips(category_id: $categoryId)
}
    `;

/**
 * __useGetStudentTipsQuery__
 *
 * To run a query within a React component, call `useGetStudentTipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentTipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentTipsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetStudentTipsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentTipsQuery, GetStudentTipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentTipsQuery, GetStudentTipsQueryVariables>(GetStudentTipsDocument, options);
      }
export function useGetStudentTipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentTipsQuery, GetStudentTipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentTipsQuery, GetStudentTipsQueryVariables>(GetStudentTipsDocument, options);
        }
export type GetStudentTipsQueryHookResult = ReturnType<typeof useGetStudentTipsQuery>;
export type GetStudentTipsLazyQueryHookResult = ReturnType<typeof useGetStudentTipsLazyQuery>;
export type GetStudentTipsQueryResult = Apollo.QueryResult<GetStudentTipsQuery, GetStudentTipsQueryVariables>;