import React, { createContext, ReactNode, useState } from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  fromPromise,
  HttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { fetchAccessToken } from "./apolloProviderOperations";
import JwtDecode, { JwtPayload } from "jwt-decode";
import { onError } from "@apollo/link-error";
import { APOLLO_STUDENT_URL } from "../constants/APIConstants";

interface AppState {
  appState: {
    loggedIn: boolean;
  };
  gqlError: {
    msg: string;
  };
  appSetLogin: (token: string, token2: string) => void;
  appSetLogout: () => void;
  appSetTokens: (token: string, token2: string) => void;
  appClearAccessToken: () => void;
  appGetUserID: () => string;
  appGetUserCategory: () => string;
  appGetUserRole: () => string;
  appGetExpiryDate: () => Date;
}
let accessToken = "";
let refreshToken = "";
let userID = "";
let userRole = "";
let expiryDate = new Date();
let category = "";

const initialAppState: AppState = {
  appState: { loggedIn: false },
  gqlError: { msg: "" },
  appSetLogin: (token: string, token2: string) => {},
  appSetLogout: () => {},
  appSetTokens: (token: string) => {},
  appClearAccessToken: () => {},
  appGetUserID: () => {
    return userID;
  },
  appGetUserRole: () => {
    return userRole;
  },
  appGetUserCategory: () => {
    return category;
  },
  appGetExpiryDate: () => {
    return expiryDate;
  },
};

export const AppStateStudentContext = createContext<AppState>(initialAppState);

export interface AppStateProviderProps {
  children: ReactNode;
}

function AppStateStudentProvider(props: AppStateProviderProps): JSX.Element {
  const [appState, setAppState] = useState({ loggedIn: false });
  const [gqlError, setGQLError] = useState({ msg: "" });

  const appSetLogin = (token: string, token2: string) => {
    accessToken = token;
    refreshToken = token2;
    localStorage.setItem("refreshToken", refreshToken);
    setAppState({ ...appState, loggedIn: true });
  };

  const appSetLogout = async () => {
    await client.resetStore();
    localStorage.removeItem("apollo-cache-persist");
    accessToken = "";
    caches.keys().then(function(names) {
      for (let name of names){
        caches.delete(name);
      }
      });
    setAppState({ ...appState, loggedIn: false });
    
  };

  const appSetTokens = (token: string, token2: string) => {
    accessToken = token;
    refreshToken = token2;
  };
  const appClearAccessToken = () => {
    accessToken = "";
  };
  const appGetAccessToken = (): string => {
    return accessToken;
  };
  const appGetUserID = (): string => {
    const decodedToken: any = JwtDecode(appGetAccessToken());
    userID = decodedToken.token_data.student_id;
    return userID;
  };

  const appGetUserRole = (): string => {
    const decodedToken: any = JwtDecode(appGetAccessToken());

    userRole = decodedToken.token_data.role_id;
    return userRole;
  };

  const appGetUserCategory = (): string => {
    const decodedToken: any = JwtDecode(appGetAccessToken());

    category = decodedToken.token_data.student_category;
    
    return String(category);
  }

  const appGetExpiryDate = (): Date => {
    const decodedToken: any = JwtDecode(appGetAccessToken());
    expiryDate = new Date(decodedToken.token_data.expiry_date);
    return expiryDate;
  };

  // apollo client
  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle: any;
        Promise.resolve(operation)
          .then((operation) => {
            operation.setContext({
              headers: {
                authorization: accessToken ? appGetAccessToken() : null,
              },
            });
          })
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));
        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      })
  );

  const tokenRefreshLink: any = new TokenRefreshLink<{
    accessToken;
    refreshToken;
  }>({
    accessTokenField: "RefreshUser",
    isTokenValidOrUndefined: () => {
      const token = appGetAccessToken();
      if (token?.length === 0) {
        return true;
      }

      try {
        //TODO Find a better solution to this
        const interMediaryFix: JwtPayload = JwtDecode(token);
        const exp = interMediaryFix?.exp;

        if (exp === undefined) {
          return false;
        }

        return Date.now() < exp * 1000;
      } catch {
        return false;
      }
    },
    fetchAccessToken: () => fetchAccessToken(APOLLO_STUDENT_URL),
    handleFetch: (newTokens) => {
      localStorage.setItem("refreshToken", newTokens.refreshToken);
      localStorage.setItem("accessToken", newTokens.accessToken);
      appSetTokens(newTokens.accessToken, newTokens.refreshToken);
    },
    handleResponse: (operation: any, accessToken) => async (response) => {
      const newTokens = {
        accessToken: await response.accessToken,
        refreshToken: await response.refreshToken,
      };
      return response;
    },
    handleError: (err) => {
      appSetLogout();
    },
  });

  const theCache = new InMemoryCache();

  persistCache({
	cache: theCache,
	storage: new LocalStorageWrapper(window.localStorage),
  });
  

  const client = new ApolloClient({
    cache: theCache,
    connectToDevTools: true,
    uri: APOLLO_STUDENT_URL,
    link: ApolloLink.from([
      tokenRefreshLink,
      onError(({ graphQLErrors, networkError }) => {
        if (
          graphQLErrors === undefined ||
          graphQLErrors[0].path === undefined
        ) {

          return;
        }
        if (graphQLErrors[0].path[0] === "refresh") {

          return;
        }
        const err = graphQLErrors[0].message;
        //setGQLError({ msg: err });
      }),
      requestLink,
      new HttpLink({
        uri: APOLLO_STUDENT_URL,
      }),
    ]),
  });

  return (
    <ApolloProvider client={client}>
      <AppStateStudentContext.Provider
        value={{
          appState,
          gqlError,
          appSetLogin,
          appSetLogout,
          appSetTokens,
          appClearAccessToken,
          appGetUserID,
          appGetUserRole,
          appGetUserCategory,
          appGetExpiryDate,
        }}
      >
        {props.children}
      </AppStateStudentContext.Provider>
    </ApolloProvider>
  );
}

export default AppStateStudentProvider;
