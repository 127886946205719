import React from 'react';
import addIcon from "../../../../assets/add_icon.png";
import "./style/Item.scss";

export type IEmptyItemProps = {
    slideOrderNumber: number;
	handleCreateSlide: () => void;
}

const EmptyItem: React.FC<IEmptyItemProps> = (props: IEmptyItemProps) => {
    return (
        <div className="Item">
            <div className="itemNumber">{`${props.slideOrderNumber}.`}</div>
			<div className="itemContent empty">
				<button onClick={props.handleCreateSlide}><img src={addIcon} alt="ADD-ICON"></img></button>
			</div>
        </div>
    );
}

export { EmptyItem };