import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TeoriLogo } from "../../../../globalComponents/TeoriLogo";
import { useGetMarkedSlidesQuery } from "../../../../graphql/generated/graphqlStudent";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../translations/translationProvider";
import { EndSlide, EndStatement, MediaType } from "../../../model";
import { StatsCard } from "./statsComponents/StatsCard";
import "./TeacherStudentStats.scss";

interface dataArray {
  averageData: number;
  data: number;
  name: string;
}

export type ITeacherStudentStatsProps = {};

const TeacherStudentStats: React.FC<ITeacherStudentStatsProps> = ({}) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  const { data, loading } = useGetMarkedSlidesQuery({fetchPolicy: "no-cache"});

  const [currentSlides, setcurrentSlides] = useState<EndSlide[]>();

  const [chosenSlide, setchosenSlide] = useState<number>();

  useEffect(() => {
    if (data) {
      const tempArray: EndSlide[] = [];
      data.getMarkedSlides?.forEach((slide) => {
        const tempStatements: EndStatement[] = [];
        slide?.slide_statements?.forEach((statement) => {
          if (statement) {
            tempStatements.push({
              answer: statement?.statement_progress_answer!,
              text: statement.sound_text!,
              correctAnswer: statement.statement_answer_id!,
              explanation: "",
              id: Number(statement.statement_progress_id!),
            });
          }
          
        });
        if(slide){

          tempArray.push({
            passed: slide.slide_pass_status!,
            media: slide.media_location!,
            mediaType: MediaType[Number(slide.media_type_id!)],
            introQuestion: slide.intro_sound_text!,
            progressID: "2",
            statements: tempStatements,
            id: slide.original_slide_id!,
          });
        }
      });
      setcurrentSlides(tempArray);
    }
  }, [data]);

  const renderSlides = (slides: EndSlide[]) => {
    if(slides.length !== 0){

      return slides.map((slide, index) => (
        <div className="slide">
        <img src={slide.media} alt="SLIDE"></img>
      </div>
    ));
  }else{
    return <div>Du har desværre ikke markeret nogle billeder</div>
  }
  };

  return (
    <div className="TeacherStudentStats">
      <div className="headerBox">
        <TeoriLogo />
      </div>
      <div className="content">
        <div className="subheader">Dine markerede billeder</div>
        <div className="slideBox">{currentSlides ? renderSlides(currentSlides) : <CircularProgress/>}</div>
      </div>
      {chosenSlide ? <StatsCard /> : null}
    </div>
  );
};

export { TeacherStudentStats };
