
export const fetchAccessToken = async (url: string, isTeacher?:boolean): Promise<Response> => {
	const payload = {
		variables: { old_token: localStorage.getItem(isTeacher ? "refreshTokenT" : "refreshToken") },
		query: `mutation($old_token: String!) {
		RefreshUser(old_token: $old_token) {
			accessToken
			refreshToken
		}
	}`,
	};
	return fetch(url, {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			authorization: "null",
			"Content-Type": "application/json; charset=utf-8",
			Accept: "application/json",
		},
	}).then(async (res) => {
		const response = await res.json();
		return response.data;
	});
};
