import React, { useEffect, useState } from "react";
import { FIND_INDEX_STATUS_NOT_FOUND } from "../../../constants/generalConstants";
import { CreateQuizSlide, LessonSlide, Tag } from "../../model";
import { findById, findIndexById } from "../../modelFunctions";
import { ItemDetails } from "./components/ItemDetails";
import { ItemsArea } from "./components/ItemsArea";
import { Settings } from "./components/Settings";
import { availableSounds } from "./CreateQuizPage";
import { useLocation } from "react-router-dom";

interface location {
  focusedSlide: number;
}

export type ICreateQuizContentProps = {
  tags: Tag[];
  lessonState: CreateQuizSlide[];
  handleFinish: (isFinished: boolean) => void;
  setLessonState: React.Dispatch<React.SetStateAction<CreateQuizSlide[]>>;
  name: string;
  setname: React.Dispatch<React.SetStateAction<string>>;
  narrationSounds: availableSounds[];
};

const CreateQuizContent: React.FC<ICreateQuizContentProps> = (
  props: ICreateQuizContentProps
) => {
  const location = useLocation<location>();

  const [hasSelectedOnce, sethasSelectedOnce] = useState(false);

  useEffect(() => {
    if (!hasSelectedOnce) {
      if (location.state) {
        if (location.state.focusedSlide) {
          const slideToSelect = props.lessonState.find(
            (slide) => slide.editID === location.state.focusedSlide
          );
          if (slideToSelect) {
            sethasSelectedOnce(true);
            setselectedSlideID(slideToSelect.id);
          }
        }
      }
    }
  }, [props.lessonState]);

  const [selectedSlideID, setselectedSlideID] = useState<number | null>(null);
  const [isSelectedSlideExpanded, setIsSelectedSlideExpanded] =
    useState<boolean>(false);

  const getSelectedSlide = (): CreateQuizSlide | undefined => {
    return findById(props.lessonState, selectedSlideID);
  };

  const getOrderNumberOfSelectedSlide = (): number | null => {
    const selectedSlideIndex = findIndexById(
      props.lessonState,
      selectedSlideID
    );
    if (selectedSlideIndex === FIND_INDEX_STATUS_NOT_FOUND) {
      return null;
    }
    return selectedSlideIndex + 1;
  };

  const selectSlide = (id: number): void => {
    setselectedSlideID(id);
  };

  const expandSelectedSlide = (id: number): void => {
    selectSlide(id);
    setIsSelectedSlideExpanded(true);
  };

  const shrinkSelectedSlide = (): void => {
    setIsSelectedSlideExpanded(false);
  };

  const copyAndUpdateLessonState = (
    callback: (lessonState: CreateQuizSlide[]) => void
  ): void => {
    const lessonStateCopy: CreateQuizSlide[] = [...props.lessonState];
    callback(lessonStateCopy);

    props.setLessonState(lessonStateCopy);
    console.log(props.lessonState);
  };

  const setSlides = (slides: CreateQuizSlide[]): void => {
    props.setLessonState(slides);
  };

  const updateSelectedSlide = (updatedSlide: CreateQuizSlide): void => {
    if (selectedSlideID === null) {
      console.warn("SLIDE IS SET TO NULL !!!");
      return;
    }
    //Get index and check if index of updated slide has been found
    const updatedSlideIndex = findIndexById(props.lessonState, selectedSlideID);
    if (updatedSlideIndex !== FIND_INDEX_STATUS_NOT_FOUND) {
      copyAndUpdateLessonState(
        (lessonState) => (lessonState[updatedSlideIndex] = updatedSlide)
      );
    }
  };

  const renderItemAreaOrItemDetails = () => {
    const selectedSlide = getSelectedSlide();
    if (isSelectedSlideExpanded && selectedSlide) {
      return (
        <ItemDetails
          shrinkSelectedSlide={shrinkSelectedSlide}
          slide={getSelectedSlide()!}
        />
      );
    }

    return (
      <ItemsArea
        slides={props.lessonState}
        setSlides={setSlides}
        selectSlide={selectSlide}
        expandSelectedSlide={expandSelectedSlide}
        narrationSounds={props.narrationSounds}
      />
    );
  };

  return (
    <div className="ContentPage">
      {renderItemAreaOrItemDetails()}
      <Settings
        key={selectedSlideID}
        tags={props.tags}
        slide={getSelectedSlide()}
        slideOrderNumber={getOrderNumberOfSelectedSlide()}
        updateSelectedSlide={updateSelectedSlide}
        handleSave={() => props.handleFinish(true)}
        handleFinish={() => props.handleFinish(false)}
        setname={props.setname}
        name={props.name}
        narrationSounds={props.narrationSounds}
      />
    </div>
  );
};

export { CreateQuizContent };
