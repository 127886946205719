import { BlobServiceClient } from "@azure/storage-blob";
import { AZURE_STORAGE_ACCOUNT } from "../constants/APIConstants";

const account = AZURE_STORAGE_ACCOUNT;

export async function uploadOwnContent(
  SASkey: string,
  file: File,
  userID: number
) {

  try {
    const blobServiceClient = new BlobServiceClient(
      `https://${account}.blob.core.windows.net?${SASkey}`
    );
    const container = blobServiceClient.getContainerClient(`user${userID}`);
    await container.createIfNotExists({ access: "blob" });

    const blockBlobClient = container.getBlockBlobClient(file.name);
    const response = await blockBlobClient.upload(file, file.size, {blobHTTPHeaders: {blobContentType: file.type}});
    return blockBlobClient.url;
  } catch (e) {
    console.log(e);
  }
}

export async function uploadLessonSound(
  SASkey: string,
  file: File,
  userID: number,
){
  try {
    const blobServiceClient = new BlobServiceClient(
      `https://${account}.blob.core.windows.net?${SASkey}`
    );
    const container = blobServiceClient.getContainerClient(`user${userID}`);

    await container.createIfNotExists({ access: "blob" });

    const blockBlobClient = container.getBlockBlobClient("lessonSounds/" + file.name);
    const response = await blockBlobClient.upload(file, file.size, {blobHTTPHeaders: {blobContentType: file.type}});
    return blockBlobClient.url.split("?sv")[0];
  } catch (e) {
    console.log(e);
  }
}

export async function uploadExplanationSound(
  SASkey: string,
  file: File,
  userID: number,
){
  try {
    const blobServiceClient = new BlobServiceClient(
      `https://${account}.blob.core.windows.net?${SASkey}`
    );
    const container = blobServiceClient.getContainerClient(`user${userID}`);

    await container.createIfNotExists({ access: "blob" });

    const blockBlobClient = container.getBlockBlobClient("slideExplanations/" + file.name);
    const response = await blockBlobClient.upload(file, file.size, {blobHTTPHeaders: {blobContentType: file.type}});
    return blockBlobClient.url.split("?sv")[0];
  } catch (e) {
    console.log(e);
  }
}
