import React, { useEffect, useState } from "react";

import { TeacherStudentStatementRow } from "./TeacherStudentStatementRow";
import "./TeacherStudentSlide.scss"
import { TeacherStudentSlide as TeacherStudentSlideAlias, TeacherStudentStatement } from '../../../../model';
import { getStringsForChosenLanguage, getChosenLanguage } from "../../../../../translations/translationProvider";
import { Slide_Progress, Statement_Grade, useGradeSlideMutation } from "../../../../../graphql/generated/graphqlStudent";

export type ITeacherStudentSlideProps = {
  slide: any;
  current: number;
  length: number;
  handleSetAnswer: (statementNumber: number, answer: number) => void;
  sendSlideOrEnd(slide: TeacherStudentSlideAlias): void;
};

const TeacherStudentSlide: React.FC<ITeacherStudentSlideProps> = (
  props: ITeacherStudentSlideProps
) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  const [statements, setstatements] = useState("defaultStat");
  const [gradeSlide] = useGradeSlideMutation();



  useEffect(() => {
    
    return () => {
      props.sendSlideOrEnd(props.slide);
    }
  }, []);

  const renderStatements = (): JSX.Element[] => {
    const tempArray: JSX.Element[] = [];
    tempArray.push(<div className="TeacherStudentStatementRow header"><div>JA</div><div>NEJ</div></div>)
    props.slide.statements.forEach((statement) => {
      tempArray.push(
        <TeacherStudentStatementRow key={statement.id} statement={statement} onNoClick={() => props.handleSetAnswer(statement.id, 2)} onYesClick={() => props.handleSetAnswer(statement.id, 1)}/>
      );
    });

    return tempArray;
  };
  return <div className="TeacherStudentSlide"><div className="slideCount">{props.current + " : " + props.length}</div>{renderStatements()}</div>;
};

export { TeacherStudentSlide };
