import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Time: any;
};

export type Achievement = {
  achievement_1?: InputMaybe<Scalars['Boolean']>;
  achievement_2?: InputMaybe<Scalars['Boolean']>;
  achievement_3?: InputMaybe<Scalars['Boolean']>;
  achievement_4?: InputMaybe<Scalars['Boolean']>;
  achievement_5?: InputMaybe<Scalars['Boolean']>;
  achievement_id?: InputMaybe<Scalars['ID']>;
  student_id?: InputMaybe<Scalars['ID']>;
};

/** Generic Objects */
export type Achievement_Type = {
  __typename?: 'Achievement_type';
  achievement_category?: Maybe<Scalars['Int']>;
  achievement_dash?: Maybe<Array<Maybe<Student_Ach_Get>>>;
  achievement_desc?: Maybe<Scalars['String']>;
  achievement_language?: Maybe<Scalars['Int']>;
  achievement_name?: Maybe<Scalars['String']>;
  achievement_type_desc?: Maybe<Scalars['String']>;
  achievement_type_id?: Maybe<Scalars['Int']>;
};

export type Activity = {
  __typename?: 'Activity';
  activity_date?: Maybe<Scalars['DateTime']>;
  activity_id?: Maybe<Scalars['ID']>;
  activity_result?: Maybe<Scalars['String']>;
  activity_time?: Maybe<Scalars['String']>;
  activity_title?: Maybe<Scalars['String']>;
  activity_type?: Maybe<Scalars['ID']>;
  dash_quiz_id?: Maybe<Scalars['ID']>;
};

export type Blocked_Slide = {
  __typename?: 'Blocked_slide';
  block_create_time?: Maybe<Scalars['DateTime']>;
  block_id?: Maybe<Scalars['ID']>;
  block_update_time?: Maybe<Scalars['DateTime']>;
  quiz_slide_id?: Maybe<Scalars['ID']>;
  school_id?: Maybe<Scalars['ID']>;
};

export type Category = {
  __typename?: 'Category';
  cat_id?: Maybe<Scalars['ID']>;
  cat_name?: Maybe<Scalars['String']>;
};

export type Class = {
  __typename?: 'Class';
  class_id?: Maybe<Scalars['ID']>;
  class_name?: Maybe<Scalars['String']>;
  creation_date?: Maybe<Scalars['DateTime']>;
  school_id?: Maybe<Scalars['ID']>;
};

export type Class_Quiz_Config_I = {
  ai_quiz_students?: InputMaybe<Scalars['String']>;
  ai_quiz_toggle?: InputMaybe<Scalars['Boolean']>;
  base_quiz_id?: InputMaybe<Scalars['ID']>;
  class_quiz_config_id?: InputMaybe<Scalars['ID']>;
  config_name?: InputMaybe<Scalars['String']>;
  include_teori?: InputMaybe<Scalars['Boolean']>;
  quiz_language?: InputMaybe<Scalars['ID']>;
  quiz_learning_mode?: InputMaybe<Scalars['Boolean']>;
  quiz_read_only?: InputMaybe<Scalars['Boolean']>;
  quiz_speed?: InputMaybe<Scalars['ID']>;
  quiz_speed_value?: InputMaybe<Scalars['Int']>;
  quiz_subtitles?: InputMaybe<Scalars['ID']>;
  save_for_later?: InputMaybe<Scalars['Boolean']>;
  slide_amount?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<InputMaybe<Tag_Input>>>;
  teacher_id?: InputMaybe<Scalars['ID']>;
};

export type Class_Quiz_Input = {
  class_quiz_config_id?: InputMaybe<Scalars['ID']>;
  class_quiz_id?: InputMaybe<Scalars['ID']>;
  class_seed?: InputMaybe<Scalars['Int']>;
  teacher_id?: InputMaybe<Scalars['ID']>;
};

export type Class_Result = {
  __typename?: 'Class_result';
  slide_id?: Maybe<Scalars['ID']>;
  slide_pass_percentage?: Maybe<Scalars['Float']>;
};

export type Combined_Lesson = {
  lesson_name?: InputMaybe<Scalars['String']>;
  lesson_owner?: InputMaybe<Scalars['ID']>;
  lessons?: InputMaybe<Array<InputMaybe<Subject_Lesson>>>;
};

export type Content_Input = {
  content_id?: InputMaybe<Scalars['ID']>;
  media_default?: InputMaybe<Scalars['Boolean']>;
  media_location?: InputMaybe<Scalars['String']>;
  media_location_enc?: InputMaybe<Scalars['String']>;
  media_owner?: InputMaybe<Scalars['Int']>;
  media_type_id?: InputMaybe<Scalars['Int']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  class_quiz_configs?: Maybe<Array<Maybe<Class_Quiz_Config>>>;
  default_lessons?: Maybe<Array<Maybe<Lesson>>>;
  default_quizzes?: Maybe<Array<Maybe<Quiz>>>;
  teacher_lessons?: Maybe<Array<Maybe<Lesson>>>;
};

export type Department = {
  __typename?: 'Department';
  department_name?: Maybe<Scalars['String']>;
  department_province?: Maybe<Scalars['String']>;
  department_zipcode?: Maybe<Scalars['String']>;
  email_id?: Maybe<Scalars['String']>;
  is_department?: Maybe<Scalars['Boolean']>;
  language_choice?: Maybe<Scalars['Int']>;
  optional_note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['ID']>;
  subuser_id: Scalars['ID'];
};

export type Language = {
  __typename?: 'Language';
  language_name?: Maybe<Scalars['String']>;
  teori_language_id?: Maybe<Scalars['String']>;
};

export type Lesson_Input = {
  lesson_category?: InputMaybe<Scalars['Int']>;
  lesson_content_desc?: InputMaybe<Scalars['String']>;
  lesson_date_format?: InputMaybe<Scalars['String']>;
  lesson_default?: InputMaybe<Scalars['Boolean']>;
  lesson_default_language?: InputMaybe<Scalars['Int']>;
  lesson_id?: InputMaybe<Scalars['ID']>;
  lesson_logo?: InputMaybe<Scalars['String']>;
  lesson_name?: InputMaybe<Scalars['String']>;
  lesson_owner?: InputMaybe<Scalars['ID']>;
  lesson_published?: InputMaybe<Scalars['Boolean']>;
  lesson_subject?: InputMaybe<Scalars['Boolean']>;
  slides?: InputMaybe<Array<InputMaybe<Slide_Input>>>;
};

export type Mark_Slide = {
  __typename?: 'Mark_slide';
  explanation_location?: Maybe<Scalars['String']>;
  intro_sound_location?: Maybe<Scalars['String']>;
  intro_sound_text?: Maybe<Scalars['String']>;
  mark_count?: Maybe<Scalars['Int']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_type_id?: Maybe<Scalars['ID']>;
  online_quiz_id: Scalars['ID'];
  original_slide_id?: Maybe<Scalars['Int']>;
  progress_id: Scalars['ID'];
  slide_pass_status?: Maybe<Scalars['Boolean']>;
  slide_statements?: Maybe<Array<Maybe<Statement_Student>>>;
  student_id?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AddStudentAccess: Scalars['JSON'];
  AdminCodeGeneration?: Maybe<Scalars['Boolean']>;
  /** Slide Block Management */
  BlockSlide: Scalars['JSON'];
  ClearStudentAccess: Scalars['JSON'];
  /** Management of New Student Access */
  CreateStudentAccess: Scalars['JSON'];
  DeleteSlideBlock: Scalars['JSON'];
  /** Management of User (School, Teacher) object */
  LoginUser: UserToken;
  RefreshUser: UserToken;
  UnlinkStudent?: Maybe<Scalars['String']>;
  UnmarkSlide?: Maybe<Scalars['JSON']>;
  UnmarkSlideTeacher?: Maybe<Scalars['JSON']>;
  UpdateStudentAccess: Scalars['JSON'];
  UserLogout: Scalars['JSON'];
  addStudentClass: Scalars['JSON'];
  cloneLesson: Scalars['JSON'];
  combineLesson: Scalars['JSON'];
  createSchoolCategory?: Maybe<Scalars['String']>;
  deleteClass?: Maybe<Scalars['String']>;
  deleteClassConfig: Scalars['JSON'];
  deleteContent: Scalars['JSON'];
  deleteLesson: Scalars['JSON'];
  deleteNotice: Scalars['JSON'];
  deleteQuiz: Scalars['JSON'];
  deleteQuizSlide: Scalars['JSON'];
  deleteQuizStatement: Scalars['JSON'];
  deleteQuizTag: Scalars['JSON'];
  deleteSlide: Scalars['JSON'];
  deleteSlideTag: Scalars['JSON'];
  deleteStudent?: Maybe<Scalars['String']>;
  deleteStudentClass?: Maybe<Scalars['String']>;
  deleteStudentNote?: Maybe<Scalars['String']>;
  deleteSubuser?: Maybe<Scalars['String']>;
  deleteTag: Scalars['JSON'];
  editStudentClassStart: Scalars['JSON'];
  insertClassQuiz: Class_Quiz;
  manageAchievement: Scalars['JSON'];
  /** Management of Class object  */
  manageClass: Scalars['JSON'];
  /** Class Quiz Management */
  manageClassConfig: Class_Quiz_Config;
  manageContent: Scalars['JSON'];
  manageDefaultContent: Scalars['JSON'];
  /** Management of Lesson Service */
  manageLesson: Scalars['JSON'];
  manageNote: Scalars['JSON'];
  manageNotice: Scalars['JSON'];
  /** Management of Quiz Service */
  manageQuiz: Scalars['JSON'];
  /** Admin Code Gen */
  manageSchool?: Maybe<Scalars['JSON']>;
  manageSlide: Scalars['JSON'];
  /** Management of Student object */
  manageStudent: Scalars['JSON'];
  /** Management of Subuser (Teacher, Department) object  */
  manageSubuser: Scalars['JSON'];
  publishLesson: Scalars['JSON'];
  setSchoolStatus?: Maybe<Scalars['JSON']>;
  unlinkSlide?: Maybe<Scalars['JSON']>;
};


export type MutationAddStudentAccessArgs = {
  access_student: Student_Access;
};


export type MutationAdminCodeGenerationArgs = {
  code_amount?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['ID']>;
};


export type MutationBlockSlideArgs = {
  slides?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationClearStudentAccessArgs = {
  student_id: Scalars['Int'];
};


export type MutationCreateStudentAccessArgs = {
  category_id?: InputMaybe<Scalars['ID']>;
  expiry_date?: InputMaybe<Scalars['DateTime']>;
  should_send_sms?: InputMaybe<Scalars['Boolean']>;
  student_phone_number?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteSlideBlockArgs = {
  slides?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRefreshUserArgs = {
  old_token: Scalars['String'];
};


export type MutationUnlinkStudentArgs = {
  student_id: Scalars['ID'];
};


export type MutationUnmarkSlideArgs = {
  unmarked_slides?: InputMaybe<Array<InputMaybe<Student_Mark>>>;
};


export type MutationUnmarkSlideTeacherArgs = {
  unmarked_original_slides?: InputMaybe<Array<InputMaybe<Student_Mark_Teacher>>>;
};


export type MutationUpdateStudentAccessArgs = {
  new_expiry_date: Scalars['DateTime'];
  student_id: Scalars['Int'];
};


export type MutationAddStudentClassArgs = {
  student_class?: InputMaybe<Student_Class>;
};


export type MutationCloneLessonArgs = {
  lesson_id: Scalars['ID'];
};


export type MutationCombineLessonArgs = {
  combined_lesson?: InputMaybe<Combined_Lesson>;
};


export type MutationCreateSchoolCategoryArgs = {
  category_id: Scalars['Int'];
  school_id: Scalars['Int'];
};


export type MutationDeleteClassArgs = {
  class_id: Scalars['ID'];
};


export type MutationDeleteClassConfigArgs = {
  class_quiz_config_id: Scalars['ID'];
};


export type MutationDeleteContentArgs = {
  content_id: Scalars['ID'];
};


export type MutationDeleteLessonArgs = {
  lesson_id: Scalars['ID'];
};


export type MutationDeleteNoticeArgs = {
  notice_id: Scalars['ID'];
};


export type MutationDeleteQuizArgs = {
  quiz_id: Scalars['ID'];
};


export type MutationDeleteQuizSlideArgs = {
  quiz_slide_id: Scalars['ID'];
};


export type MutationDeleteQuizStatementArgs = {
  quiz_statement_id: Scalars['ID'];
};


export type MutationDeleteQuizTagArgs = {
  tag_id: Scalars['ID'];
};


export type MutationDeleteSlideArgs = {
  slide_id: Scalars['ID'];
};


export type MutationDeleteSlideTagArgs = {
  delete_quiz_slide_id?: InputMaybe<Scalars['ID']>;
  delete_tag_id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteStudentArgs = {
  student_id: Scalars['ID'];
};


export type MutationDeleteStudentClassArgs = {
  class_id: Scalars['ID'];
  student_id: Scalars['ID'];
};


export type MutationDeleteStudentNoteArgs = {
  school_id: Scalars['ID'];
  student_id: Scalars['ID'];
};


export type MutationDeleteSubuserArgs = {
  subuser_id: Scalars['ID'];
};


export type MutationDeleteTagArgs = {
  tag_id: Scalars['ID'];
};


export type MutationEditStudentClassStartArgs = {
  students?: InputMaybe<Array<InputMaybe<Student_Class_Start>>>;
};


export type MutationInsertClassQuizArgs = {
  class_quiz: Class_Quiz_Input;
};


export type MutationManageAchievementArgs = {
  achievement: Achievement;
};


export type MutationManageClassArgs = {
  new_class: New_Class;
};


export type MutationManageClassConfigArgs = {
  class_quiz_config: Class_Quiz_Config_I;
};


export type MutationManageContentArgs = {
  content: Content_Input;
};


export type MutationManageDefaultContentArgs = {
  content: Content_Input;
};


export type MutationManageLessonArgs = {
  lesson: Lesson_Input;
};


export type MutationManageNoteArgs = {
  note: New_Note;
};


export type MutationManageNoticeArgs = {
  notice: New_Notice;
};


export type MutationManageQuizArgs = {
  quiz: Quiz_Input;
};


export type MutationManageSchoolArgs = {
  school: SchoolInput;
};


export type MutationManageSlideArgs = {
  slide: Slide_Input;
};


export type MutationManageStudentArgs = {
  student: New_Student;
};


export type MutationManageSubuserArgs = {
  subuser: New_Subuser;
};


export type MutationPublishLessonArgs = {
  lesson_id?: InputMaybe<Scalars['ID']>;
  lesson_published?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetSchoolStatusArgs = {
  school_id: Scalars['Int'];
  school_status: Scalars['Int'];
};


export type MutationUnlinkSlideArgs = {
  lesson_slide_id: Scalars['ID'];
  tag_id: Scalars['ID'];
};

export type New_Student = {
  expiry_date?: InputMaybe<Scalars['DateTime']>;
  first_name?: InputMaybe<Scalars['String']>;
  language_choice?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  optional_value?: InputMaybe<Scalars['String']>;
  school_id?: InputMaybe<Scalars['ID']>;
  student_category?: InputMaybe<Scalars['Int']>;
  student_class_start?: InputMaybe<Scalars['DateTime']>;
  student_email?: InputMaybe<Scalars['String']>;
  student_id?: InputMaybe<Scalars['ID']>;
  student_name?: InputMaybe<Scalars['String']>;
  student_phone_number?: InputMaybe<Scalars['String']>;
  teori_department?: InputMaybe<Scalars['ID']>;
};

export type New_Class = {
  class_id?: InputMaybe<Scalars['ID']>;
  class_name?: InputMaybe<Scalars['String']>;
  creation_date?: InputMaybe<Scalars['DateTime']>;
  school_id?: InputMaybe<Scalars['ID']>;
};

export type New_Note = {
  note_desc?: InputMaybe<Scalars['String']>;
  note_id?: InputMaybe<Scalars['ID']>;
  school_id?: InputMaybe<Scalars['ID']>;
  student_id?: InputMaybe<Scalars['ID']>;
};

export type New_Notice = {
  notice_background_color?: InputMaybe<Scalars['String']>;
  notice_body?: InputMaybe<Scalars['String']>;
  notice_expire_on?: InputMaybe<Scalars['DateTime']>;
  notice_id?: InputMaybe<Scalars['ID']>;
  notice_language?: InputMaybe<Scalars['Int']>;
  notice_publish_on?: InputMaybe<Scalars['DateTime']>;
  notice_student_dashboard?: InputMaybe<Scalars['Boolean']>;
  notice_students_landing?: InputMaybe<Scalars['Boolean']>;
  notice_teacher_landing?: InputMaybe<Scalars['Boolean']>;
};

export type New_Subuser = {
  department_name?: InputMaybe<Scalars['String']>;
  department_zipcode?: InputMaybe<Scalars['String']>;
  email_id?: InputMaybe<Scalars['String']>;
  is_department?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  school_id?: InputMaybe<Scalars['Int']>;
  skip_bill_check?: InputMaybe<Scalars['Boolean']>;
  subuser_id?: InputMaybe<Scalars['ID']>;
  teacher_name?: InputMaybe<Scalars['String']>;
};

export type Note = {
  __typename?: 'Note';
  note_desc?: Maybe<Scalars['String']>;
  note_id?: Maybe<Scalars['ID']>;
  school_id?: Maybe<Scalars['ID']>;
  student_id?: Maybe<Scalars['ID']>;
};

export type Notice = {
  __typename?: 'Notice';
  notice_background_color?: Maybe<Scalars['String']>;
  notice_body?: Maybe<Scalars['String']>;
  notice_expire_on?: Maybe<Scalars['DateTime']>;
  notice_id?: Maybe<Scalars['ID']>;
  notice_language?: Maybe<Scalars['Int']>;
  notice_publish_on?: Maybe<Scalars['DateTime']>;
  notice_student_dashboard?: Maybe<Scalars['Boolean']>;
  notice_students_landing?: Maybe<Scalars['Boolean']>;
  notice_teacher_landing?: Maybe<Scalars['Boolean']>;
};

export type Online_Quiz = {
  __typename?: 'Online_quiz';
  achievement_name?: Maybe<Scalars['String']>;
  base_quiz?: Maybe<Scalars['ID']>;
  end_time?: Maybe<Scalars['DateTime']>;
  include_teori?: Maybe<Scalars['Boolean']>;
  online_quiz_id?: Maybe<Scalars['ID']>;
  quiz_date?: Maybe<Scalars['DateTime']>;
  quiz_evaluation_test?: Maybe<Scalars['Boolean']>;
  quiz_language?: Maybe<Scalars['ID']>;
  quiz_learning_mode?: Maybe<Scalars['Boolean']>;
  quiz_pass_status?: Maybe<Scalars['Boolean']>;
  quiz_read_only?: Maybe<Scalars['Boolean']>;
  quiz_speed?: Maybe<Scalars['ID']>;
  quiz_speed_value?: Maybe<Scalars['Int']>;
  quiz_subtitles?: Maybe<Scalars['Boolean']>;
  quiz_test_name?: Maybe<Scalars['String']>;
  quiz_token_id?: Maybe<Scalars['String']>;
  save_for_later?: Maybe<Scalars['Boolean']>;
  slide_amount?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['DateTime']>;
  student_id?: Maybe<Scalars['ID']>;
};

export type Online_Quiz_Result = {
  __typename?: 'Online_quiz_result';
  achievement_name?: Maybe<Scalars['String']>;
  base_quiz?: Maybe<Scalars['ID']>;
  class_quiz_id?: Maybe<Scalars['ID']>;
  end_time?: Maybe<Scalars['DateTime']>;
  online_quiz_id?: Maybe<Scalars['ID']>;
  quiz_date?: Maybe<Scalars['DateTime']>;
  quiz_language?: Maybe<Scalars['ID']>;
  quiz_learning_mode?: Maybe<Scalars['Boolean']>;
  quiz_pass_status?: Maybe<Scalars['Boolean']>;
  quiz_test_name?: Maybe<Scalars['String']>;
  slide_amount?: Maybe<Scalars['Int']>;
  slide_progress?: Maybe<Array<Maybe<Progress>>>;
  slides_failed?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['DateTime']>;
  student_id?: Maybe<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  GetTeoriLessonStudents?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** General User Queries */
  getActiveNotices?: Maybe<Array<Maybe<Notice>>>;
  getAdminStudentLoginInfo?: Maybe<Student_Admin_Info>;
  getAllCategories?: Maybe<Array<Maybe<Category>>>;
  getAllSchools?: Maybe<Array<Maybe<School>>>;
  getAllSlides?: Maybe<Array<Maybe<Quiz_Slide>>>;
  getAvailableTags?: Maybe<Array<Maybe<Tag>>>;
  getBaseQuizInfo?: Maybe<Quiz>;
  getBlockedSlides?: Maybe<Array<Maybe<Blocked_Slide>>>;
  getCategories?: Maybe<Array<Maybe<Category>>>;
  /** Class Quiz Lookouts */
  getClassConfig?: Maybe<Array<Maybe<Class_Quiz_Config>>>;
  getClassInfo?: Maybe<Class>;
  getClassQuiz?: Maybe<Array<Maybe<Class_Quiz>>>;
  getClassQuizByID?: Maybe<Array<Maybe<Class_Quiz>>>;
  getClassQuizResult?: Maybe<Array<Maybe<Class_Result>>>;
  getClassQuizzes?: Maybe<Array<Maybe<Online_Quiz>>>;
  getClassStudents: Array<Maybe<Student>>;
  getCompleteQuizEdit?: Maybe<Quiz_Edit>;
  getCompleteQuizInfo?: Maybe<Quiz>;
  getConfigCountByID?: Maybe<Scalars['Int']>;
  getContentByType?: Maybe<Array<Maybe<Content>>>;
  getContentQuizInfo?: Maybe<Class_Quiz_Config>;
  getDepartmentInfo: Department;
  getFailedSlidesTag?: Maybe<Array<Maybe<Progress>>>;
  getLanguages?: Maybe<Array<Maybe<Language>>>;
  /** Lesson Service Lookoutsy */
  getLessonCategories: Array<Maybe<Lesson_Categories>>;
  getLessonEdit?: Maybe<Array<Maybe<Lesson_Edit>>>;
  getLessonInfo?: Maybe<Array<Maybe<Lesson>>>;
  getLessonTags?: Maybe<Array<Maybe<Tag>>>;
  getMarkedSlides?: Maybe<Array<Maybe<Mark_Slide>>>;
  getMarkedSlidesCount?: Maybe<Array<Maybe<Mark_Slide>>>;
  getMediaTypes: Array<Maybe<Media_Types>>;
  getOwnedContent?: Maybe<Array<Maybe<Content>>>;
  getPersonalConfig?: Maybe<Array<Maybe<Class_Quiz_Config>>>;
  getPubLessons?: Maybe<Array<Maybe<Lesson>>>;
  getQuizResult?: Maybe<Array<Maybe<Online_Quiz_Result>>>;
  getQuizSlidePreview?: Maybe<Array<Maybe<Quiz_Slide>>>;
  /** Azure queries */
  getSAS: Scalars['String'];
  getSchoolCategory?: Maybe<Array<Maybe<Category>>>;
  getSchoolClass?: Maybe<Array<Maybe<Class>>>;
  getSchoolClassQuiz?: Maybe<Array<Maybe<Class_Quiz>>>;
  getSchoolDepartments?: Maybe<Array<Department>>;
  /** School service data lookouts */
  getSchoolInfo: School;
  getSchoolList?: Maybe<Array<Maybe<School>>>;
  getSchoolStudents: Array<Maybe<School_Student>>;
  getSchoolSubusers?: Maybe<Array<Subuser>>;
  getSchoolSubusersAdmin?: Maybe<Array<Subuser>>;
  getSlideExplanation?: Maybe<Slide_Explanation>;
  /** Quiz Service Lookouts */
  getSlideTag?: Maybe<Array<Maybe<Quiz_Slide>>>;
  getSlidesPreview?: Maybe<Array<Maybe<Short_Slide>>>;
  getSoundContent?: Maybe<Array<Maybe<Sound_Content>>>;
  getSpokenLanguages?: Maybe<Array<Maybe<Language>>>;
  getStatementElaboration?: Maybe<Statement_Elaboration>;
  getStudentAchTypes?: Maybe<Array<Maybe<Achievement_Type>>>;
  getStudentAchievements?: Maybe<Array<Maybe<Student_Ach_Get>>>;
  getStudentActivity?: Maybe<Array<Maybe<Activity>>>;
  getStudentFailedTags?: Maybe<Array<Maybe<Failed_Tag>>>;
  /** Student service data lookouts */
  getStudentInfo: Student;
  getStudentNote: Note;
  getStudentProgress?: Maybe<Array<Maybe<Student_Progress>>>;
  getStudentsAdmin?: Maybe<Array<Maybe<Student>>>;
  getStudentsSchoolDeleted?: Maybe<Array<Maybe<School_Student>>>;
  getSubLanguages?: Maybe<Array<Maybe<Language>>>;
  getSubjectLessons?: Maybe<Array<Maybe<Lesson>>>;
  /** Teacher service data lookouts */
  getSubuserInfo: Subuser;
  getTeacherConfig?: Maybe<Array<Maybe<Class_Quiz_Config>>>;
  getTeacherDashboard?: Maybe<Dashboard>;
  getTeoriContent?: Maybe<Array<Maybe<Content>>>;
};


export type QueryGetTeoriLessonStudentsArgs = {
  lesson_id?: InputMaybe<Scalars['String']>;
};


export type QueryGetAdminStudentLoginInfoArgs = {
  student_id: Scalars['ID'];
};


export type QueryGetAllSchoolsArgs = {
  is_active: Scalars['Int'];
};


export type QueryGetAllSlidesArgs = {
  included_slides?: InputMaybe<Scalars['Int']>;
  language_chosen?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBaseQuizInfoArgs = {
  quiz_id: Scalars['ID'];
};


export type QueryGetClassConfigArgs = {
  class_quiz_config_id: Scalars['ID'];
};


export type QueryGetClassInfoArgs = {
  class_id: Scalars['ID'];
};


export type QueryGetClassQuizArgs = {
  teacher_id: Scalars['ID'];
};


export type QueryGetClassQuizByIdArgs = {
  class_quiz_id: Scalars['ID'];
};


export type QueryGetClassQuizResultArgs = {
  class_quiz_id: Scalars['ID'];
};


export type QueryGetClassQuizzesArgs = {
  class_quiz_id: Scalars['ID'];
};


export type QueryGetClassStudentsArgs = {
  class_id: Scalars['ID'];
};


export type QueryGetCompleteQuizEditArgs = {
  language_id: Scalars['ID'];
  quiz_id: Scalars['ID'];
};


export type QueryGetCompleteQuizInfoArgs = {
  quiz_id: Scalars['ID'];
  sound_language?: InputMaybe<Scalars['Int']>;
  text_language?: InputMaybe<Scalars['Int']>;
};


export type QueryGetConfigCountByIdArgs = {
  config_id: Scalars['Int'];
};


export type QueryGetContentByTypeArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  media_type_id?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetContentQuizInfoArgs = {
  class_quiz_id: Scalars['ID'];
  language_id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetDepartmentInfoArgs = {
  department_id: Scalars['ID'];
};


export type QueryGetFailedSlidesTagArgs = {
  quiz_date?: InputMaybe<Scalars['DateTime']>;
  student_id: Scalars['ID'];
  tag_id: Scalars['ID'];
};


export type QueryGetLessonEditArgs = {
  language_id: Scalars['ID'];
  lesson_id: Scalars['ID'];
};


export type QueryGetLessonInfoArgs = {
  language_id: Scalars['ID'];
  lesson_id: Scalars['ID'];
};


export type QueryGetLessonTagsArgs = {
  lesson_id: Scalars['ID'];
};


export type QueryGetMarkedSlidesArgs = {
  student_id: Scalars['ID'];
};


export type QueryGetMarkedSlidesCountArgs = {
  student_ids: Scalars['String'];
};


export type QueryGetOwnedContentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetQuizResultArgs = {
  online_quiz_id: Scalars['ID'];
};


export type QueryGetQuizSlidePreviewArgs = {
  language_id: Scalars['ID'];
  quiz_id: Scalars['ID'];
};


export type QueryGetSchoolCategoryArgs = {
  school_id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetSchoolClassQuizArgs = {
  koreskole_id: Scalars['ID'];
};


export type QueryGetSchoolSubusersAdminArgs = {
  school_id: Scalars['Int'];
};


export type QueryGetSlideExplanationArgs = {
  explanation_id: Scalars['ID'];
};


export type QueryGetSlideTagArgs = {
  tag_id: Scalars['ID'];
};


export type QueryGetSlidesPreviewArgs = {
  language_id: Scalars['ID'];
  lesson_id: Scalars['ID'];
};


export type QueryGetStatementElaborationArgs = {
  elaboration_id: Scalars['ID'];
};


export type QueryGetStudentAchTypesArgs = {
  student_id: Scalars['ID'];
};


export type QueryGetStudentAchievementsArgs = {
  achievement_type: Scalars['ID'];
  student_id: Scalars['ID'];
};


export type QueryGetStudentActivityArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  student_id: Scalars['ID'];
};


export type QueryGetStudentFailedTagsArgs = {
  student_id: Scalars['ID'];
};


export type QueryGetStudentInfoArgs = {
  student_id: Scalars['ID'];
};


export type QueryGetStudentNoteArgs = {
  student_id: Scalars['ID'];
};


export type QueryGetStudentProgressArgs = {
  student_id: Scalars['ID'];
};


export type QueryGetSubuserInfoArgs = {
  subuser_id: Scalars['ID'];
};


export type QueryGetTeacherConfigArgs = {
  teacher_id: Scalars['ID'];
};


export type QueryGetTeacherDashboardArgs = {
  category_id?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryGetTeoriContentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Quiz_Input = {
  quiz_Id?: InputMaybe<Scalars['ID']>;
  quiz_content_Desc?: InputMaybe<Scalars['String']>;
  quiz_default_language?: InputMaybe<Scalars['Int']>;
  quiz_is_draft?: InputMaybe<Scalars['Boolean']>;
  quiz_logo?: InputMaybe<Scalars['String']>;
  quiz_name?: InputMaybe<Scalars['String']>;
  quiz_time_to_complete?: InputMaybe<Scalars['DateTime']>;
  slides?: InputMaybe<Array<InputMaybe<Quiz_Slide_Input>>>;
};

/** School / Teacher objects */
export type School = {
  __typename?: 'School';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  email_id?: Maybe<Scalars['String']>;
  language_choice?: Maybe<Scalars['Int']>;
  mobile_number?: Maybe<Scalars['String']>;
  optional_note?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  school_departments?: Maybe<Array<Maybe<Department>>>;
  school_id?: Maybe<Scalars['ID']>;
  school_name: Scalars['String'];
  skip_bill_check?: Maybe<Scalars['Boolean']>;
  vat_number?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['Int']>;
};

export type SchoolInput = {
  city?: InputMaybe<Scalars['String']>;
  contact_name?: InputMaybe<Scalars['String']>;
  email_id?: InputMaybe<Scalars['String']>;
  language_choice?: InputMaybe<Scalars['Int']>;
  optional_note?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  school_id?: InputMaybe<Scalars['Int']>;
  school_name?: InputMaybe<Scalars['String']>;
  skip_bill_check?: InputMaybe<Scalars['Boolean']>;
  vat_number?: InputMaybe<Scalars['Int']>;
  zipcode?: InputMaybe<Scalars['Int']>;
};

export type School_Student = {
  __typename?: 'School_Student';
  access_code?: Maybe<Scalars['String']>;
  correct_quizzes?: Maybe<Scalars['Int']>;
  create_date?: Maybe<Scalars['DateTime']>;
  expiry_date?: Maybe<Scalars['DateTime']>;
  has_marks?: Maybe<Scalars['Boolean']>;
  student_category?: Maybe<Scalars['Int']>;
  student_class_start?: Maybe<Scalars['DateTime']>;
  student_email?: Maybe<Scalars['String']>;
  student_id: Scalars['ID'];
  student_name?: Maybe<Scalars['String']>;
  student_phone_number?: Maybe<Scalars['String']>;
  student_progress?: Maybe<Scalars['String']>;
  teori_department?: Maybe<Scalars['ID']>;
  total_quizzes?: Maybe<Scalars['String']>;
};

export type Slide_Input = {
  lesson_id?: InputMaybe<Scalars['ID']>;
  slide_id?: InputMaybe<Scalars['ID']>;
  slide_order?: InputMaybe<Scalars['Int']>;
  slide_translations: Array<InputMaybe<Slide_Translation_Input>>;
  tags?: InputMaybe<Array<InputMaybe<Tag_Input>>>;
};

export type Slide_Translation_Input = {
  keywords?: InputMaybe<Scalars['String']>;
  language_id?: InputMaybe<Scalars['Int']>;
  slide_note?: InputMaybe<Scalars['String']>;
  slide_sound_location?: InputMaybe<Scalars['String']>;
  slide_translation_id?: InputMaybe<Scalars['ID']>;
  translation_content?: InputMaybe<Scalars['Int']>;
};

/** Student objects */
export type Student = {
  __typename?: 'Student';
  create_date?: Maybe<Scalars['DateTime']>;
  expiry_date?: Maybe<Scalars['DateTime']>;
  first_name?: Maybe<Scalars['String']>;
  language_choice?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  optional_value?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['ID']>;
  school_name?: Maybe<Scalars['String']>;
  student_category?: Maybe<Scalars['ID']>;
  student_category_desc?: Maybe<Scalars['String']>;
  student_class_start?: Maybe<Scalars['DateTime']>;
  student_code?: Maybe<Scalars['String']>;
  student_email?: Maybe<Scalars['String']>;
  student_id: Scalars['ID'];
  student_name?: Maybe<Scalars['String']>;
  student_phone_number?: Maybe<Scalars['String']>;
  teori_department?: Maybe<Scalars['ID']>;
  update_time?: Maybe<Scalars['DateTime']>;
};

export type Student_Class = {
  class_id?: InputMaybe<Scalars['Int']>;
  student_id?: InputMaybe<Scalars['Int']>;
};

export type Student_Access = {
  first_name?: InputMaybe<Scalars['String']>;
  language_choice: Scalars['Int'];
  last_name?: InputMaybe<Scalars['String']>;
  optional_value?: InputMaybe<Scalars['String']>;
  student_category: Scalars['Int'];
  student_email?: InputMaybe<Scalars['String']>;
  student_id: Scalars['ID'];
  student_name: Scalars['String'];
  student_phone: Scalars['String'];
};

export type Student_Ach_Get = {
  __typename?: 'Student_ach_get';
  achievement_id?: Maybe<Scalars['ID']>;
  achievement_location?: Maybe<Scalars['String']>;
  achievement_media_type?: Maybe<Scalars['ID']>;
  achievement_name?: Maybe<Scalars['String']>;
  dash_has_fails?: Maybe<Scalars['Int']>;
  dash_quiz_id?: Maybe<Scalars['Int']>;
  dash_status?: Maybe<Scalars['Int']>;
  dash_track_id?: Maybe<Scalars['ID']>;
  dash_track_update?: Maybe<Scalars['DateTime']>;
  student_id?: Maybe<Scalars['ID']>;
};

export type Student_Admin_Info = {
  __typename?: 'Student_admin_info';
  student_id: Scalars['ID'];
  student_phone_number?: Maybe<Scalars['String']>;
  student_pin?: Maybe<Scalars['String']>;
};

export type Student_Class_Start = {
  student_class_start?: InputMaybe<Scalars['DateTime']>;
  student_id: Scalars['ID'];
};

export type Student_Progress = {
  __typename?: 'Student_progress';
  achievement_name?: Maybe<Scalars['String']>;
  dash_status?: Maybe<Scalars['Int']>;
};

export type Subject_Lesson = {
  lesson_id?: InputMaybe<Scalars['ID']>;
};

export type Subuser = {
  __typename?: 'Subuser';
  department_name?: Maybe<Scalars['String']>;
  department_zipcode?: Maybe<Scalars['String']>;
  email_id?: Maybe<Scalars['String']>;
  is_department?: Maybe<Scalars['Boolean']>;
  school_id?: Maybe<Scalars['ID']>;
  skip_bill_check?: Maybe<Scalars['Boolean']>;
  subuser_id: Scalars['ID'];
  teacher_name?: Maybe<Scalars['String']>;
};

/** Generic inputs */
export type Tag_Input = {
  tag_id?: InputMaybe<Scalars['ID']>;
  tag_name?: InputMaybe<Scalars['String']>;
};

/** User objects */
export type UserToken = {
  __typename?: 'UserToken';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type Class_Quiz = {
  __typename?: 'class_quiz';
  class_quiz_config_id?: Maybe<Scalars['ID']>;
  class_quiz_create_date?: Maybe<Scalars['DateTime']>;
  class_quiz_id?: Maybe<Scalars['ID']>;
  class_seed?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['ID']>;
};

export type Class_Quiz_Config = {
  __typename?: 'class_quiz_config';
  ai_quiz_students?: Maybe<Scalars['String']>;
  ai_quiz_toggle?: Maybe<Scalars['Boolean']>;
  base_quiz_id?: Maybe<Scalars['ID']>;
  class_quiz_config_id?: Maybe<Scalars['ID']>;
  config_creation_date?: Maybe<Scalars['DateTime']>;
  config_name?: Maybe<Scalars['String']>;
  config_update_date?: Maybe<Scalars['DateTime']>;
  include_teori?: Maybe<Scalars['Boolean']>;
  quiz_language?: Maybe<Scalars['ID']>;
  quiz_learning_mode?: Maybe<Scalars['Boolean']>;
  quiz_read_only?: Maybe<Scalars['Boolean']>;
  quiz_speed?: Maybe<Scalars['ID']>;
  quiz_speed_value?: Maybe<Scalars['Int']>;
  quiz_subtitles?: Maybe<Scalars['ID']>;
  save_for_later?: Maybe<Scalars['Boolean']>;
  slide_amount?: Maybe<Scalars['Int']>;
  slides?: Maybe<Array<Maybe<Quiz_Slide>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  teacher_id?: Maybe<Scalars['ID']>;
};

export type Content = {
  __typename?: 'content';
  content_id?: Maybe<Scalars['ID']>;
  media_default?: Maybe<Scalars['Boolean']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_owner?: Maybe<Scalars['Int']>;
  media_type_id?: Maybe<Scalars['Int']>;
};

export type Failed_Tag = {
  __typename?: 'failed_tag';
  slides_failed?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['ID']>;
  tag_name?: Maybe<Scalars['String']>;
};

export type Lesson = {
  __typename?: 'lesson';
  lesson_category?: Maybe<Scalars['Int']>;
  lesson_content_desc?: Maybe<Scalars['String']>;
  lesson_creation_date?: Maybe<Scalars['DateTime']>;
  lesson_date_format?: Maybe<Scalars['String']>;
  lesson_default?: Maybe<Scalars['Boolean']>;
  lesson_default_language?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['ID']>;
  lesson_logo?: Maybe<Scalars['String']>;
  lesson_name?: Maybe<Scalars['String']>;
  lesson_owner?: Maybe<Scalars['ID']>;
  lesson_published?: Maybe<Scalars['Boolean']>;
  lesson_subject?: Maybe<Scalars['Boolean']>;
  slides?: Maybe<Array<Maybe<Slide>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

/** Lesson Objects */
export type Lesson_Categories = {
  __typename?: 'lesson_categories';
  cat_id?: Maybe<Scalars['ID']>;
  cat_name?: Maybe<Scalars['String']>;
};

export type Lesson_Edit = {
  __typename?: 'lesson_edit';
  lesson_category?: Maybe<Scalars['Int']>;
  lesson_content_desc?: Maybe<Scalars['String']>;
  lesson_date_format?: Maybe<Scalars['String']>;
  lesson_default?: Maybe<Scalars['Int']>;
  lesson_default_language?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['ID']>;
  lesson_logo?: Maybe<Scalars['String']>;
  lesson_name?: Maybe<Scalars['String']>;
  lesson_owner?: Maybe<Scalars['ID']>;
  slides?: Maybe<Array<Maybe<Slide_Edit>>>;
};

export type Media_Types = {
  __typename?: 'media_types';
  media_type_desc?: Maybe<Scalars['String']>;
  media_type_id?: Maybe<Scalars['ID']>;
  media_type_name?: Maybe<Scalars['String']>;
};

export type Progress = {
  __typename?: 'progress';
  explanation_location?: Maybe<Scalars['String']>;
  intro_sound_location?: Maybe<Scalars['String']>;
  intro_sound_text?: Maybe<Scalars['String']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_type_id?: Maybe<Scalars['ID']>;
  online_quiz_id?: Maybe<Scalars['ID']>;
  original_slide_id?: Maybe<Scalars['ID']>;
  progress_id?: Maybe<Scalars['ID']>;
  progress_is_marked?: Maybe<Scalars['Boolean']>;
  slide_pass_status?: Maybe<Scalars['Boolean']>;
  statement_progress?: Maybe<Array<Maybe<Statement_Student>>>;
};

/** Quiz Objects */
export type Quiz = {
  __typename?: 'quiz';
  quiz_Content_Desc?: Maybe<Scalars['String']>;
  quiz_Id?: Maybe<Scalars['ID']>;
  quiz_Logo?: Maybe<Scalars['String']>;
  quiz_creation_date?: Maybe<Scalars['DateTime']>;
  quiz_default_language?: Maybe<Scalars['Int']>;
  quiz_is_draft?: Maybe<Scalars['Boolean']>;
  quiz_name?: Maybe<Scalars['String']>;
  quiz_time_to_complete?: Maybe<Scalars['DateTime']>;
  slides?: Maybe<Array<Maybe<Quiz_Slide>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type Quiz_Edit = {
  __typename?: 'quiz_edit';
  quiz_Content_Desc?: Maybe<Scalars['String']>;
  quiz_Id: Scalars['ID'];
  quiz_Logo?: Maybe<Scalars['String']>;
  quiz_creation_date?: Maybe<Scalars['DateTime']>;
  quiz_default_language?: Maybe<Scalars['Int']>;
  quiz_is_draft?: Maybe<Scalars['Boolean']>;
  quiz_name?: Maybe<Scalars['String']>;
  quiz_time_to_complete?: Maybe<Scalars['DateTime']>;
  slides: Array<Maybe<Quiz_Slide_Edit>>;
};

export type Quiz_Slide = {
  __typename?: 'quiz_slide';
  explanation_id?: Maybe<Scalars['String']>;
  explanation_location?: Maybe<Scalars['String']>;
  intro_sound_location?: Maybe<Scalars['String']>;
  intro_sound_text?: Maybe<Scalars['String']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_type_id?: Maybe<Scalars['ID']>;
  quiz_id: Scalars['ID'];
  quiz_slide_id: Scalars['ID'];
  slide_is_accesible?: Maybe<Scalars['Int']>;
  slide_statements?: Maybe<Array<Maybe<Statement_View>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type Quiz_Slide_Edit = {
  __typename?: 'quiz_slide_edit';
  explanation_id?: Maybe<Scalars['ID']>;
  intro_question_content?: Maybe<Scalars['ID']>;
  intro_question_sound?: Maybe<Array<Maybe<Sound_Content>>>;
  quiz_id: Scalars['ID'];
  quiz_slide_id: Scalars['ID'];
  slide_content?: Maybe<Array<Maybe<Content>>>;
  slide_explanations?: Maybe<Array<Maybe<Slide_Explanation>>>;
  slide_is_accesible?: Maybe<Scalars['Int']>;
  slide_statements?: Maybe<Array<Maybe<Statement_Edit>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  translation_content?: Maybe<Scalars['ID']>;
};

export type Quiz_Slide_Input = {
  explanation_id?: InputMaybe<Scalars['ID']>;
  intro_question_content?: InputMaybe<Scalars['ID']>;
  intro_question_sound?: InputMaybe<Array<InputMaybe<Sound_Content_I>>>;
  quiz_id?: InputMaybe<Scalars['ID']>;
  quiz_slide_id?: InputMaybe<Scalars['ID']>;
  slide_content?: InputMaybe<Array<InputMaybe<Content_Input>>>;
  slide_explanations?: InputMaybe<Array<InputMaybe<Slide_Explanation_Input>>>;
  slide_is_accesible?: InputMaybe<Scalars['Int']>;
  slide_statements?: InputMaybe<Array<InputMaybe<Statement>>>;
  tags?: InputMaybe<Array<InputMaybe<Tag_Input>>>;
  translation_content?: InputMaybe<Scalars['ID']>;
};

export type Short_Slide = {
  __typename?: 'short_slide';
  language_id?: Maybe<Scalars['Int']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_type_name?: Maybe<Scalars['String']>;
  slide_id?: Maybe<Scalars['ID']>;
  slide_note?: Maybe<Scalars['String']>;
  slide_order?: Maybe<Scalars['Int']>;
  slide_sound_location?: Maybe<Scalars['String']>;
};

export type Slide = {
  __typename?: 'slide';
  keywords?: Maybe<Scalars['String']>;
  language_id?: Maybe<Scalars['Int']>;
  media_location?: Maybe<Scalars['String']>;
  media_location_enc?: Maybe<Scalars['String']>;
  media_type_id?: Maybe<Scalars['Int']>;
  slide_id?: Maybe<Scalars['ID']>;
  slide_note?: Maybe<Scalars['String']>;
  slide_order?: Maybe<Scalars['Int']>;
  slide_sound_location?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type Slide_Edit = {
  __typename?: 'slide_edit';
  lesson_id?: Maybe<Scalars['Int']>;
  slide_id?: Maybe<Scalars['ID']>;
  slide_order?: Maybe<Scalars['Int']>;
  slide_translations?: Maybe<Array<Maybe<Slide_Translation_Edit>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type Slide_Explanation = {
  __typename?: 'slide_explanation';
  explanation_id?: Maybe<Scalars['ID']>;
  explanation_is_original?: Maybe<Scalars['Boolean']>;
  explanation_language?: Maybe<Scalars['ID']>;
  explanation_location?: Maybe<Scalars['String']>;
  explanation_original?: Maybe<Scalars['Int']>;
  explanation_owner?: Maybe<Scalars['ID']>;
};

export type Slide_Explanation_Input = {
  explanation_id?: InputMaybe<Scalars['ID']>;
  explanation_is_original?: InputMaybe<Scalars['Boolean']>;
  explanation_language?: InputMaybe<Scalars['ID']>;
  explanation_location?: InputMaybe<Scalars['String']>;
  explanation_original?: InputMaybe<Scalars['Int']>;
  explanation_owner?: InputMaybe<Scalars['ID']>;
};

export type Slide_Translation_Edit = {
  __typename?: 'slide_translation_edit';
  content?: Maybe<Array<Maybe<Content>>>;
  keywords?: Maybe<Scalars['String']>;
  language_id?: Maybe<Scalars['ID']>;
  slide_note?: Maybe<Scalars['String']>;
  slide_sound_location?: Maybe<Scalars['String']>;
  slide_translation_id?: Maybe<Scalars['ID']>;
  translation_content?: Maybe<Scalars['ID']>;
};

export type Sound_Content = {
  __typename?: 'sound_content';
  sound_content_id?: Maybe<Scalars['ID']>;
  sound_language?: Maybe<Scalars['Int']>;
  sound_location?: Maybe<Scalars['String']>;
  sound_text?: Maybe<Scalars['String']>;
};

export type Sound_Content_I = {
  sound_content_id?: InputMaybe<Scalars['ID']>;
  sound_language?: InputMaybe<Scalars['Int']>;
  sound_location?: InputMaybe<Scalars['String']>;
  sound_text?: InputMaybe<Scalars['String']>;
};

export type Statement = {
  elaboration_id?: InputMaybe<Scalars['ID']>;
  quiz_Statement_Id?: InputMaybe<Scalars['ID']>;
  quiz_slide_id?: InputMaybe<Scalars['ID']>;
  quiz_statement_content?: InputMaybe<Scalars['ID']>;
  quiz_statement_elaboration?: InputMaybe<Array<InputMaybe<Statement_Elaboration_Input>>>;
  quiz_statement_no?: InputMaybe<Scalars['ID']>;
  quiz_statement_sound?: InputMaybe<Array<InputMaybe<Sound_Content_I>>>;
  statement_answer_id?: InputMaybe<Scalars['Int']>;
};

export type Statement_Edit = {
  __typename?: 'statement_edit';
  elaboration_id?: Maybe<Scalars['ID']>;
  quiz_Statement_Id?: Maybe<Scalars['ID']>;
  quiz_slide_id?: Maybe<Scalars['ID']>;
  quiz_statement_content?: Maybe<Scalars['ID']>;
  quiz_statement_elaboration?: Maybe<Array<Maybe<Statement_Elaboration>>>;
  quiz_statement_no?: Maybe<Scalars['ID']>;
  quiz_statement_sound?: Maybe<Array<Maybe<Sound_Content>>>;
  statement_answer_id?: Maybe<Scalars['Int']>;
};

export type Statement_Elaboration = {
  __typename?: 'statement_elaboration';
  elaboration_id?: Maybe<Scalars['ID']>;
  elaboration_is_original?: Maybe<Scalars['Boolean']>;
  elaboration_language?: Maybe<Scalars['ID']>;
  elaboration_original?: Maybe<Scalars['Int']>;
  elaboration_owner?: Maybe<Scalars['ID']>;
  elaboration_text?: Maybe<Scalars['String']>;
};

export type Statement_Elaboration_Input = {
  elaboration_id?: InputMaybe<Scalars['ID']>;
  elaboration_is_original?: InputMaybe<Scalars['Boolean']>;
  elaboration_language?: InputMaybe<Scalars['ID']>;
  elaboration_original?: InputMaybe<Scalars['Int']>;
  elaboration_owner?: InputMaybe<Scalars['ID']>;
  elaboration_text?: InputMaybe<Scalars['String']>;
};

export type Statement_Marked = {
  __typename?: 'statement_marked';
  elaboration_id?: Maybe<Scalars['ID']>;
  elaboration_text?: Maybe<Scalars['String']>;
  quiz_Slide_id?: Maybe<Scalars['ID']>;
  quiz_Statement_Id?: Maybe<Scalars['ID']>;
  quiz_statement_content?: Maybe<Scalars['ID']>;
  quiz_statement_no?: Maybe<Scalars['ID']>;
  slide_pass_status?: Maybe<Scalars['Boolean']>;
  sound_language?: Maybe<Scalars['Int']>;
  sound_location?: Maybe<Scalars['String']>;
  sound_text?: Maybe<Scalars['String']>;
  statement_answer_id?: Maybe<Scalars['Int']>;
};

export type Statement_Student = {
  __typename?: 'statement_student';
  elaboration_Text?: Maybe<Scalars['String']>;
  online_slide_id?: Maybe<Scalars['ID']>;
  original_statement_id?: Maybe<Scalars['ID']>;
  quiz_statement_no?: Maybe<Scalars['Int']>;
  slide_pass_status?: Maybe<Scalars['Boolean']>;
  sound_location?: Maybe<Scalars['String']>;
  sound_text?: Maybe<Scalars['String']>;
  statement_answer_id?: Maybe<Scalars['Int']>;
  statement_progress_answer?: Maybe<Scalars['Int']>;
  statement_progress_id?: Maybe<Scalars['ID']>;
};

export type Statement_View = {
  __typename?: 'statement_view';
  elaboration_text?: Maybe<Scalars['String']>;
  quiz_Statement_Id?: Maybe<Scalars['ID']>;
  quiz_elaboration_id?: Maybe<Scalars['ID']>;
  quiz_slide_id?: Maybe<Scalars['ID']>;
  quiz_statement_no?: Maybe<Scalars['ID']>;
  sound_language?: Maybe<Scalars['ID']>;
  sound_location?: Maybe<Scalars['String']>;
  sound_text?: Maybe<Scalars['String']>;
  statement_answer_id?: Maybe<Scalars['Int']>;
};

export type Student_Mark = {
  progress_id?: InputMaybe<Scalars['ID']>;
  student_id?: InputMaybe<Scalars['ID']>;
};

export type Student_Mark_Teacher = {
  slide_id?: InputMaybe<Scalars['ID']>;
  student_id?: InputMaybe<Scalars['ID']>;
};

export type Tag = {
  __typename?: 'tag';
  tag_id?: Maybe<Scalars['ID']>;
  tag_name?: Maybe<Scalars['String']>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { LoginUser: (
    { __typename?: 'UserToken' }
    & Pick<UserToken, 'accessToken' | 'refreshToken'>
  ) }
);

export type GetSasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSAS'>
);

export type RefreshUserMutationVariables = Exact<{
  old_token: Scalars['String'];
}>;


export type RefreshUserMutation = (
  { __typename?: 'Mutation' }
  & { RefreshUser: (
    { __typename?: 'UserToken' }
    & Pick<UserToken, 'refreshToken' | 'accessToken'>
  ) }
);

export type GetSchoolStudentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSchoolStudentsQuery = (
  { __typename?: 'Query' }
  & { getSchoolStudents: Array<Maybe<(
    { __typename?: 'School_Student' }
    & Pick<School_Student, 'student_id' | 'correct_quizzes' | 'student_progress' | 'student_name' | 'student_email' | 'create_date' | 'student_class_start' | 'student_category' | 'student_phone_number' | 'access_code' | 'expiry_date' | 'has_marks' | 'total_quizzes'>
  )>> }
);

export type GetStudentProgressQueryVariables = Exact<{
  student_id: Scalars['ID'];
}>;


export type GetStudentProgressQuery = (
  { __typename?: 'Query' }
  & { getStudentProgress?: Maybe<Array<Maybe<(
    { __typename?: 'Student_progress' }
    & Pick<Student_Progress, 'achievement_name' | 'dash_status'>
  )>>> }
);

export type GetStudentFailedTagsQueryVariables = Exact<{
  student_id: Scalars['ID'];
}>;


export type GetStudentFailedTagsQuery = (
  { __typename?: 'Query' }
  & { getStudentFailedTags?: Maybe<Array<Maybe<(
    { __typename?: 'failed_tag' }
    & Pick<Failed_Tag, 'tag_id' | 'tag_name' | 'slides_failed'>
  )>>> }
);

export type DeleteLessonMutationVariables = Exact<{
  lesson_id: Scalars['ID'];
}>;


export type DeleteLessonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLesson'>
);

export type GetFailedSlidesTagQueryVariables = Exact<{
  student_id: Scalars['ID'];
  tag_id: Scalars['ID'];
  quiz_date?: Maybe<Scalars['DateTime']>;
}>;


export type GetFailedSlidesTagQuery = (
  { __typename?: 'Query' }
  & { getFailedSlidesTag?: Maybe<Array<Maybe<(
    { __typename?: 'progress' }
    & Pick<Progress, 'online_quiz_id' | 'progress_id' | 'original_slide_id' | 'explanation_location' | 'intro_sound_text' | 'media_location' | 'media_type_id' | 'media_location_enc' | 'intro_sound_location' | 'slide_pass_status'>
    & { statement_progress?: Maybe<Array<Maybe<(
      { __typename?: 'statement_student' }
      & Pick<Statement_Student, 'statement_progress_id' | 'online_slide_id' | 'original_statement_id' | 'statement_answer_id' | 'quiz_statement_no' | 'elaboration_Text' | 'sound_text' | 'sound_location' | 'slide_pass_status'>
    )>>> }
  )>>> }
);

export type DeleteStudentMutationVariables = Exact<{
  student_id: Scalars['ID'];
}>;


export type DeleteStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStudent'>
);

export type GetTeoriContentQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetTeoriContentQuery = (
  { __typename?: 'Query' }
  & { getTeoriContent?: Maybe<Array<Maybe<(
    { __typename?: 'content' }
    & Pick<Content, 'content_id' | 'media_type_id' | 'media_location'>
  )>>> }
);

export type EditStudentClassStartMutationVariables = Exact<{
  students?: Maybe<Array<Maybe<Student_Class_Start>> | Maybe<Student_Class_Start>>;
}>;


export type EditStudentClassStartMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editStudentClassStart'>
);

export type GetOwnedContentQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetOwnedContentQuery = (
  { __typename?: 'Query' }
  & { getOwnedContent?: Maybe<Array<Maybe<(
    { __typename?: 'content' }
    & Pick<Content, 'content_id' | 'media_type_id' | 'media_location'>
  )>>> }
);

export type GetSlidesPreviewQueryVariables = Exact<{
  lesson_id: Scalars['ID'];
  language_id: Scalars['ID'];
}>;


export type GetSlidesPreviewQuery = (
  { __typename?: 'Query' }
  & { getSlidesPreview?: Maybe<Array<Maybe<(
    { __typename?: 'short_slide' }
    & Pick<Short_Slide, 'slide_id' | 'media_type_name' | 'media_location' | 'media_location_enc'>
  )>>> }
);

export type GetLessonInfoQueryVariables = Exact<{
  lesson_id: Scalars['ID'];
  language_id: Scalars['ID'];
}>;


export type GetLessonInfoQuery = (
  { __typename?: 'Query' }
  & { getLessonInfo?: Maybe<Array<Maybe<(
    { __typename?: 'lesson' }
    & Pick<Lesson, 'lesson_name' | 'lesson_default_language' | 'lesson_category'>
    & { slides?: Maybe<Array<Maybe<(
      { __typename?: 'slide' }
      & Pick<Slide, 'slide_id' | 'language_id' | 'media_type_id' | 'slide_note' | 'media_location' | 'keywords' | 'slide_sound_location'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename?: 'tag' }
        & Pick<Tag, 'tag_id' | 'tag_name'>
      )>>> }
    )>>> }
  )>>> }
);

export type GetAvailableTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailableTagsQuery = (
  { __typename?: 'Query' }
  & { getAvailableTags?: Maybe<Array<Maybe<(
    { __typename?: 'tag' }
    & Pick<Tag, 'tag_id' | 'tag_name'>
  )>>> }
);

export type ManageContentMutationVariables = Exact<{
  content: Content_Input;
}>;


export type ManageContentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageContent'>
);

export type ManageLessonMutationVariables = Exact<{
  lesson: Lesson_Input;
}>;


export type ManageLessonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageLesson'>
);

export type ManageQuizMutationVariables = Exact<{
  quiz: Quiz_Input;
}>;


export type ManageQuizMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageQuiz'>
);

export type GetSoundContentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSoundContentQuery = (
  { __typename?: 'Query' }
  & { getSoundContent?: Maybe<Array<Maybe<(
    { __typename?: 'sound_content' }
    & Pick<Sound_Content, 'sound_content_id' | 'sound_text' | 'sound_location'>
  )>>> }
);

export type GetLessonEditQueryVariables = Exact<{
  lesson_id: Scalars['ID'];
  language_id: Scalars['ID'];
}>;


export type GetLessonEditQuery = (
  { __typename?: 'Query' }
  & { getLessonEdit?: Maybe<Array<Maybe<(
    { __typename?: 'lesson_edit' }
    & Pick<Lesson_Edit, 'lesson_id' | 'lesson_name' | 'lesson_default_language' | 'lesson_category'>
    & { slides?: Maybe<Array<Maybe<(
      { __typename?: 'slide_edit' }
      & Pick<Slide_Edit, 'slide_id'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename?: 'tag' }
        & Pick<Tag, 'tag_id' | 'tag_name'>
      )>>>, slide_translations?: Maybe<Array<Maybe<(
        { __typename?: 'slide_translation_edit' }
        & Pick<Slide_Translation_Edit, 'slide_translation_id' | 'language_id' | 'slide_note' | 'slide_sound_location' | 'translation_content' | 'keywords'>
        & { content?: Maybe<Array<Maybe<(
          { __typename?: 'content' }
          & Pick<Content, 'content_id' | 'media_type_id' | 'media_location'>
        )>>> }
      )>>> }
    )>>> }
  )>>> }
);

export type GetCompleteQuizEditQueryVariables = Exact<{
  quiz_id: Scalars['ID'];
  language_id: Scalars['ID'];
}>;


export type GetCompleteQuizEditQuery = (
  { __typename?: 'Query' }
  & { getCompleteQuizEdit?: Maybe<(
    { __typename?: 'quiz_edit' }
    & Pick<Quiz_Edit, 'quiz_Id' | 'quiz_name' | 'quiz_is_draft'>
    & { slides: Array<Maybe<(
      { __typename?: 'quiz_slide_edit' }
      & Pick<Quiz_Slide_Edit, 'quiz_id' | 'quiz_slide_id' | 'slide_is_accesible'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename?: 'tag' }
        & Pick<Tag, 'tag_id' | 'tag_name'>
      )>>>, intro_question_sound?: Maybe<Array<Maybe<(
        { __typename?: 'sound_content' }
        & Pick<Sound_Content, 'sound_language' | 'sound_text' | 'sound_location' | 'sound_content_id'>
      )>>>, slide_explanations?: Maybe<Array<Maybe<(
        { __typename?: 'slide_explanation' }
        & Pick<Slide_Explanation, 'explanation_id' | 'explanation_language' | 'explanation_location' | 'explanation_is_original'>
      )>>>, slide_content?: Maybe<Array<Maybe<(
        { __typename?: 'content' }
        & Pick<Content, 'media_owner' | 'media_location_enc' | 'media_location' | 'media_type_id' | 'content_id'>
      )>>>, slide_statements?: Maybe<Array<Maybe<(
        { __typename?: 'statement_edit' }
        & Pick<Statement_Edit, 'quiz_Statement_Id' | 'quiz_statement_no' | 'statement_answer_id' | 'quiz_statement_content'>
        & { quiz_statement_elaboration?: Maybe<Array<Maybe<(
          { __typename?: 'statement_elaboration' }
          & Pick<Statement_Elaboration, 'elaboration_id' | 'elaboration_language' | 'elaboration_text' | 'elaboration_is_original'>
        )>>>, quiz_statement_sound?: Maybe<Array<Maybe<(
          { __typename?: 'sound_content' }
          & Pick<Sound_Content, 'sound_language' | 'sound_text' | 'sound_location' | 'sound_content_id'>
        )>>> }
      )>>> }
    )>> }
  )> }
);

export type ManageClassConfigMutationVariables = Exact<{
  class_quiz_config: Class_Quiz_Config_I;
}>;


export type ManageClassConfigMutation = (
  { __typename?: 'Mutation' }
  & { manageClassConfig: (
    { __typename?: 'class_quiz_config' }
    & Pick<Class_Quiz_Config, 'class_quiz_config_id'>
  ) }
);

export type DeleteClassConfigMutationVariables = Exact<{
  class_quiz_config_id: Scalars['ID'];
}>;


export type DeleteClassConfigMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteClassConfig'>
);

export type InsertClassQuizMutationVariables = Exact<{
  class_quiz: Class_Quiz_Input;
}>;


export type InsertClassQuizMutation = (
  { __typename?: 'Mutation' }
  & { insertClassQuiz: (
    { __typename?: 'class_quiz' }
    & Pick<Class_Quiz, 'class_quiz_id'>
  ) }
);

export type GetContentQuizInfoQueryVariables = Exact<{
  class_quiz_id: Scalars['ID'];
  language_id?: Maybe<Scalars['ID']>;
}>;


export type GetContentQuizInfoQuery = (
  { __typename?: 'Query' }
  & { getContentQuizInfo?: Maybe<(
    { __typename?: 'class_quiz_config' }
    & { slides?: Maybe<Array<Maybe<(
      { __typename?: 'quiz_slide' }
      & Pick<Quiz_Slide, 'quiz_slide_id' | 'media_type_id' | 'intro_sound_text' | 'intro_sound_location' | 'media_location' | 'media_location_enc'>
      & { slide_statements?: Maybe<Array<Maybe<(
        { __typename?: 'statement_view' }
        & Pick<Statement_View, 'quiz_statement_no' | 'sound_text' | 'sound_location' | 'statement_answer_id' | 'elaboration_text'>
      )>>> }
    )>>> }
  )> }
);

export type GetTeacherDashboardQueryVariables = Exact<{
  category_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type GetTeacherDashboardQuery = (
  { __typename?: 'Query' }
  & { getTeacherDashboard?: Maybe<(
    { __typename?: 'Dashboard' }
    & { class_quiz_configs?: Maybe<Array<Maybe<(
      { __typename?: 'class_quiz_config' }
      & Pick<Class_Quiz_Config, 'class_quiz_config_id' | 'config_name' | 'config_creation_date' | 'quiz_speed' | 'quiz_language' | 'quiz_subtitles' | 'include_teori' | 'slide_amount' | 'save_for_later' | 'base_quiz_id'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename?: 'tag' }
        & Pick<Tag, 'tag_name' | 'tag_id'>
      )>>> }
    )>>>, default_quizzes?: Maybe<Array<Maybe<(
      { __typename?: 'quiz' }
      & Pick<Quiz, 'quiz_Id' | 'quiz_name' | 'quiz_creation_date'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename?: 'tag' }
        & Pick<Tag, 'tag_name' | 'tag_id'>
      )>>> }
    )>>>, teacher_lessons?: Maybe<Array<Maybe<(
      { __typename?: 'lesson' }
      & Pick<Lesson, 'lesson_id' | 'lesson_name' | 'lesson_creation_date' | 'lesson_category' | 'lesson_published'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename?: 'tag' }
        & Pick<Tag, 'tag_name' | 'tag_id'>
      )>>> }
    )>>>, default_lessons?: Maybe<Array<Maybe<(
      { __typename?: 'lesson' }
      & Pick<Lesson, 'lesson_id' | 'lesson_name' | 'lesson_creation_date' | 'lesson_category'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename?: 'tag' }
        & Pick<Tag, 'tag_name' | 'tag_id'>
      )>>> }
    )>>> }
  )> }
);

export type DeleteSlideMutationVariables = Exact<{
  slide_id: Scalars['ID'];
}>;


export type DeleteSlideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSlide'>
);

export type DeleteSlideTagMutationVariables = Exact<{
  delete_quiz_slide_id?: Maybe<Scalars['ID']>;
  delete_tag_id?: Maybe<Scalars['ID']>;
}>;


export type DeleteSlideTagMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSlideTag'>
);

export type DeleteQuizSlideMutationVariables = Exact<{
  quiz_slide_id: Scalars['ID'];
}>;


export type DeleteQuizSlideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteQuizSlide'>
);

export type DeleteQuizStatementMutationVariables = Exact<{
  quiz_statement_id: Scalars['ID'];
}>;


export type DeleteQuizStatementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteQuizStatement'>
);

export type GetStudentsAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudentsAdminQuery = (
  { __typename?: 'Query' }
  & { getStudentsAdmin?: Maybe<Array<Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'student_id' | 'expiry_date' | 'student_email' | 'create_date' | 'school_name' | 'first_name' | 'last_name' | 'student_phone_number' | 'optional_value' | 'student_category_desc' | 'student_code' | 'language_choice' | 'school_id' | 'student_category'>
  )>>> }
);

export type ManageStudentMutationVariables = Exact<{
  student: New_Student;
}>;


export type ManageStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageStudent'>
);

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = (
  { __typename?: 'Query' }
  & { getCategories?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'cat_id' | 'cat_name'>
  )>>> }
);

export type GetSchoolListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSchoolListQuery = (
  { __typename?: 'Query' }
  & { getSchoolList?: Maybe<Array<Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'school_id' | 'school_name' | 'zipcode'>
  )>>> }
);

export type GetAdminStudentLoginInfoQueryVariables = Exact<{
  student_id: Scalars['ID'];
}>;


export type GetAdminStudentLoginInfoQuery = (
  { __typename?: 'Query' }
  & { getAdminStudentLoginInfo?: Maybe<(
    { __typename?: 'Student_admin_info' }
    & Pick<Student_Admin_Info, 'student_pin' | 'student_phone_number'>
  )> }
);

export type GetStudentActivityQueryVariables = Exact<{
  student_id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetStudentActivityQuery = (
  { __typename?: 'Query' }
  & { getStudentActivity?: Maybe<Array<Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'activity_id' | 'activity_type' | 'activity_title' | 'activity_result' | 'dash_quiz_id' | 'activity_date' | 'activity_time'>
  )>>> }
);

export type BlockSlideMutationVariables = Exact<{
  slides?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type BlockSlideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BlockSlide'>
);

export type DeleteSlideBlockMutationVariables = Exact<{
  slides?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type DeleteSlideBlockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteSlideBlock'>
);

export type GetBlockedSlidesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBlockedSlidesQuery = (
  { __typename?: 'Query' }
  & { getBlockedSlides?: Maybe<Array<Maybe<(
    { __typename?: 'Blocked_slide' }
    & Pick<Blocked_Slide, 'quiz_slide_id'>
  )>>> }
);

export type GetMarkedSlidesQueryVariables = Exact<{
  student_id: Scalars['ID'];
}>;


export type GetMarkedSlidesQuery = (
  { __typename?: 'Query' }
  & { getMarkedSlides?: Maybe<Array<Maybe<(
    { __typename?: 'Mark_slide' }
    & Pick<Mark_Slide, 'progress_id' | 'online_quiz_id' | 'original_slide_id' | 'explanation_location' | 'intro_sound_text' | 'media_location' | 'media_location_enc' | 'media_type_id'>
    & { slide_statements?: Maybe<Array<Maybe<(
      { __typename?: 'statement_student' }
      & Pick<Statement_Student, 'original_statement_id' | 'statement_answer_id' | 'quiz_statement_no' | 'elaboration_Text' | 'sound_text' | 'slide_pass_status' | 'statement_progress_answer'>
    )>>> }
  )>>> }
);

export type UnmarkSlideMutationVariables = Exact<{
  unmarked_slides?: Maybe<Array<Maybe<Student_Mark>> | Maybe<Student_Mark>>;
}>;


export type UnmarkSlideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnmarkSlide'>
);

export type UnmarkSlideTeacherMutationVariables = Exact<{
  unmarked_original_slides?: Maybe<Array<Maybe<Student_Mark_Teacher>> | Maybe<Student_Mark_Teacher>>;
}>;


export type UnmarkSlideTeacherMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnmarkSlideTeacher'>
);

export type GetMarkedSlidesCountQueryVariables = Exact<{
  student_ids: Scalars['String'];
}>;


export type GetMarkedSlidesCountQuery = (
  { __typename?: 'Query' }
  & { getMarkedSlidesCount?: Maybe<Array<Maybe<(
    { __typename?: 'Mark_slide' }
    & Pick<Mark_Slide, 'progress_id' | 'online_quiz_id' | 'original_slide_id' | 'explanation_location' | 'intro_sound_text' | 'media_location' | 'media_location_enc' | 'media_type_id' | 'mark_count'>
    & { slide_statements?: Maybe<Array<Maybe<(
      { __typename?: 'statement_student' }
      & Pick<Statement_Student, 'original_statement_id' | 'statement_answer_id' | 'quiz_statement_no' | 'elaboration_Text' | 'sound_text' | 'slide_pass_status' | 'statement_progress_answer'>
    )>>>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'tag' }
      & Pick<Tag, 'tag_id' | 'tag_name'>
    )>>> }
  )>>> }
);

export type GetSubjectLessonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubjectLessonsQuery = (
  { __typename?: 'Query' }
  & { getSubjectLessons?: Maybe<Array<Maybe<(
    { __typename?: 'lesson' }
    & Pick<Lesson, 'lesson_id' | 'lesson_name' | 'lesson_category'>
  )>>> }
);

export type CombineLessonMutationVariables = Exact<{
  combined_lesson?: Maybe<Combined_Lesson>;
}>;


export type CombineLessonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'combineLesson'>
);

export type PublishLessonMutationVariables = Exact<{
  lesson_id?: Maybe<Scalars['ID']>;
  lesson_published?: Maybe<Scalars['Boolean']>;
}>;


export type PublishLessonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publishLesson'>
);

export type GetStudentAchTypesQueryVariables = Exact<{
  student_id: Scalars['ID'];
}>;


export type GetStudentAchTypesQuery = (
  { __typename?: 'Query' }
  & { getStudentAchTypes?: Maybe<Array<Maybe<(
    { __typename?: 'Achievement_type' }
    & Pick<Achievement_Type, 'achievement_name' | 'achievement_type_desc'>
    & { achievement_dash?: Maybe<Array<Maybe<(
      { __typename?: 'Student_ach_get' }
      & Pick<Student_Ach_Get, 'achievement_name' | 'dash_status'>
    )>>> }
  )>>> }
);

export type GetStudentsSchoolDeletedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudentsSchoolDeletedQuery = (
  { __typename?: 'Query' }
  & { getStudentsSchoolDeleted?: Maybe<Array<Maybe<(
    { __typename?: 'School_Student' }
    & Pick<School_Student, 'student_id' | 'correct_quizzes' | 'student_progress' | 'student_name' | 'student_email' | 'create_date' | 'student_class_start' | 'student_category' | 'student_phone_number' | 'access_code' | 'expiry_date' | 'has_marks'>
  )>>> }
);

export type UnlinkStudentMutationVariables = Exact<{
  student_id: Scalars['ID'];
}>;


export type UnlinkStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnlinkStudent'>
);

export type UnlinkSlideMutationVariables = Exact<{
  lesson_slide_id: Scalars['ID'];
  tag_id: Scalars['ID'];
}>;


export type UnlinkSlideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unlinkSlide'>
);

export type CloneLessonMutationVariables = Exact<{
  lesson_id: Scalars['ID'];
}>;


export type CloneLessonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cloneLesson'>
);

export type GetClassQuizResultQueryVariables = Exact<{
  class_quiz_id: Scalars['ID'];
}>;


export type GetClassQuizResultQuery = (
  { __typename?: 'Query' }
  & { getClassQuizResult?: Maybe<Array<Maybe<(
    { __typename?: 'Class_result' }
    & Pick<Class_Result, 'slide_id' | 'slide_pass_percentage'>
  )>>> }
);

export type GetAllSchoolsQueryVariables = Exact<{
  is_active: Scalars['Int'];
}>;


export type GetAllSchoolsQuery = (
  { __typename?: 'Query' }
  & { getAllSchools?: Maybe<Array<Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'school_id' | 'school_name' | 'contact_name' | 'email_id' | 'optional_note' | 'language_choice' | 'zipcode' | 'city' | 'password' | 'vat_number' | 'skip_bill_check'>
  )>>> }
);

export type SetSchoolStatusMutationVariables = Exact<{
  school_id: Scalars['Int'];
  school_status: Scalars['Int'];
}>;


export type SetSchoolStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setSchoolStatus'>
);

export type ManageSchoolMutationVariables = Exact<{
  school: SchoolInput;
}>;


export type ManageSchoolMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageSchool'>
);

export type GetConfigCountByIdQueryVariables = Exact<{
  configId: Scalars['Int'];
}>;


export type GetConfigCountByIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getConfigCountByID'>
);

export type CreateSchoolCategoryMutationVariables = Exact<{
  schoolId: Scalars['Int'];
  categoryId: Scalars['Int'];
}>;


export type CreateSchoolCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSchoolCategory'>
);

export type GetSchoolCategoryQueryVariables = Exact<{
  schoolId?: Maybe<Scalars['Int']>;
}>;


export type GetSchoolCategoryQuery = (
  { __typename?: 'Query' }
  & { getSchoolCategory?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'cat_id' | 'cat_name'>
  )>>> }
);

export type GetSchoolSubusersAdminQueryVariables = Exact<{
  schoolId: Scalars['Int'];
}>;


export type GetSchoolSubusersAdminQuery = (
  { __typename?: 'Query' }
  & { getSchoolSubusersAdmin?: Maybe<Array<(
    { __typename?: 'Subuser' }
    & Pick<Subuser, 'subuser_id' | 'teacher_name' | 'email_id' | 'school_id' | 'is_department' | 'department_name' | 'department_zipcode' | 'skip_bill_check'>
  )>> }
);

export type ManageSubuserMutationVariables = Exact<{
  subuser: New_Subuser;
}>;


export type ManageSubuserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageSubuser'>
);

export type DeleteSubuserMutationVariables = Exact<{
  subuserId: Scalars['ID'];
}>;


export type DeleteSubuserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubuser'>
);

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCategoriesQuery = (
  { __typename?: 'Query' }
  & { getAllCategories?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'cat_id' | 'cat_name'>
  )>>> }
);

export type GetTeoriLessonStudentsQueryVariables = Exact<{
  lessonId?: Maybe<Scalars['String']>;
}>;


export type GetTeoriLessonStudentsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetTeoriLessonStudents'>
);

export type GetAllSlidesQueryVariables = Exact<{
  included_slides?: Maybe<Scalars['Int']>;
  language_chosen?: Maybe<Scalars['Int']>;
}>;


export type GetAllSlidesQuery = (
  { __typename?: 'Query' }
  & { getAllSlides?: Maybe<Array<Maybe<(
    { __typename?: 'quiz_slide' }
    & Pick<Quiz_Slide, 'quiz_slide_id' | 'media_type_id' | 'intro_sound_text' | 'intro_sound_location' | 'media_location' | 'media_location_enc' | 'quiz_id'>
    & { slide_statements?: Maybe<Array<Maybe<(
      { __typename?: 'statement_view' }
      & Pick<Statement_View, 'quiz_statement_no' | 'sound_text' | 'sound_location' | 'statement_answer_id' | 'elaboration_text'>
    )>>>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'tag' }
      & Pick<Tag, 'tag_id' | 'tag_name'>
    )>>> }
  )>>> }
);


export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  LoginUser(email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetSasDocument = gql`
    query getSAS {
  getSAS
}
    `;

/**
 * __useGetSasQuery__
 *
 * To run a query within a React component, call `useGetSasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSasQuery(baseOptions?: Apollo.QueryHookOptions<GetSasQuery, GetSasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSasQuery, GetSasQueryVariables>(GetSasDocument, options);
      }
export function useGetSasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSasQuery, GetSasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSasQuery, GetSasQueryVariables>(GetSasDocument, options);
        }
export type GetSasQueryHookResult = ReturnType<typeof useGetSasQuery>;
export type GetSasLazyQueryHookResult = ReturnType<typeof useGetSasLazyQuery>;
export type GetSasQueryResult = Apollo.QueryResult<GetSasQuery, GetSasQueryVariables>;
export const RefreshUserDocument = gql`
    mutation RefreshUser($old_token: String!) {
  RefreshUser(old_token: $old_token) {
    refreshToken
    accessToken
  }
}
    `;
export type RefreshUserMutationFn = Apollo.MutationFunction<RefreshUserMutation, RefreshUserMutationVariables>;

/**
 * __useRefreshUserMutation__
 *
 * To run a mutation, you first call `useRefreshUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshUserMutation, { data, loading, error }] = useRefreshUserMutation({
 *   variables: {
 *      old_token: // value for 'old_token'
 *   },
 * });
 */
export function useRefreshUserMutation(baseOptions?: Apollo.MutationHookOptions<RefreshUserMutation, RefreshUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshUserMutation, RefreshUserMutationVariables>(RefreshUserDocument, options);
      }
export type RefreshUserMutationHookResult = ReturnType<typeof useRefreshUserMutation>;
export type RefreshUserMutationResult = Apollo.MutationResult<RefreshUserMutation>;
export type RefreshUserMutationOptions = Apollo.BaseMutationOptions<RefreshUserMutation, RefreshUserMutationVariables>;
export const GetSchoolStudentsDocument = gql`
    query getSchoolStudents {
  getSchoolStudents {
    student_id
    correct_quizzes
    student_progress
    student_name
    student_email
    create_date
    student_class_start
    student_category
    student_phone_number
    access_code
    expiry_date
    has_marks
    total_quizzes
  }
}
    `;

/**
 * __useGetSchoolStudentsQuery__
 *
 * To run a query within a React component, call `useGetSchoolStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolStudentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchoolStudentsQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>(GetSchoolStudentsDocument, options);
      }
export function useGetSchoolStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>(GetSchoolStudentsDocument, options);
        }
export type GetSchoolStudentsQueryHookResult = ReturnType<typeof useGetSchoolStudentsQuery>;
export type GetSchoolStudentsLazyQueryHookResult = ReturnType<typeof useGetSchoolStudentsLazyQuery>;
export type GetSchoolStudentsQueryResult = Apollo.QueryResult<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>;
export const GetStudentProgressDocument = gql`
    query getStudentProgress($student_id: ID!) {
  getStudentProgress(student_id: $student_id) {
    achievement_name
    dash_status
  }
}
    `;

/**
 * __useGetStudentProgressQuery__
 *
 * To run a query within a React component, call `useGetStudentProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentProgressQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useGetStudentProgressQuery(baseOptions: Apollo.QueryHookOptions<GetStudentProgressQuery, GetStudentProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentProgressQuery, GetStudentProgressQueryVariables>(GetStudentProgressDocument, options);
      }
export function useGetStudentProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentProgressQuery, GetStudentProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentProgressQuery, GetStudentProgressQueryVariables>(GetStudentProgressDocument, options);
        }
export type GetStudentProgressQueryHookResult = ReturnType<typeof useGetStudentProgressQuery>;
export type GetStudentProgressLazyQueryHookResult = ReturnType<typeof useGetStudentProgressLazyQuery>;
export type GetStudentProgressQueryResult = Apollo.QueryResult<GetStudentProgressQuery, GetStudentProgressQueryVariables>;
export const GetStudentFailedTagsDocument = gql`
    query getStudentFailedTags($student_id: ID!) {
  getStudentFailedTags(student_id: $student_id) {
    tag_id
    tag_name
    slides_failed
  }
}
    `;

/**
 * __useGetStudentFailedTagsQuery__
 *
 * To run a query within a React component, call `useGetStudentFailedTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentFailedTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentFailedTagsQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useGetStudentFailedTagsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentFailedTagsQuery, GetStudentFailedTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentFailedTagsQuery, GetStudentFailedTagsQueryVariables>(GetStudentFailedTagsDocument, options);
      }
export function useGetStudentFailedTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentFailedTagsQuery, GetStudentFailedTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentFailedTagsQuery, GetStudentFailedTagsQueryVariables>(GetStudentFailedTagsDocument, options);
        }
export type GetStudentFailedTagsQueryHookResult = ReturnType<typeof useGetStudentFailedTagsQuery>;
export type GetStudentFailedTagsLazyQueryHookResult = ReturnType<typeof useGetStudentFailedTagsLazyQuery>;
export type GetStudentFailedTagsQueryResult = Apollo.QueryResult<GetStudentFailedTagsQuery, GetStudentFailedTagsQueryVariables>;
export const DeleteLessonDocument = gql`
    mutation deleteLesson($lesson_id: ID!) {
  deleteLesson(lesson_id: $lesson_id)
}
    `;
export type DeleteLessonMutationFn = Apollo.MutationFunction<DeleteLessonMutation, DeleteLessonMutationVariables>;

/**
 * __useDeleteLessonMutation__
 *
 * To run a mutation, you first call `useDeleteLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonMutation, { data, loading, error }] = useDeleteLessonMutation({
 *   variables: {
 *      lesson_id: // value for 'lesson_id'
 *   },
 * });
 */
export function useDeleteLessonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLessonMutation, DeleteLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLessonMutation, DeleteLessonMutationVariables>(DeleteLessonDocument, options);
      }
export type DeleteLessonMutationHookResult = ReturnType<typeof useDeleteLessonMutation>;
export type DeleteLessonMutationResult = Apollo.MutationResult<DeleteLessonMutation>;
export type DeleteLessonMutationOptions = Apollo.BaseMutationOptions<DeleteLessonMutation, DeleteLessonMutationVariables>;
export const GetFailedSlidesTagDocument = gql`
    query getFailedSlidesTag($student_id: ID!, $tag_id: ID!, $quiz_date: DateTime) {
  getFailedSlidesTag(
    student_id: $student_id
    tag_id: $tag_id
    quiz_date: $quiz_date
  ) {
    online_quiz_id
    progress_id
    original_slide_id
    online_quiz_id
    explanation_location
    intro_sound_text
    media_location
    media_type_id
    media_location_enc
    intro_sound_location
    slide_pass_status
    statement_progress {
      statement_progress_id
      online_slide_id
      original_statement_id
      statement_answer_id
      quiz_statement_no
      elaboration_Text
      sound_text
      sound_location
      slide_pass_status
    }
  }
}
    `;

/**
 * __useGetFailedSlidesTagQuery__
 *
 * To run a query within a React component, call `useGetFailedSlidesTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFailedSlidesTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFailedSlidesTagQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *      tag_id: // value for 'tag_id'
 *      quiz_date: // value for 'quiz_date'
 *   },
 * });
 */
export function useGetFailedSlidesTagQuery(baseOptions: Apollo.QueryHookOptions<GetFailedSlidesTagQuery, GetFailedSlidesTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFailedSlidesTagQuery, GetFailedSlidesTagQueryVariables>(GetFailedSlidesTagDocument, options);
      }
export function useGetFailedSlidesTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFailedSlidesTagQuery, GetFailedSlidesTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFailedSlidesTagQuery, GetFailedSlidesTagQueryVariables>(GetFailedSlidesTagDocument, options);
        }
export type GetFailedSlidesTagQueryHookResult = ReturnType<typeof useGetFailedSlidesTagQuery>;
export type GetFailedSlidesTagLazyQueryHookResult = ReturnType<typeof useGetFailedSlidesTagLazyQuery>;
export type GetFailedSlidesTagQueryResult = Apollo.QueryResult<GetFailedSlidesTagQuery, GetFailedSlidesTagQueryVariables>;
export const DeleteStudentDocument = gql`
    mutation deleteStudent($student_id: ID!) {
  deleteStudent(student_id: $student_id)
}
    `;
export type DeleteStudentMutationFn = Apollo.MutationFunction<DeleteStudentMutation, DeleteStudentMutationVariables>;

/**
 * __useDeleteStudentMutation__
 *
 * To run a mutation, you first call `useDeleteStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentMutation, { data, loading, error }] = useDeleteStudentMutation({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useDeleteStudentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStudentMutation, DeleteStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStudentMutation, DeleteStudentMutationVariables>(DeleteStudentDocument, options);
      }
export type DeleteStudentMutationHookResult = ReturnType<typeof useDeleteStudentMutation>;
export type DeleteStudentMutationResult = Apollo.MutationResult<DeleteStudentMutation>;
export type DeleteStudentMutationOptions = Apollo.BaseMutationOptions<DeleteStudentMutation, DeleteStudentMutationVariables>;
export const GetTeoriContentDocument = gql`
    query getTeoriContent($offset: Int, $limit: Int) {
  getTeoriContent(offset: $offset, limit: $limit) {
    content_id
    media_type_id
    media_location
  }
}
    `;

/**
 * __useGetTeoriContentQuery__
 *
 * To run a query within a React component, call `useGetTeoriContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeoriContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeoriContentQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTeoriContentQuery(baseOptions?: Apollo.QueryHookOptions<GetTeoriContentQuery, GetTeoriContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeoriContentQuery, GetTeoriContentQueryVariables>(GetTeoriContentDocument, options);
      }
export function useGetTeoriContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeoriContentQuery, GetTeoriContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeoriContentQuery, GetTeoriContentQueryVariables>(GetTeoriContentDocument, options);
        }
export type GetTeoriContentQueryHookResult = ReturnType<typeof useGetTeoriContentQuery>;
export type GetTeoriContentLazyQueryHookResult = ReturnType<typeof useGetTeoriContentLazyQuery>;
export type GetTeoriContentQueryResult = Apollo.QueryResult<GetTeoriContentQuery, GetTeoriContentQueryVariables>;
export const EditStudentClassStartDocument = gql`
    mutation editStudentClassStart($students: [Student_class_start]) {
  editStudentClassStart(students: $students)
}
    `;
export type EditStudentClassStartMutationFn = Apollo.MutationFunction<EditStudentClassStartMutation, EditStudentClassStartMutationVariables>;

/**
 * __useEditStudentClassStartMutation__
 *
 * To run a mutation, you first call `useEditStudentClassStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStudentClassStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStudentClassStartMutation, { data, loading, error }] = useEditStudentClassStartMutation({
 *   variables: {
 *      students: // value for 'students'
 *   },
 * });
 */
export function useEditStudentClassStartMutation(baseOptions?: Apollo.MutationHookOptions<EditStudentClassStartMutation, EditStudentClassStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditStudentClassStartMutation, EditStudentClassStartMutationVariables>(EditStudentClassStartDocument, options);
      }
export type EditStudentClassStartMutationHookResult = ReturnType<typeof useEditStudentClassStartMutation>;
export type EditStudentClassStartMutationResult = Apollo.MutationResult<EditStudentClassStartMutation>;
export type EditStudentClassStartMutationOptions = Apollo.BaseMutationOptions<EditStudentClassStartMutation, EditStudentClassStartMutationVariables>;
export const GetOwnedContentDocument = gql`
    query getOwnedContent($offset: Int, $limit: Int) {
  getOwnedContent(offset: $offset, limit: $limit) {
    content_id
    media_type_id
    media_location
  }
}
    `;

/**
 * __useGetOwnedContentQuery__
 *
 * To run a query within a React component, call `useGetOwnedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnedContentQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetOwnedContentQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnedContentQuery, GetOwnedContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnedContentQuery, GetOwnedContentQueryVariables>(GetOwnedContentDocument, options);
      }
export function useGetOwnedContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnedContentQuery, GetOwnedContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnedContentQuery, GetOwnedContentQueryVariables>(GetOwnedContentDocument, options);
        }
export type GetOwnedContentQueryHookResult = ReturnType<typeof useGetOwnedContentQuery>;
export type GetOwnedContentLazyQueryHookResult = ReturnType<typeof useGetOwnedContentLazyQuery>;
export type GetOwnedContentQueryResult = Apollo.QueryResult<GetOwnedContentQuery, GetOwnedContentQueryVariables>;
export const GetSlidesPreviewDocument = gql`
    query getSlidesPreview($lesson_id: ID!, $language_id: ID!) {
  getSlidesPreview(lesson_id: $lesson_id, language_id: $language_id) {
    slide_id
    media_type_name
    media_location
    media_location_enc
  }
}
    `;

/**
 * __useGetSlidesPreviewQuery__
 *
 * To run a query within a React component, call `useGetSlidesPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlidesPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlidesPreviewQuery({
 *   variables: {
 *      lesson_id: // value for 'lesson_id'
 *      language_id: // value for 'language_id'
 *   },
 * });
 */
export function useGetSlidesPreviewQuery(baseOptions: Apollo.QueryHookOptions<GetSlidesPreviewQuery, GetSlidesPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSlidesPreviewQuery, GetSlidesPreviewQueryVariables>(GetSlidesPreviewDocument, options);
      }
export function useGetSlidesPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlidesPreviewQuery, GetSlidesPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSlidesPreviewQuery, GetSlidesPreviewQueryVariables>(GetSlidesPreviewDocument, options);
        }
export type GetSlidesPreviewQueryHookResult = ReturnType<typeof useGetSlidesPreviewQuery>;
export type GetSlidesPreviewLazyQueryHookResult = ReturnType<typeof useGetSlidesPreviewLazyQuery>;
export type GetSlidesPreviewQueryResult = Apollo.QueryResult<GetSlidesPreviewQuery, GetSlidesPreviewQueryVariables>;
export const GetLessonInfoDocument = gql`
    query getLessonInfo($lesson_id: ID!, $language_id: ID!) {
  getLessonInfo(lesson_id: $lesson_id, language_id: $language_id) {
    lesson_name
    lesson_default_language
    lesson_category
    lesson_default_language
    slides {
      slide_id
      language_id
      media_type_id
      language_id
      slide_note
      media_location
      keywords
      slide_sound_location
      tags {
        tag_id
        tag_name
      }
    }
  }
}
    `;

/**
 * __useGetLessonInfoQuery__
 *
 * To run a query within a React component, call `useGetLessonInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonInfoQuery({
 *   variables: {
 *      lesson_id: // value for 'lesson_id'
 *      language_id: // value for 'language_id'
 *   },
 * });
 */
export function useGetLessonInfoQuery(baseOptions: Apollo.QueryHookOptions<GetLessonInfoQuery, GetLessonInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonInfoQuery, GetLessonInfoQueryVariables>(GetLessonInfoDocument, options);
      }
export function useGetLessonInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonInfoQuery, GetLessonInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonInfoQuery, GetLessonInfoQueryVariables>(GetLessonInfoDocument, options);
        }
export type GetLessonInfoQueryHookResult = ReturnType<typeof useGetLessonInfoQuery>;
export type GetLessonInfoLazyQueryHookResult = ReturnType<typeof useGetLessonInfoLazyQuery>;
export type GetLessonInfoQueryResult = Apollo.QueryResult<GetLessonInfoQuery, GetLessonInfoQueryVariables>;
export const GetAvailableTagsDocument = gql`
    query getAvailableTags {
  getAvailableTags {
    tag_id
    tag_name
  }
}
    `;

/**
 * __useGetAvailableTagsQuery__
 *
 * To run a query within a React component, call `useGetAvailableTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailableTagsQuery, GetAvailableTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableTagsQuery, GetAvailableTagsQueryVariables>(GetAvailableTagsDocument, options);
      }
export function useGetAvailableTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableTagsQuery, GetAvailableTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableTagsQuery, GetAvailableTagsQueryVariables>(GetAvailableTagsDocument, options);
        }
export type GetAvailableTagsQueryHookResult = ReturnType<typeof useGetAvailableTagsQuery>;
export type GetAvailableTagsLazyQueryHookResult = ReturnType<typeof useGetAvailableTagsLazyQuery>;
export type GetAvailableTagsQueryResult = Apollo.QueryResult<GetAvailableTagsQuery, GetAvailableTagsQueryVariables>;
export const ManageContentDocument = gql`
    mutation manageContent($content: Content_input!) {
  manageContent(content: $content)
}
    `;
export type ManageContentMutationFn = Apollo.MutationFunction<ManageContentMutation, ManageContentMutationVariables>;

/**
 * __useManageContentMutation__
 *
 * To run a mutation, you first call `useManageContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageContentMutation, { data, loading, error }] = useManageContentMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useManageContentMutation(baseOptions?: Apollo.MutationHookOptions<ManageContentMutation, ManageContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageContentMutation, ManageContentMutationVariables>(ManageContentDocument, options);
      }
export type ManageContentMutationHookResult = ReturnType<typeof useManageContentMutation>;
export type ManageContentMutationResult = Apollo.MutationResult<ManageContentMutation>;
export type ManageContentMutationOptions = Apollo.BaseMutationOptions<ManageContentMutation, ManageContentMutationVariables>;
export const ManageLessonDocument = gql`
    mutation manageLesson($lesson: Lesson_input!) {
  manageLesson(lesson: $lesson)
}
    `;
export type ManageLessonMutationFn = Apollo.MutationFunction<ManageLessonMutation, ManageLessonMutationVariables>;

/**
 * __useManageLessonMutation__
 *
 * To run a mutation, you first call `useManageLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageLessonMutation, { data, loading, error }] = useManageLessonMutation({
 *   variables: {
 *      lesson: // value for 'lesson'
 *   },
 * });
 */
export function useManageLessonMutation(baseOptions?: Apollo.MutationHookOptions<ManageLessonMutation, ManageLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageLessonMutation, ManageLessonMutationVariables>(ManageLessonDocument, options);
      }
export type ManageLessonMutationHookResult = ReturnType<typeof useManageLessonMutation>;
export type ManageLessonMutationResult = Apollo.MutationResult<ManageLessonMutation>;
export type ManageLessonMutationOptions = Apollo.BaseMutationOptions<ManageLessonMutation, ManageLessonMutationVariables>;
export const ManageQuizDocument = gql`
    mutation manageQuiz($quiz: Quiz_input!) {
  manageQuiz(quiz: $quiz)
}
    `;
export type ManageQuizMutationFn = Apollo.MutationFunction<ManageQuizMutation, ManageQuizMutationVariables>;

/**
 * __useManageQuizMutation__
 *
 * To run a mutation, you first call `useManageQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageQuizMutation, { data, loading, error }] = useManageQuizMutation({
 *   variables: {
 *      quiz: // value for 'quiz'
 *   },
 * });
 */
export function useManageQuizMutation(baseOptions?: Apollo.MutationHookOptions<ManageQuizMutation, ManageQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageQuizMutation, ManageQuizMutationVariables>(ManageQuizDocument, options);
      }
export type ManageQuizMutationHookResult = ReturnType<typeof useManageQuizMutation>;
export type ManageQuizMutationResult = Apollo.MutationResult<ManageQuizMutation>;
export type ManageQuizMutationOptions = Apollo.BaseMutationOptions<ManageQuizMutation, ManageQuizMutationVariables>;
export const GetSoundContentDocument = gql`
    query getSoundContent {
  getSoundContent {
    sound_content_id
    sound_text
    sound_location
  }
}
    `;

/**
 * __useGetSoundContentQuery__
 *
 * To run a query within a React component, call `useGetSoundContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoundContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoundContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSoundContentQuery(baseOptions?: Apollo.QueryHookOptions<GetSoundContentQuery, GetSoundContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSoundContentQuery, GetSoundContentQueryVariables>(GetSoundContentDocument, options);
      }
export function useGetSoundContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSoundContentQuery, GetSoundContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSoundContentQuery, GetSoundContentQueryVariables>(GetSoundContentDocument, options);
        }
export type GetSoundContentQueryHookResult = ReturnType<typeof useGetSoundContentQuery>;
export type GetSoundContentLazyQueryHookResult = ReturnType<typeof useGetSoundContentLazyQuery>;
export type GetSoundContentQueryResult = Apollo.QueryResult<GetSoundContentQuery, GetSoundContentQueryVariables>;
export const GetLessonEditDocument = gql`
    query getLessonEdit($lesson_id: ID!, $language_id: ID!) {
  getLessonEdit(lesson_id: $lesson_id, language_id: $language_id) {
    lesson_id
    lesson_name
    lesson_default_language
    lesson_category
    slides {
      slide_id
      tags {
        tag_id
        tag_name
      }
      slide_translations {
        slide_translation_id
        language_id
        slide_note
        slide_sound_location
        translation_content
        keywords
        content {
          content_id
          media_type_id
          media_location
        }
      }
    }
  }
}
    `;

/**
 * __useGetLessonEditQuery__
 *
 * To run a query within a React component, call `useGetLessonEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonEditQuery({
 *   variables: {
 *      lesson_id: // value for 'lesson_id'
 *      language_id: // value for 'language_id'
 *   },
 * });
 */
export function useGetLessonEditQuery(baseOptions: Apollo.QueryHookOptions<GetLessonEditQuery, GetLessonEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonEditQuery, GetLessonEditQueryVariables>(GetLessonEditDocument, options);
      }
export function useGetLessonEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonEditQuery, GetLessonEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonEditQuery, GetLessonEditQueryVariables>(GetLessonEditDocument, options);
        }
export type GetLessonEditQueryHookResult = ReturnType<typeof useGetLessonEditQuery>;
export type GetLessonEditLazyQueryHookResult = ReturnType<typeof useGetLessonEditLazyQuery>;
export type GetLessonEditQueryResult = Apollo.QueryResult<GetLessonEditQuery, GetLessonEditQueryVariables>;
export const GetCompleteQuizEditDocument = gql`
    query getCompleteQuizEdit($quiz_id: ID!, $language_id: ID!) {
  getCompleteQuizEdit(quiz_id: $quiz_id, language_id: $language_id) {
    quiz_Id
    quiz_name
    quiz_is_draft
    slides {
      quiz_id
      quiz_slide_id
      slide_is_accesible
      tags {
        tag_id
        tag_name
      }
      intro_question_sound {
        sound_language
        sound_text
        sound_location
        sound_content_id
      }
      slide_explanations {
        explanation_id
        explanation_language
        explanation_location
        explanation_is_original
      }
      slide_content {
        media_owner
        media_location_enc
        media_location
        media_type_id
        content_id
      }
      slide_statements {
        quiz_Statement_Id
        quiz_statement_no
        statement_answer_id
        quiz_statement_content
        quiz_statement_elaboration {
          elaboration_id
          elaboration_language
          elaboration_text
          elaboration_is_original
        }
        quiz_statement_sound {
          sound_language
          sound_text
          sound_location
          sound_content_id
        }
      }
    }
  }
}
    `;

/**
 * __useGetCompleteQuizEditQuery__
 *
 * To run a query within a React component, call `useGetCompleteQuizEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompleteQuizEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompleteQuizEditQuery({
 *   variables: {
 *      quiz_id: // value for 'quiz_id'
 *      language_id: // value for 'language_id'
 *   },
 * });
 */
export function useGetCompleteQuizEditQuery(baseOptions: Apollo.QueryHookOptions<GetCompleteQuizEditQuery, GetCompleteQuizEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompleteQuizEditQuery, GetCompleteQuizEditQueryVariables>(GetCompleteQuizEditDocument, options);
      }
export function useGetCompleteQuizEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompleteQuizEditQuery, GetCompleteQuizEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompleteQuizEditQuery, GetCompleteQuizEditQueryVariables>(GetCompleteQuizEditDocument, options);
        }
export type GetCompleteQuizEditQueryHookResult = ReturnType<typeof useGetCompleteQuizEditQuery>;
export type GetCompleteQuizEditLazyQueryHookResult = ReturnType<typeof useGetCompleteQuizEditLazyQuery>;
export type GetCompleteQuizEditQueryResult = Apollo.QueryResult<GetCompleteQuizEditQuery, GetCompleteQuizEditQueryVariables>;
export const ManageClassConfigDocument = gql`
    mutation manageClassConfig($class_quiz_config: Class_quiz_config_i!) {
  manageClassConfig(class_quiz_config: $class_quiz_config) {
    class_quiz_config_id
  }
}
    `;
export type ManageClassConfigMutationFn = Apollo.MutationFunction<ManageClassConfigMutation, ManageClassConfigMutationVariables>;

/**
 * __useManageClassConfigMutation__
 *
 * To run a mutation, you first call `useManageClassConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageClassConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageClassConfigMutation, { data, loading, error }] = useManageClassConfigMutation({
 *   variables: {
 *      class_quiz_config: // value for 'class_quiz_config'
 *   },
 * });
 */
export function useManageClassConfigMutation(baseOptions?: Apollo.MutationHookOptions<ManageClassConfigMutation, ManageClassConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageClassConfigMutation, ManageClassConfigMutationVariables>(ManageClassConfigDocument, options);
      }
export type ManageClassConfigMutationHookResult = ReturnType<typeof useManageClassConfigMutation>;
export type ManageClassConfigMutationResult = Apollo.MutationResult<ManageClassConfigMutation>;
export type ManageClassConfigMutationOptions = Apollo.BaseMutationOptions<ManageClassConfigMutation, ManageClassConfigMutationVariables>;
export const DeleteClassConfigDocument = gql`
    mutation deleteClassConfig($class_quiz_config_id: ID!) {
  deleteClassConfig(class_quiz_config_id: $class_quiz_config_id)
}
    `;
export type DeleteClassConfigMutationFn = Apollo.MutationFunction<DeleteClassConfigMutation, DeleteClassConfigMutationVariables>;

/**
 * __useDeleteClassConfigMutation__
 *
 * To run a mutation, you first call `useDeleteClassConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassConfigMutation, { data, loading, error }] = useDeleteClassConfigMutation({
 *   variables: {
 *      class_quiz_config_id: // value for 'class_quiz_config_id'
 *   },
 * });
 */
export function useDeleteClassConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassConfigMutation, DeleteClassConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassConfigMutation, DeleteClassConfigMutationVariables>(DeleteClassConfigDocument, options);
      }
export type DeleteClassConfigMutationHookResult = ReturnType<typeof useDeleteClassConfigMutation>;
export type DeleteClassConfigMutationResult = Apollo.MutationResult<DeleteClassConfigMutation>;
export type DeleteClassConfigMutationOptions = Apollo.BaseMutationOptions<DeleteClassConfigMutation, DeleteClassConfigMutationVariables>;
export const InsertClassQuizDocument = gql`
    mutation insertClassQuiz($class_quiz: Class_quiz_input!) {
  insertClassQuiz(class_quiz: $class_quiz) {
    class_quiz_id
  }
}
    `;
export type InsertClassQuizMutationFn = Apollo.MutationFunction<InsertClassQuizMutation, InsertClassQuizMutationVariables>;

/**
 * __useInsertClassQuizMutation__
 *
 * To run a mutation, you first call `useInsertClassQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClassQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClassQuizMutation, { data, loading, error }] = useInsertClassQuizMutation({
 *   variables: {
 *      class_quiz: // value for 'class_quiz'
 *   },
 * });
 */
export function useInsertClassQuizMutation(baseOptions?: Apollo.MutationHookOptions<InsertClassQuizMutation, InsertClassQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertClassQuizMutation, InsertClassQuizMutationVariables>(InsertClassQuizDocument, options);
      }
export type InsertClassQuizMutationHookResult = ReturnType<typeof useInsertClassQuizMutation>;
export type InsertClassQuizMutationResult = Apollo.MutationResult<InsertClassQuizMutation>;
export type InsertClassQuizMutationOptions = Apollo.BaseMutationOptions<InsertClassQuizMutation, InsertClassQuizMutationVariables>;
export const GetContentQuizInfoDocument = gql`
    query getContentQuizInfo($class_quiz_id: ID!, $language_id: ID) {
  getContentQuizInfo(class_quiz_id: $class_quiz_id, language_id: $language_id) {
    slides {
      quiz_slide_id
      media_type_id
      intro_sound_text
      intro_sound_location
      media_location
      media_location_enc
      slide_statements {
        quiz_statement_no
        sound_text
        sound_location
        statement_answer_id
        elaboration_text
      }
    }
  }
}
    `;

/**
 * __useGetContentQuizInfoQuery__
 *
 * To run a query within a React component, call `useGetContentQuizInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentQuizInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentQuizInfoQuery({
 *   variables: {
 *      class_quiz_id: // value for 'class_quiz_id'
 *      language_id: // value for 'language_id'
 *   },
 * });
 */
export function useGetContentQuizInfoQuery(baseOptions: Apollo.QueryHookOptions<GetContentQuizInfoQuery, GetContentQuizInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentQuizInfoQuery, GetContentQuizInfoQueryVariables>(GetContentQuizInfoDocument, options);
      }
export function useGetContentQuizInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentQuizInfoQuery, GetContentQuizInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentQuizInfoQuery, GetContentQuizInfoQueryVariables>(GetContentQuizInfoDocument, options);
        }
export type GetContentQuizInfoQueryHookResult = ReturnType<typeof useGetContentQuizInfoQuery>;
export type GetContentQuizInfoLazyQueryHookResult = ReturnType<typeof useGetContentQuizInfoLazyQuery>;
export type GetContentQuizInfoQueryResult = Apollo.QueryResult<GetContentQuizInfoQuery, GetContentQuizInfoQueryVariables>;
export const GetTeacherDashboardDocument = gql`
    query getTeacherDashboard($category_id: [Int]) {
  getTeacherDashboard(category_id: $category_id) {
    class_quiz_configs {
      class_quiz_config_id
      config_name
      config_creation_date
      quiz_speed
      quiz_language
      quiz_subtitles
      include_teori
      slide_amount
      save_for_later
      base_quiz_id
      tags {
        tag_name
        tag_id
      }
    }
    default_quizzes {
      quiz_Id
      quiz_name
      quiz_creation_date
      tags {
        tag_name
        tag_id
      }
    }
    teacher_lessons {
      lesson_id
      lesson_name
      lesson_creation_date
      lesson_category
      lesson_published
      tags {
        tag_name
        tag_id
      }
    }
    default_lessons {
      lesson_id
      lesson_name
      lesson_creation_date
      lesson_category
      tags {
        tag_name
        tag_id
      }
    }
  }
}
    `;

/**
 * __useGetTeacherDashboardQuery__
 *
 * To run a query within a React component, call `useGetTeacherDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherDashboardQuery({
 *   variables: {
 *      category_id: // value for 'category_id'
 *   },
 * });
 */
export function useGetTeacherDashboardQuery(baseOptions?: Apollo.QueryHookOptions<GetTeacherDashboardQuery, GetTeacherDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeacherDashboardQuery, GetTeacherDashboardQueryVariables>(GetTeacherDashboardDocument, options);
      }
export function useGetTeacherDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeacherDashboardQuery, GetTeacherDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeacherDashboardQuery, GetTeacherDashboardQueryVariables>(GetTeacherDashboardDocument, options);
        }
export type GetTeacherDashboardQueryHookResult = ReturnType<typeof useGetTeacherDashboardQuery>;
export type GetTeacherDashboardLazyQueryHookResult = ReturnType<typeof useGetTeacherDashboardLazyQuery>;
export type GetTeacherDashboardQueryResult = Apollo.QueryResult<GetTeacherDashboardQuery, GetTeacherDashboardQueryVariables>;
export const DeleteSlideDocument = gql`
    mutation deleteSlide($slide_id: ID!) {
  deleteSlide(slide_id: $slide_id)
}
    `;
export type DeleteSlideMutationFn = Apollo.MutationFunction<DeleteSlideMutation, DeleteSlideMutationVariables>;

/**
 * __useDeleteSlideMutation__
 *
 * To run a mutation, you first call `useDeleteSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSlideMutation, { data, loading, error }] = useDeleteSlideMutation({
 *   variables: {
 *      slide_id: // value for 'slide_id'
 *   },
 * });
 */
export function useDeleteSlideMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSlideMutation, DeleteSlideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSlideMutation, DeleteSlideMutationVariables>(DeleteSlideDocument, options);
      }
export type DeleteSlideMutationHookResult = ReturnType<typeof useDeleteSlideMutation>;
export type DeleteSlideMutationResult = Apollo.MutationResult<DeleteSlideMutation>;
export type DeleteSlideMutationOptions = Apollo.BaseMutationOptions<DeleteSlideMutation, DeleteSlideMutationVariables>;
export const DeleteSlideTagDocument = gql`
    mutation deleteSlideTag($delete_quiz_slide_id: ID, $delete_tag_id: ID) {
  deleteSlideTag(
    delete_quiz_slide_id: $delete_quiz_slide_id
    delete_tag_id: $delete_tag_id
  )
}
    `;
export type DeleteSlideTagMutationFn = Apollo.MutationFunction<DeleteSlideTagMutation, DeleteSlideTagMutationVariables>;

/**
 * __useDeleteSlideTagMutation__
 *
 * To run a mutation, you first call `useDeleteSlideTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSlideTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSlideTagMutation, { data, loading, error }] = useDeleteSlideTagMutation({
 *   variables: {
 *      delete_quiz_slide_id: // value for 'delete_quiz_slide_id'
 *      delete_tag_id: // value for 'delete_tag_id'
 *   },
 * });
 */
export function useDeleteSlideTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSlideTagMutation, DeleteSlideTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSlideTagMutation, DeleteSlideTagMutationVariables>(DeleteSlideTagDocument, options);
      }
export type DeleteSlideTagMutationHookResult = ReturnType<typeof useDeleteSlideTagMutation>;
export type DeleteSlideTagMutationResult = Apollo.MutationResult<DeleteSlideTagMutation>;
export type DeleteSlideTagMutationOptions = Apollo.BaseMutationOptions<DeleteSlideTagMutation, DeleteSlideTagMutationVariables>;
export const DeleteQuizSlideDocument = gql`
    mutation deleteQuizSlide($quiz_slide_id: ID!) {
  deleteQuizSlide(quiz_slide_id: $quiz_slide_id)
}
    `;
export type DeleteQuizSlideMutationFn = Apollo.MutationFunction<DeleteQuizSlideMutation, DeleteQuizSlideMutationVariables>;

/**
 * __useDeleteQuizSlideMutation__
 *
 * To run a mutation, you first call `useDeleteQuizSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuizSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuizSlideMutation, { data, loading, error }] = useDeleteQuizSlideMutation({
 *   variables: {
 *      quiz_slide_id: // value for 'quiz_slide_id'
 *   },
 * });
 */
export function useDeleteQuizSlideMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuizSlideMutation, DeleteQuizSlideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuizSlideMutation, DeleteQuizSlideMutationVariables>(DeleteQuizSlideDocument, options);
      }
export type DeleteQuizSlideMutationHookResult = ReturnType<typeof useDeleteQuizSlideMutation>;
export type DeleteQuizSlideMutationResult = Apollo.MutationResult<DeleteQuizSlideMutation>;
export type DeleteQuizSlideMutationOptions = Apollo.BaseMutationOptions<DeleteQuizSlideMutation, DeleteQuizSlideMutationVariables>;
export const DeleteQuizStatementDocument = gql`
    mutation deleteQuizStatement($quiz_statement_id: ID!) {
  deleteQuizStatement(quiz_statement_id: $quiz_statement_id)
}
    `;
export type DeleteQuizStatementMutationFn = Apollo.MutationFunction<DeleteQuizStatementMutation, DeleteQuizStatementMutationVariables>;

/**
 * __useDeleteQuizStatementMutation__
 *
 * To run a mutation, you first call `useDeleteQuizStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuizStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuizStatementMutation, { data, loading, error }] = useDeleteQuizStatementMutation({
 *   variables: {
 *      quiz_statement_id: // value for 'quiz_statement_id'
 *   },
 * });
 */
export function useDeleteQuizStatementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuizStatementMutation, DeleteQuizStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuizStatementMutation, DeleteQuizStatementMutationVariables>(DeleteQuizStatementDocument, options);
      }
export type DeleteQuizStatementMutationHookResult = ReturnType<typeof useDeleteQuizStatementMutation>;
export type DeleteQuizStatementMutationResult = Apollo.MutationResult<DeleteQuizStatementMutation>;
export type DeleteQuizStatementMutationOptions = Apollo.BaseMutationOptions<DeleteQuizStatementMutation, DeleteQuizStatementMutationVariables>;
export const GetStudentsAdminDocument = gql`
    query getStudentsAdmin {
  getStudentsAdmin {
    student_id
    expiry_date
    student_email
    create_date
    school_name
    first_name
    last_name
    student_phone_number
    optional_value
    student_category_desc
    student_code
    language_choice
    school_id
    student_category
  }
}
    `;

/**
 * __useGetStudentsAdminQuery__
 *
 * To run a query within a React component, call `useGetStudentsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentsAdminQuery, GetStudentsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsAdminQuery, GetStudentsAdminQueryVariables>(GetStudentsAdminDocument, options);
      }
export function useGetStudentsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsAdminQuery, GetStudentsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsAdminQuery, GetStudentsAdminQueryVariables>(GetStudentsAdminDocument, options);
        }
export type GetStudentsAdminQueryHookResult = ReturnType<typeof useGetStudentsAdminQuery>;
export type GetStudentsAdminLazyQueryHookResult = ReturnType<typeof useGetStudentsAdminLazyQuery>;
export type GetStudentsAdminQueryResult = Apollo.QueryResult<GetStudentsAdminQuery, GetStudentsAdminQueryVariables>;
export const ManageStudentDocument = gql`
    mutation manageStudent($student: New_Student!) {
  manageStudent(student: $student)
}
    `;
export type ManageStudentMutationFn = Apollo.MutationFunction<ManageStudentMutation, ManageStudentMutationVariables>;

/**
 * __useManageStudentMutation__
 *
 * To run a mutation, you first call `useManageStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageStudentMutation, { data, loading, error }] = useManageStudentMutation({
 *   variables: {
 *      student: // value for 'student'
 *   },
 * });
 */
export function useManageStudentMutation(baseOptions?: Apollo.MutationHookOptions<ManageStudentMutation, ManageStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageStudentMutation, ManageStudentMutationVariables>(ManageStudentDocument, options);
      }
export type ManageStudentMutationHookResult = ReturnType<typeof useManageStudentMutation>;
export type ManageStudentMutationResult = Apollo.MutationResult<ManageStudentMutation>;
export type ManageStudentMutationOptions = Apollo.BaseMutationOptions<ManageStudentMutation, ManageStudentMutationVariables>;
export const GetCategoriesDocument = gql`
    query getCategories {
  getCategories {
    cat_id
    cat_name
  }
}
    `;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetSchoolListDocument = gql`
    query getSchoolList {
  getSchoolList {
    school_id
    school_name
    zipcode
  }
}
    `;

/**
 * __useGetSchoolListQuery__
 *
 * To run a query within a React component, call `useGetSchoolListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchoolListQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolListQuery, GetSchoolListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolListQuery, GetSchoolListQueryVariables>(GetSchoolListDocument, options);
      }
export function useGetSchoolListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolListQuery, GetSchoolListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolListQuery, GetSchoolListQueryVariables>(GetSchoolListDocument, options);
        }
export type GetSchoolListQueryHookResult = ReturnType<typeof useGetSchoolListQuery>;
export type GetSchoolListLazyQueryHookResult = ReturnType<typeof useGetSchoolListLazyQuery>;
export type GetSchoolListQueryResult = Apollo.QueryResult<GetSchoolListQuery, GetSchoolListQueryVariables>;
export const GetAdminStudentLoginInfoDocument = gql`
    query getAdminStudentLoginInfo($student_id: ID!) {
  getAdminStudentLoginInfo(student_id: $student_id) {
    student_pin
    student_phone_number
  }
}
    `;

/**
 * __useGetAdminStudentLoginInfoQuery__
 *
 * To run a query within a React component, call `useGetAdminStudentLoginInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStudentLoginInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStudentLoginInfoQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useGetAdminStudentLoginInfoQuery(baseOptions: Apollo.QueryHookOptions<GetAdminStudentLoginInfoQuery, GetAdminStudentLoginInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminStudentLoginInfoQuery, GetAdminStudentLoginInfoQueryVariables>(GetAdminStudentLoginInfoDocument, options);
      }
export function useGetAdminStudentLoginInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminStudentLoginInfoQuery, GetAdminStudentLoginInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminStudentLoginInfoQuery, GetAdminStudentLoginInfoQueryVariables>(GetAdminStudentLoginInfoDocument, options);
        }
export type GetAdminStudentLoginInfoQueryHookResult = ReturnType<typeof useGetAdminStudentLoginInfoQuery>;
export type GetAdminStudentLoginInfoLazyQueryHookResult = ReturnType<typeof useGetAdminStudentLoginInfoLazyQuery>;
export type GetAdminStudentLoginInfoQueryResult = Apollo.QueryResult<GetAdminStudentLoginInfoQuery, GetAdminStudentLoginInfoQueryVariables>;
export const GetStudentActivityDocument = gql`
    query getStudentActivity($student_id: ID!, $limit: Int, $offset: Int) {
  getStudentActivity(student_id: $student_id, limit: $limit, offset: $offset) {
    activity_id
    activity_type
    activity_title
    activity_result
    dash_quiz_id
    activity_date
    activity_time
  }
}
    `;

/**
 * __useGetStudentActivityQuery__
 *
 * To run a query within a React component, call `useGetStudentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentActivityQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetStudentActivityQuery(baseOptions: Apollo.QueryHookOptions<GetStudentActivityQuery, GetStudentActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentActivityQuery, GetStudentActivityQueryVariables>(GetStudentActivityDocument, options);
      }
export function useGetStudentActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentActivityQuery, GetStudentActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentActivityQuery, GetStudentActivityQueryVariables>(GetStudentActivityDocument, options);
        }
export type GetStudentActivityQueryHookResult = ReturnType<typeof useGetStudentActivityQuery>;
export type GetStudentActivityLazyQueryHookResult = ReturnType<typeof useGetStudentActivityLazyQuery>;
export type GetStudentActivityQueryResult = Apollo.QueryResult<GetStudentActivityQuery, GetStudentActivityQueryVariables>;
export const BlockSlideDocument = gql`
    mutation BlockSlide($slides: [ID]) {
  BlockSlide(slides: $slides)
}
    `;
export type BlockSlideMutationFn = Apollo.MutationFunction<BlockSlideMutation, BlockSlideMutationVariables>;

/**
 * __useBlockSlideMutation__
 *
 * To run a mutation, you first call `useBlockSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockSlideMutation, { data, loading, error }] = useBlockSlideMutation({
 *   variables: {
 *      slides: // value for 'slides'
 *   },
 * });
 */
export function useBlockSlideMutation(baseOptions?: Apollo.MutationHookOptions<BlockSlideMutation, BlockSlideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockSlideMutation, BlockSlideMutationVariables>(BlockSlideDocument, options);
      }
export type BlockSlideMutationHookResult = ReturnType<typeof useBlockSlideMutation>;
export type BlockSlideMutationResult = Apollo.MutationResult<BlockSlideMutation>;
export type BlockSlideMutationOptions = Apollo.BaseMutationOptions<BlockSlideMutation, BlockSlideMutationVariables>;
export const DeleteSlideBlockDocument = gql`
    mutation DeleteSlideBlock($slides: [ID]) {
  DeleteSlideBlock(slides: $slides)
}
    `;
export type DeleteSlideBlockMutationFn = Apollo.MutationFunction<DeleteSlideBlockMutation, DeleteSlideBlockMutationVariables>;

/**
 * __useDeleteSlideBlockMutation__
 *
 * To run a mutation, you first call `useDeleteSlideBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSlideBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSlideBlockMutation, { data, loading, error }] = useDeleteSlideBlockMutation({
 *   variables: {
 *      slides: // value for 'slides'
 *   },
 * });
 */
export function useDeleteSlideBlockMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSlideBlockMutation, DeleteSlideBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSlideBlockMutation, DeleteSlideBlockMutationVariables>(DeleteSlideBlockDocument, options);
      }
export type DeleteSlideBlockMutationHookResult = ReturnType<typeof useDeleteSlideBlockMutation>;
export type DeleteSlideBlockMutationResult = Apollo.MutationResult<DeleteSlideBlockMutation>;
export type DeleteSlideBlockMutationOptions = Apollo.BaseMutationOptions<DeleteSlideBlockMutation, DeleteSlideBlockMutationVariables>;
export const GetBlockedSlidesDocument = gql`
    query getBlockedSlides {
  getBlockedSlides {
    quiz_slide_id
  }
}
    `;

/**
 * __useGetBlockedSlidesQuery__
 *
 * To run a query within a React component, call `useGetBlockedSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockedSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockedSlidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlockedSlidesQuery(baseOptions?: Apollo.QueryHookOptions<GetBlockedSlidesQuery, GetBlockedSlidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlockedSlidesQuery, GetBlockedSlidesQueryVariables>(GetBlockedSlidesDocument, options);
      }
export function useGetBlockedSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlockedSlidesQuery, GetBlockedSlidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlockedSlidesQuery, GetBlockedSlidesQueryVariables>(GetBlockedSlidesDocument, options);
        }
export type GetBlockedSlidesQueryHookResult = ReturnType<typeof useGetBlockedSlidesQuery>;
export type GetBlockedSlidesLazyQueryHookResult = ReturnType<typeof useGetBlockedSlidesLazyQuery>;
export type GetBlockedSlidesQueryResult = Apollo.QueryResult<GetBlockedSlidesQuery, GetBlockedSlidesQueryVariables>;
export const GetMarkedSlidesDocument = gql`
    query getMarkedSlides($student_id: ID!) {
  getMarkedSlides(student_id: $student_id) {
    progress_id
    online_quiz_id
    original_slide_id
    explanation_location
    intro_sound_text
    media_location
    media_location_enc
    media_type_id
    slide_statements {
      original_statement_id
      statement_answer_id
      quiz_statement_no
      elaboration_Text
      sound_text
      slide_pass_status
      statement_progress_answer
    }
  }
}
    `;

/**
 * __useGetMarkedSlidesQuery__
 *
 * To run a query within a React component, call `useGetMarkedSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarkedSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarkedSlidesQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useGetMarkedSlidesQuery(baseOptions: Apollo.QueryHookOptions<GetMarkedSlidesQuery, GetMarkedSlidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarkedSlidesQuery, GetMarkedSlidesQueryVariables>(GetMarkedSlidesDocument, options);
      }
export function useGetMarkedSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarkedSlidesQuery, GetMarkedSlidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarkedSlidesQuery, GetMarkedSlidesQueryVariables>(GetMarkedSlidesDocument, options);
        }
export type GetMarkedSlidesQueryHookResult = ReturnType<typeof useGetMarkedSlidesQuery>;
export type GetMarkedSlidesLazyQueryHookResult = ReturnType<typeof useGetMarkedSlidesLazyQuery>;
export type GetMarkedSlidesQueryResult = Apollo.QueryResult<GetMarkedSlidesQuery, GetMarkedSlidesQueryVariables>;
export const UnmarkSlideDocument = gql`
    mutation UnmarkSlide($unmarked_slides: [student_mark]) {
  UnmarkSlide(unmarked_slides: $unmarked_slides)
}
    `;
export type UnmarkSlideMutationFn = Apollo.MutationFunction<UnmarkSlideMutation, UnmarkSlideMutationVariables>;

/**
 * __useUnmarkSlideMutation__
 *
 * To run a mutation, you first call `useUnmarkSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkSlideMutation, { data, loading, error }] = useUnmarkSlideMutation({
 *   variables: {
 *      unmarked_slides: // value for 'unmarked_slides'
 *   },
 * });
 */
export function useUnmarkSlideMutation(baseOptions?: Apollo.MutationHookOptions<UnmarkSlideMutation, UnmarkSlideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnmarkSlideMutation, UnmarkSlideMutationVariables>(UnmarkSlideDocument, options);
      }
export type UnmarkSlideMutationHookResult = ReturnType<typeof useUnmarkSlideMutation>;
export type UnmarkSlideMutationResult = Apollo.MutationResult<UnmarkSlideMutation>;
export type UnmarkSlideMutationOptions = Apollo.BaseMutationOptions<UnmarkSlideMutation, UnmarkSlideMutationVariables>;
export const UnmarkSlideTeacherDocument = gql`
    mutation UnmarkSlideTeacher($unmarked_original_slides: [student_mark_teacher]) {
  UnmarkSlideTeacher(unmarked_original_slides: $unmarked_original_slides)
}
    `;
export type UnmarkSlideTeacherMutationFn = Apollo.MutationFunction<UnmarkSlideTeacherMutation, UnmarkSlideTeacherMutationVariables>;

/**
 * __useUnmarkSlideTeacherMutation__
 *
 * To run a mutation, you first call `useUnmarkSlideTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkSlideTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkSlideTeacherMutation, { data, loading, error }] = useUnmarkSlideTeacherMutation({
 *   variables: {
 *      unmarked_original_slides: // value for 'unmarked_original_slides'
 *   },
 * });
 */
export function useUnmarkSlideTeacherMutation(baseOptions?: Apollo.MutationHookOptions<UnmarkSlideTeacherMutation, UnmarkSlideTeacherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnmarkSlideTeacherMutation, UnmarkSlideTeacherMutationVariables>(UnmarkSlideTeacherDocument, options);
      }
export type UnmarkSlideTeacherMutationHookResult = ReturnType<typeof useUnmarkSlideTeacherMutation>;
export type UnmarkSlideTeacherMutationResult = Apollo.MutationResult<UnmarkSlideTeacherMutation>;
export type UnmarkSlideTeacherMutationOptions = Apollo.BaseMutationOptions<UnmarkSlideTeacherMutation, UnmarkSlideTeacherMutationVariables>;
export const GetMarkedSlidesCountDocument = gql`
    query getMarkedSlidesCount($student_ids: String!) {
  getMarkedSlidesCount(student_ids: $student_ids) {
    progress_id
    online_quiz_id
    original_slide_id
    explanation_location
    intro_sound_text
    media_location
    media_location_enc
    media_type_id
    mark_count
    slide_statements {
      original_statement_id
      statement_answer_id
      quiz_statement_no
      elaboration_Text
      sound_text
      slide_pass_status
      statement_progress_answer
    }
    tags {
      tag_id
      tag_name
    }
  }
}
    `;

/**
 * __useGetMarkedSlidesCountQuery__
 *
 * To run a query within a React component, call `useGetMarkedSlidesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarkedSlidesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarkedSlidesCountQuery({
 *   variables: {
 *      student_ids: // value for 'student_ids'
 *   },
 * });
 */
export function useGetMarkedSlidesCountQuery(baseOptions: Apollo.QueryHookOptions<GetMarkedSlidesCountQuery, GetMarkedSlidesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarkedSlidesCountQuery, GetMarkedSlidesCountQueryVariables>(GetMarkedSlidesCountDocument, options);
      }
export function useGetMarkedSlidesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarkedSlidesCountQuery, GetMarkedSlidesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarkedSlidesCountQuery, GetMarkedSlidesCountQueryVariables>(GetMarkedSlidesCountDocument, options);
        }
export type GetMarkedSlidesCountQueryHookResult = ReturnType<typeof useGetMarkedSlidesCountQuery>;
export type GetMarkedSlidesCountLazyQueryHookResult = ReturnType<typeof useGetMarkedSlidesCountLazyQuery>;
export type GetMarkedSlidesCountQueryResult = Apollo.QueryResult<GetMarkedSlidesCountQuery, GetMarkedSlidesCountQueryVariables>;
export const GetSubjectLessonsDocument = gql`
    query getSubjectLessons {
  getSubjectLessons {
    lesson_id
    lesson_name
    lesson_category
  }
}
    `;

/**
 * __useGetSubjectLessonsQuery__
 *
 * To run a query within a React component, call `useGetSubjectLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectLessonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubjectLessonsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubjectLessonsQuery, GetSubjectLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectLessonsQuery, GetSubjectLessonsQueryVariables>(GetSubjectLessonsDocument, options);
      }
export function useGetSubjectLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectLessonsQuery, GetSubjectLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectLessonsQuery, GetSubjectLessonsQueryVariables>(GetSubjectLessonsDocument, options);
        }
export type GetSubjectLessonsQueryHookResult = ReturnType<typeof useGetSubjectLessonsQuery>;
export type GetSubjectLessonsLazyQueryHookResult = ReturnType<typeof useGetSubjectLessonsLazyQuery>;
export type GetSubjectLessonsQueryResult = Apollo.QueryResult<GetSubjectLessonsQuery, GetSubjectLessonsQueryVariables>;
export const CombineLessonDocument = gql`
    mutation combineLesson($combined_lesson: Combined_lesson) {
  combineLesson(combined_lesson: $combined_lesson)
}
    `;
export type CombineLessonMutationFn = Apollo.MutationFunction<CombineLessonMutation, CombineLessonMutationVariables>;

/**
 * __useCombineLessonMutation__
 *
 * To run a mutation, you first call `useCombineLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCombineLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [combineLessonMutation, { data, loading, error }] = useCombineLessonMutation({
 *   variables: {
 *      combined_lesson: // value for 'combined_lesson'
 *   },
 * });
 */
export function useCombineLessonMutation(baseOptions?: Apollo.MutationHookOptions<CombineLessonMutation, CombineLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CombineLessonMutation, CombineLessonMutationVariables>(CombineLessonDocument, options);
      }
export type CombineLessonMutationHookResult = ReturnType<typeof useCombineLessonMutation>;
export type CombineLessonMutationResult = Apollo.MutationResult<CombineLessonMutation>;
export type CombineLessonMutationOptions = Apollo.BaseMutationOptions<CombineLessonMutation, CombineLessonMutationVariables>;
export const PublishLessonDocument = gql`
    mutation publishLesson($lesson_id: ID, $lesson_published: Boolean) {
  publishLesson(lesson_id: $lesson_id, lesson_published: $lesson_published)
}
    `;
export type PublishLessonMutationFn = Apollo.MutationFunction<PublishLessonMutation, PublishLessonMutationVariables>;

/**
 * __usePublishLessonMutation__
 *
 * To run a mutation, you first call `usePublishLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishLessonMutation, { data, loading, error }] = usePublishLessonMutation({
 *   variables: {
 *      lesson_id: // value for 'lesson_id'
 *      lesson_published: // value for 'lesson_published'
 *   },
 * });
 */
export function usePublishLessonMutation(baseOptions?: Apollo.MutationHookOptions<PublishLessonMutation, PublishLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishLessonMutation, PublishLessonMutationVariables>(PublishLessonDocument, options);
      }
export type PublishLessonMutationHookResult = ReturnType<typeof usePublishLessonMutation>;
export type PublishLessonMutationResult = Apollo.MutationResult<PublishLessonMutation>;
export type PublishLessonMutationOptions = Apollo.BaseMutationOptions<PublishLessonMutation, PublishLessonMutationVariables>;
export const GetStudentAchTypesDocument = gql`
    query getStudentAchTypes($student_id: ID!) {
  getStudentAchTypes(student_id: $student_id) {
    achievement_name
    achievement_type_desc
    achievement_dash {
      achievement_name
      dash_status
    }
  }
}
    `;

/**
 * __useGetStudentAchTypesQuery__
 *
 * To run a query within a React component, call `useGetStudentAchTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentAchTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentAchTypesQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useGetStudentAchTypesQuery(baseOptions: Apollo.QueryHookOptions<GetStudentAchTypesQuery, GetStudentAchTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentAchTypesQuery, GetStudentAchTypesQueryVariables>(GetStudentAchTypesDocument, options);
      }
export function useGetStudentAchTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentAchTypesQuery, GetStudentAchTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentAchTypesQuery, GetStudentAchTypesQueryVariables>(GetStudentAchTypesDocument, options);
        }
export type GetStudentAchTypesQueryHookResult = ReturnType<typeof useGetStudentAchTypesQuery>;
export type GetStudentAchTypesLazyQueryHookResult = ReturnType<typeof useGetStudentAchTypesLazyQuery>;
export type GetStudentAchTypesQueryResult = Apollo.QueryResult<GetStudentAchTypesQuery, GetStudentAchTypesQueryVariables>;
export const GetStudentsSchoolDeletedDocument = gql`
    query getStudentsSchoolDeleted {
  getStudentsSchoolDeleted {
    student_id
    correct_quizzes
    student_progress
    student_name
    student_email
    create_date
    student_class_start
    student_category
    student_phone_number
    access_code
    expiry_date
    has_marks
  }
}
    `;

/**
 * __useGetStudentsSchoolDeletedQuery__
 *
 * To run a query within a React component, call `useGetStudentsSchoolDeletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsSchoolDeletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsSchoolDeletedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentsSchoolDeletedQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentsSchoolDeletedQuery, GetStudentsSchoolDeletedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsSchoolDeletedQuery, GetStudentsSchoolDeletedQueryVariables>(GetStudentsSchoolDeletedDocument, options);
      }
export function useGetStudentsSchoolDeletedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsSchoolDeletedQuery, GetStudentsSchoolDeletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsSchoolDeletedQuery, GetStudentsSchoolDeletedQueryVariables>(GetStudentsSchoolDeletedDocument, options);
        }
export type GetStudentsSchoolDeletedQueryHookResult = ReturnType<typeof useGetStudentsSchoolDeletedQuery>;
export type GetStudentsSchoolDeletedLazyQueryHookResult = ReturnType<typeof useGetStudentsSchoolDeletedLazyQuery>;
export type GetStudentsSchoolDeletedQueryResult = Apollo.QueryResult<GetStudentsSchoolDeletedQuery, GetStudentsSchoolDeletedQueryVariables>;
export const UnlinkStudentDocument = gql`
    mutation UnlinkStudent($student_id: ID!) {
  UnlinkStudent(student_id: $student_id)
}
    `;
export type UnlinkStudentMutationFn = Apollo.MutationFunction<UnlinkStudentMutation, UnlinkStudentMutationVariables>;

/**
 * __useUnlinkStudentMutation__
 *
 * To run a mutation, you first call `useUnlinkStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkStudentMutation, { data, loading, error }] = useUnlinkStudentMutation({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useUnlinkStudentMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkStudentMutation, UnlinkStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkStudentMutation, UnlinkStudentMutationVariables>(UnlinkStudentDocument, options);
      }
export type UnlinkStudentMutationHookResult = ReturnType<typeof useUnlinkStudentMutation>;
export type UnlinkStudentMutationResult = Apollo.MutationResult<UnlinkStudentMutation>;
export type UnlinkStudentMutationOptions = Apollo.BaseMutationOptions<UnlinkStudentMutation, UnlinkStudentMutationVariables>;
export const UnlinkSlideDocument = gql`
    mutation unlinkSlide($lesson_slide_id: ID!, $tag_id: ID!) {
  unlinkSlide(lesson_slide_id: $lesson_slide_id, tag_id: $tag_id)
}
    `;
export type UnlinkSlideMutationFn = Apollo.MutationFunction<UnlinkSlideMutation, UnlinkSlideMutationVariables>;

/**
 * __useUnlinkSlideMutation__
 *
 * To run a mutation, you first call `useUnlinkSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkSlideMutation, { data, loading, error }] = useUnlinkSlideMutation({
 *   variables: {
 *      lesson_slide_id: // value for 'lesson_slide_id'
 *      tag_id: // value for 'tag_id'
 *   },
 * });
 */
export function useUnlinkSlideMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkSlideMutation, UnlinkSlideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkSlideMutation, UnlinkSlideMutationVariables>(UnlinkSlideDocument, options);
      }
export type UnlinkSlideMutationHookResult = ReturnType<typeof useUnlinkSlideMutation>;
export type UnlinkSlideMutationResult = Apollo.MutationResult<UnlinkSlideMutation>;
export type UnlinkSlideMutationOptions = Apollo.BaseMutationOptions<UnlinkSlideMutation, UnlinkSlideMutationVariables>;
export const CloneLessonDocument = gql`
    mutation cloneLesson($lesson_id: ID!) {
  cloneLesson(lesson_id: $lesson_id)
}
    `;
export type CloneLessonMutationFn = Apollo.MutationFunction<CloneLessonMutation, CloneLessonMutationVariables>;

/**
 * __useCloneLessonMutation__
 *
 * To run a mutation, you first call `useCloneLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneLessonMutation, { data, loading, error }] = useCloneLessonMutation({
 *   variables: {
 *      lesson_id: // value for 'lesson_id'
 *   },
 * });
 */
export function useCloneLessonMutation(baseOptions?: Apollo.MutationHookOptions<CloneLessonMutation, CloneLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneLessonMutation, CloneLessonMutationVariables>(CloneLessonDocument, options);
      }
export type CloneLessonMutationHookResult = ReturnType<typeof useCloneLessonMutation>;
export type CloneLessonMutationResult = Apollo.MutationResult<CloneLessonMutation>;
export type CloneLessonMutationOptions = Apollo.BaseMutationOptions<CloneLessonMutation, CloneLessonMutationVariables>;
export const GetClassQuizResultDocument = gql`
    query getClassQuizResult($class_quiz_id: ID!) {
  getClassQuizResult(class_quiz_id: $class_quiz_id) {
    slide_id
    slide_pass_percentage
  }
}
    `;

/**
 * __useGetClassQuizResultQuery__
 *
 * To run a query within a React component, call `useGetClassQuizResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassQuizResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassQuizResultQuery({
 *   variables: {
 *      class_quiz_id: // value for 'class_quiz_id'
 *   },
 * });
 */
export function useGetClassQuizResultQuery(baseOptions: Apollo.QueryHookOptions<GetClassQuizResultQuery, GetClassQuizResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassQuizResultQuery, GetClassQuizResultQueryVariables>(GetClassQuizResultDocument, options);
      }
export function useGetClassQuizResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassQuizResultQuery, GetClassQuizResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassQuizResultQuery, GetClassQuizResultQueryVariables>(GetClassQuizResultDocument, options);
        }
export type GetClassQuizResultQueryHookResult = ReturnType<typeof useGetClassQuizResultQuery>;
export type GetClassQuizResultLazyQueryHookResult = ReturnType<typeof useGetClassQuizResultLazyQuery>;
export type GetClassQuizResultQueryResult = Apollo.QueryResult<GetClassQuizResultQuery, GetClassQuizResultQueryVariables>;
export const GetAllSchoolsDocument = gql`
    query getAllSchools($is_active: Int!) {
  getAllSchools(is_active: $is_active) {
    school_id
    school_name
    contact_name
    email_id
    optional_note
    language_choice
    zipcode
    city
    password
    vat_number
    skip_bill_check
  }
}
    `;

/**
 * __useGetAllSchoolsQuery__
 *
 * To run a query within a React component, call `useGetAllSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSchoolsQuery({
 *   variables: {
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useGetAllSchoolsQuery(baseOptions: Apollo.QueryHookOptions<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>(GetAllSchoolsDocument, options);
      }
export function useGetAllSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>(GetAllSchoolsDocument, options);
        }
export type GetAllSchoolsQueryHookResult = ReturnType<typeof useGetAllSchoolsQuery>;
export type GetAllSchoolsLazyQueryHookResult = ReturnType<typeof useGetAllSchoolsLazyQuery>;
export type GetAllSchoolsQueryResult = Apollo.QueryResult<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>;
export const SetSchoolStatusDocument = gql`
    mutation setSchoolStatus($school_id: Int!, $school_status: Int!) {
  setSchoolStatus(school_id: $school_id, school_status: $school_status)
}
    `;
export type SetSchoolStatusMutationFn = Apollo.MutationFunction<SetSchoolStatusMutation, SetSchoolStatusMutationVariables>;

/**
 * __useSetSchoolStatusMutation__
 *
 * To run a mutation, you first call `useSetSchoolStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSchoolStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSchoolStatusMutation, { data, loading, error }] = useSetSchoolStatusMutation({
 *   variables: {
 *      school_id: // value for 'school_id'
 *      school_status: // value for 'school_status'
 *   },
 * });
 */
export function useSetSchoolStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetSchoolStatusMutation, SetSchoolStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSchoolStatusMutation, SetSchoolStatusMutationVariables>(SetSchoolStatusDocument, options);
      }
export type SetSchoolStatusMutationHookResult = ReturnType<typeof useSetSchoolStatusMutation>;
export type SetSchoolStatusMutationResult = Apollo.MutationResult<SetSchoolStatusMutation>;
export type SetSchoolStatusMutationOptions = Apollo.BaseMutationOptions<SetSchoolStatusMutation, SetSchoolStatusMutationVariables>;
export const ManageSchoolDocument = gql`
    mutation manageSchool($school: SchoolInput!) {
  manageSchool(school: $school)
}
    `;
export type ManageSchoolMutationFn = Apollo.MutationFunction<ManageSchoolMutation, ManageSchoolMutationVariables>;

/**
 * __useManageSchoolMutation__
 *
 * To run a mutation, you first call `useManageSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageSchoolMutation, { data, loading, error }] = useManageSchoolMutation({
 *   variables: {
 *      school: // value for 'school'
 *   },
 * });
 */
export function useManageSchoolMutation(baseOptions?: Apollo.MutationHookOptions<ManageSchoolMutation, ManageSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageSchoolMutation, ManageSchoolMutationVariables>(ManageSchoolDocument, options);
      }
export type ManageSchoolMutationHookResult = ReturnType<typeof useManageSchoolMutation>;
export type ManageSchoolMutationResult = Apollo.MutationResult<ManageSchoolMutation>;
export type ManageSchoolMutationOptions = Apollo.BaseMutationOptions<ManageSchoolMutation, ManageSchoolMutationVariables>;
export const GetConfigCountByIdDocument = gql`
    query getConfigCountByID($configId: Int!) {
  getConfigCountByID(config_id: $configId)
}
    `;

/**
 * __useGetConfigCountByIdQuery__
 *
 * To run a query within a React component, call `useGetConfigCountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigCountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigCountByIdQuery({
 *   variables: {
 *      configId: // value for 'configId'
 *   },
 * });
 */
export function useGetConfigCountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetConfigCountByIdQuery, GetConfigCountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigCountByIdQuery, GetConfigCountByIdQueryVariables>(GetConfigCountByIdDocument, options);
      }
export function useGetConfigCountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigCountByIdQuery, GetConfigCountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigCountByIdQuery, GetConfigCountByIdQueryVariables>(GetConfigCountByIdDocument, options);
        }
export type GetConfigCountByIdQueryHookResult = ReturnType<typeof useGetConfigCountByIdQuery>;
export type GetConfigCountByIdLazyQueryHookResult = ReturnType<typeof useGetConfigCountByIdLazyQuery>;
export type GetConfigCountByIdQueryResult = Apollo.QueryResult<GetConfigCountByIdQuery, GetConfigCountByIdQueryVariables>;
export const CreateSchoolCategoryDocument = gql`
    mutation CreateSchoolCategory($schoolId: Int!, $categoryId: Int!) {
  createSchoolCategory(school_id: $schoolId, category_id: $categoryId)
}
    `;
export type CreateSchoolCategoryMutationFn = Apollo.MutationFunction<CreateSchoolCategoryMutation, CreateSchoolCategoryMutationVariables>;

/**
 * __useCreateSchoolCategoryMutation__
 *
 * To run a mutation, you first call `useCreateSchoolCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolCategoryMutation, { data, loading, error }] = useCreateSchoolCategoryMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCreateSchoolCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolCategoryMutation, CreateSchoolCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchoolCategoryMutation, CreateSchoolCategoryMutationVariables>(CreateSchoolCategoryDocument, options);
      }
export type CreateSchoolCategoryMutationHookResult = ReturnType<typeof useCreateSchoolCategoryMutation>;
export type CreateSchoolCategoryMutationResult = Apollo.MutationResult<CreateSchoolCategoryMutation>;
export type CreateSchoolCategoryMutationOptions = Apollo.BaseMutationOptions<CreateSchoolCategoryMutation, CreateSchoolCategoryMutationVariables>;
export const GetSchoolCategoryDocument = gql`
    query GetSchoolCategory($schoolId: Int) {
  getSchoolCategory(school_id: $schoolId) {
    cat_id
    cat_name
  }
}
    `;

/**
 * __useGetSchoolCategoryQuery__
 *
 * To run a query within a React component, call `useGetSchoolCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolCategoryQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetSchoolCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolCategoryQuery, GetSchoolCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolCategoryQuery, GetSchoolCategoryQueryVariables>(GetSchoolCategoryDocument, options);
      }
export function useGetSchoolCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolCategoryQuery, GetSchoolCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolCategoryQuery, GetSchoolCategoryQueryVariables>(GetSchoolCategoryDocument, options);
        }
export type GetSchoolCategoryQueryHookResult = ReturnType<typeof useGetSchoolCategoryQuery>;
export type GetSchoolCategoryLazyQueryHookResult = ReturnType<typeof useGetSchoolCategoryLazyQuery>;
export type GetSchoolCategoryQueryResult = Apollo.QueryResult<GetSchoolCategoryQuery, GetSchoolCategoryQueryVariables>;
export const GetSchoolSubusersAdminDocument = gql`
    query GetSchoolSubusersAdmin($schoolId: Int!) {
  getSchoolSubusersAdmin(school_id: $schoolId) {
    subuser_id
    teacher_name
    email_id
    school_id
    is_department
    department_name
    department_zipcode
    skip_bill_check
  }
}
    `;

/**
 * __useGetSchoolSubusersAdminQuery__
 *
 * To run a query within a React component, call `useGetSchoolSubusersAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolSubusersAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolSubusersAdminQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetSchoolSubusersAdminQuery(baseOptions: Apollo.QueryHookOptions<GetSchoolSubusersAdminQuery, GetSchoolSubusersAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolSubusersAdminQuery, GetSchoolSubusersAdminQueryVariables>(GetSchoolSubusersAdminDocument, options);
      }
export function useGetSchoolSubusersAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolSubusersAdminQuery, GetSchoolSubusersAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolSubusersAdminQuery, GetSchoolSubusersAdminQueryVariables>(GetSchoolSubusersAdminDocument, options);
        }
export type GetSchoolSubusersAdminQueryHookResult = ReturnType<typeof useGetSchoolSubusersAdminQuery>;
export type GetSchoolSubusersAdminLazyQueryHookResult = ReturnType<typeof useGetSchoolSubusersAdminLazyQuery>;
export type GetSchoolSubusersAdminQueryResult = Apollo.QueryResult<GetSchoolSubusersAdminQuery, GetSchoolSubusersAdminQueryVariables>;
export const ManageSubuserDocument = gql`
    mutation ManageSubuser($subuser: New_subuser!) {
  manageSubuser(subuser: $subuser)
}
    `;
export type ManageSubuserMutationFn = Apollo.MutationFunction<ManageSubuserMutation, ManageSubuserMutationVariables>;

/**
 * __useManageSubuserMutation__
 *
 * To run a mutation, you first call `useManageSubuserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageSubuserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageSubuserMutation, { data, loading, error }] = useManageSubuserMutation({
 *   variables: {
 *      subuser: // value for 'subuser'
 *   },
 * });
 */
export function useManageSubuserMutation(baseOptions?: Apollo.MutationHookOptions<ManageSubuserMutation, ManageSubuserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageSubuserMutation, ManageSubuserMutationVariables>(ManageSubuserDocument, options);
      }
export type ManageSubuserMutationHookResult = ReturnType<typeof useManageSubuserMutation>;
export type ManageSubuserMutationResult = Apollo.MutationResult<ManageSubuserMutation>;
export type ManageSubuserMutationOptions = Apollo.BaseMutationOptions<ManageSubuserMutation, ManageSubuserMutationVariables>;
export const DeleteSubuserDocument = gql`
    mutation DeleteSubuser($subuserId: ID!) {
  deleteSubuser(subuser_id: $subuserId)
}
    `;
export type DeleteSubuserMutationFn = Apollo.MutationFunction<DeleteSubuserMutation, DeleteSubuserMutationVariables>;

/**
 * __useDeleteSubuserMutation__
 *
 * To run a mutation, you first call `useDeleteSubuserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubuserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubuserMutation, { data, loading, error }] = useDeleteSubuserMutation({
 *   variables: {
 *      subuserId: // value for 'subuserId'
 *   },
 * });
 */
export function useDeleteSubuserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubuserMutation, DeleteSubuserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubuserMutation, DeleteSubuserMutationVariables>(DeleteSubuserDocument, options);
      }
export type DeleteSubuserMutationHookResult = ReturnType<typeof useDeleteSubuserMutation>;
export type DeleteSubuserMutationResult = Apollo.MutationResult<DeleteSubuserMutation>;
export type DeleteSubuserMutationOptions = Apollo.BaseMutationOptions<DeleteSubuserMutation, DeleteSubuserMutationVariables>;
export const GetAllCategoriesDocument = gql`
    query GetAllCategories {
  getAllCategories {
    cat_id
    cat_name
  }
}
    `;

/**
 * __useGetAllCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
      }
export function useGetAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
        }
export type GetAllCategoriesQueryHookResult = ReturnType<typeof useGetAllCategoriesQuery>;
export type GetAllCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllCategoriesLazyQuery>;
export type GetAllCategoriesQueryResult = Apollo.QueryResult<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>;
export const GetTeoriLessonStudentsDocument = gql`
    query GetTeoriLessonStudents($lessonId: String) {
  GetTeoriLessonStudents(lesson_id: $lessonId)
}
    `;

/**
 * __useGetTeoriLessonStudentsQuery__
 *
 * To run a query within a React component, call `useGetTeoriLessonStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeoriLessonStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeoriLessonStudentsQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useGetTeoriLessonStudentsQuery(baseOptions?: Apollo.QueryHookOptions<GetTeoriLessonStudentsQuery, GetTeoriLessonStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeoriLessonStudentsQuery, GetTeoriLessonStudentsQueryVariables>(GetTeoriLessonStudentsDocument, options);
      }
export function useGetTeoriLessonStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeoriLessonStudentsQuery, GetTeoriLessonStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeoriLessonStudentsQuery, GetTeoriLessonStudentsQueryVariables>(GetTeoriLessonStudentsDocument, options);
        }
export type GetTeoriLessonStudentsQueryHookResult = ReturnType<typeof useGetTeoriLessonStudentsQuery>;
export type GetTeoriLessonStudentsLazyQueryHookResult = ReturnType<typeof useGetTeoriLessonStudentsLazyQuery>;
export type GetTeoriLessonStudentsQueryResult = Apollo.QueryResult<GetTeoriLessonStudentsQuery, GetTeoriLessonStudentsQueryVariables>;
export const GetAllSlidesDocument = gql`
    query getAllSlides($included_slides: Int, $language_chosen: Int) {
  getAllSlides(
    included_slides: $included_slides
    language_chosen: $language_chosen
  ) {
    quiz_slide_id
    media_type_id
    intro_sound_text
    intro_sound_location
    media_location
    media_location_enc
    quiz_id
    slide_statements {
      quiz_statement_no
      sound_text
      sound_location
      statement_answer_id
      elaboration_text
    }
    tags {
      tag_id
      tag_name
    }
  }
}
    `;

/**
 * __useGetAllSlidesQuery__
 *
 * To run a query within a React component, call `useGetAllSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSlidesQuery({
 *   variables: {
 *      included_slides: // value for 'included_slides'
 *      language_chosen: // value for 'language_chosen'
 *   },
 * });
 */
export function useGetAllSlidesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSlidesQuery, GetAllSlidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSlidesQuery, GetAllSlidesQueryVariables>(GetAllSlidesDocument, options);
      }
export function useGetAllSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSlidesQuery, GetAllSlidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSlidesQuery, GetAllSlidesQueryVariables>(GetAllSlidesDocument, options);
        }
export type GetAllSlidesQueryHookResult = ReturnType<typeof useGetAllSlidesQuery>;
export type GetAllSlidesLazyQueryHookResult = ReturnType<typeof useGetAllSlidesLazyQuery>;
export type GetAllSlidesQueryResult = Apollo.QueryResult<GetAllSlidesQuery, GetAllSlidesQueryVariables>;