import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Quiz_Slide } from "../../../../graphql/generated/graphql";
import { useHistory, useLocation } from "react-router-dom";
import VideocamIcon from '@mui/icons-material/Videocam';

export type IAdminSlideProps = {
  slide: Quiz_Slide;
};

const AdminSlide: React.FC<IAdminSlideProps> = (props: IAdminSlideProps) => {
  const isVideo = props.slide.media_type_id === "1" ? true : false;
 const history = useHistory();

  const slideAddress = props.slide.media_location?.split("/")[3];
  const [thumbnail, setthumbnail] = useState<string>();


  const handleSlideClick = () => {
    history.push("/TOPSECRET", { isEdit: true, ID: props.slide.quiz_id, focusedSlide: Number(props.slide.quiz_slide_id) });
  }

  useEffect(() => {
    if (isVideo) {
      fetch(
        `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${slideAddress}`
      )
        .then((data) => data.json())
        .then((result) => {
          setthumbnail(result.thumbnail_url);
        });
    }
  }, []);

  if (isVideo) {
    return (
      <div key={props.slide?.quiz_slide_id} className="adminSlide" onClick={handleSlideClick}>
        <div className="vid"><VideocamIcon/></div>
        <div className="tags">
          <div className="ID">Slide ID: {props.slide.quiz_slide_id}</div>
          {props.slide.tags
            ? props.slide.tags.map((tag) => {
                return <div className="tag">{tag?.tag_name}</div>;
              })
            : null}
        </div>
        {thumbnail ? (
          <img src={thumbnail}></img>
        ) : (
          <Skeleton className="thumbnailLoad" />
        )}
        <div className="statements">
          <div className="statement">{props.slide.intro_sound_text}</div>
          {props.slide.slide_statements
            ? props.slide.slide_statements.map((statement, index) => {
                return (
                  <div className="statement" key={index}>
                    {index + 1 + ". " + statement?.sound_text}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  } else {
    return (
      <div key={props.slide?.quiz_slide_id} className="adminSlide" onClick={handleSlideClick}>
        <div className="tags">
          <div className="ID">Slide ID: {props.slide.quiz_slide_id}</div>
          {props.slide.tags
            ? props.slide.tags.map((tag) => {
                return <div className="tag">{tag?.tag_name}</div>;
              })
            : null}
        </div>
        <img src={props.slide!.media_location!}></img>
        <div className="statements">
          <div className="statement">{props.slide.intro_sound_text}</div>
          {props.slide.slide_statements
            ? props.slide.slide_statements.map((statement, index) => {
                return (
                  <div className="statement" key={index}>
                    {index + 1 + ". " + statement?.sound_text}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  }
};

export { AdminSlide };
