import { Button, createMuiTheme } from '@material-ui/core';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import Loading from '../../../../globalComponents/Loading';
import { Category, useGetSchoolCategoryQuery } from '../../../../graphql/generated/graphql';
import { CategoryCellManage } from './CategoryCellManage';

export type ICategoryCellProps = {
    school_id: number
}

const CategoryCell: React.FC<ICategoryCellProps> = (props: ICategoryCellProps) => {

    const {data: catData, loading, refetch: refetchCat} = useGetSchoolCategoryQuery({variables:{schoolId: props.school_id}})

    const [state, setstate] = useState<Category[]>();

    const [isNewCategoryOpen, setisNewCategoryOpen] = useState(false);

    const [isError, setisError] = useState(false);

    const refetch = () => {
        refetchCat();
    }

    useEffect(() => {
        if(catData?.getSchoolCategory === null){
            setisError(true);
        }
        if(catData?.getSchoolCategory){
            const tempArray: Category[] = [];
            catData.getSchoolCategory.forEach((cat) => {
                if(cat){
                    tempArray.push(cat);
                }
            })
            setstate(tempArray);
        }
    }, [catData]);

    const columns: any[] = [
            { name: "cat_id", label: "id" },
            { name: "cat_name", label: "Kategori" },
    ]

    const options: MUIDataTableOptions = {
        customToolbar: () => <div><Button onClick={() => setisNewCategoryOpen(true)}>Tilføj ny kategori</Button></div>,
        download: false,
        print: false,
        filter: false,
        sort: false,
        search: false,
        viewColumns: false,
        selectableRows: "none"
    }



    return (
        <div className='CategoryCell'>
            {isNewCategoryOpen ? <CategoryCellManage school_id={props.school_id} categories={state!} close={() => setisNewCategoryOpen(false)} refetch={refetch}/> : false}
            { isError ? <Button onClick={() => {refetch(); setisError(false);}}>prøv igen</Button> : state ? 
                <MUIDataTable title="" columns={columns} data={state} options={options} />
                :
                <Loading/> 
            }
        </div>
    );
}

export { CategoryCell };