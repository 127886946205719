import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../translations/translationProvider";
import redArrow from "../../../../assets/red_Arrow.png";
import "./Setup.scss";
import { PATH_DASHBOARD } from "../../../../constants/pathConstants";
import { QuizState } from "../../../model";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
interface Inputs {
  mode: string;
  chosenLanguage: number;
  chosenSubtitles: number;
}

export type ISetupProps = {
  id: number;
  isLearningMode: boolean;
  setIsLearningMode: React.Dispatch<React.SetStateAction<boolean>>;
  isNoSound: boolean;
  setIsNoSound: React.Dispatch<React.SetStateAction<boolean>>;
  testIcon?: string;
  nameOfTest: string;
  testType: string;
  setQuizState: React.Dispatch<React.SetStateAction<QuizState>>;
  languageID: number;
  setlanguageID: React.Dispatch<React.SetStateAction<number>>;
  subtitlesID: number;
  setsubtitlesID: React.Dispatch<React.SetStateAction<number>>;
  isTabletMode: boolean;
  setisTabletMode: React.Dispatch<React.SetStateAction<boolean>>;
  quizLanguage: string;
};

const Setup: React.FC<ISetupProps> = (props: ISetupProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const history = useHistory();
  const { register, handleSubmit, watch } = useForm<Inputs>();
  const [learningMode, setLearningMode] = useState(false);
  const [chosenTestLanguage, setChosenTestLanguage] = useState(
    Number(localStorage.getItem("studentTestChosenLanguage")) || 0
  );
  const [chosenTestSubs, setChosenTestSubs] = useState(
    Number(localStorage.getItem("studentTestChosenSubs")) || 0
  );

  const [iOS, setiOS] = useState(
    localStorage.getItem("iOSAccepted") === "TRUE" ? true : false
  );

  useEffect(() => {
    const isSafari = navigator.vendor == "Apple Computer, Inc.";
    if (isSafari) {
      props.setIsNoSound(true);
    }
  }, []);

  const [iOSAlert, setiOSAlert] = useState(false);

  const [isDescription1Expanded, setisDescription1Expanded] =
    useState<boolean>(false);
  const [isDescription2Expanded, setisDescription2Expanded] =
    useState<boolean>(false);
  const [isDescription3Expanded, setisDescription3Expanded] =
    useState<boolean>(false);

  useEffect(() => {
    if (iOS) {
      localStorage.setItem("iOSAccepted", "TRUE");
    } else {
      localStorage.setItem("iOSAccepted", "FALSE");
    }
  }, [iOS]);

  const handleChangeiOS = () => {
    if (!iOS) {
      setiOSAlert(true);
    }
    setiOS(!iOS);
  };

  const onSubmit: SubmitHandler<Inputs> = async (submitData) => {
    localStorage.setItem(
      "studentTestChosenLanguage",
      String(submitData.chosenLanguage)
    );
    localStorage.setItem(
      "studentTestChosenSubs",
      String(submitData.chosenSubtitles)
    );
    props.setlanguageID(submitData.chosenLanguage);
    props.setsubtitlesID(
      submitData.chosenSubtitles ? submitData.chosenSubtitles : 0
    );
    props.setQuizState(QuizState.Quiz);
  };

  const backToDashboard = () => {
    history.push(PATH_DASHBOARD);
  };

  const handleLearningModeChange = (event) => {
    if (event.target.value === "1") {
      props.setIsLearningMode(true);
      props.setIsNoSound(false);
    } else if (event.target.value === "2") {
      props.setIsNoSound(true);
      props.setIsLearningMode(false);
    } else {
      props.setIsLearningMode(false);
      props.setIsNoSound(false);
    }
  };

  const [languages, setLanguages] = useState([
    {
      id: 1,
      name: languageString.studentQuizPage.Setup.languages.danish,
    },
    {
      id: 2,
      name: languageString.studentQuizPage.Setup.languages.english,
    },
  ]);
  const [subs, setSubs] = useState([
    { id: 0, name: languageString.studentQuizPage.Setup.languages.none },
    {
      id: 1,
      name: languageString.studentQuizPage.Setup.languages.danish,
    },
    {
      id: 2,
      name: languageString.studentQuizPage.Setup.languages.english,
    },
    {
      id: 3,
      name: languageString.studentQuizPage.Setup.languages.faerosk,
    },
    {
      id: 4,
      name: languageString.studentQuizPage.Setup.languages.polish,
    },
    {
      id: 5,
      name: languageString.studentQuizPage.Setup.languages.arabic,
    },
  ]);

  const renderLanguages = () => {
    const languageArray: JSX.Element[] = [];
    const allowed = props.quizLanguage
      ? props.quizLanguage.split(",").map((string) => {
          return Number(string);
        })
      : null;
    if (allowed !== null && allowed.includes(0)) {
      languages.forEach((language) => {
        if (language.id === 1) {
          if (language.id === chosenTestLanguage) {
            languageArray.push(
              <option selected key={language.id} value={language.id}>
                {language.name}
              </option>
            );
          } else {
            languageArray.push(
              <option key={language.id} value={language.id}>
                {language.name}
              </option>
            );
          }
        }
      });
    } else {
      languages.forEach((language) => {
        if (language.id === chosenTestLanguage) {
          languageArray.push(
            <option selected key={language.id} value={language.id}>
              {language.name}
            </option>
          );
        } else {
          languageArray.push(
            <option key={language.id} value={language.id}>
              {language.name}
            </option>
          );
        }
      });
    }
    return languageArray;
  };

  const renderSubs = () => {
    const subArray: JSX.Element[] = [];
    if (props.quizLanguage === "ALL") {
      subs.forEach((sub) => {
        if (props.isNoSound && sub.id === 0) {
        } else {
          if (sub.id === chosenTestSubs) {
            subArray.push(
              <option selected key={sub.id} value={sub.id}>
                {sub.name}
              </option>
            );
          } else {
            subArray.push(
              <option key={sub.id} value={sub.id}>
                {sub.name}
              </option>
            );
          }
        }
      });
    } else {
      const allowed = props.quizLanguage
        ? props.quizLanguage.split(",").map((string) => {
            return Number(string);
          })
        : null;
      if (allowed !== null && allowed.includes(0)) {
        subs.forEach((sub) => {
          if (props.isNoSound && sub.id === 0) {
          } else {
            if (sub.id === chosenTestSubs) {
              subArray.push(
                <option selected key={sub.id} value={sub.id}>
                  {sub.name}
                </option>
              );
            } else {
              if (allowed.includes(sub.id)) {
                subArray.push(
                  <option key={sub.id} value={sub.id}>
                    {sub.name}
                  </option>
                );
              }
            }
          }
        });
      }else{
        subs.forEach((sub) => {
          if (props.isNoSound && sub.id === 0) {
          } else {
            if (sub.id === chosenTestSubs) {
              subArray.push(
                <option selected key={sub.id} value={sub.id}>
                  {sub.name}
                </option>
              );
            } else {
              subArray.push(
                <option key={sub.id} value={sub.id}>
                  {sub.name}
                </option>
              );
            }
          }
        });
      }
    }
    return subArray;
  };

  const setupInputs = (
    <div className="inputs">
      <h3>{languageString.studentQuizPage.Setup.setupOfTest}</h3>

      <div>
        <FormLabel component="legend" className="modeHeader">
          {languageString.studentQuizPage.Setup.smallHeader}
        </FormLabel>
        <RadioGroup
          aria-label="Prøve-type"
          defaultValue={props.isNoSound ? "2" : "0"}
          {...register("mode")}
          onChange={handleLearningModeChange}
        >
          <FormControlLabel
            value="0"
            control={<Radio />}
            label={
              <>
                {languageString.studentQuizPage.Setup.standardMode}{" "}
                <Button
                  className={
                    isDescription1Expanded
                      ? "descriptionButton active"
                      : "descriptionButton"
                  }
                  onClick={() =>
                    setisDescription1Expanded(!isDescription1Expanded)
                  }
                >
                  <QuestionMarkIcon />
                </Button>
              </>
            }
          />
          {isDescription1Expanded ? (
            <FormLabel className="description">
              {languageString.studentQuizPage.Setup.standardModeDescription}
            </FormLabel>
          ) : null}

          <FormControlLabel
            value="1"
            control={<Radio />}
            label={
              <>
                {languageString.studentQuizPage.Setup.learningMode}{" "}
                <Button
                  className={
                    isDescription2Expanded
                      ? "descriptionButton active"
                      : "descriptionButton"
                  }
                  onClick={() =>
                    setisDescription2Expanded(!isDescription2Expanded)
                  }
                >
                  <QuestionMarkIcon />
                </Button>
              </>
            }
          />

          {isDescription2Expanded ? (
            <FormLabel className="description">
              {languageString.studentQuizPage.Setup.learningModeDescription}
            </FormLabel>
          ) : null}
          <FormControlLabel
            value="2"
            control={<Radio />}
            label={
              <>
                {languageString.studentSettings.iOSCheckbox}{" "}
                <Button
                  className={
                    isDescription3Expanded
                      ? "descriptionButton active"
                      : "descriptionButton"
                  }
                  onClick={() =>
                    setisDescription3Expanded(!isDescription3Expanded)
                  }
                >
                  <QuestionMarkIcon />
                </Button>
              </>
            }
          />
          {isDescription3Expanded ? (
            <FormLabel className="description">
              {languageString.studentQuizPage.Setup.iOSExplainer}
            </FormLabel>
          ) : null}
        </RadioGroup>
        <label></label>
        {/* <div className="tabletCheck">
          <div className="header">
        <Checkbox className="tabletCheckbox" value={props.isTabletMode} onChange={() => props.setIsLearningMode(!props.isTabletMode)} />
          <label>Tablet mode</label>
          </div>
        </div> */}
      </div>
      <div className="select">
        <label>{languageString.studentQuizPage.Setup.chooseTestSound}</label>{" "}
        <select {...register("chosenLanguage")} disabled={props.isNoSound}>
          {renderLanguages()}
        </select>
      </div>
      <div className="select">
        <label>{languageString.studentQuizPage.Setup.chooseTestSubs}</label>
        <select
          {...register("chosenSubtitles")}
          disabled={!props.isLearningMode && !props.isNoSound}
          value={!props.isLearningMode && !props.isNoSound ? 0 : undefined}
        >
          {renderSubs()}
        </select>
      </div>
    </div>
  );

  return (
    <div className="QuizSetup">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="leftSide">
          <div className="backButton clickable" onClick={backToDashboard}>
            <button className="clickable">
              <img alt="LEFT-ARROW" src={redArrow}></img>
            </button>
            <span>{languageString.generalMessages.backButton}</span>
          </div>
          <div className="header">
            <h1>{props.testType}</h1>
            <hr></hr>
            <div>
              {props.testIcon ? <img src={props.testIcon} /> : null}
              <span>{props.nameOfTest}</span>
              <span className="description"></span>
            </div>
          </div>
        </div>
        <div className="rightSide">
          {setupInputs}

          <Button variant="contained" className="finishButton" type="submit">
            {languageString.studentQuizPage.Setup.startTest}
          </Button>
        </div>
      </form>
      {iOSAlert ? (
        <div className="iOSAlert">
          <div className="box">
            <div className="text">
              {languageString.studentSettings.iOSAlertText}
            </div>
            <Button onClick={() => setiOSAlert(false)}>
              {languageString.studentSettings.continue}
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export { Setup };
