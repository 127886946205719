import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { TeoriLogo } from "../../../../globalComponents/TeoriLogo";
import { getStringsForChosenLanguage, getChosenLanguage } from "../../../../translations/translationProvider";
import "./PlaceholderPage.scss";

export type IPlaceholderPageProps = {};

const PlaceholderPage: React.FC<IPlaceholderPageProps> = ({}) => {

  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  return (
    <div className="PlaceholderPage">
      <TeoriLogo />
      <div className="infoBox">
        <div className="text">
          {
            languageString.teacherStudentPage.placholder.youAreLoggedIn
          }
        </div>
        <div className="feedback">
             <CircularProgress/>
             <span>{languageString.teacherStudentPage.placholder.feedback}</span>
        </div>
       
      </div>
    </div>
  );
};

export { PlaceholderPage };
