import { useEffect, useState } from "react";

export interface WindowDimensions {
	width: number;
	height: number;
}

const getWindowDimensions = (): WindowDimensions => {
	return {
		width: window.innerWidth,
		height: window.innerHeight,
	};
};

export const useWindowDimensions = (): WindowDimensions => {
	const resize = "resize";
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		const handleChange = (): void => {
			setWindowDimensions(getWindowDimensions());
		};

		window.addEventListener(resize, handleChange);

		return () => {
			window.removeEventListener(resize, handleChange);
		};
	}, []);

	return windowDimensions;
};
