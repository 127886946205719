export function sessionStorageSaveItem<T>(key: string, item: T): void {
	sessionStorage.setItem(key, JSON.stringify(item));
}

export function sessionStorageGetItem<T>(key: string): T | null {
	const item = sessionStorage.getItem(key);

	if (typeof item === "string") {
		return <T>JSON.parse(item);
	}

	return null;
}

export function sessionStorageRemoveItem(key: string): void {
	sessionStorage.removeItem(key);
}

export function sessionStorageClear(): void {
	sessionStorage.clear();
}

export function localStorageSaveItem<T>(key: string, item: T): void {
	localStorage.setItem(key, JSON.stringify(item));
}

export function localStorageGetItem<T>(key: string): T | null {
	const item = localStorage.getItem(key);

	if (typeof item === "string") {
		return <T>JSON.parse(item);
	}

	return null;
}

export function localStorageRemoveItem(key: string): void {
	localStorage.removeItem(key);
}

export function localStorageClear(): void {
	localStorage.clear();
}
