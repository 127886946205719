import React from 'react';
import { TeoriLogo } from '../../../../globalComponents/TeoriLogo';
import "./OngoingLessonPage.scss";

export type IOngoingLessonPageProps = {
    
}

const OngoingLessonPage: React.FC<IOngoingLessonPageProps> = ({ }) => {
    return (
        <div className='OngoingLessonPage'>
      <TeoriLogo />
      <div className="infoBox">
        <div className="text">
            {"Der er en lektion igang"}
        </div>
        <div className="feedback">
        </div>
       
      </div>
        </div>
    );
}

export { OngoingLessonPage };