import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { AppStateStudentContext } from "../apollo/TeoriStudentProvider";
import { PATH_DASHBOARD } from "../constants/pathConstants";
import Loading from "../globalComponents/Loading";
import { useLoginStudentMutation } from "../graphql/generated/graphqlStudent";

export type IQuickLoginProps = {};
interface routeParams {
  phone: string;
  pin: string;
}

//This component only exists for the admin to log quickly in
const QuickLogin: React.FC<IQuickLoginProps> = ({}) => {
  const context = useContext(AppStateStudentContext);
  const history = useHistory();
  const params = useParams<routeParams>();

  const [currentState, setcurrentState] = useState(-1);

  const [LoginMutation] = useLoginStudentMutation();

  useEffect(() => {
    LoginMutation({
      variables: { phone_number: params.phone, pin_code: params.pin },
      onError: (e) => {
        if (e.message === "pin_not_set") {
          setcurrentState(1);
        } else if (e.message === "user_expired") {
          setcurrentState(0);
        } else {
        }
      },
    }).then((data) => {

      const tokens = data.data?.LoginStudent;
      if (tokens) {
        if (!tokens.login_before) {
          sessionStorage.setItem("has_login", "false");
        }
        setTimeout(() => {
          localStorage.setItem("TEORIFIRSTTIME", "NO");
          context.appSetLogin(tokens.accessToken!, tokens.refreshToken!);
          history.push(PATH_DASHBOARD);
        }, 1500);
      }
    });
  }, []);

  const renderSomething = () => {
    switch (currentState) {
      case -1:
        return (
          <div>
            <Loading />
          </div>
        );
      case 0:
        return (
          <div>
            <h2>Adgangen er udløbet</h2>
          </div>
        );
      case 1:
        return (
          <div>
            <h2>PIN-koden er ikke sat</h2>
          </div>
        );
    }
  };

  return <>{renderSomething()}</>;
};

export { QuickLogin };
