import { Translation } from "./translationTypes";
import index1 from "./quizSounds/english/english1.mp3";
import index2 from "./quizSounds/english/english2.mp3";
import index3 from "./quizSounds/english/english3.mp3";
import index4 from "./quizSounds/english/english4.mp3";

export const english: Translation = {
generalMessages: {
    backButton: "back",
    seeYourErrors: "see your errors",
    yes: "Yes",
    no: "No",
    both: "Both",
    allow: "Allow",
    deny: "Deny",
  },
  ultimateTopbar: {
    education: "Undervisning",
    studentsNstats: "Elever & statistik",
    orders: "Bestilling",
    calibrateProjector: "kalibrer projektor",
    importFromEP: "importer fra EasyPiecy",
    settings: "Profile",
    logout: "LOGOUT",
  },
  indexSounds: {
    one: "https://teoricontentstorage.blob.core.windows.net/admincontainer/english1.mp3",
    two: "https://teoricontentstorage.blob.core.windows.net/admincontainer/english2.mp3",
    three: "https://teoricontentstorage.blob.core.windows.net/admincontainer/english3.mp3",
    four: "https://teoricontentstorage.blob.core.windows.net/admincontainer/english4.mp3",
  },
  loginPage: {
    loginForm: {
      loginButton: "Login",
      userNamePlaceholder: "Type e-mail",
      passwordPlaceholder: "Type access code",
    },
    wrongInfoError: "Access code or e-mail cannot be found",
    rememberLoginInfo: "Remember login",
  },

  dashboardPage: {
    topbar: {
      lecture: "lektion",
      test: "test",
      search: "søg",
      sort: {
        sortPlaceholder: "Sorter indhold",
        byDate: "Sorter efter dato",
        byType: "Sorter efter type",
        byCategory: "Sorter efter kategori",
        byReleaseType: "Sorter efter udgivelsestype",
      },
    },
    table: {
      type: {
        lesson: "lektion",
        test: "test",
      },
      category: "kategori",
      created: "oprettet",
      status: {
        unpublished: "unpublished",
        published: "published",
        offline: "offline",
      },
      edit: "rediger",
      details: "detaljer",
    },
  },
  lessonPage: {
    of: "af",
    initialPage: {
      scanQR: "Scan QR koden for at logge ind!",
      welcomeTo: "Du har valgt lektionen: ",
      continue: "fortsæt",
      skip: "skip",
    },
  },
  createLessonPage: {
    slide: "Slide",
    addTag: "tilføj tag",
    movePicture: "flyt billede",
    withPicture: "med billede",
    addSubject: "indsæt emne",
    fromPicture: "fra billede",
    insert: "insert",
    page: "side",
    uploadMP3: "Upload MP3 fil",
    notePlaceholder: "Skriv en note her",
    save: "Gem",
    finish: "Udgiv",
    newSlide: {
      chooseWhereToGet: "Vælg hvor du vil have indholdet fra",
      noneChosen: "Intet valgt",
      teoriundervisning: "Teoriundervisning",
      ownMaterial: "Eget materiale",
      link: "Link",
    },
  },
  studentPage: {
    topbar: {
      actions: {
        placeholder: "Vælg handling",
        gatheredStats: "Samlet statistik",
        archiveChosen: "Arkiver valgte",
        resendPassword: "Gensend adgangskode (SMS)",
        category: "Category",
        yourInfo: "Your info",
        usefulInfo: "Useful tips",
      },
      searchPlaceholder: "søg",
      sort: {
        placeholder: "Sorter indhold",
        newest: "Nyeste først",
        alphabetical: "Alfabetisk",
        teamStart: "Efter holdstart dato",
        activeCodes: "Kun med aktive adgangskoder",
        archivednExpiredCodes: "Kun arkiverede / udløbne",
        noCodes: "Kun elever uden adgangskoder",
        byPercentage: "Sorter efter %",
      },
    },
    table: {
      category: "kategori",
      mobileNumber: "mob.nr",
      password: "adgangskode",
      expiry: "udløb",
      stats: "statistik",
      archive: "arkiver",
      removeAccess: "fjern adgang",
      addAccess: "tilføj adgang",
      extendedStats: {
        theseSlidesAreMarked: "Eleven har markeret disse teoriprøve billeder",
        latestActivity: "Seneste aktivitet",
      },
    },
  },
  teacherQuizPage: {
    quizSetup: {
      noOfTeoriSlides: "Antal teoriprøve billeder",
      speed: {
        header: "Hastighed",
        slow: "Langsom",
        normal: "Normal",
        fast: "Hurtig",
      },
      languageSettings: {
        header: "Sprog indstillinger",
        none: "Ingen undertekster",
        arabic: "Arabiske undertekster",
        danish: "Danske undertekster",
        english: "Engelske undertekster",
      },
      otherSetting: {
        header: "Andre indstillinger",
        includeFromTeoriDK: "Inkluder teoribilleder fra Teoriundervisning.dk",
        saveForLater: "Gem testen til senere brug",
        nameOfTestPlaceholder: "Testens navn",
      },
      strengthTest: "styrkeprøve",
      strengthTestDescription:
        "Styrkeprøve-knap: Klik på AI-knappen og test eleverne i de emner som de har sværrest ved! Vi har indsamlet data fra alle elever, og analyseret hvad de har sværrest ved! AI testen er en skræddersyet test til de elever som er logget ind lige nu! (inkluderer alle emner, og viser kun billeder eleverne ikke kan se hjemme, indeholder 10 billeder)",
      startTest: "START TEST",
      close: "afslut",
    },
    quizQRGather: {
      teoriTestStarting: "Teoritesten start om lidt",
      scanIfNotLoggedIn: "Scan hvis du ikke er logget ind",
      openYourPhone: "Åben din mobiltelefon",
    },
    quizMain: {
      left: "Left",
      right: "Right",
    },
    quizResult: {
      studentList: {
        chooseAll: "Vælg alle",
      },
      picture: "Slide"
    },
  },
  studentLoginPage: {
    mobileNumberPlaceholder: "Type mobile number",
    backButton: "back",
    continueButton: "continue",
    expiredCode: "Your access has expired",
    extend: "extend",
    Initial: {
      loginButton: "login",
      errorMessage: "Access code or mobile number not found",
      loginSuccess: "Please wait....",
      phonenumberLabel: "Mobile number",
      passwordLabel: "PINscode",
      registerDescription: "If you dont have a account, you can create it by clicking here",
      forgot: "Forgot PIN",
      haveAccessCode: "Use activation/access - code"
    },
    FirstLogin: {
      firstTimeVisiting: "Is this your first visit to the new Teoriundervisning.dk?",
      havePasscode: "Do you have an access code from our booklet or book?",
    },
    Confirmation: {
      sendConfirmationCode: "Send verification code",
      weSentASMS: "We’ve sent you the verification code on SMS",
      errorMessage: "The verification code doesn’t match",
    },
    AccessCode: {
      typeCodePlaceholder: "Type access code",
      errorMessages: {
        success: "Access code added!",
        expired: "The access code has expired",
        notFound: "We cannot find the access code",
        alreadyUsed: "The access code has been activated before",
        mobileUsed: "The mobile number is already active",
        alreadyExisting: "The mobile number already exists, so we extende your access!"
      },
    },
    CreatePIN: {
      createPIN: "Create pincode",
      repeatPIN: "Repeat pincode",
      errorMessage: "Pincodes doesn’t match",
      setPIN: "Confirm Pincode",
    },
    InformationSide: {
      header: "STUDENT LOGIN",
      Initial: {
        underHeader: "LOGIN WITH PINCODE",
        underHeaderExplainer:
          "Login with your pincode",
        buttonText: "Create user account",
      },
      FirstLogin: {
        underHeader: "FIRST TIME",
        underHeaderExplainer: "Please answer these questions to create user account",
      },
      Confirmation: {
        underHeader: "VERIFY MOBILE NUMBER",
        underHeaderExplainer:
          "We need to check if your mobile number is in our system",
      },
      AccessCode: {
        underHeader: "ACCESS CODE",
        underHeaderExplainer:
          "Enter your activation- or accesscode. After you click “Continue” you only need to use your mobile number and pin to log in.",
      },
      CreatePIN: {
        underHeader: "CREATE PINCODE",
        underHeaderExplainer: "Please create a personal pincode for your account",
      },
    },
  },
  studentSettings: {
    yourInfo: "Your account details",
    description:
      "In order for your driving school to follow and guide you in the best possible way, it is important that you enter all information correctly. Your driving school has access to your activity on the site as well as your results. It is important that ALL the fields below are filled in.",
    inputLabels: {
      firstName: "First name",
      lastName: "Last name",
      email: "E-mail",
      mobileNr: "Mobile number",
    },
    yourDrivingSchool: "Your driving school",
    languageSelect: {
      headline: "Language",
      danish: "Danish",
      english: "English",
    },
    categorySelect: {
      headline: "Category",
      car: "Car",
      motorcycle: "Motorcycle",
    },
    saveSettings: "Save",
    iOSAlertText: "Please be aware that Apple has blocked the option for autoplay of sound which we need to deploy a quiz. In this edition we’ve made a work-around to avoid the problem but we cannot guarantee that the problem cannot occur.",
    iOSCheckbox: "iPhone / iPad / Without sound",
    iOSExtended: "Guide for Mac",
    continue: "Skip",
  },
  studentDashboardPage: {
    profileSettings: "Account settings",
    header: "GETTING YOUR LICENSE",
    headerSpan:
      "Solve the tasks on this page, and get one big step closer to the driving license!",
    seeYourErrors: "Error in ",
    errorShorthand: "Result",
    trafficLightBox: {
      seeCalc: "See calculation",
      information: "Only Evaluating quizzes in Standard Mode are included in the result",
      explanation: "The calculation of your result is made exclusively from Standard Mode. If you take tests in Learning Mode, they will not count in your traffic light.The traffic light calculates your result from the last 20 days.You may therefore find that your result automatically gets worse if you did not practice for a while.",
        yourLastResult: "Your have passed",
        outofTen: "out of the last 10 tests",
      notReady: {
        header: "NOT READY YET",
        span: "If you’ve passed less than 3 evaluating quizzes of the last 10, then the light is red.",
      },
      onTheWay: {
        header: "WELL ON YOUR WAY",
        span: "If you’ve passed between 3 and 7 evaluating quizzes of the last 10, then the light is yellow.",
      },
      Ready: {
        header: "YOUR READY TO PASS.",
        span: "If you’ve passed 8 or more evaluating quizzes out of the last 10, then the light is green.",
      },
    },
    InfoBox: {
      header: "INFO FOR YOU",
      yourCalendar: "Your calendar (Drivi)",
      welcome: "Welcome to the new Teoriundervisning.dk!",
      betaText: "To pass the official theory exam, we recommend that you get a green traffic light in the Evaluating quiz box. We wish you good luck with your drivers license!",
      ratingOfDrivingSchool: {
        header: "Rate your driving school:",
        teoriundervisning: "Theory lessons",
        driving: "Driving lessons",
        totalExperience: "Overall impression",
      },
      important: "IMPORTANT!",
      youHave: "You have ",
      daysAnd: " days and ",
      hoursLeft: " hours left of your access",
      extendAccess: "Extend your access here"
    },
    startContentBox: {
      header: "GET OFF TO A GOOD START!",
      description: "Complete this section before you start solving quizzes.",
    },
    generalContentBox: {
      header: "ONE SUBJECT AT A TIME",
      description:
        "You can go through the subject quizzes in your own pace. It’s a great way to be introduced to the quizzes!",
    },
    evalTestsContentBox: {
      header: "EVALUATING QUIZZES",
      description:
        "The evaluating quizzes simulate the quiz you’ll find at the official exam. To get a green light (top of page) you have to pass at least 8 out of the last 10 evaluating quizzes.",
      button: "RANDOM QUIZ",
    },
    strengthTestContentBox: {
      header: "POWER QUIZ",
      description:
        "Get quizzed in all the slides that you answered wrong before with the Power Quiz. Supercharge your learning!",
      button: "START POWER QUIZ",
      noErrors: "All slides passed!"
    },
    onlineBookContentBox: {
      header: "THE DIGITAL THEORY BOOK ONLINE",
      description:
        "Read the complete curriculum for category B. Scan QR codes throughout the book to get video links and illustrations.",
      button: "ORDER THE PHYSICAL BOOK (DANISH EDITION)",
      buttonDescription:
        "The physical book has the same content as the online. The book is for you who prefer a book of paper. Please notice the book is in Danish.",
    },
    drivingSchoolLessonsContentBox: {
      header: "YOUR DRIVING SCHOOLS LESSONS",
      description:
        "These lessons has been published by your driving school. If you have questions for this content please ask your driving instructor.",
    },
    beforeDrivingTestContentBox: {
      header: "BEFORE THE PRACTICAL EXAM",
      description:
        "Prepare for your practical exam with these tasks.",
    },
    practicalInfoBox: {
      header: "PRACTICAL INFO",
      authorities: {
        header: "Authorities",
        pricesNfee: "Prices and fees for driver's license exams etc.",
        infoAboutKlippekort: "Info about the “klippekort” (penalty points)",
      },
      aboutTeori: {
        header: "Teoriundervisning.dk",
        howToProlong: "How to prolong access",
        problemWithTests: "If you have technical issues",
      },
      drivingSchoolCourse: {
        header: "The driving school education",
        whenSmoothTrack: "Training on the slippery track",
        documentsTeoriAndTest:
          "Documents for the theory- and practical exam",
      },
      yourOpinion: {
        header: "What’s your opinion?",
        areYouSatisfied: "Have you been satisfied with Teoriundervisning.dk?",
        yes: "Yes I have :)",
        no: "Not really.",
      },
    },
  },
  studentQuizPage: {
    Setup: {
      freeQuizFreeTest: "Free test",
      setupOfTest: "Quiz setup",
      smallHeader: "Test mode",
      learningMode: "Learning Mode",
      learningModeDescription:
        "Practice at your own pace with subtitles. You’ll get the correct answers after each quiz slide",
      standardMode: "Standard Mode",
      standardModeDescription:
        "Practice in the same pace as the official exam with no subtitles. You’ll get the result after 25 quiz slides.",
      chooseTestSound: "Narration",
      chooseTestSubs: "Subtitles",
      testSubPlaceholder: "No subtitles",
      startTest: "Start quiz",
      iOSExplainer: "IOS fungerer lidt anderledes end andre styresystemer (f eks Windows) derfor skal du bruge denne udgave for, at afvikle teoriprøverne.",
      languages: {
        none: "None",
        danish: "Danish",
        english: "English",
        arabic : "Arabic",
        faerosk: "Faeroese",
        polish: "Polish",
        //TO BE EXTENDED
      }
    },
    Quiz: {
      yes: "YES",
      no: "NO",
      errorPage: {
        somethingwentWrong: "Something is wrong here",
        tryagain: "try again",
        returntodashboard: "back to the frontpage",
      },
      loadingText: "Generating quiz",
      soundError:{
        important: "IMPORTANT",
        description: "We need permission to play sound on your device",
      }
    },
    End: {
      purchaseAccess: "BUY ACCESS",
      dontLikeImage:
        "When you mark an image with a star, your driving school can see it. This means that you can review the picture together. Applies only to driving schools with Teoriundervisning.dk teaching system.",
      dontLikeImageShorthand: "Review with instructor",
      soundExplained:
        "This slide has an extra audio explanation attached.",
      soundExplainedShorthand: "Extra explanation",
      youpassed: "Passed",
      youfailed: "Not passed",
      youhad: "You answered",
      imagesPassed: "quiz slides correctly",
    },
  },
  teacherStudentPage: {
    stats: {
      description:
        "The results below is based on the total results of the class since first login",
      progress: "class progress",
      average: "country average",
    },
    placholder: {
      youAreLoggedIn: "You’ve been logged in!",
      feedback: "Waiting for your driving instructor ...",
    },
    graduationPage: {
      correct: "correct",
      outOf: "out of",
      youHad: "You answered",
      amountOfCorrect: "quiz slides correctly",
      failed: "You didn’t pass",
      passed: "Congrats, you passed!",
    },
  },
}