import React from 'react';
import arrow from "../../../../../../assets/red_Arrow2.png";
import "./SlideArrows.scss"

export type ISlideArrowsProps = {
    handleNext: () => void;
    handlePrevious: () => void;
}

const SlideArrows: React.FC<ISlideArrowsProps> = (props: ISlideArrowsProps) => {
    return (
        <>
        <div className={"leftarrow Arrow"}>
            <button onClick={props.handlePrevious}><img src={arrow}></img></button>
        </div>
        <div className="rightarrow Arrow">
            <button onClick={props.handleNext}><img src={arrow}></img></button>
        </div>
        </>
    );
}

export { SlideArrows };