import { Button, Input, Select } from '@mui/material';
import React, { useState } from 'react';
import { useManageStudentMutation } from '../../../../../graphql/generated/graphql';
import { DatePicker } from './DatePicker';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface AddStudentBoxProps {
    categories: any[];
    setisshown: any;
    refetch: any;
}

const AddStudentBox: React.FC<AddStudentBoxProps> = (props: AddStudentBoxProps) => {
    const [manageStudent] = useManageStudentMutation();
    const [selectedCategory, setselectedCategory] = useState<string>("2");
    const [phonenumber, setphonenumber] = useState<string>("+45");
    const [selectedDate, setselectedDate] = useState<Date>(new Date());

    function padTo2Digits(num) {
        return num.toString().padStart(2, "0");
      }
    
      function formatDate(timeString) {
        const date = new Date(timeString * 1000);
        return [
          padTo2Digits(date.getDate()),
          padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join("/");
      }


    const renderOptions = () => {
        const tempArray: JSX.Element[] = [];
        props.categories.forEach((category) => {
            tempArray.push(<option value={category.id}>{category.label}</option>)
        })
        return tempArray;
    }

    const handleSubmit = () => {
        if(selectedDate && phonenumber && selectedCategory){
            manageStudent({variables:{student:{ student_category: Number(selectedCategory), student_phone_number: phonenumber, expiry_date: selectedDate.toISOString(), language_choice: 1}}}).then(() => {
                props.setisshown(false);
                props.refetch();
            })
        }
    }

    const handleDateChange = (timeString: string) => {
        setselectedDate(new Date(Number(timeString) * 1000))
    }


    return (
        <div className='AddStudentBox'>
            <div className='AddStudentBoxInputBox'>
                <Button onClick={() => props.setisshown(false)} className="closeButton"><CloseRoundedIcon/></Button>
                <div className='inputDiv'>
                <label>Mobilnummer</label>
                <Input value={phonenumber} onChange={(e) => setphonenumber(e.target.value)}></Input>
                </div>
                <div className='inputDiv'>
                <label>Kategori</label>
                <select value={selectedCategory} onChange={(e) => {console.log(e.target.value); setselectedCategory(e.target.value)}}>{renderOptions()}</select>
                </div>
                <div className='inputDiv'>
                <label>Udløbsdato</label>
                <label>{formatDate(selectedDate.getTime()/ 1000)}</label>
                <DatePicker value={String(selectedDate.getTime() / 1000)} onChange={handleDateChange}/>
                </div>
                <Button onClick={handleSubmit} className="finishButton" variant={"outlined"}>TILFØJ</Button>
            </div>
        </div>
    );
};

export default AddStudentBox;