import React, {
  FC,
  lazy,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Loading from "../globalComponents/Loading";
import {
  PATH_DASHBOARD,
  PATH_ELEV,
  PATH_ELEV_AI,
  PATH_HOME,
  PATH_LOGIN,
  PATH_PROFILE,
  PATH_QUIZ,
  PATH_STUDENT_TEACHER,
  PATH_STUDENT_TEACHER_AI,
  PATH_TEACHER_LESSON,
  PATH_TEACHER_STUDENT,
  PATH_TEST,
} from "../constants/pathConstants";
import { fetchAccessToken } from "../apollo/apolloProviderOperations";
import { APOLLO_STUDENT_URL } from "../constants/APIConstants";
import { AppStateStudentContext } from "../apollo/TeoriStudentProvider";
import { TeacherStudentController } from "../pages/Student/teacherStudentController/TeacherStudentController";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./StudentRoutes.scss";
import { FreeQuiz } from "../pages/Student/FreeQuiz/FreeQuiz";
import { QuickLogin } from "./QuickLogin";
import { A2HSComponent } from "../globalComponents/A2HSComponent";
import { StudentTeacherQuiz } from "../pages/Student/studentTeacherQuiz/StudentTeacherQuiz";

export interface RoutesProps {}

let initialized = false;

export const StudentRoutes: FC<RoutesProps> = () => {
  const { appState, appSetLogin, appSetLogout } = useContext(
    AppStateStudentContext
  );
  const isAuthenticated = appState.loggedIn;
  const { path, url } = useRouteMatch();

  const location = useLocation();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (initialized) return;

    initialized = true;
    fetchAccessToken(APOLLO_STUDENT_URL)
      .then((data: any) => {
        const failed =
          data === undefined || data?.RefreshUser.accessToken === undefined;
        failed
          ? appSetLogout()
          : appSetLogin(
              data?.RefreshUser.accessToken,
              data?.RefreshUser.refreshToken
            );
      })
      .catch((e) => {
        console.log(e);
        appSetLogout();
      })
      .finally(() => {
        setLoading(false);
      });
  });
  if (loading) {
    return <Loading />;
  }

  const LoginPage = lazy(() => import("../pages/Student/loginPage/LoginPage"));
  const DashboardPage = lazy(
    () => import("../pages/Student/dashboardPage/DashboardPage")
  );
  const SettingsPage = lazy(
    () => import("../pages/Student/settingsPage/SettingsPage")
  );
  const QuizPage = lazy(
    () => import("../pages/Student/studentQuizPage/StudentQuizPage")
  );

  const routes = [
    <Route
      key="slashRedirect"
      exact
      path={PATH_HOME}
      render={() => <Redirect to={PATH_LOGIN} />}
    />,
    <Route
      key="loginPage"
      exact
      path={PATH_LOGIN}
      render={() =>
        !isAuthenticated ? <LoginPage /> : <Redirect to={PATH_DASHBOARD} />
      }
    />,
    <Route
      key="quickLogin"
      exact
      path={PATH_LOGIN + "/:phone/:pin"}
      render={() =>
        <QuickLogin />
      }
    />,
    <Route
      key="settingsPage"
      exact
      path={PATH_PROFILE}
      render={() =>
        isAuthenticated ? <SettingsPage /> : <Redirect to={PATH_DASHBOARD} />
      }
    />,
    <Route
      key="dashboardPage"
      exact
      path={PATH_DASHBOARD}
      render={() =>
        isAuthenticated ? sessionStorage.getItem("has_login") === "false" ? <SettingsPage /> : <DashboardPage /> : <Redirect to={PATH_LOGIN} />
      }
    />,
    <Route
      key="quizPage"
      exact
      path={PATH_TEST}
      render={() =>
        isAuthenticated ? <QuizPage/> : <Redirect to={PATH_LOGIN} />
      }
    />,
    <Route
      key="lessonPage"
      exact
      path={PATH_TEACHER_LESSON + "/:id([0-9]+)"}
      render={() => (isAuthenticated ? "nuffin" : <Redirect to={PATH_LOGIN} />)}
    />,
    <Route
      key="studentTeacherQuizPage"
      exact
      path={PATH_ELEV + "/:id"}
      render={() => (isAuthenticated ?  <StudentTeacherQuiz isAI/> : <LoginPage />)}
    />,
    <Route
      key="studentTeacherQuizPage"
      exact
      path={PATH_ELEV_AI + "/:id"}
      render={() => (isAuthenticated ?  <StudentTeacherQuiz isAI/> : <LoginPage />)}
    />,
    <Route
    key="teacherQuizPage"
    exact
    path={PATH_TEACHER_STUDENT + "/:id"}
    render={() => <TeacherStudentController />}
  />,
    <Route
      key="freeQuiz"
      exact
      path={PATH_QUIZ + "/free"}
      render={() => <QuizPage isFreeQuiz={true}/>}
    />
  ];

  return (
    <>
    <Suspense fallback={<Loading />}>
          <Switch>{routes}</Switch>
    </Suspense></>
  );
};

export default StudentRoutes;
