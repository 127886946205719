import { LanguageType, Translation } from "./translationTypes";
import { localStorageGetItem, localStorageSaveItem } from "../services/browserStorageService";
import { danish } from "./danish";
import { english } from "./english";


/**
 * Function returns chosen language version, if parameter is null or type of it does
 * not fit to switch condition returns default value - english
 * @param languageType
 */
export const getStringsForChosenLanguage = (languageType: LanguageType | null): Translation => {
	switch (languageType) {
		case LanguageType.English: {
			return english;
		 }
		case LanguageType.Danish: {
			return danish;
		}
		default: {
			return danish;
		}
	}
};

export const setLanguage = (languageType: LanguageType): void => {
	localStorageSaveItem<LanguageType>("language", languageType);
};

export const getChosenLanguage = (): LanguageType | null => {
	return localStorageGetItem<LanguageType>("language");
};

export const getChosenSpokenLanguage = (languageID: number): Translation => {
	switch (languageID) {
		case 1: {
			return danish;
		 }
		case 2: {
			return english;
		}
		default: {
			return danish;
		}
	}
};
