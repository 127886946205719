/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import closeIcon from "../../../../assets/delete_icon.png";
import { CreateQuizSlide, LessonSlide, LessonType, MediaType, VideoType } from '../../../model';
import CloseIcon from "@mui/icons-material/Close";
import YouTube from 'react-youtube';
import "./style/ItemDetails.scss";
import { LessonPageVideo } from '../../lessonPage/components/LessonPageVideo';

export type IItemDetailsProps = {
    shrinkSelectedSlide: () => void;
	slide: CreateQuizSlide;
}

const ItemDetails: React.FC<IItemDetailsProps> = (props: IItemDetailsProps) => {
    console.log(props.slide.type);
    

    const whatToRender = () => {
        switch(props.slide.type){
            case MediaType.AZUREIMG:
                return IMAGE();
            case MediaType.WEB_LINK:
                return WEBSITE();
            case MediaType.STREAM:
                return STREAMVIDEO();
            case MediaType.AZUREVIDEO:
                return VIDEO();
            case MediaType.YOUTUBE:
                return YOUTUBE();
            // case MediaType.LOCALVIDEO:
            //     return LOCALVIDEO();
        }
    }

    const IMAGE = () => (<div><img src={props.slide.media}></img></div>)
    const WEBSITE = () => (<div><iframe src={typeof props.slide.tempFile === "string"  ? props.slide.tempFile : undefined}></iframe></div>)
    const VIDEO = () => (<div><LessonPageVideo key={props.slide.tempFile!.toString()} video={typeof props.slide.tempFile === "string" ? props.slide.tempFile : ""} videoType={VideoType.STREAM}/></div>)
    // const LOCALVIDEO = () =>(<div className="LOCALVIDEO"><LessonPageVideo key={props.slide.tempFile!.toString()} video={props.slide.tempFile!} videoType={VideoType.LOCALVIDEO}/></div>)
    const YOUTUBE = () =>(<div><YouTube videoId={typeof props.slide.tempFile === "string" ? props.slide.tempFile.split("?v=", 2)[1] : " "}/></div>)
    const STREAMVIDEO = () =>(<div className="ReactPlayer"><LessonPageVideo key={typeof props.slide.tempFile === "string" ? props.slide.tempFile : ""} video={typeof props.slide.tempFile === "string" ? props.slide.tempFile: ""} videoType={VideoType.STREAM}/></div>)

    return (
        <div className="Expanded">
            <button onClick={props.shrinkSelectedSlide}><CloseIcon/></button>
            {whatToRender()}
        </div>
    );
}

export { ItemDetails };