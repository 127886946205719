import { Translation } from "./translationTypes";
import index1 from "./quizSounds/danish/1.mp3";
import index2 from "./quizSounds/danish/2.mp3";
import index3 from "./quizSounds/danish/3.mp3";
import index4 from "./quizSounds/danish/4.mp3";

export const danish: Translation = {
  generalMessages: {
    backButton: "tilbage",
    seeYourErrors: "se dine fejl",
    yes: "Ja",
    no: "Nej",
    both: "Begge",
    allow: "Tillad",
    deny: "Afvis",
  },
  ultimateTopbar: {
    education: "Undervisning",
    studentsNstats: "Elever & statistik",
    orders: "Bestilling",
    calibrateProjector: "kalibrer projektor",
    importFromEP: "importer fra EasyPiecy",
    settings: "Profil",
    logout: "LOG UD",
  },
  indexSounds: {
    one: "https://teoricontentstorage.blob.core.windows.net/admincontainer/1.mp3",
    two: "https://teoricontentstorage.blob.core.windows.net/admincontainer/2.mp3",
    three: "https://teoricontentstorage.blob.core.windows.net/admincontainer/3.mp3",
    four: "https://teoricontentstorage.blob.core.windows.net/admincontainer/4.mp3",
  },
  loginPage: {
    loginForm: {
      loginButton: "Login",
      userNamePlaceholder: "Indtast e-mail",
      passwordPlaceholder: "Indtast adgangskode",
    },
    wrongInfoError: "Adgangskode eller e-mail kan ikke findes",
    rememberLoginInfo: "Husk login",
  },

  dashboardPage: {
    topbar: {
      lecture: "lektion",
      test: "test",
      search: "søg",
      sort: {
        sortPlaceholder: "Sorter indhold",
        byDate: "Sorter efter dato",
        byType: "Sorter efter type",
        byCategory: "Sorter efter kategori",
        byReleaseType: "Sorter efter udgivelsestype",
      },
    },
    table: {
      type: {
        lesson: "lektion",
        test: "test",
      },
      category: "kategori",
      created: "oprettet",
      status: {
        unpublished: "ikke udgivet",
        published: "udgivet",
        offline: "offline",
      },
      edit: "rediger",
      details: "detaljer",
    },
  },
  lessonPage: {
    of: "af",
    initialPage: {
      scanQR: "Scan QR koden for at logge ind!",
      welcomeTo: "Du har valgt lektionen: ",
      continue: "fortsæt",
      skip: "skip",
    },
  },
  createLessonPage: {
    slide: "Slide",
    addTag: "tilføj tag",
    movePicture: "flyt billede",
    withPicture: "med billede",
    addSubject: "indsæt emne",
    fromPicture: "fra billede",
    insert: "indsæt",
    page: "side",
    uploadMP3: "Upload MP3 fil",
    notePlaceholder: "Note",
    save: "Gem",
    finish: "Udgiv",
    newSlide: {
      chooseWhereToGet: "Vælg hvor du vil have indholdet fra",
      noneChosen: "Intet valgt",
      teoriundervisning: "Teoriundervisning",
      ownMaterial: "Eget materiale",
      link: "Link",
    },
  },
  studentPage: {
    topbar: {
      actions: {
        placeholder: "Vælg handling",
        gatheredStats: "Samlet statistik",
        archiveChosen: "Arkiver valgte",
        resendPassword: "Gensend adgangskode (SMS)",
        category: "Kategori",
        yourInfo: "Dine info",
        usefulInfo: "Nyttige info",
      },
      searchPlaceholder: "søg",
      sort: {
        placeholder: "Sorter indhold",
        newest: "Nyeste først",
        alphabetical: "Alfabetisk",
        teamStart: "Efter holdstart dato",
        activeCodes: "Kun med aktive adgangskoder",
        archivednExpiredCodes: "Kun arkiverede / udløbne",
        noCodes: "Kun elever uden adgangskoder",
        byPercentage: "Sorter efter %",
      },
    },
    table: {
      category: "kategori",
      mobileNumber: "mob.nr",
      password: "adgangskode",
      expiry: "udløb",
      stats: "statistik",
      archive: "arkiver",
      removeAccess: "fjern adgang",
      addAccess: "tilføj adgang",
      extendedStats: {
        theseSlidesAreMarked: "Eleven har markeret disse teoriprøve billeder",
        latestActivity: "Seneste aktivitet",
      },
    },
  },
  teacherQuizPage: {
    quizSetup: {
      noOfTeoriSlides: "Antal teoriprøve billeder",
      speed: {
        header: "Hastighed",
        slow: "Langsom",
        normal: "Normal",
        fast: "Hurtig",
      },
      languageSettings: {
        header: "Sprogindstillinger",
        none: "Ingen undertekster",
        arabic: "Arabiske undertekster",
        danish: "Danske undertekster",
        english: "Engelske undertekster",
      },
      otherSetting: {
        header: "Andre indstillinger",
        includeFromTeoriDK: "Inkluder teoritests fra Teoriundervisning.dk",
        saveForLater: "Gem testen til senere brug",
        nameOfTestPlaceholder: "Testens navn",
      },
      strengthTest: "styrkeprøve",
      strengthTestDescription:
        "Styrkeprøve-knap: Klik på AI-knappen og test eleverne i de emner som de har sværest ved! Vi har indsamlet data fra alle elever, og analyseret os frem til deres svagheder. AI testen er en skræddersyet test til de elever som er logget ind lige nu! (inkluderer alle emner, og viser kun billeder eleverne ikke kan se hjemme, indeholder 10 billeder)",
      startTest: "start test",
      close: "afslut",
    },
    quizQRGather: {
      teoriTestStarting: "Teoritesten start om lidt",
      scanIfNotLoggedIn: "Scan hvis du ikke er logget ind",
      openYourPhone: "Åben din mobiltelefon",
    },
    quizMain: {
      left: "Venstre",
      right: "Højre",
    },
    quizResult: {
      studentList: {
        chooseAll: "Vælg alle",
      },
      picture: "Billede"
    },
  },
  studentLoginPage: {
    mobileNumberPlaceholder: "Indtast dit mobilnummer",
    backButton: "tilbage",
    continueButton: "videre",
    expiredCode: "Din adgang er desværre udløbet",
    extend: "forlæng",
    Initial: {
      loginButton: "login",
      errorMessage: "Forkert PINkode eller mobilnummer",
      loginSuccess: "Vent et øjeblik....",
      phonenumberLabel: "Mobilnummer",
      passwordLabel: "PIN kode",
      registerDescription: "Hvis du ikke har en bruger kan du oprette den ved at klikke her:",
      forgot: "Glemt PIN",
      haveAccessCode: "Brug aktiveringskode"
    },
    FirstLogin: {
      firstTimeVisiting: "Er det første gang du er på det nye Teoriundervisning.dk?",
      havePasscode: "Har du en aktiveringskode fra et hæfte / en teoribog?",
    },
    Confirmation: {
      sendConfirmationCode: "Send bekræftelseskode",
      weSentASMS: "Vi har sendt dig en SMS med bekræftelseskoden",
      errorMessage: "Bekræftelseskoden matcher ikke den vi har sendt dig",
    },
    AccessCode: {
      typeCodePlaceholder: "Indtast din aktiveringskode",
      errorMessages: {
        success: "Aktiveringskode er tilføjet!",
        expired: "Aktiveringskode er udløbet",
        notFound: "Aktiveringskode eksisterer ikke",
        alreadyUsed: "Aktiveringskode er allerede blevet brugt",
        mobileUsed: "Mobilnummeret er allerede aktivt",
        alreadyExisting: "Dit nummer eksisterede allerede så vi har forlænget din adgang!"
      },
    },
    CreatePIN: {
      createPIN: "opret pinkode",
      repeatPIN: "gentag pinkode",
      errorMessage: "Pinkoderne er ikke ens",
      setPIN: "Bekræft pinkode"
    },
    InformationSide: {
      header: "ELEV LOGIN",
      Initial: {
        underHeader: "LOGIN MED PINKODE",
        underHeaderExplainer:
          "Hvis du ikke har en PIN kode, så klik på Opret dig her",
        buttonText: "Opret dig her",
      },
      FirstLogin: {
        underHeader: "FØRSTE GANG",
        underHeaderExplainer: "Besvar spørsmålene til højre, og bliv oprettet i systemet",
      },
      Confirmation: {
        underHeader: "TILKNYT DIT MOBILNUMMER",
        underHeaderExplainer:
          "Vi skal bekræfte dit mobilnummer før du bliver oprettet. Dit mobilnummer vil blive brugt som dit login.",
      },
      AccessCode: {
        underHeader: "AKTIVERINGSKODE",
        underHeaderExplainer:
          "Indtast din aktiverings- eller adgangskode. Når du klikker på “Videre”, så behøver du fremadrettet kun at benytte dit mobil nummer og pin for at logge ind. ",
      },
      CreatePIN: {
        underHeader: "OPRET PINKODE",
        underHeaderExplainer: "Opret venligst en personlig pinkode til din brugerkonto",
      },
    },
  },
  studentSettings: {
    yourInfo: "Dine konto informationer",
    description:
      "For at din køreskole kan følge og vejlede dig bedst muligt, så er det vigtigt at du indtaster alle oplysninger korrekt. Din køreskole har adgang til din aktivitet på siden samt dine resultater. Det er vigtigt at ALLE nedenstående felter udfyldes.",
    inputLabels: {
      firstName: "Fornavn",
      lastName: "Efternavn",
      email: "E-mail",
      mobileNr: "Mobil nummer",
    },
    yourDrivingSchool: "Din køreskole",
    languageSelect: {
      headline: "Sprog",
      danish: "Dansk",
      english: "English",
    },
    categorySelect: {
      headline: "Kategori",
      car: "Bil",
      motorcycle: "Motorcykel",
    },
    saveSettings: "Gem oplysninger",
    iOSAlertText: "Vi gør opmærksom på, at Apple har valgt at blokere for automatisk afspilning af lyd på IOS (iPhone/iPad). Det er noget vi desværre ikke har 100% magt over, men vi har taget højde for det i denne test. Vi kan dog ikke garantere, at der ikke kan forekomme lyd-fejl i løbet af testen",
    iOSCheckbox: "Digital Teoriprøve (+iPhone/iPad)",
    iOSExtended: "(Se vejledning til Mac her)",
    continue: "Videre",
  },
  studentDashboardPage: {
    profileSettings: "Konto",
    header: "VEJEN TIL KØREKORTET",
    headerSpan:
      "Løs opgaverne her på siden, og kom et stort skridt tættere på kørekortet!",
    seeYourErrors: "Fejl i ",
    errorShorthand: "Resultat", //CHANGED AS WE AGREED
    trafficLightBox: {
      seeCalc: "Se beregning",
      information: "Kun evaluerende prøver i Standard Mode tæller med i resultatet",
      explanation: "Beregningen af dit resultat foretages udelukkende fra prøve-formen Standard Mode. Dvs. tager du test i Learning Mode, så tæller de ikke med i dit trafiklys. Trafiklyset beregner dit resultat ud fra de sidste 20 dage. Du kan derfor godt opleve at dit resultat automatisk bliver dårligere, hvis du ikke har øvet i et stykke tid.",
      yourLastResult: "Du har bestået ",
      outofTen: "ud af de sidste 10 test",
      notReady: {
        header: "IKKE KLAR",
        span: "Rødt lys betyder, at du har bestået mindre end 3 evaluerende tests ud af de sidste 10.",
      },
      onTheWay: {
        header: "GODT PÅ VEJ",
        span: "Gult lys betyder, at du har bestået mellem 3 og 7 evaluerende tests ud af de sidste 10.",
      },
      Ready: {
        header: "DU ER KLAR TIL AT BESTÅ.",
        span: "Grønt lys betyder, at du har bestået 8 eller flere evaluerende tests ud af de sidste 10.",
      },
    },
    InfoBox: {
      header: "INFORMATIONER TIL DIG",
      yourCalendar: "Din kalender (Drivi)",
      welcome: "Velkommen til det nye Teoriundervisning.dk!",
      betaText: "For at bestå den officielle teoriprøve, så anbefaler vi at du får grønt trafiklys under de Evaluerende prøver. Vi ønsker dig held og lykke med teoriprøven!",
      ratingOfDrivingSchool: {
        header: "Vurdering af din køreskole:",
        teoriundervisning: "Teoriundervisningen",
        driving: "Kørsel",
        totalExperience: "Samlet indtryk",
      },
      important: "VIGTIGT!",
      youHave: "Du har ",
      daysAnd: " dage og ",
      hoursLeft: " timer tilbage af din adgang",
      extendAccess: "Forlæng din adgang her"
    },
    startContentBox: {
      header: "KOM GODT FRA START!",
      description: "Gennemfør denne rubrik før du går i gang med teoriprøverne.",
    },
    generalContentBox: {
      header: "ÉT EMNE AD GANGEN",
      description:
        "Når du tager en emneprøve, så kan du øve i dit eget tempo. Det er en god måde at komme i gang med teoriprøverne på!",
    },
    evalTestsContentBox: {
      header: "EVALUERENDE TEORITESTS",
      description:
        "De evaluerende tests er en simulering af de tests du vil møde til den rigtige teoriprøve. For at få grønt lys (øverst), så skal du bestå 8 ud af de sidste 10 evaluerende teoritests.",
      button: "RANDOM TEORITEST",
    },
    strengthTestContentBox: {
      header: "STYRKEPRØVEN",
      description:
        "Styrkeprøven tester dig i alle de billeder, som du tidligere har besvaret forkert. Med Styrkeprøven sætter du turbo på din indlæring!",
      button: "START STYRKEPRØVE",
      noErrors: "Alt bestået!"
    },
    onlineBookContentBox: {
      header: "ONLINE TEORIBOG",
      description:
        "Læs dig gennem hele pensum for køreteori. Brug bogens QR koder til at få oplæst bogen, se illustrationer og video.",
      button: "BESTIL FYSISK BOG",
      buttonDescription:
        "Den fysiske teoribog indeholder det samme som vores online teoribog. Bogen er til dig, som foretrækker, at have en fysisk bog fremfor online.",
    },
    drivingSchoolLessonsContentBox: {
      header: "DIN KØRESKOLES LEKTIONER",
      description:
        "Din køreskole har udgivet disse lektioner til dig! Spørg din kørelærer hvis du har spørgsmål til indholdet.",
    },
    beforeDrivingTestContentBox: {
      header: "FØR KØREPRØVEN",
      description:
        "Forbered dig på køreprøven ved, at gennemføre disse punkter:",
    },
    practicalInfoBox: {
      header: "PRAKTISKE INFO",
      authorities: {
        header: "Myndigheder",
        pricesNfee: "Priser og gebyrer i forbindelse med kørekort",
        infoAboutKlippekort: "Information om klip i kørekortet.",
      },
      aboutTeori: {
        header: "Om Teoriundervisning.dk",
        howToProlong: "Sådan forlænger du din adgang",
        problemWithTests: "Hvis du har problemer med afvikling af tests",
      },
      drivingSchoolCourse: {
        header: "Køreskoleforløbet",
        whenSmoothTrack: "Når du skal på glatbane",
        documentsTeoriAndTest:
          "Dokumenter i forbindelse med teori- og køreprøve",
      },
      yourOpinion: {
        header: "Hvad synes du?",
        areYouSatisfied: "Er du tilfreds med Teoriundervisning.dk?",
        yes: "Ja, det er jeg :)",
        no: "Nej, jeg synes det kunne blive bedre",
      },
    },
  },
  studentQuizPage: {
    Setup: {
      freeQuizFreeTest: "Gratis test",
      smallHeader: "Prøve opsætning",
      setupOfTest: "Opsætning af prøven",
      learningMode: "Learning Mode",
      learningModeDescription:
        "Her kan du øve i dit eget tempo, og inluderer hjælpetekster. Du får de rigtige svar efter gennemførelse af hvert billede. Tæller ikke med i din statistik / trafiklys.",
      standardMode: "Standard Mode",
      standardModeDescription:
        "Her øver du dig i samme tempo som til den officielle prøve, og uden hjælpetekster. Du får dit resultat efter at have gennemgået 25 billeder.",
      chooseTestSound: "Oplæsning",
      chooseTestSubs: "Undertekster",
      testSubPlaceholder: "Ingen undertekst",
      startTest: "START PRØVEN",
      iOSExplainer: "Teoriprøven afvikles med en tidsbegrænsning på 25 minutter, som den nye digitale prøve. Du har mulighed for at klikke frem og tilbage og ændre dine svar indenfor denne tid.",
      languages: {
        none: "Ingen",
        danish: "Dansk",
        english: "Engelsk",
        arabic : "Arabisk",
        faerosk: "Færøsk",
        polish: "Polsk",
        //TO BE EXTENDED
      }
    },
    Quiz: {
      yes: "JA",
      no: "NEJ",
      errorPage: {
        somethingwentWrong: "der gik noget galt",
        tryagain: "prøv igen",
        returntodashboard: "tilbage til forsiden",
      },
      loadingText: "Generer teoritest",
      soundError:{
        important: "VIGTIGT",
        description: "Vi har brug for din tilladelse til at afspille noget på din enhed",
      }
    },
    End: {
      purchaseAccess: "KØB ADGANG",
      dontLikeImage:
        "Hvis du er uenig i billedets svar eller du har behov for en forklaring, så klik på stjerne-ikonet, og så kan din køreskole se det. Det betyder, at I sammen kan gennemgå billedet. Gælder kun køreskoler med Teoriundervisning.dk undervisningssystem.",
      dontLikeImageShorthand: "Gennemgå på køreskolen",
      soundExplained:
        "Dette billede har en ekstra uddybende forklaring i lyd.",
      soundExplainedShorthand: "Ekstra forklaring",
      youpassed: "Bestået",
      youfailed: "Ikke bestået",
      youhad: "Du besvarede",
      imagesPassed: "billeder korrekt",
    },
  },
  teacherStudentPage: {
    stats: {
      description:
        "Nedenstående resultater er baseret på klassens resultater siden i loggede ind første gang",
      progress: "klassens fremgang",
      average: "landsgennemsnit",
    },
    placholder: {
      youAreLoggedIn: "Du er logget ind!",
      feedback: "Venter på din kørelærer ...",
    },
    graduationPage: {
      correct: "korrekt",
      outOf: "ud af",
      youHad: "Du besvarede",
      amountOfCorrect: "billeder korrekt",
      failed: "Du bestod desværre ikke",
      passed: "Tilykke, du bestod!",
    },
  },
};
