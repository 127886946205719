import React from 'react';
import { ILessonPageProps } from '../LessonPage';

export type ILessonPageImageProps = {
    img: string;
}

const LessonPageImage: React.FC<ILessonPageImageProps> = (props:ILessonPageImageProps) => {
    return (
        <div className="lessonPageMedia">
            <img src={props.img}></img>
        </div>
    );
}

export { LessonPageImage };