import React from "react";
import { BaseEnum } from "./utilTypes";

export const checkIfStringContains = (text: string, query: string): boolean => {
	if (text) {
		return text.toLowerCase().includes(query.toLowerCase());
	} else {
		return false;
	}
};

export const getEnumAsArray = (enumType: BaseEnum): string[] => {
	return Object.keys(enumType);
};

export const getIntegerValuesFromEnum = (enumType: BaseEnum): number[] => {
	return Object.keys(enumType)
		.filter((item) => !isNaN(parseInt(item)))
		.map((item) => parseInt(item));
};

export const getNumberOrEmptyStringFromEventTarget = (eventTarget: EventTarget & HTMLInputElement): number | string => {
	return isNaN(eventTarget.valueAsNumber) ? eventTarget.value : eventTarget.valueAsNumber;
};

export const parseHtmlSelectValueToEnum = (eventTarget: EventTarget & HTMLSelectElement): number => {
	return parseInt(eventTarget.value);
};

export const fillArrayWithEmptyStrings = (numberOfItemsInArray: number): string[] => {
	const inputValues: string[] = [];
	for (let i = 0; i < numberOfItemsInArray; i++) {
		inputValues.push("");
	}

	return inputValues;
};

export const getMergedStringsFromArray = (arrayToConvert: string[]): string => {
	return arrayToConvert.toString().split(",").join("");
};

export function getNextSiblingsFromRefObject<T1 extends Element, T2 extends Element>(
	refObject: React.RefObject<T1>,
): T2 {
	return refObject.current?.nextSibling as T2;
}

export function getPreviousSiblingsFromRefObject<T1 extends Element, T2 extends Element>(
	refObject: React.RefObject<T1>,
): T2 {
	return refObject.current?.previousSibling as T2;
}

export function getParentFromRefObject<T1 extends Element, T2 extends Element>(refObject: React.RefObject<T1>): T2 {
	return refObject.current?.parentElement as unknown as T2;
}

export function clearJsxElementArray<T1 extends JSX.Element[]>(array: T1): void {
	array.splice(0, array.length);
}

export function convertNulltoZero(inputValue: any) {
	const conversion = Number(inputValue);
	if (conversion === null) {
		return 0;
	} else if (inputValue === "-") {
		return 0;
	} else {
		return inputValue;
	}
}

export function isEndUser(pathString: string): boolean {
	if (pathString.includes("/easypiecylink/")) {
		return true;
	} else {
		return false;
	}
}

export function convertStringToValue(string: any): any {
	if (string == "" || string == " ") {
		return -1;
	} else {
		return Number(string);
	}
}
export function convertValueToString(value: any): any {
	if (value === -1) {
		return "";
	} else {
		return value;
	}
}