import React, { createContext, ReactNode, useEffect, useState } from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  fromPromise,
  HttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { fetchAccessToken } from "./apolloProviderOperations";
import JwtDecode, { JwtPayload } from "jwt-decode";
import { onError } from "@apollo/link-error";
import { APOLLO_TEACHER_URL } from "../constants/APIConstants";
import { io, Socket } from "socket.io-client";
import { makeid } from "../pages/modelFunctions";
import { createRoom, setUsername } from "../pages/socketFunction";
import { LessonSlide } from "../pages/model";

interface AppState {
  appState: {
    loggedIn: boolean;
  };
  popupState: boolean;
  popupSlide: LessonSlide | null;
  teacherSocket: {
    socket: Socket | undefined;
    room: string;
  };
  gqlError: {
    msg: string;
  };
  appSetLogin: (token: string, token2: string) => void;
  appSetLogout: () => void;
  appSetTokens: (token: string, token2: string) => void;
  appClearAccessToken: () => void;
  appGetUserID: () => string;
  appGetUserRole: () => string;
  appGetRevenueStats: () => any;
  appGetCurrentCatIDs: () => any;
  startSocket: () => void;
  openPopup: () => void;
  closePopup: () => void;
  setPopupSlide: (slide: LessonSlide | null) => void;
}
let accessToken = "";
let refreshToken = "";
let userID = "";
let userRole = "";
let popupSlide: LessonSlide; 

const initialAppState: AppState = {
  appState: { loggedIn: false },
  popupState: false,
  popupSlide: null,
  teacherSocket: {
    socket: undefined,
    room: " ",
  },
  gqlError: { msg: "" },
  appSetLogin: (token: string, token2: string) => {},
  appSetLogout: () => {},
  appSetTokens: (token: string) => {},
  appClearAccessToken: () => {},
  appGetUserID: () => {
    return userID;
  },
  appGetUserRole: () => {
    return userRole;
  },
  appGetRevenueStats: () => {},
  appGetCurrentCatIDs: () => [],
  startSocket: () => {},
  openPopup: () => {},
  closePopup: () => {},
  setPopupSlide: (slide: LessonSlide | null) => {}
};

export const AppStateContext = createContext<AppState>(initialAppState);

export interface AppStateProviderProps {
  children: ReactNode;
}

function AppStateProvider(props: AppStateProviderProps): JSX.Element {
  const [appState, setAppState] = useState({ loggedIn: false });
  const [teacherSocket, setteacherSocket] = useState<AppState["teacherSocket"]>(
    { socket: undefined, room: " " }
  );
  const [popupState, setpopupState] = useState(false);
  const [gqlError, setGQLError] = useState({ msg: "" });

  const appSetLogin = (token: string, token2: string) => {
    accessToken = token;
    refreshToken = token2;
    localStorage.setItem("refreshTokenT", refreshToken);
    setAppState({ ...appState, loggedIn: true });
  };

  const appSetLogout = async () => {
    await client.resetStore();
    localStorage.removeItem("apollo-cache-persist");
    caches.keys().then(function (names) {
      for (let name of names) {

        caches.delete(name);
      }
    });
    localStorage.setItem("refreshTokenT", "");
    accessToken = "";
    setAppState({ ...appState, loggedIn: false });
  };

  const appSetTokens = (token: string, token2: string) => {
    accessToken = token;
    refreshToken = token2;
  };
  const appClearAccessToken = () => {
    accessToken = "";
  };
  const appGetAccessToken = (): string => {
    return accessToken;
  };
  const appGetUserID = (): string => {
    const decodedToken: any = JwtDecode(appGetAccessToken());

    userID = decodedToken.token_data.user_id;
    return userID;
  };

  const appGetUserRole = (): string => {
    const decodedToken: any = JwtDecode(appGetAccessToken());

    userRole = decodedToken.token_data.role_id;
    return userRole;
  };

  const appGetRevenueStats = () => {
    const decodedToken: any = JwtDecode(appGetAccessToken());

    return {currentRevenue: decodedToken.token_data.currentRevenue, adviseType: decodedToken.token_data.adviseType}
  }
  
  const appGetCurrentCatIDs = () => {
    const decodedToken: any = JwtDecode(appGetAccessToken());
    const IDS = decodedToken.token_data.currentCategories.map((cat) => {
      return cat.cat_id;
    })
    return IDS;
  }

  const startSocket = async () => {
    
    const decodedToken: any = JwtDecode(appGetAccessToken());
    
    if(!teacherSocket.socket){
      const socket = io(
        "https://tr-appsrvc-classroom-dev-azwe.azurewebsites.net/",
        {
          transports: ["polling", "websocket"],
        }
      );
      socket.on("connect_error", (err) => {
      });
      // socket.on("connect", () => {

      // });
      setUsername(socket, {
        id: decodedToken.token_data.user_id,
        name: decodedToken.token_data.email_id,
        role: decodedToken.token_data.role_id,
      });
      createRoom(socket, decodedToken.token_data.user_id);
      setteacherSocket({
        socket: socket,
        room: decodedToken.token_data.user_id,
      });
    }else{ 
    setUsername(teacherSocket.socket, {
      id: decodedToken.token_data.user_id,
      name: decodedToken.token_data.email_id,
      role: decodedToken.token_data.role_id,
    });
    createRoom(teacherSocket.socket, decodedToken.token_data.user_id);
  }
  };

  const openPopup = () => {
    
    setpopupState(true);
  }

  const closePopup = () => {
    setpopupState(false);
  }

  const setPopupSlide = (slide: LessonSlide | null) => {
    if(slide){
      const objectToSend: LessonSlide = {media: slide.media, type: slide.type, id: slide.id, content_id: slide.content_id, notes: slide.notes}
      popupSlide = objectToSend;
      console.log(popupSlide);
      
    }

  }

  useEffect(() => {
    if(popupState){
    }
    
  }, [popupState]);
  // apollo client
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getStudentActivity: {
               // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: ["student_id"],
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },

          getTeoriContent: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
          getOwnedContent: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  });
  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle: any;
        Promise.resolve(operation)
          .then((operation) => {
            operation.setContext({
              headers: {
                authorization: accessToken ? appGetAccessToken() : null,
              },
            });
          })
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));
        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      })
  );

  const tokenRefreshLink: any = new TokenRefreshLink<{
    accessToken;
    refreshToken;
  }>({
    accessTokenField: "RefreshUser",
    isTokenValidOrUndefined: () => {
      const token = appGetAccessToken();
      if (token?.length === 0) {
        return true;
      }

      try {
        //TODO Find a better solution to this
        const interMediaryFix: JwtPayload = JwtDecode(token);
        const exp = interMediaryFix?.exp;

        if (exp === undefined) {
          return false;
        }

        return Date.now() < exp * 1000;
      } catch {
        return false;
      }
    },
    fetchAccessToken: () => fetchAccessToken(APOLLO_TEACHER_URL, true),
    handleFetch: (newTokens) => {
      localStorage.setItem("refreshTokenT", newTokens.refreshToken);
      localStorage.setItem("accessTokenT", newTokens.accessToken);
      appSetTokens(newTokens.accessToken, newTokens.refreshToken);
    },
    handleResponse: (operation: any, accessToken) => async (response) => {
      const newTokens = {
        accessToken: await response.accessToken,
        refreshToken: await response.refreshToken,
      };
      return response;
    },
    handleError: (err) => {
      appSetLogout();
    },
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      tokenRefreshLink,
      onError(({ graphQLErrors, networkError }) => {
        if (
          graphQLErrors === undefined ||
          graphQLErrors[0].path === undefined
        ) {

          return;
        }
        if (graphQLErrors[0].path[0] === "refresh") {

          return;
        }
        const err = graphQLErrors[0].message;
        setGQLError({ msg: err });
      }),
      requestLink,
      new HttpLink({
        uri: APOLLO_TEACHER_URL,
      }),
    ]),
    cache,
  });
  

  return (
    <AppStateContext.Provider
      value={{
        appState,
        popupState,
        popupSlide,
        teacherSocket,
        gqlError,
        appSetLogin,
        appSetLogout,
        appSetTokens,
        appClearAccessToken,
        appGetUserID,
        appGetUserRole,
        appGetRevenueStats,
        appGetCurrentCatIDs,
        startSocket,
        openPopup,
        closePopup,
        setPopupSlide
      }}
    >
      <ApolloProvider client={client}>{props.children}</ApolloProvider>
    </AppStateContext.Provider>
  );
}

export default AppStateProvider;
