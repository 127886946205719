import React, { useState } from "react";
import {
  getChosenLanguage,
  getStringsForChosenLanguage,
} from "../../../../translations/translationProvider";
import arrowIcon from "../../../../assets/white_Arrow.png";
import { LoginStep } from "../../../model";
import { SubmitHandler, useForm } from "react-hook-form";
import { useVerifyMobileNoLazyQuery } from "../../../../graphql/generated/graphqlStudent";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { TeoriLogo } from "../../../../globalComponents/TeoriLogo";
import "../style/FirstLogin.scss";

interface Inputs {
  mobileNumber: string;
}

export type IFirstLoginProps = {
  setLoginStep: any;
  mobileNumber: string;
  setConfirmationCode: (
    value: string | ((prevState: string) => string)
  ) => void;
  setIsLoading: (value: boolean | ((prevState: boolean) => boolean)) => void;
  setisAccessCode: React.Dispatch<React.SetStateAction<boolean>>;
  setpreFix: (value: string) => void
  preFix: string;
};

const FirstLogin: React.FC<IFirstLoginProps> = (props: IFirstLoginProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm<Inputs>();

  const [isFirstTime, setisFirstTime] = useState<boolean>();
  const [hasPasscode, sethasPasscode] = useState<boolean>();

  const [getConfirmationCode, { data, loading }] = useVerifyMobileNoLazyQuery({
    onCompleted: (data) => {
      props.setConfirmationCode(
        data.verifyMobileNo.firstString + data.verifyMobileNo.secondString
      );
      props.setIsLoading(false);
      props.setLoginStep(LoginStep.Confirmation);
    },
    nextFetchPolicy: "network-only",
  });

  const handleIsFirstTime = (event, newValue) => {
    if (newValue === false) {
      props.setLoginStep(LoginStep.Initial);
      localStorage.setItem("TEORIFIRSTTIME", "NO");
    }
    setisFirstTime(newValue);
  };
  const handleHasPasscode = (event, newValue) => {
    if(newValue === false){
      props.setLoginStep(LoginStep.Confirmation);
    }
    if(newValue === true){
      props.setLoginStep(LoginStep.ConfirmMobileAccess);
    }
    sethasPasscode(newValue);
  };

  return (
    <div className="FirstLoginPage">
      <div className="toggleGroup">
        <label>{languageString.studentLoginPage.FirstLogin.firstTimeVisiting}</label>
        <ToggleButtonGroup
          value={isFirstTime}
          exclusive
          onChange={handleIsFirstTime}
        >
          <ToggleButton value={true}>{languageString.generalMessages.yes}</ToggleButton>
          <ToggleButton value={false}>{languageString.generalMessages.no}</ToggleButton>
        </ToggleButtonGroup>
      </div>
      {isFirstTime === true ? (
        <div className="toggleGroup">
          <label>{languageString.studentLoginPage.FirstLogin.havePasscode}</label>
          <ToggleButtonGroup
            value={hasPasscode}
            exclusive
            onChange={handleHasPasscode}
          >
            <ToggleButton value={true}>{languageString.generalMessages.yes}</ToggleButton>
            <ToggleButton value={false}>{languageString.generalMessages.no}</ToggleButton>
          </ToggleButtonGroup>
        </div>
      ) : null}
    </div>
  );
};

export { FirstLogin };
