import React, { useEffect, useState } from "react";
import { EndSlide, MediaType, VideoType } from "../../../../model";
import StatementRow from "./StatementRow";
import star from "../../../../../assets/star_icon.png";
import arrow from "../../../../../assets/red_Arrow2.png";
import headphones from "../../../../../assets/headphone_icon.png";
import "./styles/Slide.scss";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../../translations/translationProvider";
import closeIcon from "../../../../../assets/red_cross.png";
import { LessonPageVideo } from "../../../../Teacher/lessonPage/components/LessonPageVideo";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { LAPTOP_VIEW_WIDTH } from "../../../../../constants/viewConstants";
import { useWindowDimensions } from "../../../../../hooks/useWindowDimensions";
import { Button } from "@material-ui/core";
import expandIcon from "../../../../../assets/expandIcon.png";
import { useMarkSlideMutation, useUnmarkSlideMutation } from "../../../../../graphql/generated/graphqlStudent";
import ChangeHistoryRoundedIcon from '@mui/icons-material/ChangeHistoryRounded';

interface SlideProps {
  key: number;
  slide: EndSlide;
  isStats?: boolean;
}

const Slide: React.FC<SlideProps> = (props: SlideProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [isMarked, setIsMarked] = useState<boolean>(props.slide.isMarked ? props.slide.isMarked : false);
  const [isEnlarged, setisEnlarged] = useState(false);

  const [markSlide] = useMarkSlideMutation({variables:{marked_slides:[String(props.slide.progressID)]}});
  const [unmarkSlide] = useUnmarkSlideMutation({variables:{unmarked_slides:[String(props.slide.progressID)]}});

  const renderStatements = (): JSX.Element[] => {
    const statementArray: JSX.Element[] = [];
    props.slide.statements.forEach((statement, index) => {
      statementArray.push(<StatementRow statement={statement} key={index} />);
    });
    return statementArray;
  };

  const goToTrafficSigns = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    window.open("https://www.teoriundervisning.dk/faerdselstavler/", "blank")
  }

  const onImageClick = () => {
    setisEnlarged(!isEnlarged);
  };

  const onMarkedClick = () => {
    if(!isMarked){
      markSlide();
    }else{
      unmarkSlide();
    }
    setIsMarked(!isMarked);
  };

  const onExplanationClick = () => {
    alert("EXPLANATION CLICK");
  };

  const { width, height } = useWindowDimensions();

  const [isLaptopView, setIsLaptopView] = useState<boolean>(
    LAPTOP_VIEW_WIDTH <= width
  );

  useEffect(() => {
    setIsLaptopView(LAPTOP_VIEW_WIDTH <= width);
  }, [width, height]);

  const zoomAbleSlide = () => {
    return (
      <div className="zoomableImage" onClick={() => onImageClick()}>
        <TransformWrapper
          initialScale={isLaptopView ? 1.2 : 0.5}
          minScale={0.3}
          centerZoomedOut={true}
          maxScale={2}
          centerOnInit
        >
          <TransformComponent wrapperClass="zoomContainer">
            {props.slide.mediaType === MediaType.AZUREIMG ? (
              <img alt="SLIDE-IMG" src={props.slide.media}></img>
            ) : (
              <div className="SLIDE-VID">
                <LessonPageVideo
                  key={props.slide.media}
                  video={props.slide.media}
                  videoType={VideoType.STREAM}
                />
              </div>
            )}
          </TransformComponent>
        </TransformWrapper>
      </div>
    );
  };

  return (
    <>
      {isEnlarged ? zoomAbleSlide() : null}
      {props.slide ? (
        <>
          <div className={props.isStats ? "Slide-expanded" : "Slide"}>
            <div className="imageAndMarkers">
              {props.slide.mediaType === MediaType.AZUREIMG ? (
                <div className="image" onClick={() => onImageClick()}>
                  <Button onClick={event => goToTrafficSigns(event)} className="trafficSign"><ChangeHistoryRoundedIcon/></Button>
                  <img alt="SLIDE-IMG" src={props.slide.media}></img>
                  <Button
                    className="expandButton"
                    onClick={() => onImageClick()}
                  >
                    <img src={expandIcon}></img>
                  </Button>
                </div>
              ) : (
                <div className="SLIDE-VID">
                  <LessonPageVideo
                    key={props.slide.media}
                    video={props.slide.media}
                    videoType={VideoType.STREAM}
                  />
                  <Button className="expandButton" onClick={() => onImageClick()}>
                    <img src={expandIcon}></img>
                  </Button>
                </div>
              )}
              <div className="icons">
                {props.slide.explanation ? (
                  <div>
                    <button onClick={onExplanationClick}>
                      <img src={headphones} alt="HEADPHONES-ICON"></img>
                    </button>
                    <label>
                      <h3>
                        {" "}
                        {isLaptopView
                          ? languageString.studentQuizPage.End
                              .soundExplainedShorthand
                          : null}
                      </h3>
                      {isLaptopView
                        ? languageString.studentQuizPage.End.soundExplained
                        : null}
                    </label>
                  </div>
                ) : null}
                <div>
                  <button
                    onClick={onMarkedClick}
                    className={isMarked ? "marked" : "unmarked"}
                  >
                    <img src={star} alt="STAR-ICON"></img>
                  </button>
                  <label>
                    <h3>
                      {isLaptopView
                        ? languageString.studentQuizPage.End
                            .dontLikeImageShorthand
                        : null}
                    </h3>
                    {isLaptopView
                      ? languageString.studentQuizPage.End.dontLikeImage
                      : languageString.studentQuizPage.End
                          .dontLikeImageShorthand}
                  </label>
                </div>
              </div>
            </div>
            <div className={"statementUI"}>
              <div className="rows">
                <div className="introQuestion">{props.slide.introQuestion}</div>
                <hr></hr>
                {renderStatements()}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>{"NO SLIDE SELECTED"}</div>
      )}
    </>
  );
};

export default Slide;
