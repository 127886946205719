import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getChosenLanguage,
  getStringsForChosenLanguage,
} from "../../../../translations/translationProvider";
import {
  MediaType,
  QuizState,
  StudentQuiz,
  StudentQuizSlide,
  StudentQuizStatement,
} from "../../../model";
import { Main } from "./components/Main";

import shortGiggle from "../../../../assets/short_giggle.mp3";
import shortFlute from "../../../../assets/short_flute.mp3";
import shortMedieval from "../../../../assets/short_medieval.mp3";
import randomImage from "../../../../assets/testImage.jpg";
import Loading from "../../../../globalComponents/Loading";
import {
  Online_Quiz,
  useCreateFreeQuizMutation,
  useCreateQuizMutation,
  useStartContentMutation,
} from "../../../../graphql/generated/graphqlStudent";
import { numberToMediaType } from "../../../modelFunctions";
import { QuizError } from "./components/QuizError";
import "./Quiz.scss";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import { Button } from "@material-ui/core";

interface locationProps {
  isLearningMode: boolean;
  hasSubtitles: boolean;
  randomEvaluation: boolean;
  quizType?: number;
  tagString?: string;
  slideString?: string;
  shouldRefetchByOnlineID?: boolean;
  isStrengthTest?: boolean;
}

export type IQuizProps = {
  setQuizState: React.Dispatch<React.SetStateAction<QuizState>>;
  isLearningMode: boolean;
  isNoSound: boolean;
  languageID: number;
  subtitlesID: number;
  manageAchievement: any;
  id: number;
  setonlineID: React.Dispatch<React.SetStateAction<string | undefined>>;
  testName: string;
  isStudentTeacher?: boolean;
  studentTeacherID?: string;
  studentTeacherToken?: string;
  isFreeQuiz?: boolean;
  isDigital?: boolean;
};

const Quiz: React.FC<IQuizProps> = (props: IQuizProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  const location = useLocation<locationProps>();
  const [quizState, setquizState] = useState<StudentQuiz>();
  const [hasSubtitles, sethasSubtitles] = useState(
    localStorage.getItem("studentTestChosenSubs") !== "0" ? true : false
  );
  const [isError, setisError] = useState(false);
  const [shouldRefresh, setshouldRefresh] = useState(false);

  const refresh = () => {
    setshouldRefresh(!shouldRefresh);
    setisError(false);
  };

  const decideQuizType = () => {
    if (location.state.shouldRefetchByOnlineID) {
      return "-1";
    } else {
      return String(props.id);
    }
  };

  const convertStringToTagIDs = (stringToSeparate: string) => {
    const array = stringToSeparate.split(",");
    const newarray = array.map((tag) => {
      return { tag_id: tag.trim() };
    });
    return newarray;
  };
  const convertStringToSlideIDs = (stringToSeparate: string) => {
    const array = stringToSeparate.split(",");
    const newarray = array.map((slide, index) => {
      return { quiz_slide_id: slide.trim() };
    });
    return newarray;
  };

  /* THIS IS USED IF THE QUIZ IS A FREE QUIZ */
  const [createFreeQuiz] = useCreateFreeQuizMutation({
    variables: {
      online_quiz: {
        quiz_speed: "2",
        quiz_subtitles:
          String(props.subtitlesID) === "0"
            ? String(props.languageID)
            : String(props.subtitlesID),
        quiz_language: String(props.languageID),
        quiz_read_only: false,
        slide_amount: 25,
        quiz_learning_mode: props.isLearningMode ? true : undefined,
      },
    },
  });

  /* ###########CRITICAL################# */
  /* WE CREATE THE QUIZ WITH THIS MUTATION */
  /* BASE-QUIZ ID IS THE DECIDER FOR WHICH KIND OF QUIZ. */
  /* -1 FOR TAGS, 0 FOR RANDOM EVALUATION QUIZ, NORMAL FLOW -> TAKES THE QUIZ CONTENT FROM THE ORIGINAL QUIZ*/
  const [createQuiz] = useCreateQuizMutation({
    variables: {
      online_quiz: {
        quiz_test_name: location.state
          ? location.state.quizType === 10
            ? "Evaluerende slide test"
            : undefined
          : undefined,
        base_quiz: location.state
          ? location.state.randomEvaluation
            ? undefined
            : decideQuizType()
          : undefined,
        quiz_speed: "2",
        quiz_subtitles:
          String(props.subtitlesID) === "0"
            ? String(props.languageID)
            : String(props.subtitlesID),
        quiz_language: String(props.languageID),
        quiz_read_only: false,
        slide_amount: 25,
        quiz_strength_test: location.state
          ? location.state.isStrengthTest
            ? true
            : false
          : false,
        quiz_learning_mode: props.isLearningMode ? true : undefined,
        quiz_random_evaluation_test: location.state
          ? location.state.randomEvaluation || false
          : false,
        online_Quiz_Tags: location.state
          ? location.state.tagString
            ? convertStringToTagIDs(location.state.tagString)
            : undefined
          : undefined,
        online_Quiz_Slides: location.state
          ? location.state.slideString
            ? convertStringToSlideIDs(location.state.slideString)
            : undefined
          : undefined,
        achievement_name: props.testName,
      },
    },
  });
  const [startContent] = useStartContentMutation();

  const setQuizStateToEnd = () => {
    props.setQuizState(QuizState.End);
  };

  //TO REMOVE THE HELP BOX
  useEffect(() => {
    const element = document.getElementById("LeadboosterContainer");
    if (element) {
      element.remove();
    }
  }, []);

  // function fillState() {
  //   const tempArray: StudentQuizSlide[] = [];
  //   for (let i = 1; i < 3; i++) {
  //     const tempStatements: StudentQuizStatement[] = [];
  //     for (let j = 1; j < 5; j++) {
  //       tempStatements.push({
  //         id: j,
  //         text:
  //           "This is statement " +
  //           j +
  //           " from slide " +
  //           i +
  //           " hutlithutythl aidattiafk aidattiafk hutlithutythl aidattiafk aidattiafk hutlithutythl aidattiafk aidattiafk",
  //         correctAnswer: 1,
  //         soundMedia: shortMedieval,
  //         explanation: "This is explanation" + j,
  //       });
  //     }

  //     tempArray.push({
  //       id: i,
  //       introQuestion: "This is slide " + i,
  //       introQuestionMedia: shortFlute,
  //       statements: tempStatements,
  //       media: i % 2 ? "https://vimeo.com/546047157/6b3cd844d3" : randomImage,
  //     });
  //   }
  //   setquizState({
  //     slides: tempArray,
  //     indexPlayer: new Audio(),
  //     soundPlayer: new Audio(),
  //   });
  // }
  function convertAPIToQuiz(state: Online_Quiz) {
    const tempArray: StudentQuizSlide[] = [];
    if (state.slide_progress) {
      for (const slide of state.slide_progress) {
        const tempStatements: StudentQuizStatement[] = [];
        if (slide?.statement_progress) {
          let index = 1;
          for (const statement of slide.statement_progress) {
            if (statement) {
              tempStatements.push({
                id: index,
                progressID: Number(statement.statement_progress_id),
                text: statement.sound_text ? statement.sound_text : "",
                correctAnswer: statement.statement_answer_id!,
                soundMedia: props.isNoSound ? "" : statement.sound_location
                  ? statement.sound_location
                  : "",
                explanation: statement.elaboration_Text
                  ? statement.elaboration_Text
                  : "",
              });
            }
            index++;
          }
        }
        tempArray.push({
          id: Number(slide?.progress_id),
          media: slide?.media_location!,
          isMarked: slide?.progress_is_marked!,
          introQuestion: slide?.intro_sound_text ? slide.intro_sound_text : "",
          introQuestionMedia: props.isNoSound ? "" : slide?.intro_sound_location
            ? slide.intro_sound_location
            : "",
          statements: tempStatements,
          mediaType: slide?.media_type_id
            ? numberToMediaType(Number(slide.media_type_id))!
            : MediaType.AZUREIMG,
        });
      }
    }
    return tempArray;
  }

  useEffect(() => {
    if (
      props.isStudentTeacher &&
      props.studentTeacherID &&
      props.studentTeacherID
    ) {
      startContent({
        variables: {
          quiz_token_id: props.studentTeacherToken,
          online_quiz_id: props.studentTeacherID,
        },
      }).then((data) => {
        if (data.data?.startContent) {
          const newState = convertAPIToQuiz(data.data?.startContent);
          setquizState({
            slides: newState,
            indexPlayer: new Audio(),
            soundPlayer: new Audio(),
            speed: data.data.startContent.quiz_speed_value
              ? Number(data.data.startContent.quiz_speed_value)
              : 3000,
          });
        } else {
          setisError(true);
        }
      });
    } else {
      if (props.isFreeQuiz) {
        createFreeQuiz().then((data) => {
          if (data.data) {
            localStorage.setItem(
              "STUDENT_ONLINE_QUIZ",
              data.data?.createFreeQuiz!.online_quiz_id!
            );
            localStorage.setItem(
              "STUDENT_ONLINE_QUIZ_TOKEN",
              data.data.createFreeQuiz!.quiz_token_id!
            );
            props.setonlineID(data.data.createFreeQuiz!.online_quiz_id!);
            startContent({
              variables: {
                quiz_token_id: data.data?.createFreeQuiz?.quiz_token_id,
                online_quiz_id: data.data?.createFreeQuiz?.online_quiz_id,
              },
            }).then((data) => {
              if (data.data?.startContent) {
                const newState = convertAPIToQuiz(data.data?.startContent);
                setquizState({
                  slides: newState,
                  indexPlayer: new Audio(),
                  soundPlayer: new Audio(),
                  speed: data.data.startContent.quiz_speed_value
                    ? Number(data.data.startContent.quiz_speed_value)
                    : 3000,
                });
              } else {
                setisError(true);
              }
            });
          } else {
            setisError(true);
          }
        });
      } else {
        createQuiz().then((data) => {
          if (data.data) {
            localStorage.setItem(
              "STUDENT_ONLINE_QUIZ",
              data.data?.createQuiz!.online_quiz_id!
            );
            localStorage.setItem(
              "STUDENT_ONLINE_QUIZ_TOKEN",
              data.data.createQuiz!.quiz_token_id!
            );
            props.setonlineID(data.data.createQuiz!.online_quiz_id!);
            startContent({
              variables: {
                quiz_token_id: data.data?.createQuiz?.quiz_token_id,
                online_quiz_id: data.data?.createQuiz?.online_quiz_id,
              },
            }).then((data) => {
              if (data.data?.startContent) {
                const newState = convertAPIToQuiz(data.data?.startContent);
                setquizState({
                  slides: newState,
                  indexPlayer: new Audio(),
                  soundPlayer: new Audio(),
                  speed: data.data.startContent.quiz_speed_value
                    ? Number(data.data.startContent.quiz_speed_value)
                    : 3000,
                });
              } else {
                setisError(true);
              }
            });
          } else {
            setisError(true);
          }
        });
      }
    }
    //fillState();
  }, [shouldRefresh]);

  if (quizState) {
    return (
      <>
        <div className="Main">
          <Main
            QuizState={quizState}
            setQuizState={setquizState}
            isLearningMode={props.isLearningMode}
            isNoSound={props.isDigital ? true : props.isNoSound}
            hasSubtitles={hasSubtitles}
            languageID={Number(props.languageID)}
            setToEnd={setQuizStateToEnd}
            manageAchievement={props.manageAchievement}
          />
        </div>
        <div
          className="screenRotate"
          style={{
            backgroundColor: props.isLearningMode ? "#ffffff" : "#1d1e20",
          }}
        >
          <div
            className="screenRotateBox"
            style={{
              backgroundColor: props.isLearningMode ? "#1d1e20" : "#ffffff",
            }}
          >
            <ScreenRotationIcon
              style={{ color: props.isLearningMode ? "#ffffff" : "#1d1e20" }}
            />
          </div>
        </div>
      </>
    );
  } else {
    if (isError) {
      return <QuizError refresh={refresh} />;
    } else {
      return (
        <Loading
          loadingText={languageString.studentQuizPage.Quiz.loadingText}
        />
      );
    }
  }
};

export { Quiz };
