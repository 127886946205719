import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  matchPath,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import PopupSlideProvider from "./apollo/PopupSlideProvider";
import AppStateStudentContext from "./apollo/TeoriStudentProvider";
import AppStateContext from "./apollo/TeoriTeacherProvider";
import { PATH_SKOLE } from "./constants/pathConstants";
import { A2HSComponent } from "./globalComponents/A2HSComponent";
import { LessonPageBarActualPopup } from "./pages/Teacher/lessonPage/components/LessonPageBarActualPopup";
import StudentRoutes from "./routes/StudentRoutes";
import TeacherRoutes from "./routes/TeacherRoutes";

function App() {
  return (
    <>
      <A2HSComponent />
      <BrowserRouter>
        <Switch>
          <Route
            path={PATH_SKOLE}
            render={() => (
              <AppStateContext>
                {" "}
                <PopupSlideProvider>
                  <TeacherRoutes />
                </PopupSlideProvider>
              </AppStateContext>
            )}
          />
          <Route
            path="/"
            render={() => (
              <AppStateStudentContext>
                <StudentRoutes />
              </AppStateStudentContext>
            )}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
