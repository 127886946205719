import { Button } from '@mui/material';
import React from 'react';
import { getStringsForChosenLanguage, getChosenLanguage } from '../../../../translations/translationProvider';
import "../style/AccessExpired.scss";

interface AccessExpiredProps {
    
}

const AccessExpired: React.FC<AccessExpiredProps> = () => {
    const languageString = getStringsForChosenLanguage(getChosenLanguage());


    const handleExtend = () => {
        window.open("https://www.teoriundervisning.dk/forlaeng");
    }
  
    return (
      <div className="AccessExpiredPage">
          <div></div>
          <div>{languageString.studentLoginPage.expiredCode}</div>
          <div><Button onClick={handleExtend} variant="contained">{languageString.studentLoginPage.extend}</Button></div>
      </div>
    );
};

export default AccessExpired;