import React, {
  FC,
  lazy,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Loading from "../globalComponents/Loading";
import {
  PATH_CREATE_LESSON,
  PATH_DASHBOARD,
  PATH_HOME,
  PATH_LESSON,
  PATH_LOGIN,
  PATH_QUIZ,
  PATH_SKOLE,
  PATH_STUDENTS,
} from "../constants/pathConstants";
import { AppStateContext } from "../apollo/TeoriTeacherProvider";
import { fetchAccessToken } from "../apollo/apolloProviderOperations";
import { APOLLO_TEACHER_URL } from "../constants/APIConstants";
import { CreateQuizPage } from "../pages/Teacher/createQuizPage/CreateQuizPage";
import { AdminStudentView } from "../pages/Teacher/admin/adminStudentView/AdminStudentView";
import { LessonPageBarActualPopup } from "../pages/Teacher/lessonPage/components/LessonPageBarActualPopup";
import { AdminSchoolView } from "../pages/Teacher/admin/adminSchoolView/AdminSchoolView";
import { LessonPageBarPopup } from "../pages/Teacher/lessonPage/components/LessonPageBarPopup";
import { AdminSlideSearch } from "../pages/Teacher/admin/adminSlideSearch/AdminSlideSearch";

export interface TeacherRoutesProps {}

let initialized = false;

export const TeacherRoutes: FC<TeacherRoutesProps> = () => {
  const { appState, appSetLogin, appSetLogout, appGetUserRole } =
    useContext(AppStateContext);
  const isAuthenticated = appState.loggedIn;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (initialized) return;
    console.log(initialized);
    initialized = true;
    try {
      fetchAccessToken(APOLLO_TEACHER_URL, true)
        .then((data: any) => {
          console.log(data);
          const failed =
            data === undefined || data?.RefreshUser.accessToken === undefined;
          failed
            ? appSetLogout()
            : appSetLogin(
                data?.RefreshUser.accessToken,
                data?.RefreshUser.refreshToken
              );
        })
        .catch((e) => {
          console.log(e);
          appSetLogout();
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  });
  if (loading) {
    return <Loading />;
  }

  const LoginPage = lazy(() => import("../pages/Teacher/loginPage/LoginPage"));
  const DashboardPage = lazy(
    () => import("../pages/Teacher/dashboardPage/DashboardPage")
  );
  const StudentPage = lazy(
    () => import("../pages/Teacher/studentPage/StudentPage")
  );
  const LessonPage = lazy(
    () => import("../pages/Teacher/lessonPage/LessonPage")
  );
  const CreateLessonPage = lazy(
    () => import("../pages/Teacher/createLessonPage/CreateLessonPage")
  );
  const QuizPage = lazy(() => import("../pages/Teacher/quizPage/QuizPage"));

  const routes = [
    <Route
      key="slashRedirect"
      exact
      path={PATH_HOME}
      render={() => <Redirect to={PATH_LOGIN} />}
    />,
    <Route
      key="loginPage"
      exact
      path={PATH_LOGIN}
      render={() =>
        !isAuthenticated ? <LoginPage /> : <Redirect to={PATH_DASHBOARD} />
      }
    />,
    <Route
      key="studentPage"
      exact
      path={PATH_STUDENTS}
      render={() =>
        isAuthenticated ? <StudentPage /> : <Redirect to={PATH_LOGIN} />
      }
    />,
    <Route
      key="dashboardPage"
      exact
      path={PATH_DASHBOARD}
      render={() =>
        isAuthenticated ? <DashboardPage /> : <Redirect to={PATH_LOGIN} />
      }
    />,
    <Route
      key="lessonPage"
      exact
      path={PATH_LESSON + "/:id([0-9]+)"}
      render={() =>
        isAuthenticated ? <LessonPage /> : <Redirect to={PATH_LOGIN} />
      }
    />,
    <Route
      key="createLessonPage"
      exact
      path={PATH_CREATE_LESSON}
      render={() =>
        isAuthenticated ? <CreateLessonPage /> : <Redirect to={PATH_LOGIN} />
      }
    />,
    <Route
      key="quizPage"
      exact
      path={PATH_QUIZ + "/:id([0-9]+)"}
      render={() =>
        isAuthenticated ? <QuizPage /> : <Redirect to={PATH_LOGIN} />
      }
    />,
    <Route
      key="createQuizPage"
      exact
      path={"/TOPSECRET"}
      render={() =>
        isAuthenticated ? <CreateQuizPage /> : <Redirect to={PATH_LOGIN} />
      }
    />,
    //   <Route
    //   key="notes"
    //   exact
    //   path={"/notes"}
    //   render={() =>
    //     isAuthenticated ? <LessonPageBarActualPopup /> : <Redirect to={PATH_LOGIN} />
    //   }
    // />,
    <Route
      key="adminStudentPage"
      exact
      path={"/admin"}
      render={() =>
        isAuthenticated && appGetUserRole() === "1" ? (
          <AdminStudentView />
        ) : (
          <Redirect to={PATH_LOGIN} />
        )
      }
    />,
    <Route
    key="adminStudentPage"
    exact
    path={"/adminschool"}
    render={() =>
      isAuthenticated && appGetUserRole() === "1" ? (
        <AdminSchoolView />
      ) : (
        <Redirect to={PATH_LOGIN} />
      )
    }
  />,
  <Route
  key="adminQuizSlide"
  exact
  path={"/adminview"}
  render={() =>
    isAuthenticated && appGetUserRole() === "1" ? (
      <AdminSlideSearch />
    ) : (
      <Redirect to={PATH_LOGIN} />
    )
  }
/>,
  ];

  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter basename={PATH_SKOLE}>
        <Route
          exact
          path={"/notes"}
          render={() => <LessonPageBarActualPopup />}
        />
        <LessonPageBarPopup/>
        <Switch>{routes}</Switch>
      </BrowserRouter>
    </Suspense>
  );
};
export default TeacherRoutes;
