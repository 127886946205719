import React, { useEffect, useState } from "react";
import AlertBox from "../../../../../../globalComponents/AlertBox";
import miniAudio from "../../../../../../assets/miniAudio.mp3";
import { PreloadMedia, MediaType } from "react-preload-media";
import { Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

export type IAudioTrackProps = {
  handleNextSlide: () => void;
  handleAfterIntro: () => void;
  introQuestionSound: string;
  introQuestionText: string;
  indexSounds: string[];
  audioSounds: string[];
  indexPlayer: HTMLAudioElement;
  soundPlayer: HTMLAudioElement;
  pauseSeconds: number;
  isPlaying: boolean;
  setisPlaying: React.Dispatch<React.SetStateAction<boolean>>;
};
/*This component is responsible for playing sound if it is not LearningMode */
const AudioTrack: React.FC<IAudioTrackProps> = (props: IAudioTrackProps) => {
  const [currentCount, setcurrentCount] = useState(-1);
  const soundPlayer = props.soundPlayer;
  const indexPlayer = props.indexPlayer;


  const playSounds = () => {
    if (indexPlayer) {
      playCurrentSound();
      props.setisPlaying(true);
    }
  };


  const [isSoundError, setisSoundError] = useState(false);

  const onYes = () => {
    indexPlayer.src =
      "https://teoricontentstorage.blob.core.windows.net/admincontainer/miniAudio.mp3";
    soundPlayer.src =
      "https://teoricontentstorage.blob.core.windows.net/admincontainer/miniAudio.mp3";
    const indexPromise = indexPlayer.play();
    const soundPromise = soundPlayer.play();

    Promise.all([indexPromise, soundPromise]).then(() => {
      loadPlayersWithSound();
      playCurrentSound();
    });
    setisSoundError(false);
  };

  const loadPlayersWithSound = () => {
    if (currentCount === -1) {
      //THIS IS THE CASE FOR INDEX SOUND
      soundPlayer.src = props.introQuestionSound;
      soundPlayer.load();
      soundPlayer.addEventListener("error", (e) => {

        setisSoundError(true);
      });
    } else {
      //THE CASE FOR EVERYTHING ELSE
      indexPlayer.src = props.indexSounds[currentCount];
      indexPlayer.load();
      indexPlayer.addEventListener("error", (e) => {

        setisSoundError(true);
      });
      soundPlayer.src = props.audioSounds[currentCount];
      soundPlayer.load();
      soundPlayer.addEventListener("error", (e) => {

        setisSoundError(true);
      });
    }
  };

  const playCurrentSound = () => {
    if (soundPlayer && indexPlayer) {
      loadPlayersWithSound();
      soundPlayer.onended = () => {
        setTimeout(() => {
          if (currentCount === props.audioSounds.length - 1) {
            setTimeout(() => props.handleNextSlide(), 500);
          } else {
            if (currentCount === -1) {
              props.handleAfterIntro();
            }
            increment();
          }
        }, props.pauseSeconds);
      };
      if (currentCount === -1) {
        const soundPromise = soundPlayer.play();
        soundPromise.catch((e) => {
          setisSoundError(true);
        });
      } else {
        indexPlayer.onended = () => {
          setTimeout(() => {
            soundPlayer.play();
          }, 500);
        };
        indexPlayer.play();
      }
    }
  };

  useEffect(() => {
    if (props.isPlaying) {
      playCurrentSound();
    }
  }, [currentCount]);

  const increment = () => {
    setcurrentCount(currentCount + 1);
  };

  const specialAlertBox = (
    <div className="specialAlert">
      <div className="box">
        <Button onClick={onYes} className={"closeButton"}>
          <ClearIcon />
        </Button>
        <div className="title">VIGTIGT</div>
        <div className="description">
          Lyden fejlede i at afspille, så vi skal have din tilladelse til at
          afspille den
        </div>
        <Button onClick={onYes} className={"okButton"}>
          OK
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      {!props.isPlaying ? (
        <div className="soundPrompt">
            <Button onClick={playSounds}>
              <PlayArrowIcon />
            </Button>
        </div>
      ) : null}
      {isSoundError ? specialAlertBox : null}
    </div>
  );
};

export { AudioTrack };
