import { BaseType, MediaType } from "./model";

/**
 * Function return item based on passed ID
 */
export function findById<T1 extends BaseType>(
  items: T1[],
  itemId: number | null
): T1 | undefined {
  return items.find((tag) => tag.id === itemId);
}

/**
 * Function return index of item based on passed ID
 */
export function findIndexById<T1 extends BaseType>(
  items: T1[],
  itemId: number | null
): number {
  return items.findIndex((tag) => tag.id === itemId);
}

/**
 * Function removes item whose ID was given
 */
export function filterById<T1 extends BaseType>(
  items: T1[],
  itemIdToDelete: number
): T1[] {
  return items.filter((item) => item.id !== itemIdToDelete);
}

/**
 * Function do numeric sort of array
 */
export function sortById<T1 extends BaseType>(items: T1[]): T1[] {
  return items.sort((first, second) => first.id - second.id);
}

/**
 * Function removes item whose ID was given and then do numeric sort of array
 */
export function filterAndSortById<T1 extends BaseType>(
  items: T1[],
  itemIdToDelete: number
): T1[] {
  return sortById(filterById(items, itemIdToDelete));
}

export function numberToMediaType(number: Number) {
  switch (number) {
    case 1:
      return MediaType.STREAM;
    case 4:
      return MediaType.LOCALVIDEO;
    case 3:
      return MediaType.YOUTUBE;
    case 2:
      return MediaType.WEB_LINK;
    case 5:
      return MediaType.AZUREIMG;
  }
}

export function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
