import React, { useState } from "react";
import {
  getChosenLanguage,
  getChosenSpokenLanguage,
  getStringsForChosenLanguage,
} from "../../../../../../translations/translationProvider";
import { StudentQuizStatement } from "../../../../../model";
import { AudioTrack } from "./AudioTrack";
import { Statement } from "./Statement";
import { PreloadMedia, MediaType } from "react-preload-media";

import "./Statements.scss";
import { Button } from "@material-ui/core";

export type IStatementsProps = {
  isLearningMode: boolean;
  isNoSound: boolean;
  hasSubtitles: boolean;
  currentStatement: number;
  statements: StudentQuizStatement[];
  introQuestion: string;
  introQuestionMedia: string;
  indexPlayer: HTMLAudioElement;
  soundPlayer: HTMLAudioElement;
  speed: number;
  handleStatementClick: (id: number) => void;
  handleNextSlide: () => void;
  languageID: number;
  isPlaying: boolean;
  setisPlaying: React.Dispatch<React.SetStateAction<boolean>>;
};

const Statements: React.FC<IStatementsProps> = (props: IStatementsProps) => {
  const languageString = getChosenSpokenLanguage(props.languageID);
  const isLearningMode = false;
  const hasSubtitles = true;

  const introStatement: StudentQuizStatement = {
    id: 0,
    soundMedia: props.introQuestionMedia,
    progressID: -1,
    text: props.introQuestion,
    correctAnswer: 1,
    explanation: "",
  };

  const indexSoundArray = [
    languageString.indexSounds.one,
    languageString.indexSounds.two,
    languageString.indexSounds.three,
    languageString.indexSounds.four,
  ];

  function getSoundMedia() {
    return props.statements.map((statement) => {
      return statement.soundMedia;
    });
  }

  const sounds = getSoundMedia().map((sound) => {
    return { type: MediaType.Audio, url: sound };
  });
  const indexSounds = indexSoundArray.map((indexSound) => {
    return { type: MediaType.Audio, url: props.isNoSound ? "" : indexSound };
  });

  const loadingDone = () => {};

  const renderStatements = (): JSX.Element[] => {
    const tempArray: JSX.Element[] = [];
    props.statements.forEach((statement, index) => {
      tempArray.push(
        <Statement
          learningMode={props.isLearningMode}
          handleClick={props.handleStatementClick}
          key={statement.id}
          indexNumber={index + 1}
          indexSoundSrc={indexSounds[statement.id - 1].url}
          currentStatement={props.currentStatement}
          statement={statement}
          indexPlayer={props.indexPlayer}
          soundPlayer={props.soundPlayer}
          languageID={props.languageID}
          isNoSound={props.isNoSound}
          statementLength={props.statements.length}
        />
      );
    });
    return tempArray;
  };

  const renderSubs = () => {
    if (props.isNoSound && props.currentStatement !== 0) {
      return props.statements[props.currentStatement - 1].text;
    } else if (props.isLearningMode && props.currentStatement !== 0) {
      return props.statements[props.currentStatement - 1].text;
    } else if (props.isNoSound || props.isLearningMode) {
      return props.introQuestion;
    }
  };

  return (
    <div
      className={
        props.isNoSound
          ? "noSound Statements"
          : props.isLearningMode
          ? "Statements"
          : "Statements noText"
      }
    >
      {props.isNoSound ? null : (
        <PreloadMedia
          media={sounds.concat(indexSounds)}
          onFinished={() => loadingDone()}
        ></PreloadMedia>
      )}

      <Statement
        learningMode={props.isLearningMode}
        handleClick={props.handleStatementClick}
        key={0}
        indexNumber={-1}
        currentStatement={props.currentStatement}
        statement={introStatement}
        indexPlayer={props.indexPlayer}
        soundPlayer={props.soundPlayer}
        statementLength={props.statements.length}
        languageID={props.languageID}
        handleNextSlide={props.handleNextSlide}
      />
      {!props.isLearningMode && !props.isNoSound ? (
        <AudioTrack
          handleNextSlide={props.handleNextSlide}
          handleAfterIntro={() => props.handleStatementClick(1)}
          pauseSeconds={props.speed}
          introQuestionSound={props.introQuestionMedia}
          introQuestionText={props.introQuestion}
          audioSounds={getSoundMedia()}
          indexSounds={indexSoundArray}
          soundPlayer={props.soundPlayer}
          indexPlayer={props.indexPlayer}
          isPlaying={props.isPlaying}
          setisPlaying={props.setisPlaying}
        />
      ) : null}
      {renderStatements()}
      {props.statements.length !== 0 ? (
        <div className="text">{renderSubs()}</div>
      ) : null}
    </div>
  );
};

export { Statements };
