import React, { useEffect, useState } from "react";
import {
  useDeleteStudentMutation,
  useGetAdminStudentLoginInfoLazyQuery,
  useGetCategoriesQuery,
  useGetStudentsAdminQuery,
  useManageStudentMutation,
} from "../../../../graphql/generated/graphql";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import Loading from "../../../../globalComponents/Loading";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddIcon from "@mui/icons-material/Add";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Button } from "@mui/material";
import { MuiDataTableEditable } from "./components/MuiDataTableEdit";
import { CategorySelect } from "./components/CategorySelect";
import { DatePicker } from "./components/DatePicker";
import { useGetSchoolListQuery, useGetStudentInfoLazyQuery } from "../../../../graphql/generated/graphqlStudent";
import { SchoolSelect } from "./components/SchoolSelect";
import "./AdminStudentView.scss";
import AddStudentBox from "./components/AddStudentBox";
import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded';
import { useLocation } from "react-router";
import IntermediaryLoading from "../../../../globalComponents/IntermediaryLoading";

export type IAdminStudentViewProps = {};

const AdminStudentView: React.FC<IAdminStudentViewProps> = ({}) => {
  const { data, loading, refetch } = useGetStudentsAdminQuery();

  const [tableData, settableData] = useState<any[]>();

  const [manageStudent] = useManageStudentMutation();
  const [deleteStudent] = useDeleteStudentMutation();

  const [getStudentInfo] = useGetAdminStudentLoginInfoLazyQuery();

  const [isLoading, setisLoading] = useState(false);

  const [isNewStudentshown, setisNewStudentshown] = useState<boolean>(false);

  const location = useLocation();

  const { data: schoolData } = useGetSchoolListQuery();
  const [schoolList, setschoolList] = useState<any[]>();

  const onDelete = (id: string) => {
    console.log(tableData);
    const newTempData = tableData?.filter((item) => item.delete !== id);
    console.log(newTempData);

    settableData(newTempData);
    deleteStudent({ variables: { student_id: id } }).then(() => refetch());
  };

  const onStudentLogin = (id: string) => {
    setisLoading(true);
    const currentPath = window.location.href.split("/skole")
    //FIRE OFF THE QUERY
    getStudentInfo({variables: {student_id: id}}).then((data) => {
      const phone = data.data?.getAdminStudentLoginInfo?.student_phone_number;
      const pin = data.data?.getAdminStudentLoginInfo?.student_pin;
      newWindow(currentPath[0] + `/login/${phone}/${pin}`);
      setisLoading(false);
    })
    //OPEN NEW WINDOW WITH THE PARAMETERS TO LOGIN
    
  }

  function newWindow(url) {
    const popupWindow = window.open(
      url,
      'popUpWindow','height=750,width=750,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')
  }

  useEffect(() => {
    if (schoolData) {
      const tempArray: any[] = [];
      schoolData.getSchoolList?.forEach((school) => {
        if (school) {
          tempArray.push({
            label: school?.school_name,
            id: school?.school_id,
            zipcode: school?.zipcode,
          });
        }
      });
      setschoolList(tempArray);
      //WE SET THE SCHOOL LIST INSIDE HERE.
      //WE GET THE DATA ON THE SIDE LOAD, AS THIS QUERY IS HEAVY AND WE CANT WAIT FOR IT EVERYTIME
    }
  }, [schoolData]);

  const { data: categoryData } = useGetCategoriesQuery();
  const [categoryList, setcategoryList] = useState<any[]>();

  useEffect(() => {
    if (categoryData) {
      const tempArray: any[] = [];
      categoryData.getCategories?.forEach((category) => {
        tempArray.push({ label: category?.cat_name, id: category?.cat_id });
      });
      setcategoryList(tempArray);
    }
  }, [categoryData]);

  const columns: any[] = [
    {
      name: "delete",
      label: "Slet",
      options: {
        customBodyRender: (value) => {
          return (
            <Button onClick={() => onDelete(value)}>
              <DeleteRoundedIcon />
            </Button>
          );
        },
        customBodyEditRender: (value, tableMeta, update) => (
          <Button disabled>
            <DeleteRoundedIcon />
          </Button>
        ),
      },
    },
    { name: "jumpToStudent", label:"Elev Login", options: {
      customBodyRender: (value) => {
        return (
          <Button onClick={() => onStudentLogin(value)}>
            <AccessibilityNewRoundedIcon />
          </Button>
        );
      },
      customBodyEditRender: (value, tableMeta, update) => (
        <Button disabled>
          <AccessibilityNewRoundedIcon />
        </Button>
      ),
    },},
    { name: "fname", label: "Navn" },
    { name: "lname", label: "Efternavn" },
    { name: "phonenumber", label: "Mobilnummer" },
    { name: "email", label: "E-mail" },
    {
      name: "school_id",
      label: "Skole",
      options: {
        customBodyEditRender: (value, tableMeta, update, rowData) => (
          <SchoolSelect value={value} onChange={update} data={schoolData} />
        ),
        customBodyRender: (value) => {
          if (schoolList) {
            const school = schoolList.filter(
              (school) => school.id === value
            )[0];
            console.log(school);
            if (school) {
              return <span>{school.label}</span>;
            } else {
              return <span>{"Ingen skole"}</span>;
            }
          } else {
            return <span>Loading...</span>;
          }
        },
      },
    },
    {
      name: "category",
      label: "Kategori",
      options: {
        customBodyEditRender: (value, tableMeta, update) => (
          <CategorySelect value={value} onChange={update} data={categoryData} />
        ),
        customBodyRender: (value, tableMeta, update) => {
          if (categoryList) {
            console.log(value);

            const category = categoryList.filter(
              (category) => category.id === value
            )[0];

            if (category) {
              return <span>{category.label}</span>;
            } else {
              return <span>ingen kategori sat</span>;
            }
          } else {
            return <span>Loading...</span>;
          }
        },
      },
    },
    {
      name: "activationDate",
      label: "Start dato",
      options: {
        customBodyRender: (value) => formatDate(value),
        customBodyEditRender: (value, tableMeta, update) => (
          <span>{formatDate(value)}</span>
        ),
      },
    },
    {
      name: "expiryDate",
      label: "Udløbsdato",
      options: {
        customBodyRender: (value) => formatDate(value),
        customBodyEditRender: (value, tableMeta, update) => (
          <div className="editDate">
            <span>{formatDate(value)}</span>
            <DatePicker onChange={update} value={value} />
          </div>
        ),
      },
    },
  ];

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(timeString) {
    const date = new Date(timeString * 1000);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  const onRowSave = (value) => {
    const newTempData = tableData;
    const objIndex = tableData?.findIndex((obj) => obj.delete === value.delete);
    if (newTempData && objIndex) {
      newTempData[objIndex] = {
        fname: value.fname,
        lname: value.lname,
        phonenumber: value.phonenumber,
        email: value.email,
        school_id: value.school_id,
        category: value.category,
        activationDate: value.activationDate,
        accessCode: value.accessCode,
        expiryDate: value.expiryDate,
        delete: value.delete,
      };
    }
    settableData(newTempData);
    manageStudent({
      variables: {
        student: {
          student_id: value.delete,
          first_name: value.fname,
          last_name: value.lname,
          student_category: Number(value.category),
          student_email: value.email,
          student_phone_number: value.phonenumber,
          school_id: value.school_id,
          expiry_date: new Date(value.expiryDate * 1000).toISOString(),
        },
      },
    }).then((data) => {
      if(typeof data.data?.manageStudent === "number"){
        refetch()
      }else{
        alert("Der gik noget galt, kontakt udviklere")
      }
    });
  };

  useEffect(() => {
    if (data) {
      const tempArray: any[] = [];
      data.getStudentsAdmin?.forEach((student) => {
        tempArray.push({
          jumpToStudent: student?.student_id,
          fname: student?.first_name ? student?.first_name : "",
          lname: student?.last_name ? student?.last_name : "",
          phonenumber: student?.student_phone_number
            ? student?.student_phone_number
            : "",
          email: student?.student_email ? student.student_email : "",
          school: student?.school_name ? student.school_name : "",
          school_id: student?.school_id ? student.school_id : "",
          category: student?.student_category ? student.student_category : "",
          activationDate: student?.create_date
            ? new Date(student?.create_date).getTime() / 1000
            : "",
          expiryDate: student?.expiry_date
            ? new Date(student?.expiry_date).getTime() / 1000
            : "ikke aktiveret",
          delete: student?.student_id,
        });
      });
      settableData(tempArray);
    }
  }, [data]);

  if (!tableData) {
    return <Loading />;
  }

  return (
    <div className="AdminStudentView">
      {isLoading ? <IntermediaryLoading/> : null}
      <div className="addButton" onClick={() => setisNewStudentshown(true)}>
        <span>Tilføj ny elev</span>
        <Button>
          <AddIcon />
        </Button>
      </div>
      {isNewStudentshown && categoryList ? (
        <AddStudentBox
          refetch={refetch}
          setisshown={setisNewStudentshown}
          categories={categoryList}
        />
      ) : null}
      <MuiDataTableEditable
        title={"Elev liste"}
        columns={columns}
        data={tableData}
        options={{ rowsPerPage: 15 }}
        onRowSave={onRowSave}
      />
    </div>
  );
};

export { AdminStudentView };
