import React, { useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import IntermediaryLoading from "../../../../globalComponents/IntermediaryLoading";
import Loading from "../../../../globalComponents/Loading";
import { TeoriLogo } from "../../../../globalComponents/TeoriLogo";
import {
  Online_Quiz,
  useCreateClassOnlineQuizMutation,
  useFinishClassOnlineQuizMutation,
  useStartContentMutation,
} from "../../../../graphql/generated/graphqlStudent";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { TeacherStudentSlide, TeacherStudentStatement } from "../../../model";
import { TeacherStudentGraduation } from "./quizComponents/TeacherStudentGraduation";
import { TeacherStudentQuiz } from "./quizComponents/TeacherStudentQuiz";
import "./TeacherStudentQuizPage.scss";
export type ITeacherStudentQuizPageProps = {
  socket: Socket;
  quizID: number | undefined;
};

const TeacherStudentQuizPage: React.FC<ITeacherStudentQuizPageProps> = (
  props: ITeacherStudentQuizPageProps
) => {
  const [loading, setloading] = useState(false);
  const [isInitial, setisInitial] = useState(true);

  const [isDone, setisDone] = useState(false);
  const [graduationState, setgraduationState] = useState<any>();

  const [quizState, setquizState] = useState<TeacherStudentSlide[]>();

  const [createClassQuiz] = useCreateClassOnlineQuizMutation({
    variables: {
      online_quiz: {
        quiz_class_id: String(props.quizID),
        quiz_test_name: "Klasse test",
        achievement_name: "Klasse test",
      },
    },
  });
  const [startContent] = useStartContentMutation();
  const [finishQuiz] = useFinishClassOnlineQuizMutation({
    variables: { quiz_class_id: String(props.quizID) },
  });

  function finishQuizNow() {
    setloading(true);
    finishQuiz().then((data) => {
      localStorage.removeItem("isOngoing")

      setgraduationState(data.data?.finishClassOnlineQuiz);
      setisDone(true);
    });
  }

  function convertAPIToQuiz(state: Online_Quiz) {
    const tempArray: TeacherStudentSlide[] = [];
    if (state.slide_progress) {
      for (const slide of state.slide_progress) {
        const tempStatements: TeacherStudentStatement[] = [];
        if (slide?.statement_progress) {
          let index = 0;
          for (const statement of slide.statement_progress) {
            if (statement) {
              tempStatements.push({
                id: index,
                progressID: Number(statement.statement_progress_id),
                correctAnswer: statement.statement_answer_id!,
              });
            }
            index++;
          }
        }
        tempArray.push({
          id: Number(slide?.progress_id),
          statements: tempStatements,
        });
      }
    }
    return tempArray;
  }

  function createAndGetQuiz() {

    const id = localStorage.getItem("online_quiz_class_id");
    const token = localStorage.getItem("online_quiz_class_token");
    const isOngoing = localStorage.getItem("isRunning");
    if (id && token && isOngoing === "true") {
      startContent({
        variables: { quiz_token_id: token, online_quiz_id: id },
      }).then((data) => {


        if (data.data) {
          const newState = convertAPIToQuiz(data.data.startContent!);
          setquizState(newState);
        }
      });
    } else {
      createClassQuiz().then((data) => {

        if (data) {
          localStorage.setItem("isOngoing", "true");
          localStorage.setItem(
            "online_quiz_class_id",
            data.data!.createClassOnlineQuiz!.online_quiz_id!
          );
          localStorage.setItem(
            "online_quiz_class_token",
            data.data?.createClassOnlineQuiz?.quiz_token_id!
          );
          startContent({
            variables: {
              quiz_token_id: data.data?.createClassOnlineQuiz?.quiz_token_id,
              online_quiz_id: data.data?.createClassOnlineQuiz?.online_quiz_id,
            },
          }).then((data) => {

            if (data.data) {
              const newState = convertAPIToQuiz(data.data.startContent!);
              setquizState(newState);
            }
          });
        }
        //THEN WE GO INTO THE NORMAL QUIZ FLOW
      });
    }
  }

  useEffect(() => {
    createAndGetQuiz();
    //WE GET THE DATA OF THE QUIZ IN HERE
    //THE QUIZ WILL BE A SPECIAL DATA TYPE, WHICH ACTUALLY ONLY LOOKS LIKE THIS
    /* quiz: {
                    slides: Slide[]
                    Slide: {
                        statements: Statement[]
                    }
                    Statement: {
                        Answer: number;
                    }
                 }*/
    //THEN IN THE END I WILL SEND IN THIS STRUCTURE TO THE API END IT EVALUATES
    props.socket.on("slide-controller-quiz", () => {
      setisInitial(false);
    });

    props.socket.on("slide-move", (data) => {
        if(Number(data) !== -1){
            setisInitial(false);
        }
    });

    props.socket.on("quiz-result-move", () => {
        setisDone(true);
    })
  }, []);
  if (quizState === undefined) {
    return <Loading />;
  }
  if (isDone) {
    return (
      <TeacherStudentGraduation
        graduationState={graduationState}
        socket={props.socket}
        quizID={props.quizID!}
      />
    );
  }
  return (
    <>
      {loading ? <IntermediaryLoading /> : null}
      {isInitial ? (
        <div className="InitalTeacherStudentQuizPage">
          <TeoriLogo />
          <div className="ReadyIcon">
          <DoneRoundedIcon/>
          </div>
        </div>
      ) : (
        <div className="TeacherStudentQuizPage">
          <TeacherStudentQuiz
            quizState={quizState}
            setQuizState={setquizState}
            socket={props.socket}
            finishQuizNow={finishQuizNow}
          />
        </div>
      )}
    </>
  );
};

export { TeacherStudentQuizPage };
