import { Button } from '@material-ui/core';
import React from 'react';
import { useAddToHomescreenPrompt } from '../hooks/A2HS';
import "./style/A2HSComponent.scss"
import teoriLogo from "../assets/smallTeoriLogo.png";

export type IA2HSComponentProps = {
    
}

const A2HSComponent: React.FC<IA2HSComponentProps> = ({ }) => {
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = React.useState(false);
  
    const hide = () => {
      setVisibleState(false);
      localStorage.setItem("A2HS", "NO")
    }
  
    React.useEffect(
      () => {
        if (prompt) {
          if(localStorage.getItem("A2HS") === "NO"){

          }else{
            setVisibleState(true);
          }
        }
      },
      [prompt]
    );
  
    if (!isVisible) {
      return <div />;
    }
  
    return (
      <div onClick={hide} className="A2HS">
        <div className='textNpicture'>
        <div className='text'>
        Vil du tilføje siden til startskærmen?
        </div>
        <div className='picture'><img src={teoriLogo}></img></div>
        </div>
        <div className='buttons'>
        <Button onClick={hide} className="FAIL">Luk</Button>
        <Button onClick={promptToInstall} className="SUCCESS">Tilføj til startskærmen</Button>
        </div>
      </div>
    );
}
export { A2HSComponent };