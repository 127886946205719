import React, { useContext, useEffect, useState } from "react";
import {
  useDeleteQuizSlideMutation,
  useGetSasQuery,
} from "../../../../graphql/generated/graphql";
import {
  getChosenLanguage,
  getStringsForChosenLanguage,
} from "../../../../translations/translationProvider";
import { CreateQuizSlide, LessonSlide } from "../../../model";
import { AppStateContext } from "../../../../apollo/TeoriTeacherProvider";
import { filterById, findById } from "../../../modelFunctions";
import { ContentItem } from "./ContentItem";
import { EmptyItem } from "./EmptyItem";
import { SlideSwapper } from "./SlideSwapper";
import "./style/ItemsArea.scss";
import { uploadOwnContent } from "../../../../services/azureStorage";
import { NewSlide } from "./NewSlide";
import { availableSounds } from "../CreateQuizPage";

export type IItemsAreaProps = {
  slides: CreateQuizSlide[];
  setSlides: (slides: CreateQuizSlide[]) => void;
  selectSlide: (id: number) => void;
  expandSelectedSlide: (id: number) => void;
  narrationSounds: availableSounds[];
};

const ItemsArea: React.FC<IItemsAreaProps> = (props: IItemsAreaProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  //TODO CHANGE FOR PROPER VALUES THAT WILL MATCH THE FUNCTIONAL REQUIREMENTS
  const maxNumberOfPages = 20;
  //This variable is responsible settings number of items of single page
  const numberOfItemsOnSinglePage = 25;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [numberOfEmptyItemsOnCurrentPage, setNumberOfEmptyItemsOnCurrentPage] =
    useState<number>(0);
  const [newSlide, setnewSlide] = useState(false);
  const { data: SASKey } = useGetSasQuery();

  const [deleteSlide] = useDeleteQuizSlideMutation();

  useEffect(() => {
    calculateEmptyItemsForCurrentPage();
  }, []);

  useEffect(() => {
    calculateEmptyItemsForCurrentPage();
  }, [props.slides.length, currentPage]);

  useEffect(() => {}, [props.slides]);

  const swapSlides = (
    firstSlideIndex: number,
    secondSlideIndex: number
  ): void => {
    // maximalOrderNumber - this is value synced with order numbers displayed
    const maximalOrderNumber = props.slides.length;
    if (
      firstSlideIndex > maximalOrderNumber ||
      secondSlideIndex > maximalOrderNumber
    ) {
      alert("Item with chosen index does not exist");
      return;
    }

    if (firstSlideIndex === secondSlideIndex) {
      alert("Slide cannot replace itself");
      return;
    }

    const firstSlide: CreateQuizSlide = {
      ...props.slides[firstSlideIndex - 1],
    };
    const secondSlide: CreateQuizSlide = {
      ...props.slides[secondSlideIndex - 1],
    };
    console.log(secondSlide);
    //	TODO ADD MORE LOGIC
    const tempSlideList = props.slides;
    tempSlideList[firstSlideIndex - 1] = secondSlide;
    tempSlideList[secondSlideIndex - 1] = firstSlide;
    const updatedIndexes = tempSlideList.map((slide, index) => {
      slide.id = index + 1;
      return slide;
    });
    console.log(tempSlideList);
    props.setSlides(updatedIndexes);
    props.selectSlide(firstSlideIndex);
  };

  const handleCreateSlide = (): void => {
    setnewSlide(true);
  };

  const handleFinishCreateSlide = async (newSlide: any): Promise<void> => {
    const slideList = props.slides;
    newSlide.id = slideList.length + 1;
    newSlide.slideFilter = "-1";

    slideList.push(newSlide);
    props.setSlides(slideList);
    props.selectSlide(newSlide.id);
    setnewSlide(false);
  };

  const handleDeleteSlide = (id: number): void => {
    const newArray = [...props.slides].filter((item) => item.id !== id);
    const updatedIndexes = newArray.map((slide, index) => {
      slide.id = index + 1;
      return slide;
    });
    const slideToDelete = findById(props.slides, id);

    if (slideToDelete?.editID) {
      deleteSlide({ variables: { quiz_slide_id: String(slideToDelete?.editID) } });
    }

    props.setSlides(updatedIndexes);
    if (props.slides.length === 1) {
      props.selectSlide(-1);
    }
  };

  const handleIncrementCurrentPage = (): void => {
    if (currentPage < maxNumberOfPages) {
      if (currentPage === lastPage) {
        setLastPage(lastPage + 1);
      }
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDecrementCurrentPage = (): void => {
    if (currentPage - 1 > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  //Calculating first place in array for certain page
  const getBeginForSlideItems = (): number => {
    //currentPage must be decremented because arrays are numbered from 0 but pages are numbered from 1
    return (currentPage - 1) * numberOfItemsOnSinglePage;
  };

  //Prevent from array outOfRange when of certain page there are not as many rows as maximal limit of rows on page
  const getEndForSlideItems = (): number => {
    const lastPlaceInArrayForCurrentPage =
      currentPage * numberOfItemsOnSinglePage;
    //return length of array when length is smaller than lastPlaceInArrayForCurrentPage
    if (props.slides.length < lastPlaceInArrayForCurrentPage) {
      return props.slides.length;
    }
    return lastPlaceInArrayForCurrentPage;
  };

  //Returns subarray of items that should be displayed on current page
  const getSlidesForCurrentPage = (): CreateQuizSlide[] => {
    return props.slides.slice(getBeginForSlideItems(), getEndForSlideItems());
  };

  // Calculates and set number of empty items that should be displayed on current page
  const calculateEmptyItemsForCurrentPage = (): void => {
    setNumberOfEmptyItemsOnCurrentPage(
      numberOfItemsOnSinglePage - getSlidesForCurrentPage().length
    );
  };

  /**
   * Returns last iteration step when for current page if slides exist
   * otherwise returns calculated begin for current page- reuse of function that is used
   * for slides array - the same behavior is needed
   */
  const getBeginForEmptyItems = (): number => {
    if (getSlidesForCurrentPage().length > 0) {
      return getEndForSlideItems();
    }
    return getBeginForSlideItems();
  };

  // Calculates end of iteration for empty items based on beginning and number of empty items on current page
  const getEndForEmptyItems = (): number => {
    return getBeginForEmptyItems() + numberOfEmptyItemsOnCurrentPage;
  };

  const findIntroQuestion = (introQuestionID: number) => {
    const chosenSound = props.narrationSounds.find(
      (element) => element.id === introQuestionID
    );
    if (chosenSound) {
      return chosenSound;
    } else {
      return undefined;
    }
  };

  const renderContentItems = (): JSX.Element[] => {
    const items: JSX.Element[] = [];
    for (
      let index = getBeginForSlideItems();
      index < getEndForSlideItems();
      index++
    ) {
      items.push(
        <ContentItem
          key={props.slides[index].id}
          slideOrderNumber={index + 1}
          slide={props.slides[index]}
          handleSelectSlide={() => props.selectSlide(props.slides[index].id)}
          handleExpandSlide={() =>
            props.expandSelectedSlide(props.slides[index].id)
          }
          handleDeleteSlide={() => handleDeleteSlide(props.slides[index].id)}
          introItem={findIntroQuestion(props.slides[index].introQuestion)}
        />
      );
    }

    for (
      let index = getBeginForEmptyItems();
      index < getEndForEmptyItems();
      index++
    ) {
      items.push(
        <EmptyItem
          key={index + 1 + "empty"}
          slideOrderNumber={index + 1}
          handleCreateSlide={handleCreateSlide}
        />
      );
    }

    return items;
  };

  const pageNumber = (
    <>
      {` ${languageString.createLessonPage.page} ${currentPage} `}
      {` ${" - "} ${lastPage} `}
    </>
  );

  // const changePageArrows = (
  // 	<>
  // 		<IconButton image={leftArrowIcon} handleButtonClick={handleDecrementCurrentPage} />
  // 		<IconButton image={rightArrowIcon} handleButtonClick={handleIncrementCurrentPage} />
  // 	</>
  // );

  return (
    <div className="ItemsArea">
      <div className="items">{renderContentItems()}</div>
      <div className="bottom">
        <div className="bottomLeft">
          <SlideSwapper swapSlides={swapSlides} />
        </div>
        <div className="bottomRight">
          {/* {pageNumber} this is for later implementation
					{changePageArrows} */}
        </div>
      </div>
      {newSlide ? (
        <NewSlide
          setnewSlide={setnewSlide}
          handleFinishCreateNewSlide={handleFinishCreateSlide}
        />
      ) : null}
    </div>
  );
};

export { ItemsArea };
