import React from 'react';
import "./style/Loading.scss";
import customLoader from "../assets/customLoader.gif";
import customLoader24 from "../assets/customLoader24fps.gif";
import { LoadingBar } from './LoadingBar';

export type ILoadingProps = {
    loadingText?: string;
    progress?: any;
}

const Loading: React.FC<ILoadingProps> = (props: ILoadingProps) => {

    const loadingElement = <img src={customLoader24}/>;

    if(props.loadingText){
       return( <div className="Loading">
            <div>{loadingElement}</div>
            <h3>{props.loadingText}</h3>
        </div>)

    }
    if(props.progress){
        return(
            <div className='Loading loadingBar'>
            {loadingElement}
            {props.progress ? <LoadingBar key={props.progress.progress} progress={props.progress.progress} name={props.progress.name} total={props.progress.total}/> : null}
            </div>
        )
    }
    return (
        <div className="Loading">
            {loadingElement}
        </div>
    );
}

export default Loading ;