import React, { FC, useEffect, useState } from "react";
import { getMergedStringsFromArray } from "../services/utilFunctions";
import { InputType, SingleCharInput } from "./SingleCharInput";

export interface PinInputProps {
	setPIN: (value: string | ((prevState: string) => string)) => void;
	PINInputState: string[];
	setPINInputState: (value: string[] | ((prevState: string[]) => string[])) => void;
	className: string;
	type?: string;
}

export const PinInput: FC<PinInputProps> = (props: PinInputProps) => {
	const emptyInputPlaceholder = "_";

	useEffect(() => {
		props.setPIN(getMergedStringsFromArray(props.PINInputState));
	}, [props, props.PINInputState]);

	const updatePINItem = (value: string | number, index: number): void => {
		const PINInputStateCopy = [...props.PINInputState];
		PINInputStateCopy[index] = value.toString();
		props.setPINInputState(PINInputStateCopy);
	};

	const handleKeyPress = (event) => {
		if (event.keyCode === 8) {
			props.setPINInputState(["", "", "", "", "", ""]);
		}
	};

	const renderSingleCharacterInputs = (): JSX.Element[] => {
		const inputs: JSX.Element[] = [];

		for (let index = 0; index < 4; index++) {

			inputs.push(
				<SingleCharInput
					className={props.className}
					key={index}
					handleKeyPress={handleKeyPress}
					ordinalNumber={index}
					isFirstInRow={index === 0}
					inputType={props.type ? InputType.Password : InputType.Text}
					placeholder={emptyInputPlaceholder}
					value={props.PINInputState[index]}
					handleSetValue={updatePINItem}
				/>,
			);
		}

		return inputs;
	};

	return <>{renderSingleCharacterInputs()}</>;
};

export default PinInput;