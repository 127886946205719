import React, { useEffect, useState } from "react";
import {
  Category,
  useCreateSchoolCategoryMutation,
  useGetAllCategoriesQuery,
} from "../../../../graphql/generated/graphql";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@material-ui/core";
import IntermediaryLoading from "../../../../globalComponents/IntermediaryLoading";
import Loading from "../../../../globalComponents/Loading";

export type ICategoryCellManageProps = {
  school_id: number;
  categories: Category[];
  close: () => void;
  refetch: () => void;
};

const CategoryCellManage: React.FC<ICategoryCellManageProps> = (
  props: ICategoryCellManageProps
) => {
  const [selectedCategory, setselectedCategory] = useState<number>();

  const { data: catData, loading } = useGetAllCategoriesQuery();

  const [availableCategories, setavailableCategories] = useState<Category[]>();

  const [createSchoolCategory] = useCreateSchoolCategoryMutation();

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (catData?.getAllCategories) {
      const tempArray: Category[] = [];
      const filtered = catData.getAllCategories.filter(
        (x) => !props.categories.filter((y) => y.cat_id === x?.cat_id).length
      );
      console.log(filtered);

      filtered.forEach((element) => {
        if (element) {
          tempArray.push(element);
        }
      });
      setavailableCategories(tempArray);
    }
  }, [props.categories, catData]);

  const handleSubmit = () => {
    if (selectedCategory) {
        setisLoading(true)
      createSchoolCategory({
        variables: { schoolId: props.school_id, categoryId: selectedCategory },
      }).then((data) => {
        props.refetch();
        props.close();
      });
    }
  };
  const handleAbort = () => {
    props.close();
  };

  const renderCategories = () => {
    return availableCategories?.map((cat) => {
      return (
        <div
          className={
            Number(cat.cat_id) === selectedCategory ? "active" : undefined
          }
          onClick={() => setselectedCategory(Number(cat!.cat_id!))}
        >
          {cat.cat_name}
        </div>
      );
    });
  };
  return (
    <div className="CategoryCellManage">
        {isLoading ? <IntermediaryLoading/> : null}
      <div className="CategoryCellManageBox">
        <div className="categories">

          {availableCategories ? availableCategories?.map((cat) => {
            return (
              <div
                className={
                  Number(cat.cat_id) === selectedCategory ? "active" : undefined
                }
                onClick={() => setselectedCategory(Number(cat!.cat_id!))}
              >
                {cat.cat_name}
              </div>
            );
          }) : <Loading/>}
        </div>
        <div className="confirm">
          <Button onClick={handleSubmit}>
            <DoneIcon />
          </Button>
          <Button onClick={handleAbort}>
            <CloseIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export { CategoryCellManage };
