import { Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import React from "react";

const ROW_MODIFY_STATES = Object.freeze(
  Object.fromEntries(["INITIAL", "EDITING"].map((t) => [t, t]))
);

const getColumnValue = (originalColumnIndex, tableMeta) =>
  tableMeta.rowData[
    tableMeta.tableState.columnOrder?.indexOf(originalColumnIndex) ??
      originalColumnIndex
  ];

const rebuildObjFromRow = (columns, tableMeta) =>
  Object.fromEntries(
    columns.map((c, originalColumnIndex) => [
      c.name,
      getColumnValue(originalColumnIndex + 1, tableMeta),
    ])
  );

const DefaultActionButton = ({ value, onEditStart, onEditFinish }) =>
  value === ROW_MODIFY_STATES.INITIAL ? (
    <Button onClick={onEditStart}>
      <EditRoundedIcon />
    </Button>
  ) : (
    <Button onClick={onEditFinish}> Save </Button>
  );

const EditableCell = ({ editing, value, onChange, columnMeta, tableMeta }) =>
  editing
    ? columnMeta.options?.customBodyEditRender?.(
        value,
        tableMeta,
        onChange
      ) ?? <input className="standardInput" value={value} onChange={(e) => onChange(e.target.value)} />
    : columnMeta.options?.customBodyRender?.(value, tableMeta, onChange) ??
      value;

const actionColumnRenderFactory =
  (columns, onRowSave, actionButtonCmp) => (value, tableMeta, update) =>
    actionButtonCmp({
      value,
      onEditStart: () => update(ROW_MODIFY_STATES.EDITING),
      onEditFinish: () => {
        const rowObj = rebuildObjFromRow(columns, tableMeta);
        onRowSave(rowObj);
        update(ROW_MODIFY_STATES.INITIAL);
      },
    });

const injectColumnRender = (columns) => {
 return columns.map((c) => ({
    ...c,
    options: {
      ...c.options,
      customBodyRender: (value, tableMeta, onChange) => {
        const uiState = getColumnValue(0, tableMeta);
        return (
          <EditableCell
            editing={uiState === ROW_MODIFY_STATES.EDITING}
            columnMeta={c}
            {...{ value, tableMeta, onChange }}
          />
        );
      },
    },
  }))};
const MuiDataTableEditable: React.FC<any> = ({
  data,
  columns,
  onRowSave,
  onRowClick,
  actionButtonCmp = DefaultActionButton,
  ...otherProps
}) => {
  const dataWithUiState = Array.isArray(data[0])
    ? data.map((t) => [ROW_MODIFY_STATES.INITIAL, ...t])
    : data.map((t) => ({ uiState: ROW_MODIFY_STATES.INITIAL, ...t }));

  const actionColumn = {
    name: "uiState",
    label: "Rediger",
    options: {
      customBodyRender: actionColumnRenderFactory(
        columns,
        onRowSave,
        actionButtonCmp
      ),
    },
  };

  const columnsWithEditableRender = injectColumnRender(columns);

  const columnsWithCustomRenderAndActionColumn = [
    actionColumn,
    ...columnsWithEditableRender
    
  ];

  return (
    <MUIDataTable
      data={dataWithUiState}
      columns={columnsWithCustomRenderAndActionColumn}

      {...otherProps}
    />
  );
};

export { MuiDataTableEditable };
