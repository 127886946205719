import React, { useEffect, useState } from "react";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../translations/translationProvider";
import arrowIcon from "../../../../assets/white_Arrow.png";
import { SubmitHandler, useForm } from "react-hook-form";
import { useVerifyStudentMutation } from "../../../../graphql/generated/graphqlStudent";
import { LoginStep } from "../../../model";
import "../style/AccessCode.scss";
import { Button } from "@mui/material";
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';

interface Inputs {
  passcode: string;
  mobileNumber: string;
}

export type IAccessCodeProps = {
  setLoginStep: any;
  mobileNumber: string;
  setMobileNumber: (value: string | ((prevState: string) => string)) => void;
  setIsLoading: (value: boolean | ((prevState: boolean) => boolean)) => void;
  setpreFix: (value: string) => void
  preFix: string;
};

const AccessCode: React.FC<IAccessCodeProps> = (props: IAccessCodeProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  const [errorMessage, setErrorMessage] = useState<string>();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    setValue
  } = useForm<Inputs>();

  const [activateCode] = useVerifyStudentMutation();

  useEffect(() => {
    if(props.mobileNumber){
      setValue("mobileNumber", props.mobileNumber)
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async (submitData) => {
    props.setIsLoading(true);
    activateCode({
      variables: {
        student_phone_number: props.preFix + submitData.mobileNumber,
        entry_code: submitData.passcode,
      },
    }).then((data) => {
      switch (data.data?.VerifyStudent) {
        case "NOT_FOUND":
          setErrorMessage(
            languageString.studentLoginPage.AccessCode.errorMessages.notFound
          );
          break;
        case "MAXIMUM_USES_CODE":
          setErrorMessage(
            languageString.studentLoginPage.AccessCode.errorMessages.alreadyUsed
          );
          break;
        case "EXPIRED_CODE":
          setErrorMessage(
            languageString.studentLoginPage.AccessCode.errorMessages.expired
          );
          break;
        case "DUPLICATE_NUMBER":
          setErrorMessage(
            languageString.studentLoginPage.AccessCode.errorMessages.mobileUsed
          );
          break;
        case "USER_VERIFIED":
          setErrorMessage(
            languageString.studentLoginPage.AccessCode.errorMessages.success
          );
          props.setMobileNumber(watch("mobileNumber"));
          setTimeout(() => {props.setLoginStep(LoginStep.CreatePin)}, 1500)
          break;
        case "ACCESS_EXTENDED":
          setErrorMessage(
            languageString.studentLoginPage.AccessCode.errorMessages.alreadyExisting
          );
          props.setMobileNumber(watch("mobileNumber"));
          setTimeout(() => {props.setLoginStep(LoginStep.CreatePin)}, 1500)
          break;
        default:
          setErrorMessage("Kritisk fejl, kontakt supportchatten");
          break;
      }
      props.setIsLoading(false)
    });
    
  };

  return (
    <div className="AccessCodePage">
      <div className="lastColumn">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="firstInput">
            <input
              defaultValue={""}
              placeholder={
                languageString.studentLoginPage.AccessCode.typeCodePlaceholder
              }
              {...register("passcode", { required: true })}
              className={errors.passcode ? "codeInput formError" : "codeInput"}
            />
          </div>
          <div className="firstInput">
          <input
            value={props.preFix}
            onChange={(event) => props.setpreFix(event.target.value)}
            className="preFix"
          />
            <input
              defaultValue={props.mobileNumber}
              placeholder={
                languageString.studentLoginPage.mobileNumberPlaceholder
              }
              {...register("mobileNumber", { required: true })}
              className={errors.mobileNumber ? "mobileInput formError" : "mobileInput"}
            />
          </div>
          <span className="error">{errorMessage ? errorMessage : null}</span>

          <div className="bottomButton">
              <Button type="submit" variant="contained">
               <span>{languageString.studentLoginPage.continueButton}</span><DoneOutlineRoundedIcon/>
              </Button>
             
          </div>
        </form>
      </div>
    </div>
  );
};

export { AccessCode };
