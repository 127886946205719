import React, { useEffect, useState } from "react";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../../../translations/translationProvider";
import { StudentQuizSlide } from "../../../../../model";
import StatementRow from "../../../End/components/StatementRow";
import "./LearningMode.scss";
import star from "../../../../../../assets/star_icon.png";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import headphones from "../../../../../../assets/headphone_icon.png";
import { LAPTOP_VIEW_WIDTH } from "../../../../../../constants/viewConstants";
import { useWindowDimensions } from "../../../../../../hooks/useWindowDimensions";
import { useMarkSlideMutation, useUnmarkSlideMutation } from "../../../../../../graphql/generated/graphqlStudent";

export type ILearningModeProps = {
  slide: StudentQuizSlide;
  learningModeGradeSlide: () => void;
};

const LearningMode: React.FC<ILearningModeProps> = (
  props: ILearningModeProps
) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  const [isShown, setisShown] = useState(true);
  const [isMarked, setIsMarked] = useState<boolean>(props.slide.isMarked ? props.slide.isMarked : false);
  const [markSlide] = useMarkSlideMutation({variables:{marked_slides:[String(props.slide.id)]}});
  const [unmarkSlide] = useUnmarkSlideMutation({variables:{unmarked_slides:[String(props.slide.id)]}});

  const renderStatements = (): JSX.Element[] => {
    const statementArray: JSX.Element[] = [];
    props.slide.statements.forEach((statement, index) => {
      statementArray.push(<StatementRow statement={statement} key={index} isQuiz={true} />);
    });
    return statementArray;
  };

  const { width, height } = useWindowDimensions();

  const [isLaptopView, setIsLaptopView] = useState<boolean>(
    LAPTOP_VIEW_WIDTH <= width
  );


  useEffect(() => {
    props.learningModeGradeSlide();
  }, []);

  const onDetailsClick = () => {
    setisShown(!isShown);
  };

  const onMarkedClick = () => {
    if(!isMarked){
      markSlide();
    }else{
      unmarkSlide();
    }
    setIsMarked(!isMarked);
  }

  const onExplanationClick = () => {
    alert("EXPLANATION CLICK");
  }

  return (
    <div className="LearningMode">
      <div className={isShown ? "statementUI" : "statementUI hidden"}>
        <div className="rows">
          <div className="introQuestion">{props.slide.introQuestion}</div>
          {renderStatements()}
        </div>
        <div className="icons">
          <div>
            <button
              onClick={onMarkedClick}
              className={isMarked ? "marked" : "unmarked"}
            >
              <img src={star} alt="STAR-ICON"></img>
            </button>
            {isLaptopView ? <label><h3>{languageString.studentQuizPage.End.dontLikeImageShorthand}</h3>{languageString.studentQuizPage.End.dontLikeImage}</label> : <label><h3>{languageString.studentQuizPage.End.dontLikeImageShorthand}</h3></label>}
            
          </div>
          {/* <div> //TODO ADD BACK IN WHEN WE HAVE EXPLANATIONS READY FOR ONLINE QUIZ
            <button onClick={onExplanationClick}>
              <img src={headphones} alt="HEADPHONES-ICON"></img>
            </button>
            <label>{languageString.studentQuizPage.End.soundExplained}</label>
          </div> */}
        </div>
        <button
        className={
          isShown ? "detailsCloseButton clickable" : "hidden"
        }
        onClick={onDetailsClick}
      >
        <KeyboardArrowDownRoundedIcon className="arrow"/>
      </button>
      </div>

      <button
        className={
          !isShown ? "detailsButton clickable" : "hidden"
        }
        onClick={onDetailsClick}
      >
        <KeyboardArrowDownRoundedIcon className="arrow"/>
      </button>
    </div>
  );
};

export { LearningMode };
