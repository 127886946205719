import { Alert, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { AppStateStudentContext } from "../../../apollo/TeoriStudentProvider";
import { useAddTeoriLessonStudentMutation, useGetStudentNameQuery } from "../../../graphql/generated/graphqlStudent";
import { joinRoom, setUsername } from "../../socketFunction";
import LoginPage from "../loginPage/LoginPage";
import { PlaceholderPage } from "./controllerPages/PlaceholderPage";
import { TeacherStudentQuizPage } from "./controllerPages/TeacherStudentQuizPage";
import { TeacherStudentStats } from "./controllerPages/TeacherStudentStats";
import { OngoingLessonPage } from "./controllerPages/OngoingLessonPage";
import "./TeacherStudentController.scss";
import { TeoriLogo } from "../../../globalComponents/TeoriLogo";
import { TeacherStudentGraduation } from "./controllerPages/quizComponents/TeacherStudentGraduation";

export type ITeacherStudentControllerProps = {};

const TeacherStudentController: React.FC<
  ITeacherStudentControllerProps
> = ({}) => {
  const [currentState, setcurrentState] = useState<number>();
  const { appState, appGetUserID } = useContext(AppStateStudentContext);
  const [socket, setsocket] = useState<Socket | undefined>();
  const [quizID, setquizID] = useState<number>();
  const { data: nameData, loading: nameLoading } = useGetStudentNameQuery({
    fetchPolicy: "network-only",
  });
  const [error, seterror] = useState<Error>();
  const [hasListerner, sethasListerner] = useState(false);

  const [isConnected, setisConnected] = useState(false);


  const [addStudent] = useAddTeoriLessonStudentMutation({variables:{lessonId: window.location.href.split("/")[4]}});


  useEffect(() => {
    if(appState.loggedIn){

      addStudent().then((data) => {
        if(data.data?.addTeoriLessonStudent){
          const response = data.data.addTeoriLessonStudent;
        if(response === "STUDENT_ADDED"){
          setcurrentState(0)
          setisConnected(true);
        }else if(response === "STUDENT_ALREADY_ADDED"){
          setcurrentState(0)
          setisConnected(true);
        }
      }
    })
  }
  }, [appState.loggedIn]);




  const renderContent = () => {
    if (appState.loggedIn) {
      if (!isConnected) {
        return <div className="connecting"><TeoriLogo/><div className="infoBox">
        <div className="feedback">
             <CircularProgress/>
             <span>{"Forbinder dig til sessionen"}</span>
        </div>
       
      </div></div>;
      } else {
        switch (currentState) {
          case 0:
            return <TeacherStudentStats />;
          case 1:
            return "SLIDES";
          case 2:
            return (
              <TeacherStudentQuizPage
                socket={socket!}
                quizID={quizID}
                key={quizID}
              />
            );
          case 3:
            return <OngoingLessonPage />;
          case 4:
            return <TeacherStudentGraduation socket={socket!} quizID={quizID!}/> 
          default:
            return <PlaceholderPage />;
        }
      }
    } else {
      return <LoginPage />;
    }
  };

  return (
    <>

      <div className="TeacherStudentController">
        {renderContent()}
      </div>
    </>
  );
};

export { TeacherStudentController };
