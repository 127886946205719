export enum LanguageType {
  English = "English",
  Danish = "Dansk",
  Polish = "Polski",
}

export interface Translation {
  generalMessages: GeneralMessages;
  ultimateTopbar: UltimateTopBar;
  indexSounds: IndexSounds;
  loginPage: LoginPage;
  dashboardPage: DashboardPage;
  lessonPage: LessonPage;
  createLessonPage: CreateLessonPage;
  studentPage: StudentPage;
  teacherQuizPage: TeacherQuizPage;
  studentLoginPage: StudentLoginPage;
  studentSettings: StudentSettings;
  studentDashboardPage: StudentDashboardPage;
  studentQuizPage: StudentQuizPage;
  teacherStudentPage: TeacherStudentPage;
}

interface GeneralMessages {
  seeYourErrors: string;
  backButton: string;
  yes: string;
  no: string;
  both: string;
  allow: string;
  deny: string;
}
interface UltimateTopBar {
  education: string;
  studentsNstats: string;
  orders: string;
  calibrateProjector: string;
  importFromEP: string;
  settings: string;
  logout: string;
}

interface IndexSounds {
  one: string;
  two: string;
  three: string;
  four: string;
}
interface LoginPage {
  loginForm: {
    loginButton: string;
    userNamePlaceholder: string;
    passwordPlaceholder: string;
  };
  wrongInfoError: string;
  rememberLoginInfo: string;
}

interface DashboardPage {
  topbar: {
    lecture: string;
    test: string;
    search: string;
    sort: {
      sortPlaceholder: string;
      byDate: string;
      byType: string;
      byCategory: string;
      byReleaseType: string;
    };
  };
  table: {
    type: {
      lesson: string;
      test: string;
    };
    category: string;
    created: string;
    status: {
      unpublished: string;
      published: string;
      offline: string;
    };
    edit: string;
    details: string;
  };
}

interface LessonPage {
  of: string;
  initialPage: {
    scanQR: string;
    welcomeTo: string;
    continue: string;
    skip: string;
  };
}
interface CreateLessonPage {
  slide: string;
  addTag: string;
  movePicture: string;
  withPicture: string;
  addSubject: string;
  fromPicture: string;
  insert: string;
  page: string;
  uploadMP3: string;
  notePlaceholder: string;
  save: string;
  finish: string;
  newSlide: {
    chooseWhereToGet: string;
    noneChosen: string;
    teoriundervisning: string;
    ownMaterial: string;
    link: string;
  };
}

interface StudentPage {
  topbar: {
    actions: {
      placeholder: string;
      gatheredStats: string;
      archiveChosen: string;
      resendPassword: string;
      usefulInfo: string;
      yourInfo: string;
      category: string;
    };
    searchPlaceholder: string;
    sort: {
      placeholder: string;
      newest: string;
      alphabetical: string;
      teamStart: string;
      activeCodes: string;
      archivednExpiredCodes: string;
      noCodes: string;
      byPercentage: string;
    };
  };
  table: {
    category: string;
    mobileNumber: string;
    password: string;
    expiry: string;
    stats: string;
    archive: string;
    removeAccess: string;
    addAccess: string;
    extendedStats: {
      theseSlidesAreMarked: string;
      latestActivity: string;
    };
  };
}

interface TeacherQuizPage {
  quizSetup: {
    noOfTeoriSlides: string;
    speed: {
      header: string;
      slow: string;
      normal: string;
      fast: string;
    };
    languageSettings: {
      header: string;
      none: string;
      arabic: string;
      danish: string;
      english: string;
    };
    otherSetting: {
      header: string;
      includeFromTeoriDK: string;
      saveForLater: string;
      nameOfTestPlaceholder: string;
    };
    strengthTest: string;
    strengthTestDescription: string;
    startTest: string;
    close: string;
  };
  quizQRGather: {
    teoriTestStarting: string;
    scanIfNotLoggedIn: string;
    openYourPhone: string;
  };
  quizMain: {
    left: string;
    right: string;
  };
  quizResult: {
    studentList: {
      chooseAll: string;
    };
    picture: string;
  };
}

/*HERE ON AND DOWN IT IS THE STUDENT THINGS */
interface StudentLoginPage {
  mobileNumberPlaceholder: string;
  backButton: string;
  continueButton: string;
  expiredCode: string;
  extend: string;
  Initial: {
    loginButton: string;
    errorMessage: string;
    loginSuccess: string;
    phonenumberLabel: string;
    passwordLabel: string;
    forgot: string;
    registerDescription: string;
    haveAccessCode: string;
  };
  FirstLogin: {
    firstTimeVisiting: string;
    havePasscode: string;
  };
  Confirmation: {
    sendConfirmationCode: string;
    weSentASMS: string;
    errorMessage: string;
  };
  AccessCode: {
    typeCodePlaceholder: string;
    errorMessages: {
      notFound: string;
      alreadyUsed: string;
      mobileUsed: string;
      expired: string;
      success: string;
      alreadyExisting: string;
    };
  };
  CreatePIN: {
    createPIN: string;
    repeatPIN: string;
    errorMessage: string;
    setPIN: string;
  };
  InformationSide: {
    header: string;
    Initial: {
      underHeader: string;
      underHeaderExplainer: string;
      buttonText?: string;
    };
    FirstLogin: {
      underHeader: string;
      underHeaderExplainer: string;
    };
    Confirmation: {
      underHeader: string;
      underHeaderExplainer: string;
    };
    AccessCode: {
      underHeader: string;
      underHeaderExplainer: string;
    };
    CreatePIN: {
      underHeader: string;
      underHeaderExplainer: string;
    };
  };
}

interface StudentSettings {
  yourInfo: string;
  description: string;
  inputLabels: {
    firstName: string;
    lastName: string;
    email: string;
    mobileNr: string;
  };
  yourDrivingSchool: string;
  languageSelect: {
    headline: string;
    danish: string;
    english: string;
  };
  categorySelect: {
    headline: string;
    car: string;
    motorcycle: string;
  };
  saveSettings: string;
  iOSCheckbox: string;
  iOSExtended: string;
  iOSAlertText: string;
  continue: string;
}

interface StudentDashboardPage {
  profileSettings: string;
  header: string;
  headerSpan: string;
  seeYourErrors: string;
  errorShorthand: string;

  trafficLightBox: {
    information: string;
    explanation: string;
    yourLastResult: string;
    outofTen: string;
    seeCalc: string;
    notReady: {
      header: string;
      span: string;
    };
    onTheWay: {
      header: string;
      span: string;
    };
    Ready: {
      header: string;
      span: string;
    };
  };
  InfoBox: {
    header: string;
    yourCalendar: string;
    welcome: string;
    betaText: string;
    ratingOfDrivingSchool: {
      header: string;
      teoriundervisning: string;
      driving: string;
      totalExperience: string;
    };
    important: string;
    youHave: string;
    daysAnd: string;
    hoursLeft: string;
    extendAccess: string;
  };
  startContentBox: {
    header: string;
    description: string;
  };
  generalContentBox: {
    header: string;
    description: string;
  };
  evalTestsContentBox: {
    header: string;
    description: string;
    button: string;
  };
  strengthTestContentBox: {
    header: string;
    description: string;
    button: string;
    noErrors: string;
  };
  onlineBookContentBox: {
    header: string;
    description: string;
    button: string;
    buttonDescription: string;
  };
  drivingSchoolLessonsContentBox: {
    header: string;
    description: string;
  };
  beforeDrivingTestContentBox: {
    header: string;
    description: string;
  };
  practicalInfoBox: {
    header: string;
    authorities: {
      header: string;
      pricesNfee: string;
      infoAboutKlippekort: string;
    };
    aboutTeori: {
      header: string;
      howToProlong: string;
      problemWithTests: string;
    };
    drivingSchoolCourse: {
      header: string;
      whenSmoothTrack: string;
      documentsTeoriAndTest: string;
    };
    yourOpinion: {
      header: string;
      areYouSatisfied: string;
      yes: string;
      no: string;
    };
  };
}
interface StudentQuizPage {
  Setup: {
    freeQuizFreeTest: string;
    setupOfTest: string;
    smallHeader: string;
    learningMode: string;
    learningModeDescription: string;
    standardMode: string;
    standardModeDescription: string;
    chooseTestSound: string;
    chooseTestSubs: string;
    testSubPlaceholder: string;
    startTest: string;
    iOSExplainer: string;
    languages: {
      none: string;
      danish: string;
      english: string;
      arabic: string;
      faerosk: string;
      polish: string;
      //TO BE EXTENDED
    };
  };
  Quiz: {
    yes: string;
    no: string;
    errorPage: {
      somethingwentWrong: string;
      tryagain: string;
      returntodashboard: string;
    };
    loadingText: string;
    soundError: {
      important: string;
      description: string;
    };
  };
  End: {
    purchaseAccess: string;
    dontLikeImage: string;
    dontLikeImageShorthand: string;
    soundExplained: string;
    soundExplainedShorthand: string;
    youpassed: string;
    youfailed: string;
    youhad: string;
    imagesPassed: string;
  };
}

interface TeacherStudentPage {
  stats: {
    description: string;
    progress: string;
    average: string;
  };
  placholder: {
    youAreLoggedIn: string;
    feedback: string;
  };
  graduationPage: {
    correct: string;
    outOf: string;
    youHad: string;
    passed: string;
    failed: string;
    amountOfCorrect: string;
  };
}
