import React from 'react';
import { getStringsForChosenLanguage, getChosenLanguage } from '../../../../../translations/translationProvider';
import "./styles/ResultView.scss";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';

export type IResultViewProps = {
    passStatus: boolean;
    amountCorrect: number;
    amount: number;
    isFreeQuiz?: boolean;
}

const ResultView: React.FC<IResultViewProps> = (props: IResultViewProps) => {

    const languageString = getStringsForChosenLanguage(getChosenLanguage()); 

    const renderStatus = () => {
        if(props.passStatus){
            return(
                <div className="passed">
                    <h3>{languageString.studentQuizPage.End.youpassed}</h3>
                    <hr></hr>
                </div>
            )
        }else{
            return(
                <div className="failed">
                    <h3>{languageString.studentQuizPage.End.youfailed}</h3>
                    <hr></hr>
                </div>
            )
        }
    }
    return (
        <div className='ResultView'>
        <div className="ResultViewBox">
            <div className="leftView">
                {props.passStatus ? <Button variant="outlined"><DoneRoundedIcon className='success'/></Button> : <Button variant="outlined"><CloseRoundedIcon className='failed'/></Button>}
            </div>
            <div className='rightView'>

            {renderStatus()}
            <div className='amount'>
                {languageString.studentQuizPage.End.youhad + " " + props.amountCorrect + "/" + props.amount + " " + languageString.studentQuizPage.End.imagesPassed}
            </div>
            </div>
        </div>
        {props.isFreeQuiz ? <div className='purchaseAccessBox'>
            <Button variant='contained' onClick={() => window.open("https://www.teoriundervisning.dk/#koebadgang")}>{languageString.studentQuizPage.End.purchaseAccess}</Button>
        </div> : null}
        </div>
    );
}

export { ResultView };