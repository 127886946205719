import React, { useState } from 'react';
import { useManageSubuserMutation } from '../../../../graphql/generated/graphql';

export type ISubuserCellManageProps = {
    
}

const SubuserCellManage: React.FC<ISubuserCellManageProps> = ({ }) => {

    
    const [manageSub] = useManageSubuserMutation();
    //THIS IS NOT A DONE COMPONENT
    //TODO FIX THIS AT A LATER DATE
    const [email, setemail] = useState<string>();
    const [password, setpassword] = useState<string>();
    const [name, setname] = useState<string>();
    const [departmentName, setdepartmentName] = useState<string>();
    const [departmentZIP, setdepartmentZIP] = useState<string>();
    const [isDepartment, setisDepartment] = useState(false);
    const [skip, setskip] = useState(true);

    return (
        <div className='SubuserCellManageProps'>
            <input></input>
            <input></input><input></input>
        </div>
    );
}

export { SubuserCellManage };