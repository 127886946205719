import React, { useEffect, useState } from "react";
import { EndSlide, EndStatement, MediaType } from "../../../model";
import SlideIMG from "../../../../assets/testImage.jpg";
import SlideSelector from "./components/SlideSelector";
import Slide from "./components/Slide";
import Loading from "../../../../globalComponents/Loading";
import "./End.scss";
import {
  Online_Quiz_Result,
  useGetLatestResultQuery,
  useGetQuizResultQuery,
} from "../../../../graphql/generated/graphqlStudent";
import { numberToMediaType } from "../../../modelFunctions";
import { useLocation } from "react-router";



export type IEndProps = {
  id: number;
  shouldFetchByOnlineID: boolean;
  onlineID?: string;
  isTeacher?: boolean;
  isFreeQuiz?:boolean;
};

const End: React.FC<IEndProps> = (props: IEndProps) => {
  const [endContent, setendContent] = useState<EndSlide[]>([]);
  //TODO ADD SUPPORT FOR A VIDEO IN THIS COMPONENT
  const [result, setresult] = useState<boolean>(false);
  const [isFailed, setisFailed] = useState(false);
  const { data: resultData, loading: resultLoading, refetch } = useGetLatestResultQuery({
    variables: { base_quiz_id: props.id === 0 || !props.id ? undefined : String(props.id) },
    skip: props.isFreeQuiz ? true : props.shouldFetchByOnlineID,
    fetchPolicy:"network-only"
  });

   const {data: onlineQuizData, loading: onlineQuizLoading, refetch: onlineQuizRefetch} = useGetQuizResultQuery({
     variables: { online_quiz_id: props.onlineID ? props.onlineID : "-1" },
     skip: props.isFreeQuiz ? false : !props.shouldFetchByOnlineID,
     fetchPolicy:"network-only"
   });


  const refetchQuery = () => {
    setisFailed(false);
    if(!props.shouldFetchByOnlineID){
      refetch();
    }else{
      onlineQuizRefetch();
    }
  }

  const getThumbnail = async (mediaString: string) => {
    const slideAddress = mediaString.split("/")[3];

    return await fetch(
      `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${slideAddress}`
    )
      .then((data) => data.json())
      .then((result) => {
        return result.thumbnail_url;
      });
  };

  const decideStatementAnswer = (passStatus: boolean, answerID: number) => {
    if(passStatus){
      return answerID;
    }else{
      if(answerID === 1){
        return 2;
      }else{
        return 1;
      }
    }
  }

  async function convertAPIToEndState(apiState: Online_Quiz_Result) {
    const tempArray: EndSlide[] = [];
    let slideIndex = 1;
    if (apiState.slide_progress) {
      for (const slide of apiState.slide_progress) {
        if (slide) {
          const tempStatements: EndStatement[] = [];
          if (slide?.statement_progress) {
            let index = 1;
            for (const statement of slide.statement_progress) {
              if (statement) {
                tempStatements.push({
                  id: index,
                  text: statement.sound_text ? statement.sound_text : "",
                  correctAnswer: statement.statement_answer_id!,
                  answer: Number(statement.statement_progress_answer),
                  explanation: statement.elaboration_Text
                    ? statement.elaboration_Text
                    : "",
                });
              }
              index++;
            }
          }
          tempArray.push({
            id: slideIndex,
            isMarked: slide.progress_is_marked!,
            progressID: slide.progress_id!,
            media: slide?.media_location ? slide.media_location : " ",
            tempMedia: slide?.media_location
            ? numberToMediaType(Number(slide.media_type_id)) ===
              MediaType.STREAM
              ? await getThumbnail(slide.media_location)
              : slide.media_location
            : " ",
            introQuestion: slide?.intro_sound_text
              ? slide.intro_sound_text
              : "",
            statements: tempStatements,
            passed: slide.slide_pass_status ? slide.slide_pass_status : false,
            mediaType: slide?.media_type_id
              ? numberToMediaType(Number(slide.media_type_id))!
              : MediaType.AZUREIMG,
          });
        }
        slideIndex++;
      }
      setresult(apiState.quiz_pass_status ? apiState.quiz_pass_status : false);
      return tempArray;
    }
  }
  useEffect(() => {
    if(!props.shouldFetchByOnlineID && !props.isFreeQuiz){
      if (!resultLoading) {
        if (resultData) {
          if (resultData.getLatestResult) {
            convertAPIToEndState(resultData.getLatestResult[0]!).then((data) => {
              if (data) {
                setendContent(data);
              }
            });
          }else{
            setisFailed(true);
          }
        }else{
          setisFailed(true);
        }
      }
    }else{
      if (!onlineQuizLoading) {
        if (onlineQuizData) {
          if (onlineQuizData.getQuizResult) {
            convertAPIToEndState(onlineQuizData.getQuizResult[0]!).then((data) => {
              if (data) {
                setendContent(data);
              }
            });
          }else{
            setisFailed(true);
          }
        }else{
          setisFailed(true);
        }
      }
    }
  }, [resultData, resultLoading, onlineQuizLoading, onlineQuizData]);
  return (
    <>
      <div className="QuizEnd">
        <SlideSelector slides={endContent} result={result} refetch={refetchQuery} isFailed={isFailed} isTeacher={props.isTeacher} isFreeQuiz={props.isFreeQuiz} />
      </div>
    </>
  );
};

export { End };