import React, { useEffect, useState } from 'react';
import { GetCategoriesQuery, useGetCategoriesQuery } from '../../../../../graphql/generated/graphql';

export type ICategorySelectProps = {
    data?: GetCategoriesQuery;
    value: string;
    onChange: any;
}

const CategorySelect: React.FC<ICategorySelectProps> = (props:ICategorySelectProps) => {

    const [categories, setcategories] = useState<JSX.Element[]>([<option value={"-1"}>{"Loading"}</option>]);

    const handleChange = (event) => {
        props.onChange(event.target.value)
    }

    useEffect(() => {
        if(props.data?.getCategories){
            const tempArray: any[] = [];
            props.data.getCategories.forEach((category) => {
                if(category){
                    tempArray.push(<option value={category.cat_id!}>{category?.cat_name}</option>)
                }
            })
            setcategories(tempArray);
        }
    }, [props.data]);
    return (
        <select onChange={handleChange} value={props.value}>
            {categories}
        </select>
    );
}

export { CategorySelect };