import React, { useEffect, useState } from "react";
import {
  getChosenLanguage,
  getStringsForChosenLanguage,
} from "../../../../translations/translationProvider";
import {
  CreateQuizSlide,
  CreateQuizStatement,
  LessonSlide,
  Tag,
} from "../../../model";
import saveIcon from "../../../../assets/save_icon.png";
import whiteArrow from "../../../../assets/white_Arrow.png";
import "./style/Settings.scss";
import TextField from "@mui/material/TextField";
import { styled } from "@material-ui/styles";
import {
  filterAndSortById,
  findById,
  findIndexById,
} from "../../../modelFunctions";
import { Button, Chip } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { FIND_INDEX_STATUS_NOT_FOUND } from "../../../../constants/generalConstants";
import { Statement } from "./Statement";
import AddIcon from "@mui/icons-material/Add";
import { IntroStatement } from "./IntroStatement";
import { useDeleteQuizStatementMutation, useDeleteSlideTagMutation, useGetSoundContentQuery } from "../../../../graphql/generated/graphql";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
export type ISettingsProps = {
  tags: Tag[];
  slide: CreateQuizSlide | undefined;
  slideOrderNumber: number | null;
  updateSelectedSlide: (slide: CreateQuizSlide) => void;
  handleSave: () => void;
  handleFinish: () => void;
  name: string;
  setname: React.Dispatch<React.SetStateAction<string>>;
  narrationSounds: availableSounds[];
};

interface availableSounds {
  key: string;
  id: number;
  label: string;
}

const Settings: React.FC<ISettingsProps> = (props: ISettingsProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  const [tempTags, settempTags] = useState(props.tags);

  const [isIntroQuestion, setisIntroQuestion] = useState(false);

  const [removeTag] = useDeleteSlideTagMutation();

  const [deleteStatement] = useDeleteQuizStatementMutation();

  const [statementBoxStates, setstatementBoxStates] = useState<
    CreateQuizStatement[]
  >([]);

  useEffect(() => {
    setInitialStatementBoxStatesValue();
  }, [props.slide?.id]);

  const handleOnDragEnd = (result: DropResult) => {
    if (result.destination) {
      const items = statementBoxStates;
      const [removed] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, removed);
      
      items.forEach((statement, index) => {
        statement.id = index+1;
      })
      console.log(items);
      setstatementBoxStates(items);
      console.log("do i get called?");
      
    }
  };

  const setInitialStatementBoxStatesValue = (): void => {
    if (!props.slide) {
      return;
    }
    if (props.slide.statements) {
      setstatementBoxStates(
        props.slide?.statements.map((statement, index) => {
          const state: CreateQuizStatement = {
            originalIndex: index,
            id: statement.id,
            editID: statement.editID ? statement.editID : undefined,
            soundAndText: statement.soundAndText,
            answer: statement.answer,
            elaboration: statement.elaboration,
            hasError: statement.hasError ? statement.hasError : false,
          };
          return state;
        })
      );
    }
  };

  const copyAndUpdateSlideState = (
    slide: CreateQuizSlide,
    callback: (slideCopy: CreateQuizSlide) => void
  ): void => {
    const slideCopy: CreateQuizSlide = slide;
    callback(slideCopy);
    props.updateSelectedSlide(slideCopy);
  };

  const handleSlideSoundExplanation = (event: React.ChangeEvent<any>, id, languageID) => {
    if(!props.slide){
      return;
    }
    console.log(event.target.files);
    
    const fileData = event.target.files?.[0];
    let tempExplanations = props.slide?.soundExplanations;
    if(fileData){
      console.log(fileData);
      
        if(tempExplanations){
          const explanationToChange = tempExplanations[id];
          if(explanationToChange){
            explanationToChange.tempFile = fileData;
          }else{
            tempExplanations.push({id: id, languageID: languageID, tempFile: fileData, media: fileData})
          }
        }else{
            tempExplanations= [{id: id, languageID: languageID, tempFile: fileData, media: fileData}];
        }
      copyAndUpdateSlideState(props.slide, (slideCopy) => {
        slideCopy.soundExplanations = tempExplanations;
      })
    }
  }

  const removeSlideSoundExplanation = (event, id) => {
    if(!props.slide){
      return;
    }
    if(!props.slide.soundExplanations){
      return;
    }else{
      const tempExplanations = props.slide.soundExplanations;
      const newtempExplanations = tempExplanations.filter(item => item.languageID !== id);
      console.log(newtempExplanations);
      
      copyAndUpdateSlideState(props.slide, (slideCopy) => {
        slideCopy.soundExplanations = newtempExplanations;
      })
    }

  }

  const deleteQuestion = (id: number, apiID?: string): void => {
    if (!props.slide) {
      return;
    }
    if(apiID){
      deleteStatement({variables:{quiz_statement_id: String(apiID)}})
    }
    copyAndUpdateSlideState(props.slide, (slideCopy) => {
      const filteredStatements = filterAndSortById(slideCopy.statements, id);
      let index = 1;
      for (const statement of filteredStatements) {
        statement.id = index;
        index++;
      }
      slideCopy.statements = filteredStatements;
      setstatementBoxStates(filteredStatements);
    });
  };

  const deleteIntroStatement = () => {
    if (!props.slide) {
      return;
    }

    copyAndUpdateSlideState(props.slide, (slideCopy) => {
      slideCopy.introQuestion = -1;
      setisIntroQuestion(false);
    });
  };

  const addIntroStatement = () => {
    if (!props.slide) {
      return;
    }
    copyAndUpdateSlideState(props.slide, (slideCopy) => {
      slideCopy.introQuestion = -1;
      setisIntroQuestion(false);
    });
    setisIntroQuestion(true);
  };

  const updateIntroStatement = (soundValue: number) => {
    if (!props.slide) {
      return;
    }
    copyAndUpdateSlideState(props.slide, (slideCopy) => {
      slideCopy.introQuestion = soundValue;
    });
  };

  const addStatement = (): void => {
    if (
      !props.slide ||
      (props.slide.statements && props.slide.statements.length > 4)
    ) {
      alert("NO MORE THAN 4 STATEMENTS CAN BE ADDED"); //TODO ADD LANGUAGE STRING
      return;
    }
    console.log("do i execute");

    let newStatement: CreateQuizStatement = {
      originalIndex: props.slide.statements ? props.slide.statements.length + 1 : 1,
      id: props.slide.statements ? props.slide.statements.length + 1 : 1,
      soundAndText: { id: -1, soundLocation: "", soundText: "none" },
      answer: -1,
      elaboration:[{languageID: 1, text: "", id: 0}, {languageID: 2, text: "", id: 0}],
    };
    const statementsCopy = [...statementBoxStates];
    statementsCopy.push(newStatement);
    setstatementBoxStates(statementsCopy);
  };

  const renderTags = () => {
    const tempArray: JSX.Element[] = [];
    tempArray.push(
      <option key={-1} value={-1}>
        {"Vælg tag"}
      </option>
    );
    tempTags.forEach((tag) => {
      tempArray.push(
        <option key={tag.id} value={tag.id}>
          {tag.name}
        </option>
      );
    });
    return tempArray;
  };

  const handleChangeTags = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const chosenTagID = Number(event.target.value);
    const chosenTag = findById(tempTags, chosenTagID);
    console.log(chosenTag);
    if (!props.slide) {
      return;
    } else if (chosenTag) {
      copyAndUpdateSlideState(props.slide, (slideCopy) => {
        if (slideCopy.tags) {
          slideCopy.tags.push(chosenTag);
        } else {
          slideCopy.tags = [chosenTag];
        }
      });
      console.log(tempTags.filter((tag) => tag.id === chosenTagID));

      settempTags((prevState) =>
        prevState.filter((tag) => tag.id !== chosenTagID)
      );
    }
  };

  const handleRemoveTag = (id: number) => {
    if (props.slide?.tags) {
      console.log("hi");

      const chosenTag = findById(props.slide?.tags, id);
      if(props.slide.editID && chosenTag){
        removeTag({variables:{delete_quiz_slide_id: String(props.slide.editID), delete_tag_id: String(chosenTag.id)}})
      }
      console.log(chosenTag);

      copyAndUpdateSlideState(props.slide, (slideCopy) => {
        if (slideCopy.tags) {
          const filteredSlideTags = slideCopy.tags.filter(
            (tag) => tag.id !== chosenTag?.id
          );
          slideCopy.tags = filteredSlideTags;
        }
      });
      setTags();
      renderSelectedTags();
    }
  };

  const handleSlideFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if(!props.slide){
      return
    }
    copyAndUpdateSlideState(props.slide, (slideCopy) => {
      slideCopy.slideFilter = event.target.value;
    })
  }

  function setTags() {
    console.log("do i render");

    let tempArray: Tag[] = props.tags;
    if (props.slide?.tags) {
      props.slide?.tags.forEach((tag) => {
        tempArray = tempArray.filter((tempTag) => tempTag.id !== tag.id);
      });
      settempTags(tempArray);
    }
  }

  const renderSelectedTags = () => {
    const tempChipArray: JSX.Element[] = [];
    if (props.slide?.tags) {
      props.slide?.tags.forEach((tag) => {
        tempChipArray.push(
          <Chip
            key={tag.id}
            variant="outlined"
            label={tag.name}
            onDelete={() => handleRemoveTag(tag.id)}
          />
        );
      });
    }
    return tempChipArray;
  };

  const renderSlideFilter = () => {
    return(
      <select onChange={handleSlideFilter} value={props.slide?.slideFilter}>
        <option value="-1">Vælg indholds modtager</option>
        <option value="0">Ingen</option>
        <option value="1">Elever</option>
        <option value="2">Lærere</option>
        <option value="3">Begge</option>
      </select>
    )
  }

  const renderStatements = (): JSX.Element => {
    if (props.slide?.statements) {
      const statements = props.slide?.statements.map((statement, index) => {
        const statementState = findById(statementBoxStates, statement.id);

        if (statementState) {
          return (
            <Draggable
              key={statement.originalIndex}
              draggableId={String(statement.originalIndex)}
              index={index}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Statement
                    key={statement.originalIndex}
                    narrationSounds={props.narrationSounds}
                    statement={statementState}
                    setStatements={setstatementBoxStates}
                    deleteStatement={deleteQuestion}
                    length={statementBoxStates.length}
                  />
                </div>
              )}
            </Draggable>
          );
        }
      });

      return (
        <>
          {statements}
          {props.slide?.statements.length < 4 ? (
            <div>
              <Button className="addStatementButton" onClick={addStatement}>
                <AddIcon />
              </Button>
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <div>
          <Button className="addStatementButton" onClick={addStatement}>
            <AddIcon />
          </Button>
        </div>
      );
    }
  };

  const renderIntroQuestion = (): JSX.Element => {
    if (isIntroQuestion && props.slide?.introQuestion) {
      return (
        <IntroStatement
          key={0}
          introQuestion={props.slide?.introQuestion}
          narrationSounds={props.narrationSounds}
          deleteIntroStatement={deleteIntroStatement}
          updateIntroStatement={updateIntroStatement}
        />
      );
    } else {
      return (
        <div>
          <Button className="addStatementButton" onClick={addIntroStatement}>
            <AddIcon />
          </Button>
        </div>
      );
    }
  };

  const renderSoundExplanationButtons = ():JSX.Element[] => {
    const currentLanguages =[ {
      id: 1,
      language: "Dansk",
    }, {
      id: 2,
      language: "Engelsk",
    }]

    return currentLanguages.map((language, index) => {
      if(props.slide?.soundExplanations){
        if(props.slide.soundExplanations[index]){
          return <Button onClick={(event) => removeSlideSoundExplanation(event, language.id)}>{currentLanguages[index].language} < CloseIcon/></Button>
        }else{
          return <Button><input type={"file"} accept={".mp3"} onChange={(event) => handleSlideSoundExplanation(event, index, language.id)}></input>Tiløj {language.language}</Button>
        }
      }else{
        return <Button><input type={"file"} accept={".mp3"} onChange={(event) => handleSlideSoundExplanation(event, index, language.id)}></input>Tiløj {language.language}</Button>
      }
    })
  }

  useEffect(() => {
    console.log("i execute");

    setTags();
    renderSelectedTags();
    if (props.slide?.introQuestion !== -1) {
      setisIntroQuestion(true);
    }
  }, [props.slide]);

  useEffect(() => {
    if (!props.slide) {
      return;
    }
    copyAndUpdateSlideState(props.slide, (slideCopy) => {
      slideCopy.statements = statementBoxStates;
      let hasError = false;
      slideCopy.statements.forEach((statement) => {
        if (statement.hasError) {
          hasError = true;
        }
      });

      slideCopy.hasError = hasError;
    });
  }, [statementBoxStates]);

  return (
    <div className={props.slide ? "Settings" : "Settings disabled"}>
      <input
        className="nameInput"
        value={props.name}
        onChange={(event) => props.setname(event.target.value)}
      />
      <span>
        {languageString.createLessonPage.slide + " " + props.slideOrderNumber}
      </span> 
      <div className="idDisplay">ID : {props.slide?.editID ? props.slide.editID : "-" }</div>
      <div className="tagSelectAndSlideFilter">
      <select value={-1} onChange={handleChangeTags}>
        {renderTags()}
      </select>
      {renderSlideFilter()}</div>
      <div className="selectedTags">{renderSelectedTags()}</div>
      <div className="soundExplanations"><label>Lydforklaringer til slide: </label>{renderSoundExplanationButtons()}</div>
      <div className="statements">
        {renderIntroQuestion()}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="actualStatements">
            {(provided) => (
              <ul className="actualStatements" ref={provided.innerRef}>
                {renderStatements()}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="bottom">
        <div className="finish">
          <Button
            onClick={props.handleSave}
            variant="contained"
            className="clickable"
          >
            <img alt="SAVE-ICON" src={saveIcon}></img>
          </Button>
          <label>{languageString.createLessonPage.save}</label>
        </div>
        <div className="finish">
          <Button
            onClick={props.handleFinish}
            variant="contained"
            className="finishButton"
          >
            <img alt="FINISH-ICON" src={whiteArrow}></img>
          </Button>
          <label>{languageString.createLessonPage.finish}</label>
        </div>
      </div>
    </div>
  );
};

export { Settings };
