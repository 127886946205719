import React, { useEffect, useRef, useState } from "react";
import PinInput from "../../../../globalComponents/PinInput";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../translations/translationProvider";
import arrowIcon from "../../../../assets/white_Arrow.png";
import { fillArrayWithEmptyStrings } from "../../../../services/utilFunctions";
import { useAddPinStudentMutation } from "../../../../graphql/generated/graphqlStudent";
import { LoginStep } from "../../../model";
import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";
import "../style/CreatePin.scss";
import { Button } from "@mui/material";

export type ICreatePinProps = {
  setLoginStep: any;
  mobileNumber: string;
  setIsLoading: (value: boolean | ((prevState: boolean) => boolean)) => void;
  setPINCode: (value: string | ((prevState: string) => string)) => void;
  preFix: string;
  setpreFix: (value: string) => void
};

const CreatePin: React.FC<ICreatePinProps> = (props: ICreatePinProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [createPIN, setCreatePIN] = useState("");
  const [createPINInputState, setCreatePINInputState] = useState<string[]>(() =>
    fillArrayWithEmptyStrings(4)
  );
  const [repeatPIN, setRepeatPIN] = useState("");
  const [repeatPINInputState, setRepeatPINInputState] = useState<string[]>(() =>
    fillArrayWithEmptyStrings(4)
  );
  const [error, setError] = useState(false);
  const [goodToGo, setGoodToGo] = useState(false);

  const [addPINtoStudent] = useAddPinStudentMutation();

  const handleNextClick = () => {
    props.setIsLoading(true);
    addPINtoStudent({
      variables: {
        phone_number: props.preFix + props.mobileNumber,
        pin_code: createPIN,
      },
    }).then((data) => {
      props.setIsLoading(false);
      if (data.data?.AddPinStudent === "1") {
        props.setLoginStep(LoginStep.Initial);
      } else {
      }
    });
  };

  useEffect(() => {
    if (
      createPIN.length > 3 &&
      repeatPIN.length > 3 &&
      createPIN === repeatPIN
    ) {
      setGoodToGo(true);
      setError(false);
    } else if (
      createPIN.length > 3 &&
      repeatPIN.length > 3 &&
      createPIN !== repeatPIN
    ) {
      setError(true);
    } else {
      setGoodToGo(false);
      setError(false);
    }
  }, [createPIN, repeatPIN]);

  useEffect(() => {
     const firstpininput = document.getElementsByClassName("pinInput")[0];
     if(firstpininput && firstpininput instanceof HTMLInputElement){
       firstpininput.focus();
     }
  }, []);

  const errorMessage = (
    <span>{languageString.studentLoginPage.CreatePIN.errorMessage}</span>
  );

  return (
    <div className="CreatePINPage">
      <div className="firstColumn">
        <div className="pinNmessage">
          <span>{languageString.studentLoginPage.CreatePIN.createPIN}</span>
          <div className="pinContainer">
            <PinInput
              setPIN={setCreatePIN}
              setPINInputState={setCreatePINInputState}
              PINInputState={createPINInputState}
              className="pinInput"
            />
          </div>
        </div>
        <div className="pinNmessage">
          <span>{languageString.studentLoginPage.CreatePIN.repeatPIN}</span>
          <div className="pinContainer">
            <PinInput
              setPIN={setRepeatPIN}
              setPINInputState={setRepeatPINInputState}
              PINInputState={repeatPINInputState}
              className="pinInput"
            />
          </div>
        </div>
      </div>
      <div className="error">{error ? errorMessage : null}</div>
      <div className="lastColumn">
        <div className="bottomButton">
          <Button
            disabled={!goodToGo}
            variant="contained"
            onClick={handleNextClick}
          >
            {languageString.studentLoginPage.CreatePIN.setPIN}{" "}
            <DoneOutlineRoundedIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export { CreatePin };
