import React, { useEffect, useState } from "react";
import { GetSchoolListQuery } from "../../../../../graphql/generated/graphql";

export type ISchoolSelectProps = {
  data?: GetSchoolListQuery;
  onChange: any;
  value: string;
};

const SchoolSelect: React.FC<ISchoolSelectProps> = (
  props: ISchoolSelectProps
) => {
  const [schoolList, setschoolList] = useState([
    <option value="-1">Loading...</option>]
  );

  const handleChange = (event) => {
      console.log(event.target.value);
      props.onChange(event.target.value)
  }

  useEffect(() => {
    if (props.data) {
        const tempArray: JSX.Element[] = [];
        props.data.getSchoolList?.forEach((school) => {
            tempArray.push(<option value={school!.school_id!}>{school?.school_name + ` (${school?.zipcode})`}</option>)
        })
        setschoolList(tempArray);
      //WE SET THE SCHOOL LIST INSIDE HERE.
      //WE GET THE DATA ON THE SIDE LOAD, AS THIS QUERY IS HEAVY AND WE CANT WAIT FOR IT EVERYTIME
    }
  }, [props.data]);
  return <select onChange={handleChange} value={props.value}>{schoolList}</select>;
};

export { SchoolSelect };
