import { LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export type ILoadingBarProps = {
    key: number
    progress: number;
    total: number;
    name: number;
}

const LoadingBar: React.FC<ILoadingBarProps> = (props: ILoadingBarProps) => {
    const [ïnsideProgress, setïnsideProgress] = useState(props.progress);



    useEffect(() => {
        const interval =  setInterval(() => setïnsideProgress((prevState) => {return  prevState + 1.5}), 500);
    }, [props.progress]);

    return (
        <div className="loadingBar">
            <LinearProgress variant="determinate" value={(ïnsideProgress / props.total) * 100} />
           {/* <span>{props.name}</span> */}
        </div>
    );
}

export { LoadingBar };