import React, { useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import Loading from "../../../../../globalComponents/Loading";
import { TeoriLogo } from "../../../../../globalComponents/TeoriLogo";
import { useGetClassStudentParticipantQuery, useGetClassStudentResultQuery, useGetQuizResultQuery } from "../../../../../graphql/generated/graphqlStudent";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../../translations/translationProvider";
import "./TeacherStudentGraduation.scss"
import { TeacherStudentResultSlide } from "./TeacherStudentResultSlide";

export type ITeacherStudentGraduationProps = {
  graduationState?: any;
  socket: Socket;
  quizID: number;
};
interface QuizPassState {
  amount: number;
  failed: number;
  passed: number;
}

interface ResultSlide{
  introSoundText: string;
  statements: ResultStatement[];
}
interface ResultStatement{
  answer: number;
  correctAnswer: number;
  text: string;
  passed: boolean;
}

const TeacherStudentGraduation: React.FC<ITeacherStudentGraduationProps> = (
  props: ITeacherStudentGraduationProps
) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [isLoading, setisLoading] = useState(true);
  const [state, setstate] = useState<QuizPassState | undefined>();
  const [selectedSlide, setselectedSlide] = useState<number | undefined>();
  const [selectedState, setselectedState] = useState<number>(0);
  const [slides, setslides] = useState<ResultSlide[]>();
  const [currentStatement, setcurrentStatement] = useState<number>(0);
  const {data: slideData, loading} = useGetClassStudentResultQuery({variables:{class_quiz_id: String(props.quizID)}, pollInterval: 15000})
  const {data: resultData} = useGetQuizResultQuery({variables: {online_quiz_id: localStorage.getItem("online_quiz_class_id") ? localStorage.getItem("online_quiz_class_id")! : "-1"}, pollInterval: 15000 })
  const {data: participantData} = useGetClassStudentParticipantQuery({variables:{class_quiz_id: String(props.quizID)}, pollInterval: 15000})

  const [hasBeenCalled, sethasBeenCalled] = useState(false);

  const [AmountState, setAmountState] = useState<any>(undefined);

  useEffect(() => {
    if(participantData?.getClassStudentParticipant){
      setstate({amount: participantData.getClassStudentParticipant.student_amount! , failed: participantData.getClassStudentParticipant.student_failed!, passed: participantData.getClassStudentParticipant.student_passed!})
    }
  }, [participantData]);

  useEffect(() => {
    if(resultData?.getQuizResult){
      
      if(resultData.getQuizResult[0]){

        setAmountState({slide_amount: resultData.getQuizResult[0].slide_amount, slides_failed: resultData.getQuizResult[0].slides_failed, quiz_pass_status: resultData.getQuizResult[0].quiz_pass_status})
        const tempSlideArray: ResultSlide[] = [];
        if(slideData?.getClassStudentResult){
          
          slideData.getClassStudentResult.forEach((slide) => {
            const tempStatementArray: ResultStatement[] = [];
            slide?.slide_statements?.forEach((statement) => {
              tempStatementArray.push({
                answer: statement?.statement_progress_answer!,
              correctAnswer: statement?.statement_answer_id!,
              text: statement?.sound_text!,
              passed: statement?.slide_pass_status!
            })
          })
         
          tempSlideArray.push({
            introSoundText: slide?.intro_sound_text!,
            statements: tempStatementArray
          })
        })
        setslides(tempSlideArray); 
        sethasBeenCalled(true);
      }
      }
    }
  }, [resultData]);
  
  const setStateAndSlide = (slideID: number, state: number) => {
    if(slides){

      
      setselectedSlide(slideID);
      setselectedState(state);
    }
  }

  const setStatementAndSlide = (slideID: number, statementID: number) => {
    if(slides){

      
      setselectedSlide(slideID);
      setcurrentStatement(statementID);
    }
  }

  useEffect(() => {
    //IN HERE WE SHOULD FETCH THE RESULT IN SOMEWAY
    if(slides){
      props.socket.on("quiz-direct-slide", (data) => {
        setStateAndSlide(Number(data.slide_id), Number(data.current_stage));
      })
      props.socket.on("quiz-direct-statement", (data) => {
        setStatementAndSlide(Number(data.slide_id), Number(data.current_statement));
      })
    }
  }, [slides]);
  


  const renderGradState = () => {
    if(!hasBeenCalled){
      return (
        <div className="success">
          <div>{"Vent venligst..."}</div>
          <div>
            {"...."}
          </div>
          <div>{"...."}% af din klasse bestod</div>
        </div>
      )
    }
    if (AmountState.quiz_pass_status === true) {
      return (
        <div className="success">
          <div>{languageString.teacherStudentPage.graduationPage.passed}</div>
          <div>
            {languageString.teacherStudentPage.graduationPage.youHad +
              " " +
              (AmountState.slide_amount -
                AmountState.slides_failed) +
              " " +
              languageString.teacherStudentPage.graduationPage.outOf + 
              " " + 
              AmountState.slide_amount +
              " " +
              languageString.teacherStudentPage.graduationPage.correct}
          </div>
          <div>{state ? 100 - (state?.failed / state?.amount * 100) : " "}% af din klasse bestod</div>
        </div>
      );
    } else {
      return <div className="failure">
          <div>{languageString.teacherStudentPage.graduationPage.failed}</div>
          <div>
            {languageString.teacherStudentPage.graduationPage.youHad +
              " " +
              (AmountState.slide_amount -
                AmountState.slides_failed) +
              " " +
              languageString.teacherStudentPage.graduationPage.outOf + 
              " " + AmountState.slide_amount +
              " " +
              languageString.teacherStudentPage.graduationPage.correct}
          </div>
          <div>{state ? 100 - (state?.failed / state?.amount * 100) : " "}% af din klasse bestod</div>
      </div>;
    }
  };
  return (
    <div className="TeacherStudentGraduation">
      {AmountState === undefined ? (
        <>
          <Loading />
        </>
      ) : (
        <>
        <TeoriLogo />
        {renderGradState()}
        {selectedSlide !== undefined && selectedSlide !== -1 ? <TeacherStudentResultSlide current={selectedSlide + 1} length={slides!.length} PassedSlide={slides![selectedSlide]} currentState={selectedState} currentStatement={currentStatement}/> : null}
        </>
      )}
    </div>
  );
};

export { TeacherStudentGraduation };
