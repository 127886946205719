import React, { useContext, useEffect, useState } from "react";
import PinInput from "../../../../globalComponents/PinInput";
import { fillArrayWithEmptyStrings } from "../../../../services/utilFunctions";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../translations/translationProvider";
import { LoginStep } from "../../../model";
import arrowIcon from "../../../../assets/white_Arrow.png";
import { useLoginStudentMutation } from "../../../../graphql/generated/graphqlStudent";
import IntermediaryLoading from "../../../../globalComponents/IntermediaryLoading";
import failIcon from "../../../../assets/fail_icon.png";
import successIcon from "../../../../assets/checkmark_icon.png";
import { AppStateStudentContext } from "../../../../apollo/TeoriStudentProvider";
import {
  PATH_DASHBOARD,
  PATH_PROFILE,
} from "../../../../constants/pathConstants";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { TeoriLogo } from "../../../../globalComponents/TeoriLogo";
import "../style/Initial.scss";

interface loginType {}

export type IInitialProps = {
  setLoginStep: any;
  mobileNumber: string;
  setMobileNumber: (value: string | ((prevState: string) => string)) => void;
  PIN: string;
  setPIN: (value: string | ((prevState: string) => string)) => void;
  setIsLoading: (value: boolean | ((prevState: boolean) => boolean)) => void;
  buttonText?: string;
  buttonAction?: () => void;
  setpreFix: (value: string) => void
  preFix: string;
  handleActivationClick: () => void;
  handleRemoveSupport: () => void;
};

const Initial: React.FC<IInitialProps> = (props: IInitialProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [goodToGo, setGoodToGo] = useState(false);
  const [Login, { loading }] = useLoginStudentMutation({fetchPolicy: "no-cache"});
  const context = useContext(AppStateStudentContext);
  const history = useHistory();

  const [localLoading, setLocalLoading] = useState(false);

  const [error, setError] = useState<string>();

  const [PINInputState, setPINInputState] = useState<string[]>(() =>
    fillArrayWithEmptyStrings(4)
  );

  const handleChangePhoneNumber = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      props.setMobileNumber(event.target.value);
    }
  };

  const handleFirstTimeButton = () => {
    props.setLoginStep(LoginStep.FirstLogin);
  };

  const handleForgotPIN = () => {
    props.setLoginStep(LoginStep.Confirmation);
  }

  const handleLoginClick = async () => {
    setLocalLoading(true);
    await Login({
      variables: {
        phone_number: props.preFix + props.mobileNumber,
        pin_code: props.PIN,
      }, onError: (e) => {
        if(e.message === "pin_not_set"){
          props.setLoginStep(LoginStep.CreatePin);
        }else if(e.message === "user_expired"){
          props.setLoginStep(LoginStep.AccessExpired);
        }else{
          setError(languageString.studentLoginPage.Initial.errorMessage);
        }
      } 
    })
      .then((data) => {

        const tokens = data.data?.LoginStudent;
        if (tokens) {
          if (!tokens.login_before) {
            sessionStorage.setItem("has_login", "false");
          }
          props.handleRemoveSupport();
          setTimeout(() => {
            localStorage.setItem("TEORIFIRSTTIME", "NO");
            context.appSetLogin(tokens.accessToken!, tokens.refreshToken!);
          }, 1500);
        }
      })
      .catch((e) => {
        
      });
  };

  useEffect(() => {
    if (props.mobileNumber.length >= 4 && props.PIN.length > 3) {
      setGoodToGo(true);
    } else {
      setGoodToGo(false);
    }
  }, [props.mobileNumber, props.PIN]);

  useEffect(() => {
    if (localLoading) {
      if (error) {
        setTimeout(() => {
          setLocalLoading(false);
          setError(undefined);
        }, 2200);
      }
    }
  }, [localLoading, error]);

  const handleKeyDown = (event) => {
    if(event.code === "Enter"){
      handleLoginClick();
    }
  }

  const finalLoader = () => {
    while (loading) {
      return <IntermediaryLoading />;
    }

    return (
      <div className="opaqueContainer">
        {error ? (
          <div className="error">
            <img src={failIcon} alt="errorImage"></img>
            <span>{error}</span>
          </div>
        ) : (
          <div className="success">
            <img src={successIcon} alt="successImage"></img>
            <span>{languageString.studentLoginPage.Initial.loginSuccess}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="InitialPage">
      <div className="loginInput">
        <label>
          {languageString.studentLoginPage.Initial.phonenumberLabel}
        </label>
        <div className="firstInput">
          <input
            value={props.preFix}
            onChange={(event) => props.setpreFix(event.target.value)}
            className="preFix"
          />
          <input
            placeholder={
              languageString.studentLoginPage.mobileNumberPlaceholder
            }
            value={props.mobileNumber}
            onChange={handleChangePhoneNumber}
            type="tel"
          ></input>
        </div>
        <label>{languageString.studentLoginPage.Initial.passwordLabel}</label>
        <div className="pinContainer" onKeyDown={handleKeyDown}>
          <PinInput
            className="PINInput"
            setPIN={props.setPIN}
            setPINInputState={setPINInputState}
            PINInputState={PINInputState}
            type={"password"}
          />
        </div>
        <div className="bottomButton">
          <Button
            className={!goodToGo ? "disabledButton loginButton" : "loginButton"}
            onClick={handleLoginClick}
          >
            {languageString.studentLoginPage.Initial.loginButton}
          </Button>
          <div className="forgot" onClick={handleForgotPIN}>{languageString.studentLoginPage.Initial.forgot}</div>
        </div>
        <div className="registerButton">
        <Button onClick={props.buttonAction}>{props.buttonText}</Button>
        <Button className="accessCode" onClick={props.handleActivationClick}>{languageString.studentLoginPage.Initial.haveAccessCode}</Button>
        </div>
      </div>
      {localLoading ? finalLoader() : null}
    </div>
  );
};

export { Initial };
