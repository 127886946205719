import { Button, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useEffect, useState } from "react";
import "./OwnMaterial.scss";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import randomImage from "../../../../../assets/testImage.jpg";
import { LessonSlide, LessonType, MediaType } from "../../../../model";
import VideoThumbnail from "react-video-thumbnail";
import {
  useGetOwnedContentLazyQuery,
  useGetOwnedContentQuery,
  useGetSasLazyQuery,
  useGetSasQuery,
  useManageContentMutation,
} from "../../../../../graphql/generated/graphql";
import { AppStateContext } from "../../../../../apollo/TeoriTeacherProvider";
import { uploadOwnContent } from "../../../../../services/azureStorage";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CircularProgress } from "@material-ui/core";

export type IOwnMaterialProps = {
  handleFinishCreateNewSlide: (newSlide: any) => void;
};

const OwnMaterial: React.FC<IOwnMaterialProps> = (props: IOwnMaterialProps) => {
  const context = useContext(AppStateContext);

  const [isFileInput, setisFileInput] = useState(false);
  const [fileBlob, setfileBlob] = useState<File | undefined>(undefined);
  const [videoThumbnail, setvideoThumbnail] = useState<any | undefined>(
    undefined
  );
  const [isLoading, setisLoading] = useState(true);
  const [getSAS, { data: SASKey }] = useGetSasLazyQuery({
  });

  const [manageContent] = useManageContentMutation();
  const [isPictureUploading, setisPictureUploading] = useState(false);

  const [selectedSlide, setselectedSlide] = useState(-1);
  const [ownMaterialList, setownMaterialList] = useState<JSX.Element[]>([
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
    <Skeleton variant="rectangular" />,
  ]);

  const {
    data: ownedContentData,
    loading,
    fetchMore,
    refetch
  } = useGetOwnedContentQuery({
    variables: {},
  });


  useEffect(() => {
    if(!SASKey?.getSAS){
      getSAS();
    }
   
  }, []);

  async function setOwnMaterial() {
    const tempArray: JSX.Element[] = [];
    if (ownedContentData?.getOwnedContent) {
      for (const slide of ownedContentData.getOwnedContent) {
        if (slide?.media_type_id === 1) {
          const slideAddress = slide.media_location?.split("/")[3];

          await fetch(
            `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${slideAddress}`
          )
            .then((data) => data.json())
            .then((result) => {
              console.log(result.thumbnail_url);

              tempArray.push(
                <img
                  src={result.thumbnail_url}
                  id={slide.content_id ? slide.content_id : "not found"}
                  className={
                    selectedSlide === Number(slide.content_id)
                      ? "selected"
                      : selectedSlide === -1
                      ? "none-selected"
                      : "not-selected"
                  }
                  onClick={(event) =>
                    handleSelectSlide(Number(slide.content_id), selectedSlide)
                  }
                  alt={slide?.media_location ? slide.media_location : "Random"}
                  key={slide!.media_location}
                ></img>
              );
            });
        } else {
          tempArray.push(
            <img
              src={slide?.media_location ? slide.media_location : ""}
              id={slide!.content_id ? slide!.content_id : "not found"}
              className={
                selectedSlide === Number(slide!.content_id)
                  ? "selected"
                  : selectedSlide === -1
                  ? "none-selected"
                  : "not-selected"
              }
              onClick={(event) =>
                handleSelectSlide(Number(slide!.content_id), selectedSlide)
              }
              alt={slide?.media_location ? slide.media_location : "Random"}
              key={slide!.content_id}
            ></img>
          );
        }
      }
    }
    console.log(tempArray.length);
    tempArray.reverse();
    setownMaterialList(tempArray);
  }

  useEffect(() => {
    if (!loading) {
      console.log(ownedContentData);
      setOwnMaterial();
    }
  }, [selectedSlide, loading, ownedContentData]);

  const handleSelectSlide = (id: number, selectedSlideNow) => {
    console.log("mee");

    if (id === selectedSlideNow) {
      console.log("the same");

      setselectedSlide(-1);
    } else {
      setselectedSlide(id);
    }
  };

  const handleUpload = async (event: React.ChangeEvent<any>) => {
    const fileData = event.target.files;
    console.log(fileData);

    if (SASKey) {
      setisPictureUploading(true);
      for (const file of fileData) {
        await uploadOwnContent(
          SASKey.getSAS,
          file,
          Number(context.appGetUserID())
        ).then(async (data) => {
          if (data) {
            const url = data?.split("?sv")[0];
            await manageContent({
              variables: { content: { media_location: url, media_type_id: 5 } },
            }).then((data) => refetch());
          }
        });
      }
    }
    setTimeout(() => setisPictureUploading(false), 2000);
  };
  const handleRemoveUpload = () => {
    setfileBlob(undefined);
    setisFileInput(false);
    if (videoThumbnail) {
      setvideoThumbnail(undefined);
    }
  };

  const handleFinish = () => {
    if (isFileInput && fileBlob) {
      if (videoThumbnail) {
        const newSlide = {
          media: videoThumbnail,
          tempFile: fileBlob,
          type: MediaType.LOCALVIDEO,
          notes: "",
        };
        props.handleFinishCreateNewSlide(newSlide);
      } else {
        const newSlide = {
          media: URL.createObjectURL(fileBlob),
          tempFile: fileBlob,
          type: MediaType.AZUREIMG,
          notes: "",
        };
        props.handleFinishCreateNewSlide(newSlide);
      }
    }
    if (selectedSlide !== -1) {
      const theSelectedSlide = ownMaterialList.find(
        (slide) => slide.props.id === String(selectedSlide)
      );

      if (theSelectedSlide) {
        const newSlide = {
          content_id: theSelectedSlide.props.id,
          media: theSelectedSlide.props.src,
          tempFile: theSelectedSlide.props.alt, //THIS IS TO STORE THE PATH WHERE IT IS
          type: theSelectedSlide.props.src.includes(
            "https://i.vimeocdn.com/video"
          )
            ? MediaType.STREAM
            : MediaType.AZUREIMG,
          notes: "",
        };
        props.handleFinishCreateNewSlide(newSlide);
      }
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      fetchMore({
        variables: {
          limit: ownMaterialList.length + 30,
          offset: ownMaterialList.length,
        },
      });
    }
  };

  const renderFileorButton = () => {
    if (isPictureUploading) {
      return (
        <Button className="uploadButton" color="inherit">
          <CircularProgress />
        </Button>
      );
    } else {
      return (
        <Button className="uploadButton" color="inherit">
          <FileUploadIcon />
          <span>UPLOAD BILLEDER HER</span>
          <input
            type="file"
            accept=".jpg, .png, .webp"
            multiple={true}
            onChange={handleUpload}
          ></input>{" "}
        </Button>
      );
    }
  };

  const doneButton = (
    <div className="done">
      <button
        className={isFileInput || selectedSlide !== -1 ? "finishAble" : ""}
        onClick={handleFinish}
      >
        <DoneOutlineIcon />
      </button>
    </div>
  );

  return (
    <>
      <div className="OwnMaterial" >
        <div className={isFileInput ? "ownSlides disabled" : "ownSlides"} onScroll={handleScroll}>
          {ownMaterialList}
        </div>
        <div
          className={
            selectedSlide !== -1 ? "uploadSlide disabled" : "uploadSlide"
          }
        >
          {renderFileorButton()}
        </div>
      </div>
      {doneButton}
    </>
  );
};

export default OwnMaterial;
