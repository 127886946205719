import React, { useState } from "react";
import { MediaType, VideoType } from "../../../../../model";
import { Video } from "./Video";
import { Image } from "./Image";
import "./Media.scss";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export type IMediaProps = {
  isNoSound: boolean;
  media: string;
  mediaType: MediaType;
  hasVideoEnded: boolean;
  setHasVideoEnded: React.Dispatch<React.SetStateAction<boolean>>;
};



const Media: React.FC<IMediaProps> = (props: IMediaProps) => {


  const videoOrSlide = (): JSX.Element => {
    let isVideo;
    const string = props.media.split("//", 2);

    if (props.mediaType === MediaType.STREAM) {
      return (
        <div>
        <Video
          key={props.media}
          video={props.media}
          videoType={VideoType.STREAM}
          hasVideoEnded={props.hasVideoEnded}
          setHasVideoEnded={props.setHasVideoEnded}
          isNoSound={props.isNoSound}
        />
        </div>
      );
    } else {
      props.setHasVideoEnded(true);
      if(props.isNoSound){
        return (
          <div>
          <Image key={props.media} image={props.media} isNoSound={true} />
          </div>

        )
      }
        return (
          <div>

        <TransformWrapper maxScale={2}>
          <TransformComponent
            wrapperClass="zoomProvider"
            contentClass="zoomContent"
          >
            <Image key={props.media} image={props.media} />{" "}
          </TransformComponent>
        </TransformWrapper>
        </div>

      );
    }
  };

  return <div className="Media">{videoOrSlide()}</div>;
};

export { Media };
