import React, { useEffect, useState } from "react";
import { EndStatement } from "../../../../model";
import correct from "../../../../../assets/checkmark_white.png";
import faulty from "../../../../../assets/wrong_cross.png";
import "./styles/SlideSelector.scss";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../../translations/translationProvider";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface StatementRowProps {
  key: number;
  statement: EndStatement;
  isQuiz?: boolean;
}

const StatementRow: React.FC<StatementRowProps> = (
  props: StatementRowProps
) => {
  const [isExplanationShown, setisExplanationShown] = useState<boolean>(true);
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  const renderStatement = (): JSX.Element => {
    /* Flow for if the answer is the correct answer */
    if (props.statement.answer) {
      return (
        <>
          <div
            className={
              props.statement.answer === 1
                ? "statementBox selected"
                : "statementBox notSelected"
            }
          >
            {languageString.studentQuizPage.Quiz.yes}
          </div>
          <div
            className={
              props.statement.answer === 2
                ? "statementBox selected"
                : "statementBox notSelected"
            }
          >
            {languageString.studentQuizPage.Quiz.no}
          </div>
          {props.statement.answer === props.statement.correctAnswer ? (
            <DoneRoundedIcon className="successIcon" />
          ) : (
            <CloseRoundedIcon className="failedIcon" />
          )}
        </>
      );
    } else {
      return (
        <>
          <div className={"statementBox notSelected"}>
            {languageString.studentQuizPage.Quiz.yes}
          </div>
          <div className={"statementBox notSelected"}>
            {languageString.studentQuizPage.Quiz.no}
          </div>
          {<CloseRoundedIcon className="failedIcon" />}
        </>
      );
    }
  };

  const TextNExplanation = (
    <div className="text">
      <span onClick={() => setisExplanationShown(!isExplanationShown)}>
        {props.statement.id + ". " + props.statement.text}
      </span>
      {isExplanationShown || props.isQuiz ? (
        <span className="explanation">{(props.statement.correctAnswer === 1 ? languageString.generalMessages.yes + ". " : languageString.generalMessages.no + ". ") + props.statement.explanation}</span>
      ) : null}
    </div>
  );

  return (
    <div className="statementRow">
      {TextNExplanation}
      <div className="answers">{renderStatement()}</div>
    </div>
  );
};

export default StatementRow;
