import React, { useEffect, useRef, useState } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint,
  LabelSeries,
} from "react-vis";
import "./StatsCard.scss";

export type IStatsCardProps = {
};

const StatsCard: React.FC<IStatsCardProps> = (props: IStatsCardProps) => {

  return(<div></div>
  );
};

export { StatsCard };
