import { Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import Loading from '../../../../globalComponents/Loading';
import { Subuser, useDeleteSubuserMutation, useGetSchoolSubusersAdminQuery, useManageSubuserMutation } from '../../../../graphql/generated/graphql';
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from '@mui/icons-material/Close';
import { SubuserCellManage } from './SubuserCellManage';

export type ISubuserCellProps = {
    school_id: number;
}

interface subusers{
    subuser_id: number;
    teacher_name: string;
    email_id: string;
    school_id: number;
    is_department: boolean;
    department_name: string;
    department_zipcode: string;
    skip_bill_check: boolean;
}

const SubuserCell: React.FC<ISubuserCellProps> = (props: ISubuserCellProps) => {

    const {data: subuserData, loading} = useGetSchoolSubusersAdminQuery({variables: {schoolId: props.school_id}})
    const [deleteSubuser] = useDeleteSubuserMutation();

    const [manageDepartment] = useManageSubuserMutation();

    const [state, setstate] = useState<Subuser[]>();

    const [isManageOpen, setisManageOpen] = useState(false);

    useEffect(() => {
        if(subuserData?.getSchoolSubusersAdmin){
            setstate(subuserData.getSchoolSubusersAdmin);
        }
    }, [subuserData]);

    const onDelete = (id: string) => {
        const newTempData = state?.filter((item) => item.subuser_id !== id);
    
        setstate(newTempData);
        deleteSubuser({variables:{subuserId: id}})
      };
    

    const renderSubusers = () => {
        return state?.map((user) => {
            return (<div>
                <div>{user.email_id}</div>
            </div>)
        })
    }

    const columns: any[] = [
        {
            name: "subuser_id",
            label: "Slet",
            options: {
              customBodyRender: (value) => {
                return (
                  <Button onClick={() => onDelete(value)}>
                    {<DeleteRoundedIcon />}
                  </Button>
                );
              },
              customBodyEditRender: (value, tableMeta, update) => (
                <Button disabled>
                  <DeleteRoundedIcon />
                </Button>
              ),
            },
          },
            { name: "email_id", label: "Email" },
            { name: "teacher_name", label: "Lærer-navn" },
            { name: "department_name", label: "Afdelings-navn" },
            { name: "department_zipcode", label: "Afdelings-zip" },
            { name: "is_department", label: "Afdeling", options: {              customBodyRender: (value) => {
                return (
                    <>{value ? <DoneIcon/> :  <CloseIcon/>}</>
                );
              },} },
            { name: "skip_bill_check", label: "Spring-over", options: {              customBodyRender: (value) => {
                return (
                    <>{value ? <DoneIcon/> :  <CloseIcon/>}</>
                );
              },} },
        
    ]

    const renderAddNew = () => {
        return <Button onClick={() => setisManageOpen(true)}>Tilføj ny afdeling</Button>
    }


    return (
        <div>
            {state ? 
            <>
            {isManageOpen ? <SubuserCellManage/> : null}
            {/*renderAddNew()*/}
            <MUIDataTable
            title=""
            data={state}
            columns={columns} />
            </>
         : <Loading/>}
        </div>
    );
}

export { SubuserCell };