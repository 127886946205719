import React, { useContext, useEffect, useRef, useState } from "react";
import QRCode from "qrcode.react";
import { AppStateContext } from "../../../../apollo/TeoriTeacherProvider";
import IntermediaryLoading from "../../../../globalComponents/IntermediaryLoading";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../translations/translationProvider";
import { LessonSlide, MediaType, VideoType } from "../../../model";
import { LessonPageImage } from "./LessonPageImage";
import { LessonPageSlide } from "./LessonPageSlide";
import { LessonPageVideo } from "./LessonPageVideo";
import "./style/LessonPageBarPopup.scss";

export type ILessonPageBarActualPopupProps = {};

const LessonPageBarActualPopup: React.FC<
  ILessonPageBarActualPopupProps
> = ({}) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [data, setdata] = useState<LessonSlide>();
  const currentPath = window.location.href.split("/notes");
  const { popupState } = useContext(AppStateContext);

  const containerToGetHeight = useRef<HTMLDivElement>(null);
  const refToMinimizeQR = useRef<HTMLDivElement>(null);
  const [QRSize, setQRSize] = useState(128);

  useEffect(() => {
    if (data?.type === MediaType.QRCODE) {
      setQRSize(
        containerToGetHeight.current?.offsetHeight! -
          refToMinimizeQR.current?.offsetHeight!
      );
    }
  });

  useEffect(() => {
    window.addEventListener("message", (event) => {
      console.log(event.data);

      if (event.data.current) {
        if (event.data.current === "WAITING") {
          setdata(undefined);
        }
        console.log(event.data.current);
        setdata(event.data.current);
      }
    });
  }, []);

  if (data) {
    const renderSlide = () => {
      if (
        data.type === MediaType.AZUREVIDEO ||
        data.type === MediaType.STREAM ||
        data.type === MediaType.YOUTUBE
      ) {
        return (
          <div className="slideVideo">
            <LessonPageVideo
              isLesson
              key={data.media}
              video={data.media}
              videoType={VideoType.STREAM}
            />
          </div>
        );
      } else if (data.type === MediaType.WEB_LINK) {
        return <iframe src={data.media} className="lessonIframe"></iframe>;
      } else if (data.type === MediaType.QRCODE) {
        return (
          <>
            <div className="QRCode" ref={containerToGetHeight}>
              <div
                className="BETA"
                style={{ width: QRSize + "px" }}
                ref={refToMinimizeQR}
              >
                {data.media}
              </div>
              <div className="actualQR">
              <QRCode
                value={data.media}
                size={QRSize}
                ref={containerToGetHeight}
              />
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="slideIMG">
            <LessonPageImage img={data.media} />
          </div>
        );
      }
    };
    return (
      <div className="ActualPopup">
        <div className="slide">
          {data.content_id === "0" ? (
            <div className="overlay">
              <div className="left">
                {languageString.teacherQuizPage.quizMain.left}
              </div>
              <div className="middle">{data.notes}</div>
              <div className="right">
                {languageString.teacherQuizPage.quizMain.right}
              </div>
            </div>
          ) : null}
          {renderSlide()}
        </div>
      </div>
    );
  } else {
    return (
      <div className="ActualPopup">
        <div className="slide">
          <div className="slideIMG">
            <LessonPageImage
              img={
                "https://teoricontentstorage.blob.core.windows.net/admincontainer/teori-2skaerm.jpg"
              }
            />
          </div>
        </div>
      </div>
    );
  }
};

export { LessonPageBarActualPopup };
