import React, { useEffect, useState } from "react";
import { MediaType, StudentQuizSlide } from "../../../../model";
import { Answers } from "./SlideComponents/Answers";
import { Media } from "./SlideComponents/Media";
import { SlideArrows } from "./SlideComponents/SlideArrows";
import { Statements } from "./SlideComponents/Statements";
import "./Slide.scss";
import {
  getChosenLanguage,
  getStringsForChosenLanguage,
} from "../../../../../translations/translationProvider";
import { LearningMode } from "./SlideComponents/LearningMode";
import { Button } from "@material-ui/core";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DigitalStatements from "./SlideComponents/DigitalStatements";
import { useLocation } from "react-router-dom";

export type ISlideProps = {
  isLearningMode: boolean;
  isNoSound: boolean;
  hasSubtitles: boolean;
  slide: StudentQuizSlide;
  soundPlayer: HTMLAudioElement;
  indexPlayer: HTMLAudioElement;
  speed: number;
  handleNextSlide: () => void;
  handlePreviousSlide: () => void;
  handleSetAnswer: (statementNumber: number, answer: number) => void;
  learningModeGradeSlide: () => void;
  languageID: number;
  isPlaying: boolean;
  setisPlaying: React.Dispatch<React.SetStateAction<boolean>>;
};

const Slide: React.FC<ISlideProps> = (props: ISlideProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  //THIS IS WHERE WE SET THE TIME

  const [hasVideoEnded, sethasVideoEnded] = useState(false);
  const [isStatementsDone, setisStatementsDone] = useState(false);
  const [currentStatement, setcurrentStatement] = useState(0);


  const location = useLocation();
  const nameOfTest = location.state ? location.state.nameOfTest ?  location.state.nameOfTest : "" : "";

  const [isPlaying, setisPlaying] = useState(false);
  const [hasFirstStatementPlayed, sethasFirstStatementPlayed] = useState(false);

  const handleAnswerPress = () => {
    if (currentStatement !== props.slide.statements.length) {
      setcurrentStatement(currentStatement + 1);
    } else if (props.isLearningMode) {
      setisStatementsDone(true);
    }
  };

  const handleYesAnswer = () => {
    props.handleSetAnswer(currentStatement, 1);
    setTimeout(() => handleAnswerPress(), 500);
    if (currentStatement === props.slide.statements.length && props.isNoSound) {
      setTimeout(() => props.handleNextSlide(), 500);
    }
  };
  const handleNoAnswer = () => {
    props.handleSetAnswer(currentStatement, 2);
    setTimeout(() => handleAnswerPress(), 500);
    if (currentStatement === props.slide.statements.length && props.isNoSound) {
      setTimeout(() => props.handleNextSlide(), 500);
    }
  };

  //Some logic for the new digital teoritest.
  //The method should explain itself in how it works
  const handleDigitalClick = (statementNumber: number) => {

    props.slide.statements.forEach((statement, index) => {
      if(statement.answer){
      }else{
        props.handleSetAnswer(index + 1, 2);
      }
    })
    
    if (props.slide.statements[statementNumber - 1].answer === 1) {
      
      props.handleSetAnswer(statementNumber, 2);
    } else {
      props.handleSetAnswer(statementNumber, 1);
    }
  };

  const handleStatementClick = (id: number) => {
    setcurrentStatement(id);
  };

  const displayDigitalTest = () => {
    return (
      <div className="DigitalQuizSlide">
        <div className="header"><h4>{nameOfTest}</h4></div>
        <div className="content">

        <Media
          isNoSound={props.isNoSound}
          media={props.slide.media}
          setHasVideoEnded={sethasVideoEnded}
          hasVideoEnded={hasVideoEnded}
          mediaType={props.slide.mediaType}
          />
        <DigitalStatements
          statements={props.slide.statements}
          introQuestion={props.slide.introQuestion}
          introQuestionMedia={props.slide.introQuestionMedia}
          indexPlayer={props.indexPlayer}
          soundPlayer={props.soundPlayer}
          handleStatementClick={handleDigitalClick}
          />
        </div>
      </div>
    );
  };

  if (props.isNoSound) {
    return displayDigitalTest();
  } else {
    return (
      <div
        className="QuizSlide"
        style={{
          backgroundColor: props.isLearningMode ? "#ffffff" : "#1d1e20",
        }}
      >
        <Media
          isNoSound={props.isNoSound}
          media={props.slide.media}
          setHasVideoEnded={sethasVideoEnded}
          hasVideoEnded={hasVideoEnded}
          mediaType={props.slide.mediaType}
        />

        {
          //ADDED SLIDE ARROWS TO CONFORM TO THE NEW TEST WHERE YOU CAN NAVIGATE THROUGH WHOLE QUIZ
          props.isNoSound ? (
            <SlideArrows
              handleNext={props.handleNextSlide}
              handlePrevious={props.handlePreviousSlide}
            />
          ) : null
        }
        {hasVideoEnded ? (
          <>
            {!isStatementsDone ? (
              <>
                <Statements
                  languageID={props.languageID}
                  isLearningMode={props.isLearningMode}
                  isNoSound={props.isNoSound}
                  hasSubtitles={props.isNoSound ? true : props.hasSubtitles}
                  currentStatement={currentStatement}
                  statements={props.slide.statements}
                  introQuestion={props.slide.introQuestion}
                  introQuestionMedia={props.slide.introQuestionMedia}
                  indexPlayer={props.indexPlayer}
                  soundPlayer={props.soundPlayer}
                  handleStatementClick={handleStatementClick}
                  handleNextSlide={props.handleNextSlide}
                  speed={props.speed}
                  isPlaying={props.isPlaying}
                  setisPlaying={props.setisPlaying}
                />
                {props.isNoSound && currentStatement === 0 ? (
                  <div className="Answers">
                    <div>
                      <Button
                        onClick={() => setcurrentStatement(1)}
                        className="answerButton"
                      >
                        <ArrowForwardIcon />
                      </Button>
                    </div>
                  </div>
                ) : null}
                {currentStatement !== 0 &&
                props.slide.statements.length !== 0 ? (
                  <Answers
                    key={currentStatement}
                    handleNoAnswer={handleNoAnswer}
                    handleYesAnswer={handleYesAnswer}
                    isNoSound={props.isNoSound}
                    currentAnswer={
                      props.slide.statements[currentStatement - 1].answer
                    }
                    nextSound={
                      currentStatement !== props.slide.statements.length
                        ? localStorage.getItem("iOSAccepted") === "TRUE"
                          ? props.slide.statements[currentStatement].soundMedia
                          : undefined
                        : undefined
                    }
                    currentSound={
                      localStorage.getItem("iOSAccepted") === "TRUE"
                        ? props.slide.statements[currentStatement - 1]
                            .soundMedia
                        : undefined
                    }
                    soundPlayer={
                      localStorage.getItem("iOSAccepted") === "TRUE"
                        ? props.soundPlayer
                        : undefined
                    }
                    handleNext={
                      currentStatement === props.slide.statements.length
                        ? props.isLearningMode
                          ? () => setisStatementsDone(true)
                          : props.handleNextSlide
                        : undefined
                    }
                    isLearningMode={props.isLearningMode}
                  />
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
        {isStatementsDone ? (
          <>
            <SlideArrows
              handleNext={props.handleNextSlide}
              handlePrevious={props.handlePreviousSlide}
            />
            <LearningMode
              slide={props.slide}
              learningModeGradeSlide={props.learningModeGradeSlide}
            />
          </>
        ) : null}
      </div>
    );
  }
};

export { Slide };
