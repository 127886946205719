import React from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import { getStringsForChosenLanguage, getChosenLanguage } from '../../../../../translations/translationProvider';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router';
import { PATH_DASHBOARD } from '../../../../../constants/pathConstants';
import "./QuizError.scss";

export type IQuizErrorProps = {
    refresh: () => void;
}

const QuizError: React.FC<IQuizErrorProps> = (props :IQuizErrorProps) => {
    const languageString = getStringsForChosenLanguage(getChosenLanguage());
    const history = useHistory();

    return (
        <div className='QuizError'>
            <div>
                <h3>{languageString.studentQuizPage.Quiz.errorPage.somethingwentWrong}</h3>
            </div>
            <div className='tryAgain'>
                <Button onClick={props.refresh}>{languageString.studentQuizPage.Quiz.errorPage.tryagain}<ReplayIcon/></Button>
            </div>
            <div className="backButton">
            <Button  onClick={() => history.push(PATH_DASHBOARD)}><ArrowBackIcon/></Button>
            <span>{languageString.studentQuizPage.Quiz.errorPage.returntodashboard}</span>
            </div>
        </div>
    );
}

export { QuizError };