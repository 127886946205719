import React, { useState } from "react";
import { getStringsForChosenLanguage, getChosenLanguage } from "../../../../../translations/translationProvider";
import CheckIcon from '@mui/icons-material/Check';

import { TeacherStudentStatement } from "../../../../model";

export type ITeacherStudentStatementRowProps = {
  statement: TeacherStudentStatement;
  onYesClick: () => void;
  onNoClick: () => void;
};

const TeacherStudentStatementRow: React.FC<ITeacherStudentStatementRowProps> = (
  props: ITeacherStudentStatementRowProps
) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [isYesActive, setisYesActive] = useState(false);
  const [isNoActive, setisNoActive] = useState(false);

  const handleYesClick = () => {
    if (isNoActive) {
      setisNoActive(false);
    }
    setisYesActive(true);
    props.onYesClick();
  };

  const handleNoClick = () => {
    if (isYesActive) {
      setisYesActive(false);
    }
    setisNoActive(true);
    props.onNoClick();
  };

  return (
    <div className="TeacherStudentStatementRow">
      <div
        className={isYesActive ? "active rowBox" : "rowBox"}
        onClick={handleYesClick}
      >
        {isYesActive ? <CheckIcon/>  : null}
      </div>

      <div
        className={isNoActive ? "active rowBox" : "rowBox"}
        onClick={handleNoClick}
      >
        {" "}
        {isNoActive ? <CheckIcon/> : null}
      </div>
    </div>
  );
};

export { TeacherStudentStatementRow };
