import React from "react";
import {
  getChosenLanguage,
  getStringsForChosenLanguage,
} from "../../../../../translations/translationProvider";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckIcon from "@mui/icons-material/Check";

export type ITeacherStudentResultSlideProps = {
  PassedSlide?: ResultSlide;
  currentStatement: number;
  currentState: number;
  current: number;
  length: number;
};

interface ResultSlide {
  introSoundText: string;
  statements: ResultStatement[];
}
interface ResultStatement {
  answer: number;
  correctAnswer: number;
  text: string;
  passed: boolean;
}
/* THIS SLIDE IS RESPONSIBLE FOR SHOWING WHAT SLIDE THE TEACHER SHOWs */
const TeacherStudentResultSlide: React.FC<ITeacherStudentResultSlideProps> = (
  props: ITeacherStudentResultSlideProps
) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  const renderStatementsOneAtATime = () => {
    const tempArray: JSX.Element[] = [];
    props.PassedSlide?.statements.forEach((statement, index) => {
      if (props.currentStatement < index) {
      } else {
        if (statement.answer) {
          tempArray.push(
            <>
              <div className="index">{index + 1}.</div>
              <div className="statement">
                <div
                  className={
                    statement.answer === 1
                      ? "statementBox selected"
                      : "statementBox notSelected"
                  }
                >
                  {statement.answer === 1 ? <CheckIcon /> : null}
                </div>
                <div
                  className={
                    statement.answer === 2
                      ? "statementBox selected"
                      : "statementBox notSelected"
                  }
                >
                  {statement.answer === 2 ? <CheckIcon /> : null}
                </div>
                {statement.answer === statement.correctAnswer ? (
                  <DoneRoundedIcon className="successIcon" />
                ) : (
                  <CloseRoundedIcon className="failedIcon" />
                )}
              </div>
            </>
          );
        } else {
          tempArray.push(
            <div className="statement">
              <div className="index">{index + 1}.</div>
              <div className={"statementBox notSelected"}></div>
              <div className={"statementBox notSelected"}></div>
              {<CloseRoundedIcon className="failedIcon" />}
            </div>
          );
        }
      }
    });

    return tempArray;
  };

  const renderStatements = (): JSX.Element[] => {
    const tempArray: JSX.Element[] = [];
    props.PassedSlide?.statements.forEach((statement, index) => {
      if (statement.answer) {
        tempArray.push(
          <>
            <div className="index">{index + 1}.</div>
            <div className="statement">
              <div
                className={
                  statement.answer === 1
                    ? "statementBox selected"
                    : "statementBox notSelected"
                }
              >
                {statement.answer === 1 ? <CheckIcon /> : null}
              </div>
              <div
                className={
                  statement.answer === 2
                    ? "statementBox selected"
                    : "statementBox notSelected"
                }
              >
                {statement.answer === 2 ? <CheckIcon /> : null}
              </div>
              {statement.answer === statement.correctAnswer ? (
                <DoneRoundedIcon className="successIcon" />
              ) : (
                <CloseRoundedIcon className="failedIcon" />
              )}
            </div>
          </>
        );
      } else {
        tempArray.push(
          <>
            <div className="index">{index + 1}.</div>
            <div className="statement">
              <div className={"statementBox notSelected"}></div>
              <div className={"statementBox notSelected"}></div>
              {<CloseRoundedIcon className="failedIcon" />}
            </div>
          </>
        );
      }
    });

    return tempArray;
  };

  return (
    <div className="TeacherStudentResultSlide">
      <div className="slideCount">{props.current + " : " + props.length}</div>
      <div className="statement header">
        <div className={"statementBox"}>JA</div>
        <div className={"statementBox"}>NEJ</div>
        {<CloseRoundedIcon className="failedIcon" />}
      </div>
      {props.currentState === 1
        ? renderStatementsOneAtATime()
        : renderStatements()}
    </div>
  );
};

export { TeacherStudentResultSlide };
