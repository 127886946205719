import { Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loading from "../../../globalComponents/Loading";
import { TeoriLogo } from "../../../globalComponents/TeoriLogo";
import { useAddAiStudentMutation, useCreateClassOnlineQuizMutation, useGetClassQuizByConfigIdLazyQuery } from "../../../graphql/generated/graphqlStudent";
import { getStringsForChosenLanguage, getChosenLanguage } from "../../../translations/translationProvider";
import StudentQuizPage from "../studentQuizPage/StudentQuizPage";
import "./StudentTeacherQuiz.scss";

export type IStudentTeacherQuizProps = {
  isAI?: boolean;
};

const StudentTeacherQuiz: React.FC<IStudentTeacherQuizProps> = (props: IStudentTeacherQuizProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [isLoading, setisLoading] = useState(true);

  const quizID = window.location.href.split("/")[4];

  const [classID, setclassID] = useState<string>();
  const [classToken, setclassToken] = useState<string>();

  const [currentAIState, setcurrentAIState] = useState(0);

  const [addAIStudent, {data : aiStudentData}] = useAddAiStudentMutation({variables: {configId: Number(quizID)}})

  const [getClassByConfig, {data : classByConfig}] = useGetClassQuizByConfigIdLazyQuery({variables: {config_id: Number(quizID)}, fetchPolicy: "no-cache"})

  const [createClassQuiz, { data: createQuizdata }] =
    useCreateClassOnlineQuizMutation({
      variables: {
        online_quiz: {
          quiz_class_id: String(quizID),
          quiz_test_name: "Klasse test",
          achievement_name: "Klasse test",
        },
      },
    });

    const [iOS, setiOS] = useState(localStorage.getItem("iOSAccepted") === "TRUE" ? true : false);

    useEffect(() => {
      if(iOS){
        localStorage.setItem("iOSAccepted", "TRUE");
      }else{
        localStorage.setItem("iOSAccepted", "FALSE");
      }
    }, [iOS]);

    const handleChangeiOS = () => {
      setiOS(!iOS);
    }

    const createAIQuiz = (interval: NodeJS.Timeout) => {
      addAIStudent().then((data) => {
        const currentValue = data.data?.addAIStudent

        
        if(currentValue === 1){
          setcurrentAIState(1);
        }else if(currentValue === 2){
          setcurrentAIState(2);
        }else{
          setcurrentAIState(3);
          setisLoading(true);
          clearInterval(interval);
          createClassQuiz({
            variables: {
              online_quiz: {
                quiz_class_id: String(currentValue!),
                quiz_test_name: "Klasse test",
                achievement_name: "Klasse test",
              },
            },
          })
        }
      })
    }

    const createClassByConfig = (interval: NodeJS.Timeout) => {
      getClassByConfig().then((data) => {
        const currentValue = data.data?.getClassQuizByConfigID

        
        if(currentValue === 1){
          setcurrentAIState(1);
        }else if(currentValue === 2){
          setcurrentAIState(2);
        }else{
          setcurrentAIState(3);
          setisLoading(true);
          clearInterval(interval);
          createClassQuiz({
            variables: {
              online_quiz: {
                quiz_class_id: String(currentValue!),
                quiz_test_name: "Klasse test",
                achievement_name: "Klasse test",
              },
            },
          })
        }
      })
    }

    useEffect(() => {
      if(props.isAI){
        const interval = setInterval(() => {createAIQuiz(interval);}, 5000);
        return () => clearInterval(interval);
      }else{
        const interval = setInterval(() => {createClassByConfig(interval);}, 5000);
        return () => {clearInterval(interval);};
      }
    }, []);

  useEffect(() => {
    if (createQuizdata) {
      localStorage.setItem("STUDENT_ONLINE_QUIZ", createQuizdata.createClassOnlineQuiz!.online_quiz_id!)
      localStorage.setItem("STUDENT_ONLINE_QUIZ_TOKEN", createQuizdata.createClassOnlineQuiz?.quiz_token_id!)
      setclassID(createQuizdata.createClassOnlineQuiz!.online_quiz_id!);
      setclassToken(createQuizdata.createClassOnlineQuiz?.quiz_token_id!);
      setisLoading(false);
    }
  }, [createQuizdata]);


  const renderiOS = () => {
    return(
      <div className="iOSContainer">
      <div className="select iOSBox" onClick={handleChangeiOS}>
      <Checkbox className="iOScheckbox" checked={iOS} />
      <label className="iOSLabel">{languageString.studentSettings.iOSCheckbox}</label>
      </div>
      </div>
    )
  }

  const renderAIPart = () => {
    if(currentAIState === 0){
      return (<Loading></Loading>)
    }
    if(currentAIState === 1){
      if(props.isAI){
        return <div className="AIIntermediate"><div className="TeoriLogo"><TeoriLogo/></div><div className="text">Indlæser testen</div>{renderiOS()}</div>
      }else{
        return <div className="AIIntermediate"><div className="TeoriLogo"><TeoriLogo/></div><div className="text">Venter på din lærer</div>{renderiOS()}</div>
      }
    }
    if(currentAIState === 2){
      return <div className="AIIntermediate"><div className="TeoriLogo"><TeoriLogo/></div><div className="text">Venter på din lærer frigiver testen</div>{renderiOS()}</div>
    }else{
      return(
        <>
        {
          isLoading ? 
          <Loading/> :         <StudentQuizPage
          isStudentTeacher={true}
          studentTeacherID={classID}
          studentTeacherToken={classToken}
          isDigital={iOS}
          />
        }
        </>
      )
    }
  }
  return (
    <>
      {renderAIPart()}
    </>
  );
};

export { StudentTeacherQuiz };
