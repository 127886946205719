import React, { useState } from "react";
import { LessonSlide, LessonType } from "../../../model";
import CloseIcon from "@mui/icons-material/Close";
import "./style/NewSlide.scss";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../translations/translationProvider";
import TeoriContent from "./newSlideComponents/TeoriContent";
import OwnMaterial from "./newSlideComponents/OwnMaterial";
export type INewSlideProps = {
  setnewSlide: React.Dispatch<React.SetStateAction<boolean>>;
  handleFinishCreateNewSlide: (newSlide: any) => void;
};

const NewSlide: React.FC<INewSlideProps> = (props: INewSlideProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());

  const [chosenState, setchosenState] = useState(0);


  const renderNewSlideSelector = () => {
    switch(chosenState){
      case 0: 
        return <TeoriContent
            handleFinishCreateNewSlide={props.handleFinishCreateNewSlide}
          />
      case 1: 
        return <OwnMaterial
            handleFinishCreateNewSlide={props.handleFinishCreateNewSlide}
          />
    }
  }

  return (
    <div className="NewSlide">
      <div className="contentBox">
        <button
          className="closeButton clickable"
          onClick={() => props.setnewSlide(false)}
        >
          
          <CloseIcon />
        </button>
        <select value={chosenState} onChange={(event) => setchosenState(Number(event.target.value))}>
            <option value={0}>Teoriundervisning indhold</option>
            <option value={1}>Eget indhold</option>
          </select>
        <div className="contentType">
          {renderNewSlideSelector()}
          
        </div>
      </div>
    </div>
  );
};

export { NewSlide };
