import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AlertBox from "../../../../../../globalComponents/AlertBox";
import { getChosenSpokenLanguage } from "../../../../../../translations/translationProvider";
import { StudentQuizStatement } from "../../../../../model";
import ClearIcon from "@mui/icons-material/Clear";

export type IStatementProps = {
  indexNumber: number;
  learningMode: boolean;
  currentStatement: number;
  handleClick: (id: number) => void;
  statement: StudentQuizStatement;
  indexSoundSrc?: string;
  indexPlayer: HTMLAudioElement;
  soundPlayer: HTMLAudioElement;
  statementLength: number;
  handleNextSlide?: () => void;
  languageID: number;
  isNoSound?: boolean;
};

const Statement: React.FC<IStatementProps> = (props: IStatementProps) => {
  const languageString = getChosenSpokenLanguage(props.languageID);
  const [isActive, setisActive] = useState(false);
  const indexSound = props.indexPlayer;
  const sound = props.soundPlayer;

  const [isSoundError, setisSoundError] = useState(false);

  const onYes = () => {
    if (props.currentStatement === 0) {
      if (props.statement.soundMedia) {
        sound.src = props.statement.soundMedia;
        sound.play();
        sound.onended = () => {
          props.handleClick(props.statement.id + 1);
          if (props.statementLength === 0) {
            if (props.handleNextSlide) {
              props.handleNextSlide();
            }
          }
        };
      }
    } else {
      if (props.indexSoundSrc) {
        indexSound.src = props.indexSoundSrc;
        indexSound.play();
        indexSound!.onended = () => {
          sound.play();
        };
      }
    }
    setisSoundError(false);
  };

  useEffect(() => {
    if (props.learningMode) {
      if (props.currentStatement === props.statement.id) {
        setisActive(true);

        sound.src = props.statement.soundMedia;
        const soundPromise = sound.play();
        soundPromise.catch((e) => {
          setisSoundError(true);
        });
        sound.onended = () => {
          if (props.currentStatement === 0) {
            if (props.statementLength === 0) {
              if (props.handleNextSlide) {
                props.handleNextSlide();
              }
            }
            setTimeout(() => props.handleClick(props.statement.id + 1), 2000);
          }
        };
      } else {
        setisActive(false);
      }
    } else {
      if (props.currentStatement === props.statement.id) {
        setisActive(true);
      } else {
        setisActive(false);
      }
    }
  }, [props.currentStatement]);

  const specialAlertBox = (
    <div className="specialAlert">
      <div className="box">
        <Button onClick={onYes} className={"closeButton"}>
          <ClearIcon />
        </Button>
        <div className="title">VIGTIGT</div>
        <div className="description">
          Din browser fejlede i at afspille lyden, vi skal have din tilladelse
          for at afspille den igen
        </div>
        <Button onClick={onYes} className={"okButton"}>
          JA
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div
        className={isActive ? "active Statement" : "Statement"}
        onClick={() => props.handleClick(props.statement.id)}
      >
        <Button variant="contained">
          {props.statement.id !== 0 ? props.indexNumber : "?"}
        </Button>
      </div>
      {isSoundError ? specialAlertBox : null}
    </>
  );
};

export { Statement };
