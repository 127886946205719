import React, { useEffect, useState } from "react";
import ReactStopwatch from "react-stopwatch";

export type IStopwatchProps = {
  setNewTime: (value: React.SetStateAction<string>) => void;
};

const Stopwatch: React.FC<IStopwatchProps> = (props: IStopwatchProps) => {
  const [time, settime] = useState(0);

  useEffect(() => {
    
    setTimeout(() => settime(time + 1), 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => settime(time + 1), 1000)
  }, [time]);

  useEffect(() => {
    const date = new Date(0);
    date.setSeconds(time); // specify value for SECONDS here
    const timeString = date.toISOString().substring(11, 19);
    props.setNewTime(timeString);
  }, [time]);
  return <></>;
};

export { Stopwatch };
