import React, { useState } from 'react';
import { StudentQuizStatement } from '../../../../../model';
import { Checkbox } from '@material-ui/core';
import "./DigitalStatements.scss";

interface DigitalStatementsProps {
    statements: StudentQuizStatement[];
    introQuestion: string;
    introQuestionMedia: string;
    indexPlayer: HTMLAudioElement;
    soundPlayer: HTMLAudioElement;
    handleStatementClick: (id: number) => void;
    
}

const DigitalStatements: React.FC<DigitalStatementsProps> = (props: DigitalStatementsProps) => {


    const [shouldUpdate, setshouldUpdate] = useState(false);

    const handleStatementClickIOC = (index: number) => {
        setshouldUpdate(!shouldUpdate);
        props.handleStatementClick(index + 1);
    } 

    const renderStatement = (statement: StudentQuizStatement, index: number) => {
        return (
            <div key={index} className={statement.answer === 1 ? "DigitalStatement marked" : 'DigitalStatement'} onClick={() => handleStatementClickIOC(index)}>
                <div className='checkBoxDiv'>
                <Checkbox checked={statement.answer === 1}/>
                </div>
                <div className='textDiv'>
                {statement.text}
                </div>
            </div>
        )
    }


    return (
        <div className='DigitalStatements'>
            <div className="IntroQuestion"><h1>{props.introQuestion}</h1></div>
            {/*<div className='helperText'>Vælg det eller de korrekte svar</div>*/}    
            <div className='statementBox'>{props.statements.map((statement, index) => renderStatement(statement, index))}</div>
        </div>
    );
};

export default DigitalStatements;