export interface BaseType{
    id: number;
}
export enum LoginStep{
    Initial = 0,
    FirstLogin= 1,
    FirstLoginAccessCode=2,
    Confirmation = 3,
    CreatePin=4,
    AccessExpired=5,
    ConfirmMobileAccess=6,
}

export enum dashboardStatus{
    Unpublished = 0,
    Published = 1,
    Offline = 2
}

export enum dashboardType{
    Lesson = 0,
    Test = 1,
    TestConfig = 2,
}

export enum QuizState {
    Setup = 0,
    Quiz = 1,
    End = 2
}

export enum VideoType {
    YOUTUBE = "Youtube",
    STREAM = "Stream",
    LOCALVIDEO = "LocalVideo",
    AZURE = "Azure"
}
export enum LessonType {
    VIDEO = "Video",
    IMAGE = "Image",
	STREETVIEW = "StreetView",
    YOUTUBE = "Youtube",
    TEORIIMAGE = "TeoriImage",
	TEORIVIDEO = "TeoriVideo",
}

export enum MediaType {
    STREAM = "STREAM", //COULD BE VIMEO  *NOT AZURE*
    LOCALVIDEO = "LOCALVIDEO",
    YOUTUBE = "YOUTUBE",
    WEB_LINK = "WEBLINK", //THIS COULD BE A LINK TO A WEBSITE OR MAPS
    AZUREVIDEO = "AZURE", //A STREAMING LINK TO AZURE 
    AZUREIMG = "AZUREIMG", //A LINK TO THE STORAGE ACCOUNT,
    //WE STILL NEED A TYPE FOR WHEN IMAGES ARE NOT STORED IN AZURE
    QRCODE = "QRCODE"//A SPECIAL TYPE FOR POPUP


}

export enum contentType {
    STARTCONTENT = 5,
    SUBJECTS = 6,
    EVALTEST = 3,
    ONLINEBOOK = 1,
    SCHOOLLESSONS = 4,
    BEFOREDRIVINGTEST = 2,

}

/* USES achievement_desc_type from the API */
export enum typeOfContentDashboard {
    STREAM = 1,
    YOUTUBE = 2,
    WEBLINK = 3,
    AZUREVIDEO = 4,
    AZUREIMG = 5,
    BOOK = 6,
    QUIZ = 7,
    DOWNLOAD = 8,
    TAG = 9,
    SLIDES = 10,
    LESSON = 11
}



export enum AchievementGradeStatus {
    NotTouched = -1,
    Failed = 0,
    Completed = 1

}

export enum Category {
    A = 1,
    B = 2,
    C = 3,
    "B/E" = 4,
}


export interface AdminStudentTableRow extends BaseType{
    firstName: string;
    lastName: string;
    phoneNumber: string;
    category: string;
    accessCode: string;
    schoolName: string;
    startDate: Date;
    expirationDate: Date;

}


export interface DashboardTableRow extends BaseType {
    type: dashboardType;
    name: string;
    isOwn: boolean;
    tags: Tag[];
    category: string;
    createDate: Date;
    status: string
    default?: boolean;
    configs?: any;
}


export interface StudentTableRow extends BaseType {
    isMarked: boolean;
    correctQuizzes: number;
    preparationStatus: number;
    achivementProgress: string;
    name: string;
    creationDate: Date;
    startDate: Date;
    category: string;
    mobileNumber: string;
    code: string;
    codeExpiry: Date;
    stats: any;
    hasMarked?: boolean;
    totalQuizzes?: string; 

}

export interface ActivityTableData extends BaseType {
    date: Date;
    title: string;
    answers: string;
    type: number;
    quizID?: string;
    activity_time?: string;
}
export interface ContentBoxInfo extends BaseType{
    status: AchievementGradeStatus;
    name: string;
    has_fails: boolean;
    type: contentType;
    achievement_id: number;
    contentLocation: string;
    contentType: number;
    tagString?: string;
    slideString?: string;
    onlineQuizID?: string;
    quizLanguage?: string;

    
}

export interface StudentSetupLanguages extends BaseType {
    name: string;
}


/*I define the structure of how slides will function here*/
export interface Tag extends BaseType{
    name: string;
}
export interface Slide extends BaseType{
    media: string;
    tags?: Tag[];
    introQuestion: string;
}
export interface Statement extends BaseType{
    sound?: string;
    text: string;
    correctAnswer: number;
    elaboration?: string;
}

export interface EndStatement extends Statement{
    answer?: number;
    explanation: string;
}

export interface EndSlide extends Slide{
    passed?: boolean;
    statements: EndStatement[];
    isMarked?: boolean;
    tempMedia?: string;
    mediaType: MediaType;
    explanation?: string;
    progressID: string;
}


/*Structure of a Teacher Quiz */
export interface ClassQuizStatement extends BaseType{
    text: string;
    sound: string;
    indexSound: string;
    subtitle?: string;
}

export interface ClassQuizSlide extends BaseType{
    media: string;
    introQuestionSound: string;
    introQuestionText?: string;
    statements: ClassQuizStatement[];
    introQuestionSubtitle?: string;
    isVideo: boolean;
}

/*Structure of TeacherStudent Quiz */
export interface TeacherStudentStatement extends BaseType{
    progressID?: number;
    correctAnswer: number;
    answer?: number;
}
export interface TeacherStudentSlide extends BaseType{
    statements: TeacherStudentStatement[];
}

//Structure of Student Quiz
export interface StudentQuizStatement extends Statement{
    soundMedia: string;
    progressID: number;
    answer?: number;
    explanation: string;
}
export interface StudentQuizSlide extends Slide{
    introQuestionMedia: string;
    mediaType: MediaType;
    statements: StudentQuizStatement[];
    isMarked?: boolean;
}

export interface StudentQuiz{
    speed: number;
    slides: StudentQuizSlide[];
    indexPlayer: HTMLAudioElement;
    soundPlayer: HTMLAudioElement;

}


/*Structure of how the QuizResult types is */
export interface ResultSlide extends Slide{
    backendID: string;
    introQuestionMedia?: string;
    statements: Statement[];
    isVideo?: boolean;
}
export interface ResultStudentSlide extends BaseType{
    passed?: boolean;
}
export interface ResultStudent extends BaseType{
    name: string;
    slides: ResultStudentSlide[];
}

/*Structure of Lessons //THIS IS DIFFERENT BECAUSE LESSONSLIDE CAN HAVE DIFFERENT TYPE OF MEDIAS*/
export interface LessonSlide extends BaseType {
    editID?: number;
    translationID?: string;
    media: string;
    tempFile?: File | string;
    content_id: string;
    tags?: Tag[];
    type: MediaType;
    notes: string;
    keywords?: string;
    sound?: string | File;
}

export interface CreateQuizStatementSound extends BaseType{
    soundLocation: string;
    soundText: string;
}

export interface CreateQuizStatementElaboration extends BaseType{
    editID?: string;
    languageID: number;
    text: string;
    isOriginal?: boolean;
}

export interface CreateQuizStatement extends BaseType{
    editID?: number;
    hasError?: boolean;
    soundAndText: CreateQuizStatementSound;
    answer: number;
    elaboration: CreateQuizStatementElaboration[];
    originalIndex: number;
}

export interface CreateQuizExplanation extends BaseType{
    editID?: string;
    languageID : number;
    media: string;
    tempFile?: File | string;
    isOriginal?: boolean;
}

export interface CreateQuizSlide extends BaseType{
    editID?: number;
    hasError?: boolean;
    media: string;
    soundExplanations?: CreateQuizExplanation[]
    tempFile?: File | string;
    content_id: string;
    tags?: Tag[],
    type: MediaType;
    introQuestion: number;
    statements: CreateQuizStatement[];
    slideFilter: string;
}