import React, { useEffect, useState } from "react";
import { EndSlide, MediaType } from "../../../../model";
import success from "../../../../../assets/checkmark_icon.png";
import failed from "../../../../../assets/invertedfail_icon.png";
import arrow from "../../../../../assets/red_Arrow.png";
import Slide from "./Slide";
import Loading from "../../../../../globalComponents/Loading";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../../translations/translationProvider";
import { PATH_DASHBOARD } from "../../../../../constants/pathConstants";
import { useHistory, useLocation } from "react-router-dom";
import { ResultView } from "./ResultView";
import { Button } from "@material-ui/core";

interface endLocationProps{
  achivementTypeID?: string;
}

interface SlideSelectorProps {
  slides: EndSlide[];
  result: boolean;
  refetch: () => void;
  isFailed: boolean;
  isTeacher?: boolean;
  isFreeQuiz?: boolean;
}

const SlideSelector: React.FC<SlideSelectorProps> = (
  props: SlideSelectorProps
) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [slideArray, setSlideArray] = useState<JSX.Element[]>();
  const history = useHistory();

  const location = useLocation<endLocationProps>();

  const [currentSlide, setCurrentSlide] = useState(-1);
  const setSlide = (id: number) => {
    setCurrentSlide(id - 1); //WEIRD ERROR APPARENTLY SETTED IT -1 OFF
  };

  const goBack = () => {
    if(props.isFreeQuiz){
      if(currentSlide === -1){
        window.open("https://www.teoriundervisning.dk/#koebadgang");
      }else{
        setCurrentSlide(-1);
      }
    }else{
      history.push(PATH_DASHBOARD, {achievementTypeID: location.state ? location.state.achivementTypeID ? location.state.achivementTypeID : "" : ""});
    }
  };

  const goToBottom = () => {
    if (document.getElementById("slides")) { 
      document.getElementById("slides")!.scrollTop =
      document.getElementById("slides")!.scrollTop + 
        document.getElementsByClassName("slide")![0].scrollHeight * 2;
    }
  };


  function calculateCorrectAmount(){
    let amount = 0;
    props.slides.forEach((slide) => {
      if(slide.passed){
        amount++;
      }
    })
    return amount;
  }


  useEffect(() => {
    const TempSlideArray: JSX.Element[] = props.slides.map((slide) => {
      let errorCount = 0;
      slide.statements.forEach((statement) => {
          if(statement.answer !== statement.correctAnswer){
            errorCount++;
          } 
      })
      return (
        <div
          className={currentSlide === slide.id ? "slide selected clickable" : "slide clickable"}
          onClick={() => setSlide(slide.id)}
          key={slide.id}
        >
          <img alt="thumbnail" src={slide.mediaType === MediaType.STREAM ? slide.tempMedia : slide.media}></img>
          <img
            className="status"
            alt="slideStatus"
            src={slide.passed ? success : failed}
          ></img>
          <div className="slidename">{slide.id + ". " + slide.introQuestion}</div>
        </div>
      );
    });
    setSlideArray(TempSlideArray);
  }, [props.slides.length]);

  return (
    <>
      <div className="SlideSelector">
        {props.isTeacher ? null :<div className="backButton" >
          <button className="clickable" onClick={goBack}>
            <img alt="arrowLeft" src={arrow}></img>
          </button>
          <label className="clickable" onClick={goBack}>{languageString.generalMessages.backButton}</label>
        </div>}
        <div className="slides" id="slides">
          {slideArray ? slideArray : <Loading />}
        </div>
        <button className="downButton clickable" onClick={goToBottom}>
          <img alt="arrowDown" src={arrow}></img>
        </button>
      </div>
      {props.isFailed ? <div className="retryButton"><Button onClick={() => props.refetch()}>PRØV IGEN</Button></div> : 
      (props.slides.length > 0 ? (
        currentSlide === -1 ? <ResultView passStatus={props.result} amountCorrect={calculateCorrectAmount()} amount={props.slides.length} isFreeQuiz={props.isFreeQuiz}/> : <Slide
          slide={props.slides[currentSlide]}
          key={props.slides[currentSlide].id}
        />
        
      ) : (
        <Loading />
      ))}
    </>
  );
};

export default SlideSelector;
