import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../../../../translations/translationProvider";
import "./Answers.scss";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export type IAnswersProps = {
  handleYesAnswer: () => void;
  handleNoAnswer: () => void;
  isNoSound: boolean;
  currentAnswer?: number;
  soundPlayer?: HTMLAudioElement;
  nextSound?: string;
  currentSound?: string;
  handleNext?: () => void;
  isLearningMode: boolean;
};

const Answers: React.FC<IAnswersProps> = (props: IAnswersProps) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [isYesActive, setisYesActive] = useState(
    props.currentAnswer === 1 ? true : false
  );
  const [isNoActive, setisNoActive] = useState(
    props.currentAnswer === 2 ? true : false
  );

  const handlePlayStatement = () => {
    if(props.isNoSound){

    }else{

      if (props.soundPlayer && props.currentSound) {
        props.soundPlayer.src = props.currentSound;
        props.soundPlayer.play();
        props.soundPlayer.onended = () => {
          props.soundPlayer!.onended = null;
        };
      }
    }
  };

//   useEffect(() => {
//     if (props.soundPlayer) {
//         console.log(props.soundPlayer.paused);
        
//       if (!props.soundPlayer.paused ) {
//         console.log("i am playing!!");

//         sethasPlayed(true);
//         //Its playing...do your job
//       } else {
//         //Not playing...maybe paused, stopped or never played.
//       }
//     }
//   }, []);



  const handleYesAnswer = () => {
    if (isNoActive) {
      setisNoActive(false);
    }
    setisYesActive(true);
    props.handleYesAnswer();
    
    if (props.soundPlayer && props.nextSound) {
    //   props.soundPlayer.src = props.nextSound;
    //   setTimeout(() => props.soundPlayer!.play(), 250);
    }
  };

  const handleNoAnswer = () => {
    if (isYesActive) {
      setisYesActive(false);
    }
    setisNoActive(true);
    props.handleNoAnswer();
    if (props.soundPlayer && props.nextSound) {
    //   props.soundPlayer.src = props.nextSound;
    //   setTimeout(() => props.soundPlayer!.play(), 1000);
    }
  };

    return (
      <div className="Answers">
        <div>
          <button
            className={
              isYesActive ? "answerButton active left" : "answerButton left"
            }
            onClick={handleYesAnswer}
          >
            {languageString.studentQuizPage.Quiz.yes}
          </button>
        </div>
        <div>
          <button
            className={
              isNoActive ? "answerButton active right" : "answerButton right"
            }
            onClick={handleNoAnswer}
          >
            {languageString.studentQuizPage.Quiz.no}
          </button>
        </div>
      </div>
    );
};

export { Answers };
