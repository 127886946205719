import React, { createContext, ReactNode, useState } from "react";
import { LessonSlide } from "../pages/model";

interface PopupSlideState {
popupState: boolean;
  popupSlide: LessonSlide | null;
  openPopup: () => void;
  closePopup: () => void;
  setPopupSlide: (slide: LessonSlide | null) => void;
}

const initialPopupSlideState: PopupSlideState = {
    popupState: false,
    popupSlide: null,
  openPopup: () => {},
  closePopup: () => {},
  setPopupSlide: (slide: LessonSlide | null) => {}
};

export const PopupSlideContext = createContext<PopupSlideState>(
  initialPopupSlideState
);

export interface PopupSlideProviderProps {
  children: ReactNode;
}

function PopupSlideProvider(props: PopupSlideProviderProps): JSX.Element {
  const [popupSlide, setPopupSlide] = useState<LessonSlide | null>(null);
  const [popupState, setpopupState] = useState(false);

  const openPopup = () => {
    
    setpopupState(true);
  }

  const closePopup = () => {
    setpopupState(false);
  }

  return (
    <PopupSlideContext.Provider value={{ popupSlide, setPopupSlide, openPopup, closePopup, popupState }}>
      {props.children}
    </PopupSlideContext.Provider>
  );
}

export default PopupSlideProvider;