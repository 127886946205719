import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useManageAchievementMutation,
  useManageBeforeDrivingMutation,
  useManageEvalTestsMutation,
  useManageStartContentMutation,
  useManageSubjectMutation,
} from "../../../graphql/generated/graphqlStudent";
import {
  getStringsForChosenLanguage,
  getChosenLanguage,
} from "../../../translations/translationProvider";
import { QuizState } from "../../model";
import { End } from "./End/End";
import { Quiz } from "./Quiz/Quiz";
import { Setup } from "./Setup/Setup";
import bookIcon from "../../../assets/iconsStudentDashboard/book_icon.png";
import { Stopwatch } from "../../../globalComponents/Stopwatch";

interface stateReceived {
  id: number;
  nameOfTest: string;
  testType: string;
  img: string;
  errorCheck?: boolean;
  quizType?: number;
  achievementID?: number;
  shouldRefetchByOnlineID?: boolean;
  onlineQuizID: string;
  achievementTypeID?: string;
  quizLanguage?: string;
}

export type IStudentQuizPageProps = {
  isStudentTeacher?: boolean;
  studentTeacherID?: string;
  studentTeacherToken?: string;
  isFreeQuiz?: boolean;
  isDigital?: boolean;
};

const StudentQuizPage: React.FC<IStudentQuizPageProps> = (
  props: IStudentQuizPageProps
) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const location = useLocation<stateReceived>();
  const id = location.state ? location.state.id : 0;
  const [isLearningMode, setisLearningMode] = useState<boolean>(false);
  const [isNoSound, setisNoSound] = useState<boolean>(false);
  const [languageID, setlanguageID] = useState(1);
  const [subtitlesID, setsubtitlesID] = useState(0);

  const [timeString, settimeString] = useState("00:00:00");

  /*This is the state that controls where we are in the quiz*/
  const [quizState, setQuizState] = useState<QuizState>(
    location.state
      ? location.state.errorCheck
        ? QuizState.End
        : QuizState.Setup
      : props.isFreeQuiz
      ? QuizState.Setup
      : QuizState.Quiz
  );

  const [isTabletMode, setisTabletMode] = useState(false);

  const [shouldFetchByOnlineID, setshouldFetchByOnlineID] = useState(
    location.state
      ? location.state.shouldRefetchByOnlineID
        ? true
        : false
      : false
  );
  const [onlineID, setonlineID] = useState<string | undefined>(
    location.state
      ? location.state.onlineQuizID
        ? location.state.onlineQuizID
        : undefined
      : undefined
  );

  const [manageAchievementMutation] = useManageAchievementMutation();

  useEffect(() => {
    const element = document.getElementById("LeadboosterContainer");
    if (element) {
      element.remove();
    }
  }, []);

  /* THIS IS RESPONSIBLE FOR UPDATING THE ACHIEVEMENT ON THE STUDENT DASHBOARD */
  const manageAchievement = async (
    passStatus: boolean,
    hasFails: boolean,
    onlineQuizID?: number
  ) => {
    if (!props.isFreeQuiz) {
      if (!props.isStudentTeacher) {
        if (location.state.quizType && location.state.achievementID) {
          return manageAchievementMutation({
            variables: {
              achievement: {
                achievement_has_fails: hasFails ? 1 : 0,
                achievement_id: String(location.state.achievementID),
                achievement_status: passStatus ? 1 : 0,
                achievement_quiz_id: onlineQuizID ? onlineQuizID : undefined,
                time_spent: timeString
              },
            },
          });
        }
      }
    }
  };

  const renderQuizState = () => {
    switch (quizState) {
      case QuizState.Setup:
        return (
          <Setup
            id={id}
            isLearningMode={isLearningMode}
            setIsLearningMode={setisLearningMode}
            isNoSound={isNoSound}
            setIsNoSound={setisNoSound}
            nameOfTest={
              props.isFreeQuiz
                ? languageString.studentQuizPage.Setup.freeQuizFreeTest
                : location.state.nameOfTest
            }
            testType={
              props.isFreeQuiz
                ? "TEORIUNDERVISNING.DK"
                : location.state.testType
            }
            setQuizState={setQuizState}
            testIcon={props.isFreeQuiz ? bookIcon : location.state.img}
            languageID={languageID}
            setlanguageID={setlanguageID}
            subtitlesID={subtitlesID}
            setsubtitlesID={setsubtitlesID}
            isTabletMode={isTabletMode}
            setisTabletMode={setisTabletMode}
            quizLanguage={props.isFreeQuiz ? "" : location.state.quizLanguage}
          />
        );
      case QuizState.Quiz:
        return (
          <>
          <Stopwatch setNewTime={settimeString}/>
          <Quiz
            id={id}
            isLearningMode={isLearningMode}
            isNoSound={isNoSound}
            languageID={languageID}
            subtitlesID={subtitlesID}
            setQuizState={setQuizState}
            manageAchievement={manageAchievement}
            setonlineID={setonlineID}
            testName={
              location.state ? location.state.nameOfTest : "Klasse test"
            }
            isStudentTeacher={props.isStudentTeacher}
            studentTeacherID={props.studentTeacherID}
            studentTeacherToken={props.studentTeacherToken}
            isFreeQuiz={props.isFreeQuiz}
            isDigital={props.isDigital}
          /></>
        );
      case QuizState.End:
        return (
          <End
            id={id}
            shouldFetchByOnlineID={
              props.isStudentTeacher ? true : shouldFetchByOnlineID
            }
            onlineID={
              props.isStudentTeacher ? props.studentTeacherID : onlineID
            }
            isFreeQuiz={props.isFreeQuiz}
          />
        );
    }
  };

  return <div style={{ height: "100%" }}>{renderQuizState()}</div>;
};

export default StudentQuizPage;
