import React from 'react';
import logo from "../assets/TeoriLogo_small.png"
import Teacherlogo from "../assets/EasyPiecy_Undervisning.png";
import "./style/TeoriLogo.scss";
import { useHistory } from "react-router-dom";
import { PATH_DASHBOARD } from '../constants/pathConstants';

export type ITeoriLogoProps = {
    studentPage?: boolean;
    onClick?: () => void;
}

const TeoriLogo: React.FC<ITeoriLogoProps> = (props: ITeoriLogoProps) => {
    const history = useHistory();
    return (
        <div className="logoContainer" onClick ={props.onClick ? props.onClick : () => history.push(PATH_DASHBOARD)}>
              <img src={logo} alt="TEORI-LOGO" className="logo"/>
        </div>
      
    );
}

export { TeoriLogo };
