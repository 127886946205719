import React, { useEffect, useState } from "react";
import { TeoriLogo } from "../../../../globalComponents/TeoriLogo";
import {
  getChosenLanguage,
  getStringsForChosenLanguage,
} from "../../../../translations/translationProvider";
import arrowIcon from "../../../../assets/white_Arrow.png";
import VerificationInput from "../../../../globalComponents/VerificationInput";
import { fillArrayWithEmptyStrings } from "../../../../services/utilFunctions";
import { LoginStep } from "../../../model";
import { useVerifyMobileNoLazyQuery } from "../../../../graphql/generated/graphqlStudent";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import "../style/Confirmation.scss";
import IntermediaryLoading from "../../../../globalComponents/IntermediaryLoading";

export type IConfirmationProps = {
  setLoginStep: any;
  setMobileNumber: (value: string | ((prevState: string) => string)) => void;
  mobileNumber: string;
  confirmationCode: string;
  isAccessCode: boolean;
  setpreFix: (value: string) => void
  preFix: string;
};

const Confirmation: React.FC<IConfirmationProps> = (
  props: IConfirmationProps
) => {
  const languageString = getStringsForChosenLanguage(getChosenLanguage());
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [code, setCode] = useState<string>(props.confirmationCode);
  const [isSMSsent, setisSMSsent] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [verificationInput, setVerificationInput] = useState<string[]>(() =>
    fillArrayWithEmptyStrings(6)
  );
  const [error, setError] = useState(false);
  const [confirmationError, setconfirmationError] = useState<string>();
  const [goodToGo, setGoodToGo] = useState(false);

  const [getConfirmationCode, { data, loading }] = useVerifyMobileNoLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.verifyMobileNo.firstString === "student_not_found") {
        setconfirmationError("Nummeret har ikke adgang til siden");
        setisLoading(false);
      } else if (
        data.verifyMobileNo.firstString === "" &&
        data.verifyMobileNo.secondString === ""
      ) {
        setconfirmationError("Nummeret har allerede en bruger koblet til");
      } else {
        setCode(
          data.verifyMobileNo.firstString + data.verifyMobileNo.secondString
        );
        setisSMSsent(true);
      }
      setisLoading(false);
    },
    onError: (data) => {
      setisLoading(false);
      setconfirmationError("Noget gik galt, prøv igen");
    },
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (isLoading) {
      setisLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (verificationCode.length > 5 && verificationCode === code) {
      setGoodToGo(true);
      setError(false);
    } else if (verificationCode.length > 5 && verificationCode !== code) {
      setError(true);
    } else {
      setGoodToGo(false);
      setError(false);
    }
  }, [verificationCode]);

  const errorMessage = (
    <span>{languageString.studentLoginPage.Confirmation.errorMessage}</span>
  );

  const handleContinue = () => {
    if (props.isAccessCode) {
      props.setLoginStep(LoginStep.FirstLoginAccessCode);
    } else {
      props.setLoginStep(LoginStep.CreatePin);
    }
  };

  const handleSendSMS = () => {
    if (props.mobileNumber) {
      setisLoading(true);
      getConfirmationCode({
        variables: { student_phone_number: props.preFix + props.mobileNumber },
      });
    }
  };
  const handleChangePhoneNumber = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      props.setMobileNumber(event.target.value);
    }
  };

  const renderMobileInput = () => {
    return (
      <div className="mobileInput">
        <div className="firstInput">
          <input
            value={props.preFix}
            onChange={(event) => props.setpreFix(event.target.value)}
            className="preFix"
          />
          <input
            placeholder={
              languageString.studentLoginPage.mobileNumberPlaceholder
            }
            value={props.mobileNumber}
            onChange={handleChangePhoneNumber}
            className="mobileNumber"
          ></input>
        </div>
        <span>{confirmationError ? confirmationError : null}</span>
        <div className="sendButton">
          <Button onClick={handleSendSMS} variant="contained" fullWidth>
            <label>
              {
                languageString.studentLoginPage.Confirmation
                  .sendConfirmationCode
              }
            </label>
            <SendIcon />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="ConfirmationPage">
      {loading ? <IntermediaryLoading /> : null}
      {isSMSsent ? (
        <div className="confirmationInput">
          <div className="top">
            <span>
              {languageString.studentLoginPage.Confirmation.weSentASMS}
            </span>
            <div className="pinContainer6char">
              <VerificationInput
                setCode={setVerificationCode}
                VerificationInputState={verificationInput}
                setVerificationInputState={setVerificationInput}
                className={goodToGo ? "verifyInputs correct" : "verifyInputs"}
              />
            </div>
            <div className="error">{error ? errorMessage : null}</div>
            <div className="bottomButton">
              <Button
                variant="contained"
                disabled={!goodToGo}
                onClick={handleContinue}
              >
                BEKRÆFT
              </Button>
            </div>
          </div>
        </div>
      ) : (
        renderMobileInput()
      )}
    </div>
  );
};

export { Confirmation };
