import { Button, Checkbox, Switch, TableCell } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  useGetAllSchoolsQuery,
  useManageSchoolMutation,
  useSetSchoolStatusMutation,
} from "../../../../graphql/generated/graphql";
import { CategorySelect } from "../adminStudentView/components/CategorySelect";
import { SchoolSelect } from "../adminStudentView/components/SchoolSelect";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddIcon from "@mui/icons-material/Add";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoneIcon from "@mui/icons-material/Done";
import Loading from "../../../../globalComponents/Loading";
import { MuiDataTableEditable } from "../adminStudentView/components/MuiDataTableEdit";
import IntermediaryLoading from "../../../../globalComponents/IntermediaryLoading";
import { AddSchoolBox } from "./AddSchoolBox";
import "./AdminSchoolView.scss";
import { TableRow } from "@mui/material";
import { SubuserCell } from "./SubuserCell";
import { CategoryCell } from "./CategoryCell";
import CloseIcon from "@mui/icons-material/Close";

export type IAdminSchoolViewProps = {};

const AdminSchoolView: React.FC<IAdminSchoolViewProps> = ({}) => {
  const [isNewShown, setisNewShown] = useState(true);

  const {
    data: schoolData,
    loading: schoolsLoading,
    refetch,
  } = useGetAllSchoolsQuery({ variables: { is_active: isNewShown ? 1 : 0 } });

  const [manageSchool] = useManageSchoolMutation();
  const [manageSchoolStatus] = useSetSchoolStatusMutation();

  const [intermediaryLoading, setintermediaryLoading] = useState(false);
  const [creatingNewTeacher, setcreatingNewTeacher] = useState(false);

  const [tableData, settableData] = useState<any[]>();

  const handleCreateNewTeacher = () => {};

  useEffect(() => {
    if (schoolData) {
      const tempArray: any[] = [];
      schoolData.getAllSchools!.forEach((school) => {
        tempArray.push({
          school_id: school?.school_id,
          school_name: school?.school_name,
          email_id: school?.email_id,
          language_choice: school?.language_choice,
          vat_number: school?.vat_number,
          contact_name: school?.contact_name,
          password: school?.password,
          zipcode: school?.zipcode,
          city: school?.city,
          optional_note: school?.optional_note,
          delete: school?.school_id,
          skip_bill_check: school?.skip_bill_check,
        });
      });
      settableData(tempArray);
      setintermediaryLoading(false);
    }
  }, [schoolData]);

  const handleStatus = () => {
    setintermediaryLoading(true);
    setisNewShown(!isNewShown);
  };

  const onRowClick = (value) => {
    console.log(value);
  };

  const onDelete = (id: string) => {
    const newTempData = tableData?.filter((item) => item.delete !== id);

    settableData(newTempData);
    manageSchoolStatus({
      variables: { school_id: Number(id), school_status: isNewShown ? 0 : 1 },
    }).then(() => refetch());
  };

  const onRowSave = (value) => {
    const newTempData = tableData;
    const objIndex = tableData?.findIndex((obj) => obj.delete === value.delete);
    if (newTempData && objIndex) {
      newTempData[objIndex] = {
        school_id: value.school_id,
        school_name: value.school_name,
        email_id: value.email_id,
        language_choice: value.language_choice,
        vat_number: value.vat_number,
        contact_name: value.contact_name,
        optional_note: value.optional_note,
        password: value.password,
        zipcode: value.zipcode,
        city: value.city,
      };
    }
    settableData(newTempData);
    console.log(value);

    manageSchool({
      variables: {
        school: {
          school_id: value.delete ? Number(value.delete) : 0,
          school_name: value.school_name ? value.school_name : " ",
          email_id: value.email_id,
          language_choice: value.language_choice,
          vat_number: value.vat_number ? Number(value.vat_number) : 0,
          contact_name: value.contact_name ? value.contact_name : " ",
          optional_note: value.optional_note ? value.optional_note : " ",
          password: value.password,
          zipcode: value.zipcode ? Number(value.zipcode) : 0,
          city: value.city ? value.city : " ",
          skip_bill_check: value.skip_bill_check ? value.skip_bill_check : false,
        },
      },
    }).then((data) => {
      if (typeof data.data?.manageSchool === "number") {
        refetch();
      } else {
        alert("Der gik noget galt, kontakt udviklere");
      }
    });
  };

  const columns: any[] = [
    {
      name: "delete",
      label: isNewShown ? "Slet" : "Aktiver",
      options: {
        customBodyRender: (value) => {
          return (
            <Button onClick={() => onDelete(value)}>
              {isNewShown ? <DeleteRoundedIcon /> : <DoneIcon />}
            </Button>
          );
        },
        customBodyEditRender: (value, tableMeta, update) => (
          <Button disabled>
            <DeleteRoundedIcon />
          </Button>
        ),
      },
    },
    { name: "school_name", label: "Skole-navn" },
    { name: "email_id", label: "Email" },
    { name: "vat_number", label: "VAT-nummer" },
    { name: "contact_name", label: "Kontakt" },
    { name: "password", label: "Kodeord" },
    { name: "zipcode", label: "ZIP" },
    { name: "city", label: "By" },
    { name: "optional_note", label: "Note" },
    {
      name: "skip_bill_check",
      label: "Spring-over",
      options: {
        customBodyRender: (value) => {
          return <>{value ? <DoneIcon /> : <CloseIcon />}</>;
        },
        customBodyEditRender: (value, tableMeta, update) => (
          
          <Checkbox checked={value} onChange={e => update(Boolean(e.target.value))}/>
        ),
      },
    },
  ];

  if (!tableData) {
    return <Loading />;
  }
  return (
    <div className="AdminSchoolView">
      {intermediaryLoading || schoolsLoading ? <IntermediaryLoading /> : null}
      <div className="topButtons">
        <Button onClick={() => setisNewShown(!isNewShown)}>
          {isNewShown ? "Vis slettede" : "Vis aktive"}
        </Button>
        <Button onClick={() => setcreatingNewTeacher(true)}>
          Tilføj ny skole
        </Button>
      </div>
      <div className="tableBox">
        <MuiDataTableEditable
          title={"Skole liste"}
          columns={columns}
          data={tableData}
          options={{
            rowsPerPage: 15,
            responsive: "standard",
            selectableRows: "none",
            expandableRows: true,
            expandableRowsHeader: false,
            isRowExpandable: () => true,
            renderExpandableRow: (rowData, rowMeta) => {
              console.log(rowData[1].props.tableMeta.rowData);

              const colSpan = rowData.length + 1;
              return (
                <TableRow>
                  <TableCell>
                    <CategoryCell
                      school_id={Number(rowData[1].props.tableMeta.rowData[1])}
                    />
                  </TableCell>
                  <TableCell colSpan={colSpan}>
                    <SubuserCell
                      school_id={Number(rowData[1].props.tableMeta.rowData[1])}
                    />
                  </TableCell>
                </TableRow>
              );
            },
            onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
              console.log(curExpanded, allExpanded, rowsExpanded),
          }}
          onRowSave={onRowSave}
        />
      </div>
      {creatingNewTeacher ? (
        <AddSchoolBox setcreatingNewTeacher={setcreatingNewTeacher} />
      ) : null}
    </div>
  );
};

export { AdminSchoolView };
