import React, { useEffect, useState } from "react";
import IntermediaryLoading from "../../../../../globalComponents/IntermediaryLoading";
import Loading from "../../../../../globalComponents/Loading";
import {
  Slide_Progress,
  Statement_Grade,
  useFinishQuizMutation,
  useGradeSlideMutation,
} from "../../../../../graphql/generated/graphqlStudent";
import { MediaType, StudentQuiz, StudentQuizSlide } from "../../../../model";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "./Main.scss";
import { Slide } from "./Slide";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import watch from "../../../../../assets/ur.png";
import icon from "../../../../../assets/iconForTest.png";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//A RANDOM FUNCTION FOR SHOWING MINUTES
function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  let digitalDisplay = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  digitalDisplay += (mins === 0 ? "mindre end 1" : mins) + " min"

  return digitalDisplay;
}

export type IMainProps = {
  QuizState: StudentQuiz;
  setQuizState: React.Dispatch<React.SetStateAction<StudentQuiz | undefined>>;
  isLearningMode: boolean;
  isNoSound: boolean;
  hasSubtitles: boolean;
  setToEnd: () => void;
  manageAchievement: any;
  languageID: number;
  
};

const Main: React.FC<IMainProps> = (props: IMainProps) => {
  const [currentCount, setcurrentCount] = useState(0);
  const [isVideo, setisVideo] = useState();

  const [time, settime] = useState<number>(1500);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (time === 0 && props.isNoSound) {
        finishQuizMethod();
        clearInterval(intervalId);
      } else {
        settime((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [time, props.isNoSound]);

  const [isPlaying, setisPlaying] = useState(false);

  const [gradeSlide] = useGradeSlideMutation();
  const [finishQuiz] = useFinishQuizMutation();
  const [isFinishLoading, setisFinishLoading] = useState(false);

  //WE PRELOAD THE NEXT IMAGE HERE
  useEffect(() => {
    if (props.QuizState.slides[currentCount + 1]) {
      if (
        props.QuizState.slides[currentCount + 1].mediaType ===
        MediaType.AZUREIMG
      ) {
        new Image().src = props.QuizState.slides[currentCount + 1].media;
      }
      if (props.QuizState.slides[currentCount + 1]) {
        props.QuizState.slides[currentCount + 1].statements.forEach(
          (statement) => {
            new Audio().src = statement.soundMedia;
          }
        );
      }
    }
  }, [currentCount]);

  function gradeSlidetoAPI(slide: StudentQuizSlide) {
    let gradedSlide: Slide_Progress;
    const gradedStatements: Statement_Grade[] = [];
    for (const statement of slide.statements) {
      if (statement.answer) {
        if (statement.correctAnswer === 3) {
          gradedStatements.push({
            statement_progress_id: String(statement.progressID),
            slide_pass_status: true,
            statement_progress_answer: statement.answer,
          });
        } else {
          if (statement.correctAnswer === statement.answer) {
            gradedStatements.push({
              statement_progress_id: String(statement.progressID),
              slide_pass_status: true,
              statement_progress_answer: statement.answer,
            });
          } else {
            gradedStatements.push({
              statement_progress_id: String(statement.progressID),
              slide_pass_status: false,
              statement_progress_answer: statement.answer,
            });
          }
        }
      } else {
        gradedStatements.push({
          statement_progress_id: String(statement.progressID),
          slide_pass_status: false,
          statement_progress_answer: statement.answer,
        });
      }
    }
    gradedSlide = {
      progress_id: String(slide.id),
      statement_progress: gradedStatements,
    };
    return gradedSlide;
  }

  const handleNextSlide = () => {
    const newSlide = gradeSlidetoAPI(props.QuizState?.slides[currentCount]);
    let retryCount = 0;

    if (currentCount < props.QuizState.slides.length - 1) {
      if (!props.isLearningMode) {
        gradeSlide({ variables: { gradedSlide: newSlide } });
      }
      setcurrentCount(currentCount + 1);
    } else {
      setisFinishLoading(true);
      if (!props.isLearningMode) {
        gradeSlide({ variables: { gradedSlide: newSlide } }).then(async () => {
          await finishQuizMethod();
        });
      } else {
        finishQuizMethod();
      }
    }
  };

  const learningModeGradeSlide = () => {
    if (!props.isLearningMode) {
      return;
    }
    const newSlide = gradeSlidetoAPI(props.QuizState?.slides[currentCount]);
    gradeSlide({ variables: { gradedSlide: newSlide } });
  };

  const finishQuizMethod = async () => {
    setisFinishLoading(true);
    return await finishQuiz({
      variables: {
        online_quiz_id: localStorage.getItem("STUDENT_ONLINE_QUIZ"),
        online_quiz_token: localStorage.getItem("STUDENT_ONLINE_QUIZ_TOKEN"),
      },
    }).then((data) => {
      if (data.data?.finishQuiz) {
        props.manageAchievement(
          data.data?.finishQuiz?.quiz_pass_status,
          data.data.finishQuiz.slides_failed !== 0 ? true : false,
          Number(localStorage.getItem("STUDENT_ONLINE_QUIZ"))
        );
        props.setToEnd();
      } else {
        return "error";
      }
    });
  };
  const handlePreviousSlide = () => {
    if (currentCount > 0) {
      setcurrentCount(currentCount - 1);
    }
  };


// Inside your component
const [open, setOpen] = useState(false);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const handleProceed = () => {
  // Place your finishQuizMethod logic here
  finishQuizMethod();
  setOpen(false);
};


  const handleSetAnswer = (statementNumber: number, answer: number) => {
    props.setQuizState((prevState) => {
      const copyState = prevState;
      if (copyState) {
        copyState.slides[currentCount].statements[statementNumber - 1].answer =
          answer;
        return copyState;
      }
    });
  };
  //<div className="countdown">{fancyTimeFormat(time)}</div> : null
  
  return (
    <>
      {props.isNoSound ? (
        <div className="DigitalTestHeader">
          <div className="logoNName">
            <div className="newLogo">
              <img src={icon}></img>
            </div>
            <div className="name">Easypiecy Teoritest</div>
          </div>
          <div className="time">
            <div className="newLogo">
              <img src={watch}></img>
            </div>
            <div className="timeShow">{fancyTimeFormat(time)}</div>
          </div>
          <Button className="finishDigitalButton" onClick={handleClickOpen}>
            <CloseRoundedIcon className="failed" />
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Afslut teoritest?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Er du sikker på du vil afslutte teoritesten før tid?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Annuller
              </Button>
              <Button onClick={handleProceed} color="primary" autoFocus>
                Afslut test
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      {props.isNoSound ? null : (
        <div className="counter">
          {currentCount + 1 + " : " + props.QuizState.slides.length}
        </div>
      )}
      {props.isNoSound ? null : (
        <Button className="finishNowButton" onClick={finishQuizMethod}>
          <CloseRoundedIcon
            className="failed"
            style={{ color: props.isLearningMode ? "#1d1e20" : "#ffffff" }}
          />
        </Button>
      )}
      <Slide
        isLearningMode={props.isLearningMode}
        isNoSound={props.isNoSound}
        hasSubtitles={props.hasSubtitles}
        key={currentCount}
        slide={props.QuizState?.slides[currentCount]}
        indexPlayer={props.QuizState.indexPlayer}
        soundPlayer={props.QuizState.soundPlayer}
        handleNextSlide={handleNextSlide}
        handlePreviousSlide={handlePreviousSlide}
        handleSetAnswer={handleSetAnswer}
        speed={props.QuizState.speed}
        learningModeGradeSlide={learningModeGradeSlide}
        languageID={props.languageID}
        isPlaying={isPlaying}
        setisPlaying={setisPlaying}
      />
      {props.isNoSound ? (
        <div className="DigitalNextButtons">
          {
            <Button
              variant="contained"
              onClick={handlePreviousSlide}
              className={currentCount === 0 ? "hidden" : ""}
            >
              <ArrowBackIcon />
              <div className="textContainer">Forrige</div>
            </Button>
          }
          <div className="status">
            {"Spørgsmål " +
              (currentCount + 1) +
              " ud af " +
              props.QuizState.slides.length}
          </div>
          <Button variant="contained" onClick={handleNextSlide}>
            <div className="textContainer">Næste</div>
            <ArrowForwardIcon />
          </Button>
        </div>
      ) : null}
      {isFinishLoading ? <IntermediaryLoading /> : null}
    </>
  );
};

export { Main };
