import React, { FC, useEffect, useRef } from "react";
import { INPUT_TAG_NAME } from "../constants/viewConstants";
import { getNextSiblingsFromRefObject, getPreviousSiblingsFromRefObject } from "../services/utilFunctions";

export enum InputType {
	Text = "text",
	Number = "number",
	Password = "password"
}

export interface SingleCharInputProps {
	ordinalNumber: number;
	handleKeyPress: any;
	isFirstInRow: boolean;
	inputType: InputType;
	placeholder: string;
	value: number | string;
	handleSetValue: (value: string, index: number) => void;
	className: string;
}

export const SingleCharInput: FC<SingleCharInputProps> = (props: SingleCharInputProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (props.isFirstInRow) {
			inputRef.current?.focus();
		}
	}, []);

	/**
	 * Function is finding sibling of input with current actions and it is is input it call focus action otherwise
	 * it finds next html element which is type input
	 * When there are no more nextSibling which are inputs the focus chain is closed
	 */
	const focusNextInput = (): void => {
		let input: HTMLInputElement = getNextSiblingsFromRefObject<HTMLInputElement, HTMLInputElement>(inputRef);
		while (!(input?.tagName === INPUT_TAG_NAME || input === null)) {
			input = input?.nextSibling as HTMLInputElement;
		}
		input?.focus();
	};

	const focusFirstInput = (): void => {
		let input: HTMLInputElement = getPreviousSiblingsFromRefObject<HTMLInputElement, HTMLInputElement>(inputRef);
		while (!(input?.tagName === INPUT_TAG_NAME || input === null)) {
			input = input?.previousSibling as HTMLInputElement;
		}
		const parent = input?.parentElement;
		if (parent) {
			if (parent.firstChild) {
				input = parent.firstChild as HTMLInputElement;

				input.focus();
			}
		}
	};

	const handleKeyPress = (event) => {
		if (event.keyCode === 8) {
			focusFirstInput();
			props.handleKeyPress(event);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {

		if (event.target.value !== "") {
			focusNextInput();
		}
		if (event.target.value) {
			const substitute = event.target.value.split("");
			if (substitute.length > 1) {
				substitute.shift();
			}

			props.handleSetValue(substitute[0].toUpperCase(), props.ordinalNumber);
		} else {
			props.handleSetValue(event.target.value.toUpperCase(), props.ordinalNumber);
		}
	};

	return (
		<input
			className={props.className}
			ref={inputRef}
			type={props.inputType}
			maxLength={2}
			placeholder={props.placeholder}
			value={props.value}
			onChange={handleChange}
			onKeyUp={handleKeyPress}
		/>
	);
};

export default SingleCharInput;
