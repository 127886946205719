import React from "react";
import "./style/IntermediaryLoading.scss";
import customLoader from "../assets/customLoader.gif";
import customLoader24 from "../assets/customLoader24fps.gif";

export type IIntermediaryLoadingProps = {};

const IntermediaryLoading: React.FC<IIntermediaryLoadingProps> = ({}) => {
  return (
    <div className="opaqueContainer">
      <div className="loadingElement"><img src={customLoader24}/></div>
    </div>
  );
};

export default IntermediaryLoading;
