import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ClearIcon from "@mui/icons-material/Clear";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import "./Image.scss";

export type IImageProps = {
  key: string;
  image: string;
  isNoSound?: boolean;
};

const Image: React.FC<IImageProps> = (props: IImageProps) => {
  const [isZoomed, setisZoomed] = useState(false);

  const handleSetZoom = () => {
    if (props.isNoSound) {
      setisZoomed(!isZoomed);
    }
  };

  return (
    <>
      {isZoomed ? (
        <div className="zoomedPic" onClick={() => handleSetZoom()}>
          <Button className="close" onClick={() => handleSetZoom()}>
            <ClearIcon />
          </Button>
          <TransformWrapper maxScale={2}>
            <TransformComponent
              wrapperClass="zoomProvider"
              contentClass="zoomContent"
            >
              <img src={props.image}></img>
            </TransformComponent>
          </TransformWrapper>
        </div>
      ) : null}
<div onClick={() => handleSetZoom()} className="imageWrapper">
<img src={props.image} alt="SLIDE" />
{props.isNoSound && (
  <div className="expandIcon">
    <ZoomOutMapIcon />
  </div>
)}


</div>

    </>
  );
};

export { Image };
