import React, { FC, useEffect, useState } from "react";
import { getMergedStringsFromArray } from "../services/utilFunctions";
import { InputType, SingleCharInput } from "./SingleCharInput";

export interface VerificationInputProps {
	setCode: (value: string | ((prevState: string) => string)) => void;
	VerificationInputState: string[];
	setVerificationInputState: (value: string[] | ((prevState: string[]) => string[])) => void;
	className: string;
}

export const VerificationInput: FC<VerificationInputProps> = (props: VerificationInputProps) => {
	const emptyInputPlaceholder = "_";

	useEffect(() => {
		props.setCode(getMergedStringsFromArray(props.VerificationInputState));
	}, [props, props.VerificationInputState]);

	const updateCodeItem = (value: string | number, index: number): void => {
		const VerificationInputStateCopy = [...props.VerificationInputState];
		VerificationInputStateCopy[index] = value.toString();
		props.setVerificationInputState(VerificationInputStateCopy);
	};

	const handleKeyPress = (event) => {
		if (event.keyCode === 8) {
			props.setVerificationInputState(["", "", "", "", "", ""]);
		}
	};

	const renderSingleCharacterInputs = (): JSX.Element[] => {
		const inputs: JSX.Element[] = [];

		for (let index = 0; index < 6; index++) {
			if (index === 6 / 2) {
				inputs.push(
					<>
						<span key="separator"> - </span>
					</>,
				);
			}

			inputs.push(
				<SingleCharInput
					className={props.className}
					key={index}
					handleKeyPress={handleKeyPress}
					ordinalNumber={index}
					isFirstInRow={index === 0}
					inputType={InputType.Text}
					placeholder={emptyInputPlaceholder}
					value={props.VerificationInputState[index]}
					handleSetValue={updateCodeItem}
				/>,
			);
		}

		return inputs;
	};

	return <>{renderSingleCharacterInputs()}</>;
};

export default VerificationInput;