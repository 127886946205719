const SLASH = "/";
const LOGIN = "login";
const DASHBOARD = "dashboard";
const CERTIFICATE_CONFIGURATION = "certificate-configuration";
const USER_GUIDE = "user-guide";
const CERTIFICATE_PREFIX = "certificate";
const USER_SCREEN = "user-screen";
const QUIZ_MODULE = "quiz-module";
const STUDENTS = "students";
const TEACHER = "teacher";
const CERT_ID = "id";
const COLON = ":";
const TEST = "test";
const LESSON = "lesson"
const QUIZ = "quiz"
const PROFILE = "profile"
const CREATE = "create";
const STUDENT = "student"
const SKOLE = "skole"
const ELEV = "elev"


export const PATH_HOME: string = SLASH;
export const PATH_LOGIN: string = SLASH + LOGIN;
export const PATH_DASHBOARD: string = SLASH + DASHBOARD;
export const PATH_CERTIFICATE_CONFIGURATION: string = SLASH + CERTIFICATE_CONFIGURATION;
export const PATH_LESSON: string = SLASH + LESSON;
export const PATH_CREATE_LESSON: string = SLASH + CREATE + LESSON;
export const PATH_TEACHER_LESSON: string = SLASH + TEACHER + LESSON;
export const PATH_QUIZ: string = SLASH + QUIZ;
export const PATH_ELEV: string = SLASH + ELEV;
export const PATH_ELEV_AI: string = SLASH + ELEV + "AI"
export const PATH_USER_GUIDE: string = SLASH + USER_GUIDE;
export const PATH_TEST: string = SLASH + TEST;
export const PATH_STUDENTS: string = SLASH + STUDENTS;
export const PATH_TEACHER_STUDENT: string = SLASH + TEACHER + STUDENT;
export const PATH_STUDENT_TEACHER: string = SLASH + STUDENT + TEACHER;
export const PATH_STUDENT_TEACHER_AI: string = SLASH + STUDENT + TEACHER + "AI";
export const PATH_SKOLE: string  = SLASH + SKOLE;
export const PATH_PROFILE: string = SLASH + PROFILE